import { FC } from "react";
import { Icon, IconName } from "Components/UI";
import { GetAvatar, ThumbType } from "Utils/generateThumbnail";
import Tooltip from "Components/UI/Tooltip";
import { BtnContainer, Container } from "./styles";

interface IItem {
  ID: string;
  username: string;
  userAvatar?: string;
  userAvatarThumb?: string;
  userAvatarThumbType?: ThumbType;
  privileges: number[];
  hasPlottingPermission?: boolean;
  loading?: boolean;
  onChange: (values: any) => void;
}

const buttonsPermission = ['Visualizar', 'Download', 'Upload', 'Editar'];

const Item: FC<IItem> = ({
  ID,
  username,
  userAvatar,
  userAvatarThumb,
  userAvatarThumbType,
  privileges,
  hasPlottingPermission,
  loading,
  onChange,
}) => {
  const changeHasPlottingPermission = () => {
    onChange({
      privileges,
      ID,
      hasPlottingPermission: !hasPlottingPermission
    });
  };

  const changePermissionLevel = (level: number) => {
    if (level !== 0) {
      const permissionIndex = privileges.indexOf(level);
      permissionIndex >= 0
        ? privileges.splice(permissionIndex, 1)
        : privileges.push(level);
      onChange({
        ID,
        hasPlottingPermission,
        privileges
      });
    }
  };

  return (
    <Container>
      <div className="avatarContainer">
        <GetAvatar
          name={username}
          shape='circle'
          src={userAvatar}
          thumbSrc={userAvatarThumb}
          thumbType={userAvatarThumbType}
        />
        <span className="userName">
          {username}
        </span>
      </div>
      <div className="actionContainer">
        {buttonsPermission.map((name, i) => (
          <Tooltip title={name} key={name}>
            <BtnContainer
              hasprivileges={privileges.includes(i)}
              isdisabled={loading}
              onClick={() => loading ? undefined : changePermissionLevel(i)}
            >
              <Icon
                icon={name.toLowerCase() as IconName}
                customSize={20}
                className="iconPermissionBtn"
              />
            </BtnContainer>
          </Tooltip>
        ))}
        <Tooltip title="Plotagem">
          <BtnContainer
            hasprivileges={hasPlottingPermission}
            isdisabled={loading}
            onClick={loading ? undefined : changeHasPlottingPermission}
          >
            <Icon
              icon="menuPlotagem"
              customSize={20}
              className="iconPermissionBtn"
            />
          </BtnContainer>
        </Tooltip>
      </div>
    </Container>
  )
}

export default Item;
