import React, { FC } from "react";

import styles from "./LoadingArchives.module.scss";

import SkeletonMain from "../LoadingMain";
import ColumnHeader from "Components/UI/Table/ColumnHeader";

export interface ILoadingArchives {
  multiple?: number;
}

const LoadingArchives: FC<ILoadingArchives> = ({ multiple = 1 }) => {
  const skeletonObra = {
    render: (index: number) => {
      return (   
        <tr key={index.toString()} className={styles['rowTable']}>
          <td className={styles['tdCheckLoad']}>
            <span className={styles['rowCheckLoad']}>
              <SkeletonMain width="16px" height="16px" borderRadius="4px" />
            </span>
          </td>
          <td>
            <span className={styles['rowName']}>
              <SkeletonMain width="16px" height="16px" borderRadius="4px" marginLeft="12px" minWidth="16px"/>
              <SkeletonMain width="100%" height="12px" borderRadius="2px" marginLeft="12px" minWidth="146px" />
            </span>
          </td>
          <td style={{ width: '100px' }}>
            <SkeletonMain width="100%" height="12px" borderRadius="2px" />
          </td>
          <td  style={{ width: '125px' }}>
            <SkeletonMain width="100%" height="12px" borderRadius="2px" />
          </td>
          <td  style={{ width: '150px' }}>
            <SkeletonMain width="100%" height="12px" borderRadius="2px" />
          </td>
          <td  style={{ width: '130px' }}>
            <SkeletonMain width="100%" height="12px" borderRadius="2px" />
          </td>
          <td  style={{ width: '150px' }}>
            <SkeletonMain width="100%" height="12px" borderRadius="2px" />
          </td>
          <td style={{ width: '100px', paddingRight: '8px' }}>
            <SkeletonMain width="100%" height="12px" borderRadius="2px" />
          </td>
        </tr>
      )
    }
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(skeletonObra);
  }

  return (
    <>
      <table className={styles['renderTable']}>
        <thead className={styles['headTable']}>
          <tr>
            <th className={styles['rowCheck']}>
              <span>
                <input className={styles['checkbox']} type="checkbox" disabled />
              </span>
            </th>
            <th>
              <ColumnHeader
                title="NOME"
              />
            </th>
            <th style={{ width: '100px' }}>
              <ColumnHeader
                title="TIPO"
                showFilter={()=>{}}
              />
            </th>
            <th style={{ width: '125px' }}>
              <ColumnHeader
                title="TAMANHO"
              />
            </th>
            <th style={{ width: '150px' }}>
              <ColumnHeader
                title="VISUALIZAÇÃO"
              />
            </th>
            <th  style={{ width: '130px' }}>
              <ColumnHeader
                title="VERSÃO"
                showFilter={()=>{}}
              />
            </th>
            <th  style={{ width: '150px' }}>
              <ColumnHeader
                title="MODIFICADO"
                showFilter={()=>{}}
              />
            </th>
            <th  style={{ width: '100px', paddingRight: '8px' }}>
              <ColumnHeader
                title="STATUS"
                showFilter={()=>{}}
              />
            </th>
          </tr>
        </thead>
        
        <tbody className={styles['rowTable']}>
          {multipleSkeletonsObras.map((skeleton, index) => skeleton.render(index))}
        </tbody>
      </table>
    </>
  )
}

export default LoadingArchives;