import { Http } from "Utils/Http";
import { AxiosResponse } from "axios";
import { IStorePlanResponse } from "./interfaces/Store/IStorePlanResponse";
import { IStorePlanDeleteRequest, IStorePlanRequest } from "./interfaces/Store/IStorePlanRequest";
import { ITenantAdm } from "./interfaces/Tenant/ITenantAdm";
import { IUserStoreItemRequest } from "./interfaces/Store/IUserStoreItemRequest";
import { IPurchaseHistoriesRequest } from "./interfaces/Store/IPurchaseHistoriesRequest";
import { ITenantConfirmUpdatePlanRequest } from "./interfaces/Tenant/ITenantConfirmUpdatePlanRequest";
import { IUpdateTenantAdminRequest } from "./interfaces/Tenant/IUpdateTenantAdminRequest";

export class AdmApi {
  static async listPlans(): Promise<AxiosResponse<IStorePlanResponse[]>> {
    const axios = await Http.axios();
    return axios.get('/StorePlan/List');
  }

  static async getPlan(planId: number): Promise<AxiosResponse<IStorePlanResponse>> {
    const axios = await Http.axios();
    return axios.get(`/StorePlan/Get/${planId}`);
  }

  static async createOrUpdatePlan(plan: IStorePlanRequest): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    if(plan.StorePlansId) {
      return axios.put(`/StorePlan/Update/${plan.StorePlansId}`, plan)
    }
    return axios.post('/StorePlan/Create', plan);
  }

  static async deletePlan(plan: IStorePlanDeleteRequest): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    return axios.delete(`/StorePlan/Delete/${plan.planId}`)
  }

  static async listTenants(): Promise<AxiosResponse<ITenantAdm[]>> {
    const axios = await Http.axios();
    return axios.get('/Adm/ListTenants');
  }
  
  static async updateTenant(tenant: IUserStoreItemRequest): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    return axios.put(`/Adm/UpdateUserStoreItem/${tenant.UserStoreItemsId}`, tenant);
  }

  static async createOrUpdateTenantPayment(tenant: IPurchaseHistoriesRequest): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    if(tenant.PurchaseHistoryId) {
      return axios.put(`/Adm/UpdatePurchase/${tenant.PurchaseHistoryId}`, tenant)
    }
    return axios.post('/Adm/AddPurchase', tenant);
  }

  static async getBillingData(userAdminId: string): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    return axios.get(`/Adm/GetBillingData/${userAdminId}`);
  }

  static async confirmUpdatePlan(tenantUpdatePlan: ITenantConfirmUpdatePlanRequest): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    return axios.post(`/Adm/ConfirmUpdateStorePlan?userStoreItemId=${tenantUpdatePlan.userStoreItemId}&isApproved=${tenantUpdatePlan.isApproved}`);
  }

  static async listTenantUsers(id: number): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    return axios.get(`/Adm/ListTenantUsers?tenantId=${id}`);
  }

  static async updateTenantAdmin(tenantUpdateAdmin: IUpdateTenantAdminRequest): Promise<AxiosResponse<object>> {
    const axios = await Http.axios();
    return axios.patch('/Adm/UpdateTenantAdminUser', tenantUpdateAdmin);
  }
}
