import { FC } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import usePanels from './usePanels';
import SignalR from 'Components/UI/SignalR';
import TabsShell, { PathActiveEnum } from 'Components/UI/TabsShell';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import SimpleViewerComponent from 'Components/UI/SimpleViewerComponent';
import Header from './components/Header';
import LoadingRender from './components/LoadingRender';
import Image from './components/Image';
import { extensionsImage } from 'Utils/ViewerUtils';
import DropboxFolderEmbed from './components/DropboxFolderEmbed';
import { Container } from './styles';

interface IPanels {
  match: any;
}

const Panels: FC<Props> = ({
  match,
  fixedPanels,
  notFixedPanels,
  isLoading,
  panelsFileInfo,
  fileViewerAutodesk,
  userInfo,
  obrasList,
  globalLoading,
  currentTenant,
}) => {
  const {
    viewerGoogle,
    panel,
    hasDownloadPermission,
    activeSrcTypeOption,
    stringContent,
    isViewerRender,
    isInternalItem,
    isDropboxType,
    buttons,
    activeListButton,
    handleActiveListButton,
    handleOpenFolder,
    handleDownload,
  } = usePanels({
    panelId: match.params.panelId,
    csId: Number(match.params.constructionSiteId),
    fixedPanels,
    notFixedPanels,
    isLoading,
    panelsFileInfo,
    fileViewerAutodesk,
    userInfo,
    obrasList,
    globalLoading,
    currentTenant,
  });

  return (
    <>
      <SignalR />
      <TabsShell
        ConstructionSiteId={match.params.constructionSiteId}
        active={PathActiveEnum.panels}
        loading={isLoading}
        match={match}
        panelName={panel?.Name}
      />
      <Container>
        {isInternalItem && panelsFileInfo && activeSrcTypeOption && (
          <>
            <Header
              file={panelsFileInfo}
              buttons={buttons}
              activeListButton={activeListButton}
              onActiveListButton={handleActiveListButton}
              onOpenFolder={handleOpenFolder}
            />
            {isViewerRender && (
              <>
                {(
                  (panelsFileInfo.extension === 'pdf' && !panelsFileInfo.bucketFileName) ||
                  ((panelsFileInfo.extension !== 'pdf') && (
                  (panelsFileInfo?.isRendering && (fileViewerAutodesk?.etapa ?? 0) < 4) ||
                  (!panelsFileInfo?.isRendering && (fileViewerAutodesk?.etapa ?? 0) === 5) ||
                  (panelsFileInfo?.fileRenderStatus === null && (fileViewerAutodesk?.etapa ?? 0) !== 5)))
                ) ? (
                  <LoadingRender fileViewerAutodesk={fileViewerAutodesk} />
                ) : (
                  <SimpleViewerComponent fileInfo={panelsFileInfo} />
                )}
              </>
            )}
            {!isViewerRender && (
              <>
                {extensionsImage.includes(panelsFileInfo.extension) && (
                  <Image
                    src={`${process.env.REACT_APP_BASE_API_URL}/Viewer/FileViewer?fileInfoViewer=${panelsFileInfo.fileViewer}`}
                    onDownload={handleDownload}
                  />
                )}
                {!extensionsImage.includes(panelsFileInfo.extension) && (
                  <>
                    {panelsFileInfo.extension === 'pdf' && (
                      <iframe
                        title="pdfViewer"
                        width="100%"
                        height="100%"
                        src={`${process.env.REACT_APP_BASE_API_URL}/Viewer/FileViewer?fileInfoViewer=${panelsFileInfo.fileViewer}${hasDownloadPermission ? '' : '#toolbar=0'}`}
                      />
                    )}
                    {panelsFileInfo.extension !== 'pdf' && (
                      <iframe
                        title="pdfViewer"
                        width="100%"
                        height="100%"
                        src={`${viewerGoogle}${process.env.REACT_APP_BASE_API_URL}/Viewer/FileViewer?fileInfoViewer=${panelsFileInfo.fileViewer}`}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        {!isInternalItem && activeSrcTypeOption && (
          <>
            {isDropboxType && (
              <DropboxFolderEmbed link={stringContent} />
            )}
            {!isDropboxType && (
              <>
                {activeSrcTypeOption === 'html' && stringContent && (
                  <iframe
                    title={panel?.Name}
                    srcDoc={stringContent}
                    width="100%"
                    height="100%"
                    referrerPolicy="no-referrer"
                    sandbox="allow-scripts"
                  />
                )}
                {activeSrcTypeOption === 'url' && stringContent && (
                  <iframe
                    title={panel?.Name}
                    src={stringContent}
                    width="100%"
                    height="100%"
                  />
                )}
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.panels,
  userInfo: state.auth.userInfo,
  obrasList: state.obra.ObrasList,
  globalLoading: state.activities.externalGlobalLoading,
  currentTenant: state.tenant.currentListTenant,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IPanels;

export default connector(Panels);
