import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { IListFilesRequest } from '../../Data/interfaces/ListagemArquivos/IListFilesRequest';
import {
  IListFilesResponse,
  IFileData,
  IRenderingSignalR,
  IRenameFileResponse,
} from '../../Data/interfaces/ListagemArquivos/IListFilesResponse';
import { IDownloadFilesRequest, IProgressDownloading } from '../../Data/interfaces/Obra/IDownloadFilesRequest';
import { IUpdateFileInfoRequest } from '../../Data/interfaces/Obra/IUpdateFileInfoRequest';
import { IFileRenameData } from '../../Data/interfaces/ListagemArquivos/IFileRenameRequest';
import { ICreateFolderRequest } from '../../Data/interfaces/Obra/ICreateFolderRequest';
import { IDeleteFilesRequest } from '../../Data/interfaces/Obra/IDeleteFilesRequest';
import { IMoveFileRequest } from '../../Data/interfaces/ListagemArquivos/IMoveFileRequest';
import { IUnarchiveFilesRequest } from '../../Data/interfaces/Obra/IUnarchiveFilesRequest';
import { IAutodeskViewer } from '../../Data/interfaces/Viewer/IAutodeskViewer';
import { IVerifyAutodeskProgress } from '../../Data/interfaces/ListagemArquivos/IVerifyAutodeskProgress';
import { ISignalRArchive } from 'Data/interfaces/Upload/IUploadFileData';
import { ICurrentUserPrivileges } from 'Data/interfaces/User/ICurrentUserPrivileges';
import { IObraResponse } from 'Data/interfaces/Obra/IObraListResponse';

export enum ListagemArquivosActionKeys {
  LIST_FILES_REQUEST = 'LIST_FILES_REQUEST',
  LIST_FILES_SUCCESS = 'LIST_FILES_SUCCESS',
  LIST_FILES_FAILED = 'LIST_FILES_FAILED',

  UPDATE_FILE_LIST = 'UPDATE_FILE_LIST',

  ADD_OBRA_CHUNCK_DATA = 'ADD_OBRA_CHUNCK_DATA',

  DOWNLOAD_FILES_REQUEST = 'DOWNLOAD_FILES_REQUEST',
  DOWNLOAD_FILES_SUCCESS = 'DOWNLOAD_FILES_SUCCESS',
  DOWNLOAD_FILES_FAILED = 'DOWNLOAD_FILES_FAILED',
  PROGRESS_DOWNLOAD = 'PROGRESS_DOWNLOAD',

  DELETE_FILES_REQUEST = 'DELETE_FILES_REQUEST',
  DELETE_FILES_SUCCESS = 'DELETE_FILES_SUCCESS',
  DELETE_FILES_FAILED = 'DELETE_FILES_FAILED',

  RENAME_FILE_REQUEST = 'RENAME_FILE_REQUEST',
  RENAME_FILE_SUCCESS = 'RENAME_FILE_SUCCESS',
  RENAME_FILE_FAILED = 'RENAME_FILE_FAILED',

  UPDATE_FILE_INFO_REQUEST = 'UPDATE_FILE_INFO_REQUEST',
  UPDATE_FILE_INFO_SUCCESS = 'UPDATE_FILE_INFO_SUCCESS',
  UPDATE_FILE_INFO_FAILED = 'UPDATE_FILE_INFO_FAILED',

  CREATE_FOLDER_REQUEST = 'CREATE_FOLDER_REQUEST',
  CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS',
  CREATE_FOLDER_FAILED = 'CREATE_FOLDER_FAILED',

  SET_EDIT_FORM_INDEX = 'SET_EDIT_FORM_INDEX',

  SET_SHOW_EDIT_TABLE = 'SET_SHOW_EDIT_TABLE',

  SET_SHOW_PLOT_REQUEST_TABLE = 'SET_SHOW_PLOT_REQUEST_TABLE',

  SET_SHOW_MODAL_DELETE_FILES = 'SET_SHOW_MODAL_DELETE_FILES',

  SET_SHOW_MODAL_CREATE_FOLDER = 'SET_SHOW_MODAL_CREATE_FOLDER',

  SET_SHOW_MODAL_INFO_FILES = 'SET_SHOW_MODAL_INFO_FILES',

  SET_SHOW_MODAL_DOWNLOAD_MULTIPLE_FILES = 'SET_SHOW_MODAL_DOWNLOAD_MULTIPLE_FILES',

  TOOGLE_SHOW_FILTER_TIPO = 'TOOGLE_SHOW_FILTER_TIPO',
  TOOGLE_SHOW_FILTER_VISUALIZATION = 'TOOGLE_SHOW_FILTER_VISUALIZATION',
  TOOGLE_SHOW_FILTER_STATUS = 'TOOGLE_SHOW_FILTER_STATUS',
  TOOGLE_SHOW_FILTER_MODIFICADO = 'TOOGLE_SHOW_FILTER_MODIFICADO',
  TOOGLE_SHOW_FILTER_VERSIONS = 'TOOGLE_SHOW_FILTER_VERSIONS',
  TOOGLE_SHOW_FILTER_MODIFICADO_DATA = 'TOOGLE_SHOW_FILTER_MODIFICADO_DATA',
  TOOGLE_SHOW_FILTER_MODIFICADO_USERS = 'TOOGLE_SHOW_FILTER_MODIFICADO_USERS',

  SET_SELECTED_EDIT_ROW_KEYS = 'SET_SELECTED_EDIT_ROW_KEYS',

  SET_SELECTED_PLOT_REQUEST_ROW_KEYS = 'SET_SELECTED_PLOT_REQUEST_ROW_KEYS',

  ADD_NEW_FILE = 'ADD_NEW_FILE',

  LIST_FILES_MOVE_TO_REQUEST = 'LIST_FILES_MOVE_TO_REQUEST',
  LIST_FILES_MOVE_TO_SUCCESS = 'LIST_FILES_MOVE_TO_SUCCESS',
  LIST_FILES_MOVE_TO_FAILED = 'LIST_FILES_MOVE_TO_FAILED',
  LIST_FILES_MOVE_TO_CLEAR = 'LIST_FILES_MOVE_TO_CLEAR',

  MOVE_FILE_REQUEST = 'MOVE_FILE_REQUEST',
  MOVE_FILE_SUCCESS = 'MOVE_FILE_SUCCESS',
  MOVE_FILE_FAILED = 'MOVE_FILE_FAILED',

  UNARCHIVE_FILES_REQUEST = 'UNARCHIVE_FILES_REQUEST',
  UNARCHIVE_FILES_SUCCESS = 'UNARCHIVE_FILES_SUCCESS',
  UNARCHIVE_FILES_FAILED = 'UNARCHIVE_FILES_FAILED',

  SET_AUTODESK_VIEWER_RENDERING_RESPONSE = 'SET_AUTODESK_VIEWER_RENDERING_RESPONSE',
  
  SET_FILE_STATUS_RENDERING = 'SET_FILE_STATUS_RENDERING',
  SET_FILE_STATUS_RERENDERING = 'SET_FILE_STATUS_RERENDERING',
  
  VERIFY_AUTODESK_PROGRESS = 'VERIFY_AUTODESK_PROGRESS',

  SET_UPLOAD_PROGRESS_RESPONSE = 'SET_UPLOAD_PROGRESS_RESPONSE',

  FILES_VERSION_REQUEST = 'FILES_VERSION_REQUEST',
  FILES_VERSION_SUCCESS = 'FILES_VERSION_SUCCESS',
  FILES_VERSION_FAILED = 'FILES_VERSION_FAILED',
  
  LOAD_OBSOLETE_FILES = 'LOAD_OBSOLETE_FILES',

  CURRENT_USER_PRIVILEGES = 'CURRENT_USER_PRIVILEGES',
}

export const ListagemArquivosActions = {
  listFiles: (listFilesRequest: IListFilesRequest): ListFilesAction =>
    createAction(ListagemArquivosActionKeys.LIST_FILES_REQUEST, listFilesRequest),
  listFilesSuccess: (listFilesResponse: IListFilesResponse): ListFilesSuccessAction =>
    createAction(ListagemArquivosActionKeys.LIST_FILES_SUCCESS, listFilesResponse),
  listFilesFailure: (err: string): ListFilesFailureAction =>
    createAction(ListagemArquivosActionKeys.LIST_FILES_FAILED, err),
  updateFileList: (files: IFileData[]): UpdateFileListAction =>
    createAction(ListagemArquivosActionKeys.UPDATE_FILE_LIST, files),

  addObraChunckData: (obra: IObraResponse): AddObraChunckData =>
    createAction(ListagemArquivosActionKeys.ADD_OBRA_CHUNCK_DATA, obra),

  downloadFiles: (request: IDownloadFilesRequest): DownloadFilesAction =>
    createAction(ListagemArquivosActionKeys.DOWNLOAD_FILES_REQUEST, request),
  downloadFilesSuccess: (response: any): DownloadFilesSuccessAction =>
    createAction(ListagemArquivosActionKeys.DOWNLOAD_FILES_SUCCESS, response),
  downloadFilesFailure: (err: string): DownloadFilesFailureAction =>
    createAction(ListagemArquivosActionKeys.DOWNLOAD_FILES_FAILED, err),
  progressDownload: (request: IProgressDownloading): ProgressDownloadAction =>
    createAction(ListagemArquivosActionKeys.PROGRESS_DOWNLOAD, request),

  deleteFiles: (request: IDeleteFilesRequest): DeleteFilesAction =>
    createAction(ListagemArquivosActionKeys.DELETE_FILES_REQUEST, request),
  deleteFilesSuccess: (response: IDeleteFilesRequest): DeleteFilesSuccessAction =>
    createAction(ListagemArquivosActionKeys.DELETE_FILES_SUCCESS, response),
  deleteFilesFailure: (err: string): DeleteFilesFailureAction =>
    createAction(ListagemArquivosActionKeys.DELETE_FILES_FAILED, err),

  renameFile: (request: IFileRenameData): RenameFileAction =>
    createAction(ListagemArquivosActionKeys.RENAME_FILE_REQUEST, request),
  renameFileSuccess: (response: IRenameFileResponse): RenameFileSuccessAction =>
    createAction(ListagemArquivosActionKeys.RENAME_FILE_SUCCESS, response),
  renameFileFailure: (err: string): RenameFileFailureAction =>
    createAction(ListagemArquivosActionKeys.RENAME_FILE_FAILED, err),

  updateFileInfo: (request: IUpdateFileInfoRequest[]): UpdateFileInfoAction =>
    createAction(ListagemArquivosActionKeys.UPDATE_FILE_INFO_REQUEST, request),
  updateFileInfoSuccess: (
    response: IUpdateFileInfoRequest[]
  ): UpdateFileInfoSuccessAction =>
    createAction(ListagemArquivosActionKeys.UPDATE_FILE_INFO_SUCCESS, response),
  updateFileInfoFailure: (err: string): UpdateFileInfoFailureAction =>
    createAction(ListagemArquivosActionKeys.UPDATE_FILE_INFO_FAILED, err),

  createFolder: (request: ICreateFolderRequest): CreateFolderAction =>
    createAction(ListagemArquivosActionKeys.CREATE_FOLDER_REQUEST, request),
  createFolderSuccess: (response: IFileData): CreateFolderSuccessAction =>
    createAction(ListagemArquivosActionKeys.CREATE_FOLDER_SUCCESS, response),
  createFolderFailure: (err: string): CreateFolderFailureAction =>
    createAction(ListagemArquivosActionKeys.CREATE_FOLDER_FAILED, err),

  setEditFormIndex: (index: number): SetEditFormIndexAction =>
    createAction(ListagemArquivosActionKeys.SET_EDIT_FORM_INDEX, index),

  setShowEditTable: (visible: boolean): SetShowEditTableAction =>
    createAction(ListagemArquivosActionKeys.SET_SHOW_EDIT_TABLE, visible),

  setShowPlotRequestTable: (visible: boolean): SetShowPlotRequestTableAction =>
    createAction(ListagemArquivosActionKeys.SET_SHOW_PLOT_REQUEST_TABLE, visible),

  setShowModalDeleteFiles: (visible: boolean): SetShowModalDeleteFilesAction =>
    createAction(ListagemArquivosActionKeys.SET_SHOW_MODAL_DELETE_FILES, visible),

  setShowModalCreateFolder: (visible: boolean): SetShowModalCreateFolderAction =>
    createAction(ListagemArquivosActionKeys.SET_SHOW_MODAL_CREATE_FOLDER, visible),

  setShowModalInfoFiles: (visible: boolean): SetShowModalInfoFilesAction =>
    createAction(ListagemArquivosActionKeys.SET_SHOW_MODAL_INFO_FILES, visible),

  setSelectedEditRowKeys: (keys: string[]): SelectedEditRowKeysAction =>
    createAction(ListagemArquivosActionKeys.SET_SELECTED_EDIT_ROW_KEYS, keys),

  setSelectedPlotRequestRowKeys: (keys: number[]): SelectedPlotRequestRowKeysAction =>
    createAction(ListagemArquivosActionKeys.SET_SELECTED_PLOT_REQUEST_ROW_KEYS, keys),

  addNewFile: (file: IFileData): AddNewFileAction =>
    createAction(ListagemArquivosActionKeys.ADD_NEW_FILE, file),

  setShowFilterTipo: (visible: boolean): ToogleShowFilterTipoAction =>
    createAction(ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_TIPO, visible),

  setShowFilterVisualization: (visible: boolean): ToogleShowFilterVisualizationAction =>
    createAction(ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_VISUALIZATION, visible),

  setShowFilterStatus: (visible: boolean): ToogleShowFilterStatusAction =>
    createAction(ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_STATUS, visible),

  setShowVersions: (visible: boolean): ToogleShowFilterVersionsAction =>
    createAction(ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_VERSIONS, visible),

  setShowModificado: (
    visible: boolean,
    dropdownVisible: boolean
  ): ToogleShowFilterModificadoAction =>
    createAction(ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_MODIFICADO, {
      show: visible,
      showDropdown: dropdownVisible
    }),
  setShowModificadoData: (visible: boolean): ToogleShowFilterModificadoDataAction =>
    createAction(ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_MODIFICADO_DATA, visible),
  setShowModificadoUsers: (visible: boolean): ToogleShowFilterModificadoUsersAction =>
    createAction(ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_MODIFICADO_USERS, visible),

  listFilesMoveTo: (listFilesRequest: IListFilesRequest): ListFilesMoveToAction =>
    createAction(ListagemArquivosActionKeys.LIST_FILES_MOVE_TO_REQUEST, listFilesRequest),
  listFilesMoveToSuccess: (
    listFilesResponse: IListFilesResponse
  ): ListFilesMoveToSuccessAction =>
    createAction(
      ListagemArquivosActionKeys.LIST_FILES_MOVE_TO_SUCCESS,
      listFilesResponse
    ),
  listFilesMoveToFailure: (err: string): ListFilesMoveToFailureAction =>
    createAction(ListagemArquivosActionKeys.LIST_FILES_MOVE_TO_FAILED, err),
  listFilesMoveToClear: (): ListFilesMoveToClearAction =>
    createAction(ListagemArquivosActionKeys.LIST_FILES_MOVE_TO_CLEAR),

  moveFile: (listFilesRequest: IMoveFileRequest): MoveFileAction =>
    createAction(ListagemArquivosActionKeys.MOVE_FILE_REQUEST, listFilesRequest),
  moveFileSuccess: (listFilesResponse: IMoveFileRequest): MoveFileSuccessAction =>
    createAction(ListagemArquivosActionKeys.MOVE_FILE_SUCCESS, listFilesResponse),
  moveFileFailure: (err: string): MoveFileFailureAction =>
    createAction(ListagemArquivosActionKeys.MOVE_FILE_FAILED, err),

  unarchiveFiles: (request: IUnarchiveFilesRequest): UnarchiveFilesAction =>
    createAction(ListagemArquivosActionKeys.UNARCHIVE_FILES_REQUEST, request),
  unarchiveFilesSuccess: (response: IUnarchiveFilesRequest): UnarchiveFilesSuccessAction =>
    createAction(ListagemArquivosActionKeys.UNARCHIVE_FILES_SUCCESS, response),
  unarchiveFilesFailure: (err: string): UnarchiveFilesFailureAction =>
    createAction(ListagemArquivosActionKeys.UNARCHIVE_FILES_FAILED, err),

  autodeskViewerRenderingResponse: (data: IRenderingSignalR): AutodeskViewerRenderingResponseAction =>
    createAction(ListagemArquivosActionKeys.SET_AUTODESK_VIEWER_RENDERING_RESPONSE, data),

  setFileStatusRendering: (data: IAutodeskViewer): SetFileStatusRenderingAction =>
    createAction(ListagemArquivosActionKeys.SET_FILE_STATUS_RENDERING, data),

  setFileStatusReRendering: (data: IAutodeskViewer): SetFileStatusReRenderingAction =>
    createAction(ListagemArquivosActionKeys.SET_FILE_STATUS_RERENDERING, data),

  setShowModalDownloadMultipleFiles: (value: boolean): SetShowModalDownloadMultipleFiles =>
    createAction(ListagemArquivosActionKeys.SET_SHOW_MODAL_DOWNLOAD_MULTIPLE_FILES, value),

  verifyAutodeskProgress: (data: IVerifyAutodeskProgress): VerifyAutodeskProgressAction =>
    createAction(ListagemArquivosActionKeys.VERIFY_AUTODESK_PROGRESS, data),

  uploadProgressResponse: (data: ISignalRArchive): UploadProgressResponseAction =>
    createAction(ListagemArquivosActionKeys.SET_UPLOAD_PROGRESS_RESPONSE, data),

  filesVersion: (request: IListFilesRequest): FilesVersionAction =>
    createAction(ListagemArquivosActionKeys.FILES_VERSION_REQUEST, request),
  filesVersionSuccess: (response: IListFilesResponse): FilesVersionSuccessAction =>
    createAction(ListagemArquivosActionKeys.FILES_VERSION_SUCCESS, response),
  filesVersionFailure: (err: string): FilesVersionFailureAction =>
    createAction(ListagemArquivosActionKeys.FILES_VERSION_FAILED, err),
  
  loadObsoleteFiles: (request: boolean): LoadObsoleteFilesAction =>
    createAction(ListagemArquivosActionKeys.LOAD_OBSOLETE_FILES, request),

  currentUserPrivileges: (value: ICurrentUserPrivileges): CurrentUserPrivilegesAction =>
    createAction(ListagemArquivosActionKeys.CURRENT_USER_PRIVILEGES, value),
};

export type ListagemArquivosActionUnion = ActionsUnion<typeof ListagemArquivosActions>;

export type ListFilesAction = Action<
  ListagemArquivosActionKeys.LIST_FILES_REQUEST,
  IListFilesRequest
>;
export type ListFilesSuccessAction = Action<
  ListagemArquivosActionKeys.LIST_FILES_SUCCESS,
  IListFilesResponse
>;
export type ListFilesFailureAction = Action<
  ListagemArquivosActionKeys.LIST_FILES_FAILED,
  string
>;

export type UpdateFileListAction = Action<
  ListagemArquivosActionKeys.UPDATE_FILE_LIST,
  IFileData[]
>;

export type AddObraChunckData = Action<
  ListagemArquivosActionKeys.ADD_OBRA_CHUNCK_DATA, 
  IObraResponse
>;

export type DownloadFilesAction = Action<
  ListagemArquivosActionKeys.DOWNLOAD_FILES_REQUEST,
  IDownloadFilesRequest
>;
export type DownloadFilesSuccessAction = Action<
  ListagemArquivosActionKeys.DOWNLOAD_FILES_SUCCESS,
  any
>;
export type DownloadFilesFailureAction = Action<
  ListagemArquivosActionKeys.DOWNLOAD_FILES_FAILED,
  string
>;

export type ProgressDownloadAction = Action<
  ListagemArquivosActionKeys.PROGRESS_DOWNLOAD,
  IProgressDownloading
>;

export type DeleteFilesAction = Action<
  ListagemArquivosActionKeys.DELETE_FILES_REQUEST,
  IDeleteFilesRequest
>;
export type DeleteFilesSuccessAction = Action<
  ListagemArquivosActionKeys.DELETE_FILES_SUCCESS,
  IDeleteFilesRequest
>;
export type DeleteFilesFailureAction = Action<
  ListagemArquivosActionKeys.DELETE_FILES_FAILED,
  string
>;

export type RenameFileAction = Action<
  ListagemArquivosActionKeys.RENAME_FILE_REQUEST,
  IFileRenameData
>;
export type RenameFileSuccessAction = Action<
  ListagemArquivosActionKeys.RENAME_FILE_SUCCESS,
  IRenameFileResponse
>;
export type RenameFileFailureAction = Action<
  ListagemArquivosActionKeys.RENAME_FILE_FAILED,
  string
>;

export type UpdateFileInfoAction = Action<
  ListagemArquivosActionKeys.UPDATE_FILE_INFO_REQUEST,
  IUpdateFileInfoRequest[]
>;
export type UpdateFileInfoSuccessAction = Action<
  ListagemArquivosActionKeys.UPDATE_FILE_INFO_SUCCESS,
  IUpdateFileInfoRequest[]
>;
export type UpdateFileInfoFailureAction = Action<
  ListagemArquivosActionKeys.UPDATE_FILE_INFO_FAILED,
  string
>;

export type CreateFolderAction = Action<
  ListagemArquivosActionKeys.CREATE_FOLDER_REQUEST,
  ICreateFolderRequest
>;
export type CreateFolderSuccessAction = Action<
  ListagemArquivosActionKeys.CREATE_FOLDER_SUCCESS,
  IFileData
>;
export type CreateFolderFailureAction = Action<
  ListagemArquivosActionKeys.CREATE_FOLDER_FAILED,
  string
>;

export type AddNewFileAction = Action<ListagemArquivosActionKeys.ADD_NEW_FILE, IFileData>;

export type SetEditFormIndexAction = Action<
  ListagemArquivosActionKeys.SET_EDIT_FORM_INDEX,
  number
>;

export type SetShowEditTableAction = Action<
  ListagemArquivosActionKeys.SET_SHOW_EDIT_TABLE,
  boolean
>;

export type SetShowPlotRequestTableAction = Action<
  ListagemArquivosActionKeys.SET_SHOW_PLOT_REQUEST_TABLE,
  boolean
>;

export type SetShowModalDeleteFilesAction = Action<
  ListagemArquivosActionKeys.SET_SHOW_MODAL_DELETE_FILES,
  boolean
>;

export type SetShowModalCreateFolderAction = Action<
  ListagemArquivosActionKeys.SET_SHOW_MODAL_CREATE_FOLDER,
  boolean
>;

export type SetShowModalInfoFilesAction = Action<
  ListagemArquivosActionKeys.SET_SHOW_MODAL_INFO_FILES,
  boolean
>;

export type SetShowModalDownloadMultipleFiles = Action<
  ListagemArquivosActionKeys.SET_SHOW_MODAL_DOWNLOAD_MULTIPLE_FILES,
  boolean
>;

export type SelectedEditRowKeysAction = Action<
  ListagemArquivosActionKeys.SET_SELECTED_EDIT_ROW_KEYS,
  string[]
>;

export type SelectedPlotRequestRowKeysAction = Action<
  ListagemArquivosActionKeys.SET_SELECTED_PLOT_REQUEST_ROW_KEYS,
  number[]
>;

export type ToogleShowFilterTipoAction = Action<
  ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_TIPO,
  boolean
>;

export type ToogleShowFilterVisualizationAction = Action<
  ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_VISUALIZATION,
  boolean
>;

export type ToogleShowFilterStatusAction = Action<
  ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_STATUS,
  boolean
>;

export type ToogleShowFilterVersionsAction = Action<
  ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_VERSIONS,
  boolean
>;

export type ToogleShowFilterModificadoAction = Action<
  ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_MODIFICADO,
  { showDropdown: boolean; show: boolean }
>;
export type ToogleShowFilterModificadoDataAction = Action<
  ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_MODIFICADO_DATA,
  boolean
>;
export type ToogleShowFilterModificadoUsersAction = Action<
  ListagemArquivosActionKeys.TOOGLE_SHOW_FILTER_MODIFICADO_USERS,
  boolean
>;

export type ListFilesMoveToAction = Action<
  ListagemArquivosActionKeys.LIST_FILES_MOVE_TO_REQUEST,
  IListFilesRequest
>;
export type ListFilesMoveToSuccessAction = Action<
  ListagemArquivosActionKeys.LIST_FILES_MOVE_TO_SUCCESS,
  IListFilesResponse
>;
export type ListFilesMoveToFailureAction = Action<
  ListagemArquivosActionKeys.LIST_FILES_MOVE_TO_FAILED,
  string
>;
export type ListFilesMoveToClearAction = Action<
  ListagemArquivosActionKeys.LIST_FILES_MOVE_TO_CLEAR
>;

export type MoveFileAction = Action<
  ListagemArquivosActionKeys.MOVE_FILE_REQUEST,
  IMoveFileRequest
>;
export type MoveFileSuccessAction = Action<
  ListagemArquivosActionKeys.MOVE_FILE_SUCCESS,
  IMoveFileRequest
>;
export type MoveFileFailureAction = Action<
  ListagemArquivosActionKeys.MOVE_FILE_FAILED,
  string
>;

export type UnarchiveFilesAction =
  Action<ListagemArquivosActionKeys.UNARCHIVE_FILES_REQUEST, IUnarchiveFilesRequest>;
export type UnarchiveFilesSuccessAction =
  Action<ListagemArquivosActionKeys.UNARCHIVE_FILES_SUCCESS, IUnarchiveFilesRequest>;
export type UnarchiveFilesFailureAction =
  Action<ListagemArquivosActionKeys.UNARCHIVE_FILES_FAILED, string>;

export type SetFileStatusRenderingAction = Action<
  ListagemArquivosActionKeys.SET_FILE_STATUS_RENDERING,
  IAutodeskViewer
>;

export type SetFileStatusReRenderingAction = Action<
  ListagemArquivosActionKeys.SET_FILE_STATUS_RERENDERING,
  IAutodeskViewer
>;

export type AutodeskViewerRenderingResponseAction = Action<
  ListagemArquivosActionKeys.SET_AUTODESK_VIEWER_RENDERING_RESPONSE,
  IRenderingSignalR
>;

export type VerifyAutodeskProgressAction = Action<
  ListagemArquivosActionKeys.VERIFY_AUTODESK_PROGRESS, IVerifyAutodeskProgress>;

export type UploadProgressResponseAction = Action<
  ListagemArquivosActionKeys.SET_UPLOAD_PROGRESS_RESPONSE,
  ISignalRArchive
>;

export type FilesVersionAction =
  Action<ListagemArquivosActionKeys.FILES_VERSION_REQUEST, IListFilesRequest>;
export type FilesVersionSuccessAction =
  Action<ListagemArquivosActionKeys.FILES_VERSION_SUCCESS, IListFilesResponse>;
export type FilesVersionFailureAction =
  Action<ListagemArquivosActionKeys.FILES_VERSION_FAILED, string>;
  
  export type LoadObsoleteFilesAction =
  Action<ListagemArquivosActionKeys.LOAD_OBSOLETE_FILES, boolean>;

  export type CurrentUserPrivilegesAction =
    Action<ListagemArquivosActionKeys.CURRENT_USER_PRIVILEGES, ICurrentUserPrivileges>;
