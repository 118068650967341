import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ListagemArquivosActions } from "Store/ListagemArquivos/ListagemArquivos.actions";
import { IObraData } from "Data/interfaces/Obra/IObraData";
import { createSchema } from "../../fileInfo.schema";
import { 
  INomenclatureData, 
  SeparatorsData, 
  VersionFieldEnumLabel,
} from "Data/interfaces/Nomenclatura/INomenclatureData";
import { getValueField } from "Pages/ListagemArquivos/ListagemArquivos.helpers";
import Button from "Components/UI/Button";
import Field from "Components/UI/Field";
import Form from "Components/UI/Form/Form";
import styles from "../../ListagemArquivos.module.scss";

interface IEdicaoNomenclatura {
  record: any;
  nomenclature: INomenclatureData;
  obra: IObraData;
  isNamingPatternEnabled?: boolean;
  onSubmit: (values: any) => void;
  isLoadingRenameFile: boolean;
  text: any;
  toggleEditForm?: () => void;
  islistEdit?: boolean;
  onCancel?: () => void;
  index: number;
}

const EdicaoNomenclatura: FC<IEdicaoNomenclatura> = ({
  record,
  nomenclature,
  obra,
  isNamingPatternEnabled,
  onSubmit,
  isLoadingRenameFile,
  text,
  toggleEditForm,
  islistEdit,
  onCancel,
  index,
}) => {
  const dispatch = useDispatch();
  const [isSent, setIsSent] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const versionFields = useMemo(() => {
    if (isNamingPatternEnabled) {
      return obra.VersionConfig.VersionFields;
    }

    return nomenclature.VersionFields;
  }, [
    isNamingPatternEnabled,
    nomenclature.VersionFields,
    obra.VersionConfig.VersionFields,
  ]);

  useEffect(() => {
    if (!isLoadingRenameFile && isSent) {
      dispatch(ListagemArquivosActions.setEditFormIndex(index));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRenameFile]);

  return (
    <div className={`${styles['formWrapper']} ${islistEdit ? styles['formWrapperListEdit'] : ''}`}>
      <Form
        schema={createSchema(versionFields, record.HasNomenclatureControl)}
        onSubmit={(values) => {
          onSubmit(values);
          setIsSent(true);
          setActiveIndex(index);
        }}
      >
        <Field label='' type='hidden' name='constructionSiteId' value={record.FileInfo.ConstructionSiteFk} />
        <Field label='' type='hidden' name='fileId' value={record.FileInfo.ApiId} />
        <Field label='' type='hidden' name='oldName' value={record.Name} />
        <Field label='' type='hidden' name='extension' value={record.Extension} />
        <Field label='' type='hidden' name='fileInfoId' value={record.FileInfo.FileInfoId} />
        <Field label='' type='hidden' name='api' value={record.FileInfo.Api} />
        <Field label='' type='hidden' name='status' value={record.FileInfo.Status} />
        <Field label='' type='hidden' name='hasNomenclatureControl' value={record.HasNomenclatureControl?.toString()} />
        <Field label='' type='hidden' name='index' defaultValue={index} />
        <div className={styles['fieldsWrapper']} >
          {record.HasNomenclatureControl ? (
            versionFields?.map((field) => (
              <span key={`fields${field.Ordem}`}>
                <Field
                  disabled={isLoadingRenameFile && (activeIndex === index)}
                  name={`field${field.Ordem}`}
                  value={getValueField(text, field.Ordem, versionFields)}
                  label={field.CampoPersonalizado
                    || VersionFieldEnumLabel.get(field.CampoVersaoId)
                    || ''
                  }
                />
                <Field
                  name={`separator${field.Ordem}`}
                  label=''
                  value={SeparatorsData.get(field.Separador)?.Value}
                  type='hidden'
                />
              </span>
            ))
          ) : (
            <div className={styles['simpleFileNameWrapper']}>
              <Field
                name='simpleFileName'
                disabled={isLoadingRenameFile && (activeIndex === index)}
                label='Nome do Arquivo' value={record.Name.split('.').slice(0, -1).join('.') || undefined}
              />
            </div>
          )}
        </div>
        <div className={styles['descriptionWrapper']}>
          <Field name='description' label='Descrição' disabled={isLoadingRenameFile && (activeIndex === index)} value={record.FileInfo.Description || undefined} />
        </div>
        <div className={styles['buttonsWrapper']}>
          {(islistEdit && onCancel) ? (
            <>
              <Button onClick={onCancel} disabled={isLoadingRenameFile}>
                Cancelar
              </Button>
              <Button htmlType='submit' type='primary' loading={isLoadingRenameFile}>
                Salvar
              </Button>
            </>
          ) : (
            toggleEditForm && (
              <>
                <Button onClick={toggleEditForm} disabled={isLoadingRenameFile}>
                  Cancelar
                </Button>
                <Button
                  htmlType='submit'
                  type='primary'
                  disabled={isLoadingRenameFile && (activeIndex === index)}
                  loading={isLoadingRenameFile && (activeIndex === index)}
                >
                  Salvar
                </Button>
              </>
            )
          )}
        </div>
      </Form>
    </div>
  )
};

export default EdicaoNomenclatura;
