import { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { HolderOutlined } from "@ant-design/icons";
import { DragButton, FieldRow } from "./styles";
import { UniqueIdentifier } from "@dnd-kit/core";

interface ISimpleSortableItemWithButton {
  id: string;
  activeId?: UniqueIdentifier | null;
  disabled?: boolean;
  className?: string;
  dragClassName?: string;
}

const SimpleSortableItemWithButton: FC<ISimpleSortableItemWithButton> = ({
  id,
  activeId,
  disabled,
  className,
  dragClassName,
  children,
}) => {
  const {
    attributes,
    listeners,
    transition,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    isDragging,
  } = useSortable({
    id: id,
  });

  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition
  };

  return (
    <FieldRow
      key={`key-${id}`}
      ref={setNodeRef}
      style={style}
      className={id === activeId ? dragClassName : className}
    >
      {!disabled && (
        <DragButton
          isgrabbing={`${id === activeId}`}
          {...attributes}
          {...listeners}
          ref={setActivatorNodeRef}
        >
          <HolderOutlined rev="" className="iconDrag" />
        </DragButton>
      )}
      {children}
    </FieldRow>
  );
}

export default SimpleSortableItemWithButton;
