import { RefObject } from "react";
import { ViewerApi } from "Data/Viewer.api";
import { ContentRect } from "react-measure";
import { IFileInfo } from "Data/interfaces/Viewer/IGetFileInfo";

export interface ISimpleViewer {
  viewerDiv: RefObject<HTMLDivElement>;
  options: Autodesk.Viewing.InitializerOptions;
  options3D: Autodesk.Viewing.Viewer3DConfig;
  fileInfo?: IFileInfo;
  isCostPdfRender?: boolean;
  onStarted: (value: boolean) => void;
}

export class SimpleViewer {
  public started: boolean;
  public currentViewer: Autodesk.Viewing.GuiViewer3D | null;
  private div: any;
  private fileInfo?: IFileInfo;
  private options: Autodesk.Viewing.InitializerOptions;
  private options3D: Autodesk.Viewing.Viewer3DConfig;
  private resizeHandling: NodeJS.Timeout | null;
  private viewerDoc: Autodesk.Viewing.Document | null;
  private viewables: Autodesk.Viewing.BubbleNode[] | null;
  private page: number;
  private totalPage?: number;
  private listenersStarted: boolean;
  private pdfRender: boolean;
  private urnsLoaded: Set<string>;
  private pdfPath?: string;
  private onStarted: (value: boolean) => void;

  constructor() {
    this.div = null;
    this.started = false;
    this.fileInfo = undefined;
    this.currentViewer = null;
    this.options = {};
    this.options3D = {};
    this.resizeHandling = null;
    this.viewerDoc = null;
    this.viewables = null;
    this.page = 0;
    this.totalPage = undefined;
    this.listenersStarted = false;
    this.pdfRender = false;
    this.urnsLoaded = new Set();
    this.pdfPath = undefined;
    this.onStarted = () => undefined;
  }

  setOptions(props: ISimpleViewer) {
    this.div = props.viewerDiv.current;
    this.options = props.options;
    this.options3D = props.options3D;
    this.fileInfo = props.fileInfo;
    if (props.fileInfo?.extension === 'pdf') {
      this.page = 1;
    }
    this.onStarted = props.onStarted;
  }

  async getAccessTokenAndStart() {
    try {
      const { data } = await ViewerApi.GetAccessToken();
      this.options.accessToken = data.data.access_token;
      this.start();
    } catch (error) {
      console.log({ error });
      window.location.href = '/login';
    }
  }

  start() {
    Autodesk.Viewing.Initializer(this.options, () => {
      this.currentViewer = new Autodesk.Viewing.GuiViewer3D(this.div, this.options3D);

      if (this.currentViewer) {
        const startedCode = this.currentViewer.start();

        if (startedCode > 0) {
          console.error('Failed to create a Viewer: WebGL not supported.');
          return;
        }

        this.started = true;
        this.onStarted(this.started);
        console.log('Start!');
      } else {
        console.log('Start Error!!!');
      }
    })
  }

  loadUrn(urn: string) {
    if (!this.div && !this.currentViewer && !this.started) return;

    if (
      !this.urnsLoaded.has(urn) ||
      (this.fileInfo?.bucketFileName &&
        !this.urnsLoaded.has(this.fileInfo.bucketFileName))
    ) {
      if (this.fileInfo?.extension === 'pdf' && this.fileInfo.bucketFileName) {
        this.urnsLoaded.add(this.fileInfo.bucketFileName);
        this.pdfPath = `${process.env.REACT_APP_BASE_AUTODESK_FILE_PATH || 'https://developer.api.autodesk.com/oss/v2/buckets/maletadoengenheiroprod01/objects/'}${this.fileInfo?.bucketFileName}`;

        if (this.currentViewer && !this.pdfRender) {
          this.currentViewer.loadExtension('Autodesk.PDF')
            .then((value) => {
              this.pdfRender = true;
              if (this.currentViewer && this.pdfPath) {
                this.currentViewer.loadModel(
                  this.pdfPath,
                  { page: this.page },
                  (success) => {
                    const pdfSuccessRenderInfo = success?.getData()?.getPDF();
                    const pdfInfo = pdfSuccessRenderInfo?._pdfInfo;
                    this.totalPage = pdfInfo?.numPages;
                  }
                );
              }
            })
            .catch(err => {
              console.log('loadExtension Autodesk.PDF Error: ', err)
            });
        }

      } else {
        const formatUrn = this.formatUrn(urn);
        this.urnsLoaded.add(urn);

        Autodesk.Viewing.Document.load(
          formatUrn,
          this.onDocumentLoadSuccess,
          this.onDocumentLoadError
        );
      }
    }
  }

  formatUrn(urn: string, isReverse?: boolean) {
    if (urn.startsWith('urn:')) {
      if (isReverse) return urn.replace('urn:', '');
      return urn;

    } else {
      if (isReverse) return urn;
      return `urn:${urn}`
    }
  }

  extractFileNameFromUrn(urn: string) {
    const decode64 = atob(urn);
    const fileNameIndex = decode64.indexOf('constructionId')
    const fileName = decode64.slice(fileNameIndex);
    return fileName;
  }

  onDocumentLoadSuccess = async (viewerDocument: Autodesk.Viewing.Document) => {
    this.viewerDoc = viewerDocument;
    this.viewables = viewerDocument.getRoot().search({ type: 'geometry' });

    if (this.currentViewer !== null) {
      this.currentViewer.loadDocumentNode(this.viewerDoc, this.viewables[this.page], {
        keepCurrentModels: true,
        globalOffset: { x: 0, y: 0, z: 0 },
      })
      console.log('Load Render!')
    } else {
      console.log('Error Render!')
      this.viewerFinishAndClosed();
    }
  }

  onDocumentLoadError = (errorCode: Autodesk.Viewing.ErrorCodes) => {
    console.log({ errorCode });
    this.viewerFinishAndClosed();
  }

  initEventListeners() {
    if (this.currentViewer) {
      console.log('Started Listeners!!')
      this.currentViewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.getLoadChanged);
      this.currentViewer.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.getToolbarCreated);
      this.listenersStarted = true;
    }
  }

  stopEventListeners() {
    if (this.currentViewer) {
      console.log('Stopped Listeners!!')
      this.currentViewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.getLoadChanged);
      this.currentViewer.removeEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.getToolbarCreated);
      this.listenersStarted = false;
    }
  }

  getLoadChanged = async () => {
    if (this.currentViewer) {
      if (this.fileInfo?.extension !== 'pdf') {
        if (this.fileInfo) {
          const { AutodeskXlsExtension } = await import('./AutodeskXlsExtension');
          AutodeskXlsExtension.register();
          this.currentViewer.loadExtension('Autodesk.Xls.Extension', {
            fileInfo: this.fileInfo,
            accessToken: this.options.accessToken,
          });
        }

        const { AutodeskCustomNavigation } = await import('./AutodeskCustomNavigation');
        AutodeskCustomNavigation.register();
        this.currentViewer.loadExtension('Autodesk.Custom.Navigation', {
          viewerDoc: this.viewerDoc,
          viewables: this.viewables,
        });

        this.currentViewer.getExtension('Autodesk.Measure', (ext: any) => {
          ext.measureTool.setUnits('m');
        });
      }
    }
  }

  getToolbarCreated = async () => {
    if (this.currentViewer) {
      if (this.fileInfo?.extension === 'pdf') {
        const { AutodeskPdfPaginationExtension } = await import('./AutodeskPdfExtension');
        AutodeskPdfPaginationExtension.register();
        this.currentViewer.loadExtension('Autodesk.PdfPagination.Extension', {
          page: this.page,
          totalPage: this.totalPage,
          pdfPath: this.pdfPath,
          onPagination: (newIndex: number) => this.onPagination(newIndex),
        });

        this.currentViewer.getExtension('Autodesk.Measure', (ext: any) => {
          ext.measureTool.setUnits('m');
        });
      }
    }
  }

  onPagination(page: number) {
    this.page = page;
  }

  viewerFinishAndClosed() {
    this.started = false;
    this.onStarted(false);
    if (this.listenersStarted) {
      this.stopEventListeners();
    }
    if (this.currentViewer) {
      this.currentViewer.tearDown();
      this.currentViewer.finish();
    }
    this.urnsLoaded = new Set();
    this.currentViewer = null;
    if (this.resizeHandling) clearTimeout(this.resizeHandling);
    console.log('Finish!')
  }

  handleResize(rect: ContentRect) {
    if (this.resizeHandling) clearTimeout(this.resizeHandling)

    this.resizeHandling = setTimeout(() => {
      if (this.currentViewer) this.currentViewer.resize()
    }, 100)
  }
}
