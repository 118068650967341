import React, { FC, useMemo } from "react";
import { List, Spin, Switch, ConfigProvider, Empty } from "antd";
import { DisciplineIconsEnum } from "Pages/Disciplinas";
import { Icon } from "Components/UI";
import styles from './Models.module.scss';
import EmptySearch from "Components/UI/SearchViewerInput/EmptySearch";
import HighlighterWords from "Components/UI/HighlightWords";
import { IModelsTree } from "Data/interfaces/FederatedViewer/IModelsTree";
import TreeView from "./components/TreeView";
import { IDisciplineViewerList } from "Data/interfaces/FederatedViewer/IDisciplineViewerList";
import InfoRender from "Components/UI/InfoRender";
import { IHideNodes, ISelectedNode } from "Data/interfaces/FederatedViewer/INodes";
import OtherActionsFile from "./components/OtherActionsFile";
import { ApisEnum } from "Data/enums/Apis";
import { Dispatch } from "redux";
import { FederatedViewerActions } from "Store/FederatedViewer/FederatedViewer.actions";
import { IUpdateStatusInViewer } from "Data/interfaces/ListagemArquivos/IUpdateStatusInViewer";
import { IAutodeskViewer } from "Data/interfaces/Viewer/IAutodeskViewer";
import { IFileData } from "Data/interfaces/ListagemArquivos/IListFilesResponse";
import Ellipsis from "Components/UI/Ellipsis";
import AccessPermission from "Utils/AcessPermission";
import { Mixpanel } from "Utils/MixPanel";
import { LoadingOutlined } from "@ant-design/icons";
import { IUserInforResponse } from "Data/interfaces/Auth/IUserInfoResponse";
import { useSelector } from "react-redux";
import { getCurrentTenant } from "Store/Tenant/Tenant.selector";

interface IModels {
  activeDisciplineId: number | null;
  openDiscipline: (disciplineId: number) => void;
  backDisciplines: () => void;
  disciplineList: IDisciplineViewerList[];
  childrenDisciplinesActive?: IFileData[];
  searchDiscipline: string;
  searchDisciplineFiles: string;
  loadUrn: string | null;
  onLoadUrn: (urn: string | null) => void;
  onActiveUrns: (urn: string, extension: string) => void;
  accessViewerTab?: number | boolean;
  urns?: string[];
  onCTAMultipleRender: (index: number) => void;
  modelsTree?: IModelsTree[];
  onToggleNode: (node: number, urn: string) => void;
  hideNodes: IHideNodes[];
  onToggleSelectNode: (node: number, urn: string) => void;
  selectedNode: ISelectedNode | null;
  constructionSiteId: number;
  api: ApisEnum;
  userInfo?: IUserInforResponse;
  dispatch: Dispatch;
}

const Models: FC<IModels> = ({
  activeDisciplineId,
  openDiscipline,
  backDisciplines,
  disciplineList,
  childrenDisciplinesActive,
  searchDiscipline,
  searchDisciplineFiles,
  loadUrn,
  onLoadUrn,
  onActiveUrns,
  accessViewerTab,
  urns,
  onCTAMultipleRender,
  modelsTree,
  onToggleNode,
  hideNodes,
  onToggleSelectNode,
  selectedNode,
  constructionSiteId,
  api,
  userInfo,
  dispatch,
}) => {
  const currentTenant = useSelector(getCurrentTenant);

  const antIcon = <LoadingOutlined rev=""style={{ color: '#3547C8' , fontSize: 14 }} spin />;

  const activeDiscipline = useMemo(() => {
    if (activeDisciplineId) {
      return disciplineList.find(discipline => discipline.ConstructionSiteDisciplinesId === activeDisciplineId);
    }
    return undefined;
  }, [activeDisciplineId, disciplineList]);

  const handleUrns = (urn: string, extension: string) => {
    Mixpanel.track({
      name: 'RENDER_FILE', 
      props: {
        constructionSiteId: Number(constructionSiteId),
        api: api,
        extension: extension,
        federatedViewer: true,
      },
      userInfo,
      currentListTenant: currentTenant,
    });

    if (!urns?.includes(urn)) {
      onLoadUrn(urn);
    }
    onActiveUrns(urn, extension);
  }

  const iconLeft = (name: string) => {
    if ((DisciplineIconsEnum as any)[name]) {
      return (DisciplineIconsEnum as any)[name];
    }
    return 'menuContexto'; 
  }

  const renderEmpty = () => {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  }

  const handleUnbindFile = (file: IFileData) => {
    if (activeDiscipline) {
      const updateStatusInViewer: IUpdateStatusInViewer = {
        fileApiId: file.Identifier.ApiId || file.Identifier.ItemId,
        constructionSiteId: constructionSiteId,
        constructionSiteDisciplineId: activeDiscipline.ConstructionSiteDisciplinesId,
        api,
        inViewer: false,
        discipline: activeDiscipline,
        currentFile: file,
      };
      dispatch(FederatedViewerActions.updateStatusInViewer(updateStatusInViewer));
    }
  }

  const handleReRenderFileError = (file: IFileData) => {
    const request: IAutodeskViewer = {
      ConstructionSiteId: constructionSiteId,
      FileApiId: file.Identifier.ApiId || file.Identifier.ItemId,
    };

    if (file.FileInfo.BucketFile?.Status === 3) {
      dispatch(FederatedViewerActions.setFileStatusReRendering(request));
      dispatch(FederatedViewerActions.autoDeskViewer(request));
    }
    
    if (file.FileInfo.BucketFile?.Status === 2) {
      dispatch(FederatedViewerActions.setFileStatusReRendering(request));
      dispatch(FederatedViewerActions.autoDeskViewer(request));
    }
  };

  return (
    <ConfigProvider renderEmpty={() => renderEmpty()}>
      {(activeDisciplineId && activeDiscipline) ? (
        <div className={styles['listFilesWrapper']}>
            <List.Item
              className={styles['headerListFiles']}
            >
              <div className={styles['headerListFilesContent']}>
                <button 
                  onClick={() => backDisciplines()}
                  className={styles['iconBack']}
                >
                  <Icon icon="retroceder" customSize={14} customColor="#BEC2C6" />
                </button>
                <Icon 
                  icon={iconLeft(activeDiscipline.CustomName || activeDiscipline.Discipline.Name)} 
                  customSize={18} 
                  className={styles['']} 
                  customColor="#BEC2C6" 
                />
                <Ellipsis 
                  maxLength={32}
                  phrase={activeDiscipline.CustomName || activeDiscipline.Discipline.Name}
                  className={styles['disciplineHeader']}
                />
              </div>
            </List.Item>
          
          {searchDisciplineFiles && !childrenDisciplinesActive ? (
            <EmptySearch 
              searchDisciplineFiles={searchDisciplineFiles}
            />
          ) : (
            <List
              className={styles['contentListFiles']} 
              itemLayout="horizontal" 
              dataSource={childrenDisciplinesActive}
              split={false}
              renderItem={(item, index) => {
                const modelExist = modelsTree?.find(model => model.urn.replace('urn:', '') === item.FileInfo.BucketFile?.Urn);
                const hideNodesExist = hideNodes.find(nodes => nodes.urn === item.FileInfo.BucketFile?.Urn);
                const selectedNodeExist = selectedNode?.urn === item.FileInfo.BucketFile?.Urn ? selectedNode?.nodeId : null;
                
                return (
                  <>
                    <List.Item className={styles['rowFile']}>
                        <div className={styles['leftRowFile']}>
                          <div className={styles['headerRowFile']}>
                            <HighlighterWords 
                              text={item.Name} 
                              maxLength={32}
                              wordSearched={searchDisciplineFiles} 
                              className={styles['disciplineListText']} 
                            />
                          </div>
                          <div className={styles['footerRowFile']}>
                            <Icon icon="arquivar" customSize={9} customColor="#BEC2C6" className={styles['disciplineListIcon']}/>
                            <span className={styles['disciplineListText']}>
                              <InfoRender 
                                status={item.FileInfo.BucketFile?.Status}
                                signalRender={item.RenderingSignalR}
                              />
                            </span>
                            <Ellipsis 
                              maxLength={10}
                              phrase={`Versão ${item.Revision || '-'}`}
                              className={styles['disciplineListText']}
                            />
                            <span className={styles['disciplineListText']}>
                              {`${item.SizeInBytes.toFixed(1)} MB`}
                            </span>
                          </div>
                        </div>
                        {false && (<Spin indicator={antIcon} style={{marginRight: '8px'}} />)}
                        {(accessViewerTab || (((!accessViewerTab || (accessViewerTab === 0))) && (urns && urns.length < 1))) && (
                          <Switch
                            size="small"
                            onChange={() => handleUrns(`urn:${item.FileInfo.BucketFile?.Urn}` || '', item.Extension)} 
                            checked={urns?.includes(`urn:${item.FileInfo.BucketFile?.Urn}` || '')}
                            disabled={!item.FileInfo.BucketFile?.Urn || typeof loadUrn === 'string' || (item.FileInfo.BucketFile.Status === 3)}
                          />
                        )}
                        {((!accessViewerTab || (accessViewerTab === 0))) && (urns && urns.length >= 1) && (
                          <Switch
                            size="small"
                            onClick={() => urns?.includes(`urn:${item.FileInfo.BucketFile?.Urn}` || '') ? handleUrns(`urn:${item.FileInfo.BucketFile?.Urn}` || '', item.Extension) : onCTAMultipleRender(index)} 
                            checked={urns?.includes(`urn:${item.FileInfo.BucketFile?.Urn}` || '') ? true : false}
                            disabled={!item.FileInfo.BucketFile?.Urn || typeof loadUrn === 'string' || (item.FileInfo.BucketFile.Status === 3)}
                          />
                        )}
                        {(((AccessPermission.isAdmin() || AccessPermission.isCoordenador()) && item.FileInfo.BucketFile?.Status === 1) || item.FileInfo.BucketFile?.Status !== 1) && (
                          <OtherActionsFile 
                            file={item}
                            onUnbindFile={handleUnbindFile}
                            onReRenderFileError={handleReRenderFileError}
                          />
                        )}
                    </List.Item>
                    {modelExist && (
                      <List.Item className={styles['rowTree']}>
                        <TreeView 
                          result={modelExist} 
                          onToggleNode={onToggleNode} 
                          hideNodes={hideNodesExist?.hideNodes || []}
                          showNodes={hideNodesExist?.showNodes || []}
                          onToggleSelectNode={onToggleSelectNode}
                          selectedNode={selectedNodeExist}
                        />
                      </List.Item>
                    )}
                  </>
                )
              }}
            />
          )}
        </div>
      ) : (
        searchDiscipline && !disciplineList.length ? (
          <EmptySearch 
            searchDiscipline={searchDiscipline}
          />
        ) : (
          <div className={styles['disciplinesWrapper']}>
            <List
              itemLayout="horizontal"
              dataSource={disciplineList}
              split={false}
              renderItem={item => (
                <List.Item
                  className={styles['rowDisciplinesList']}
                  onClick={() => openDiscipline(item.ConstructionSiteDisciplinesId)}
                >
                  <div className={styles['leftRowDisciplinesList']}>
                    <Icon 
                      icon={iconLeft(item.CustomName || item.Discipline.Name)}  
                      customSize={18} 
                      className={styles['']} 
                      customColor="#BEC2C6" 
                    />
                    <HighlighterWords 
                      text={item.CustomName || item.Discipline.Name} 
                      maxLength={28}
                      wordSearched={searchDiscipline} 
                      className={styles['disciplineListText']} 
                    />
                  </div>
                  <Icon icon="retroceder" customSize={8} className={styles['iconExpanded']} color="cinzaSuperior" />
                </List.Item>
              )}
            />
          </div>
        )
      )}
    </ConfigProvider>
  )
}

export default Models;
