import React, { FC, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Measure from 'react-measure';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import styles from './ViewerTeste.module.scss';
import stylesDrawer from '../../../Pages/FederatedViewer/components/PropertiesDrawer/PropertiesDrawer.module.scss';
import PropertiesDrawer from '../../../Pages/FederatedViewer/components/PropertiesDrawer';
import ButtonDrawer from 'Components/UI/ButtonDrawer';
import { Icon } from 'Components/UI';
import useInternalViewer from './useInternalViewer';
import { ICamera, INode, IPrancha, IView } from 'Pages/FederatedViewer/useFederatedViewer';
import './viewerTeste.css';

export interface IInternalViewer {
  loadUrn: string | null;
  onLoadUrn: (urn: string | null) => void;
  onActiveUrns: (urn: string, extension: string) => void;
  drawerOpen: boolean;
  toggleNode: INode;
  toggleSelectNode: INode;
  toggleViewCamera: ICamera;
  toggleView: IView;
  onToggleNode: (node: number | null, urn: string | null) => void;
  onToggleSelectNode: (node: number | null, urn: string | null) => void;
  onTogglePrancha: (prancha: Autodesk.Viewing.BubbleNode | null, urn: string | null, id: number | null) => void;
  onToggleViewCamera: (camera: Autodesk.Viewing.BubbleNode | null, urn: string | null, id: number | null) => void;
  onToggleView: (view: Autodesk.Viewing.BubbleNode | null, urn: string | null, id: number | null) => void;
  togglePrancha: IPrancha;
  onClearAllToggles: () => void;
}

export interface IExtensions {
  docNow: Autodesk.Viewing.Document | null;
  viewables: Autodesk.Viewing.BubbleNode[] | null;
}

export interface IModelNode {
  urn: string | null;
  model: Autodesk.Viewing.Model | null;
}

const InternalViewer: FC<Props> = ({
  loadUrn,
  onLoadUrn,
  onActiveUrns,
  urns,
  extensionValidateds,
  unloadUrn,
  drawerOpen,
  models,
  views,
  pranchas,
  selectedProperties,
  toggleNode,
  hideNodes,
  toggleSelectNode,
  toggleViewCamera,
  toggleView,
  onToggleNode,
  onToggleSelectNode,
  onTogglePrancha,
  onToggleViewCamera,
  onToggleView,
  togglePrancha,
  onClearAllToggles,
}) => {
  const viewerDiv = useRef<HTMLDivElement>(null);

  const {
    handleResize,
    drawerPropertiesVisible,
    closeDrawerProperties,
    showDrawerProperties,
  } = useInternalViewer({
    viewerDiv,
    loadUrn,
    onLoadUrn,
    onActiveUrns,
    urns,
    extensionValidateds,
    unloadUrn,
    models,
    views,
    pranchas,
    hideNodes,
    toggleNode,
    toggleSelectNode,
    toggleViewCamera,
    toggleView,
    onToggleNode,
    onToggleSelectNode,
    onTogglePrancha,
    onToggleViewCamera,
    onToggleView,
    togglePrancha,
    onClearAllToggles,
  });

  return (
    <Measure onResize={handleResize}>
      {({measureRef}) => 
        <div 
          ref={measureRef}
          className={`${drawerOpen ? styles['viewer'] : styles['customViewer']}`}  
        >
          <div 
            ref={viewerDiv} 
          ></div>
          <ButtonDrawer
            onClick={() => drawerPropertiesVisible ? closeDrawerProperties() : showDrawerProperties()}
            style={{
              position: 'absolute',
              marginTop: 24,
              right: drawerPropertiesVisible ? 326 : 14,
              zIndex: 99,
            }}
            placement={drawerPropertiesVisible ? "Fechar painél" : "Expandir painél"}
            border
            delay
          >
            <span className={stylesDrawer['iconToggleWrap']} >
                <Icon icon="avancarDuplo"
                  customSize={10}
                  className={`${stylesDrawer['iconChildren']} ${drawerPropertiesVisible ? stylesDrawer['iconActive'] : ''}`} 
                />
            </span>
          </ButtonDrawer>
          {drawerPropertiesVisible && (
            <PropertiesDrawer
              properties={selectedProperties}
              visible={drawerPropertiesVisible}
              onClose={closeDrawerProperties}
              onShow={showDrawerProperties}
            />
          )}
        </div>
      }
    </Measure>
  );
}

const mapState = (state: IGlobalReducerState) => ({
  ...state.federatedViewer,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IInternalViewer;

export default connector(InternalViewer);
