/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from 'react';
import { SimpleViewer } from 'Utils/Viewer/SimpleViewer';
import Measure from 'react-measure';
import { IFileInfo } from 'Data/interfaces/Viewer/IGetFileInfo';
import { Container } from './styles';

import 'Static/css/viewer-forge.css';

interface ISimpleViewerComponent {
  fileInfo?: IFileInfo;
}

const SimpleViewerComponent: FC<ISimpleViewerComponent> = ({ fileInfo }) => {
  const viewerDiv = useRef<HTMLDivElement>(null);
  const viewer = useRef<SimpleViewer | null>(null);
  const [currStarted, setCurrStarted] = useState(false);

  const options = {
    env: 'AutodeskProduction',
    api: 'derivativeV2',
    accessToken: '',
    language: 'pt',
  };
  const options3D = {
    theme: 'light-theme',
    extensions: ['Autodesk.Measure'],
  };

  useEffect(() => {
    viewer.current = new SimpleViewer();

    if (viewerDiv.current !== null && fileInfo) {
      viewer.current.setOptions({
        viewerDiv,
        options,
        options3D,
        fileInfo: fileInfo as IFileInfo,
        isCostPdfRender: false,
        onStarted: setCurrStarted,
      });
      viewer.current.getAccessTokenAndStart();
    }

    return () => {
      if (!viewer.current?.started) return;
      viewer.current?.viewerFinishAndClosed();
    }
  }, []);

  useEffect(() => {
    if (
      viewer.current?.started &&
      viewer.current?.currentViewer &&
      viewerDiv.current !== null &&
      (fileInfo?.urn ||
        (fileInfo?.bucketFileName && fileInfo.extension === 'pdf'))
    ) {
      viewer.current.loadUrn(fileInfo.urn);
      viewer.current.initEventListeners();
    }
  }, [
    viewer.current?.started,
    currStarted,
    viewer.current?.currentViewer,
    fileInfo?.urn,
  ]);

  return (
    <Measure onResize={(rect) => viewer.current?.handleResize(rect)}>
      {({ measureRef }) =>
        <Container ref={measureRef}>
          <div key="viewer1" ref={viewerDiv} className="simpleViewer" />
        </Container>
      }
    </Measure>
  );
};

export default SimpleViewerComponent;
