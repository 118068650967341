import { FC } from "react";
import Modal from "Components/UI/Modal/Modal";
import Button from "Components/UI/Button";
import { Icon } from "Components/UI";
import { ICustomOption } from "./useOptionsSelect";
import ConfirmCloseModal from "./Modals/ConfirmCloseModal";
import MoreAction from "../../components/MoreAction";
import Tooltip from "Components/UI/Tooltip";
import ConfirmDeleteModal from "./Modals/ConfirmDeleteModal";
import {
  ITopicCommunicationType,
  ITopicDnDResponse,
  ITopicPoint,
  ITopicStage,
  ITopicStatus,
} from "Data/interfaces/Activities/IDragAndDropDataFormat";
import LoadingActivitiesExternalLinkHeader from "Components/UI/CustomLoading/LoadingActivitiesExternalLinkHeader";
import LoadingActivitiesExternalLinkContent from "Components/UI/CustomLoading/LoadingActivitiesExternalLinkContent";
import { ConfigProvider } from "antd";
import useShowCreateActivity from "./useShowCreateActivity";
import FormContent from "./components/FormContent";
import { TypeUpdateStatus } from "Data/interfaces/Activities/IUpdateActivityRequest";
import SpinCustom from "Components/UI/SpinCustom";
import AccessPermission from "Utils/AcessPermission";
import { IUserInforResponse } from "Data/interfaces/Auth/IUserInfoResponse";
import ActionsContent from "./components/ActionsContent";
import {
  ButtonsWrapper,
  Content,
  FooterHr,
  LeftContent,
  RightContent,
  TooltipLink,
  VerticalHr,
  Wrapper,
} from "./styles";

interface ICreateActivity {
  visible: boolean;
  onCancel: () => void;
  loadingExternalLink: boolean;
  loading: boolean;
  loadingUpdate: boolean;
  loadingDelete: boolean;
  updateActivity: ITopicDnDResponse | null;
  error?: string;
  csId: number;
  title: string;
  topicStatus: ITopicStatus[];
  topicStages: ITopicStage[];
  topicPoints: ITopicPoint[];
  topicCommunicationType: ITopicCommunicationType[];
  visibilityOptions: ICustomOption[];
  priorityOptions: ICustomOption[];
  priorityNoBgOptions: ICustomOption[];
  statusOptions: ICustomOption[];
  typeOptions: ICustomOption[];
  stageOptions: string[];
  pointsOptions: string[];
  communicationOptions: string[];
  userOptions: ICustomOption[];
  disciplineOptions: ICustomOption[];
  updateStatus: TypeUpdateStatus;
  getOld: boolean;
  onOpenShowActivity: (activitieId: string) => void;
  newActivityGuid?: string;
  userInfo?: IUserInforResponse;
}

export type TypeCustomError = 'title' | 'discipline' | 'point' | 'date';

const ShowCreateActivity: FC<ICreateActivity> = ({
  visible,
  onCancel,
  loading,
  loadingExternalLink,
  loadingUpdate,
  loadingDelete,
  title,
  csId,
  topicStatus,
  topicStages,
  topicPoints,
  topicCommunicationType,
  updateActivity,
  visibilityOptions,
  priorityOptions,
  priorityNoBgOptions,
  statusOptions,
  typeOptions,
  stageOptions,
  pointsOptions,
  communicationOptions,
  userOptions,
  disciplineOptions,
  updateStatus,
  getOld,
  onOpenShowActivity,
  newActivityGuid,
  userInfo,
}) => {
  const {
    isSubmit,
    titleValue,
    defaultValues,
    showCancelModal,
    showDeleteModal,
    showToolTip,
    errors,
    handleTitleValue,
    copyInviteLink,
    submit,
    handleIsSubmit,
    dynamicUpdateSubmit,
    confirmCancelShowCreateActivity,
    confirmDeleteActivity,
    openCancelModal,
    openDeleteModal,
    handleClearCustomError,
    renderEmpty,
    handleCustomError,
    closeCancelModal,
    closeDeleteModal,
  } = useShowCreateActivity({
    onCancel,
    loading,
    loadingExternalLink,
    loadingUpdate,
    loadingDelete,
    updateActivity,
    csId,
    topicStatus,
    topicStages,
    topicPoints,
    topicCommunicationType,
    typeOptions,
    updateStatus,
    getOld,
    onOpenShowActivity,
    newActivityGuid,
    userInfo,
  });

  const isUpdate = !!updateActivity?.Guid;
  const updateLoading = updateStatus === 'atualizando';
  const updateSave = updateStatus === 'atualizado';
  const deletePermission = AccessPermission.deleteActivitiePermission(
    userInfo,
    updateActivity?.CreationAuthor,
    updateActivity?.TopicAssignedUsers,
    updateActivity?.Visibility
  );

  const footerButtons = (
    <ButtonsWrapper>
      <Button
        onClick={openCancelModal}
        type="text"
        className="buttonCancel"
      >
        Cancelar
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        onClick={() => handleIsSubmit(true)}
        className="buttonSubmit"
        disabled={loading}
        loading={loading}
      >
        Cadastrar
      </Button>
    </ButtonsWrapper>
  );

  return (
    <Modal
      visible={visible}
      onCancel={isUpdate ? confirmCancelShowCreateActivity : openCancelModal}
      footerButtons={(isUpdate || loadingExternalLink) ? null : footerButtons}
      footerActive={false}
      width={854}
      zIndex={showCancelModal ? 9 : undefined}
      customClassName
    >
      <ConfigProvider renderEmpty={() => renderEmpty()}>
        <Wrapper>
          {loadingExternalLink && (
            <LoadingActivitiesExternalLinkHeader />
          )}
          {!loadingExternalLink && (
            <div className="header">
              <span className="titleHeader">
                {updateActivity
                  ? (
                    <span>
                      <span className="titleHeaderBold">
                        #{updateActivity.Identifier}
                      </span>
                      {` / ${updateActivity.Type?.Name}`}
                    </span>
                  )
                  : title}
              </span>
              {updateActivity && (
                <div className="multipleIconHeader">
                  {updateStatus && (
                    <div className="updateStatus">
                      {updateLoading && (
                        <span className="updateStatusSpan">
                          <SpinCustom
                            size={14}
                          />
                          <span className="updateStatusTextLoading">
                            Salvando
                          </span>
                        </span>
                      )}
                      {updateSave && (
                        <span className="updateStatusSpan">
                          <Icon
                            icon="confirmacao"
                            color="verde"
                            customSize={14}
                          />
                          <span className="updateStatusTextSave">
                            Salvo
                          </span>
                        </span>
                      )}
                    </div>
                  )}
                  {isUpdate && updateActivity.ConstructionSiteDisciplines.length <= 0 &&
                    <Tooltip
                      placement="top"
                      overlay="Todos os campos obrigatórios precisam estar preenchidos."
                    >
                      <span>
                        <Icon
                          icon="aviso"
                          customSize={14}
                          customColor={'#FF3D57'}
                          className="iconHeaderAlarm"
                        />
                      </span>
                    </Tooltip>
                  }
                  <div className="iconHeader">
                    <Tooltip
                      overlay={
                        showToolTip && (
                          <TooltipLink>
                            <Icon icon="confirmacao" customColor="#09B66D" />
                            <span>Link copiado para a área de transferência</span>
                          </TooltipLink>
                        )
                      }
                      placement="top"
                      open={showToolTip}
                    >
                      <Icon
                        icon="link"
                        customSize={12}
                        customColor={'#324150'}
                        onClick={() => copyInviteLink(updateActivity.Guid)}
                      />
                    </Tooltip>
                  </div>
                  {deletePermission && (
                    <MoreAction
                      className="iconHeader"
                      onDelete={() => openDeleteModal()}
                    />
                  )}
                </div>
              )}
              {!isUpdate && (
                <span className="iconHeader">
                  <Icon
                    icon="cancelar"
                    customSize={8}
                    customColor={'#324150'}
                    onClick={isUpdate ? confirmCancelShowCreateActivity : openCancelModal}
                  />
                </span>
              )}
            </div>
          )}

          <Content>
            <LeftContent>
              {loadingExternalLink && (
                <LoadingActivitiesExternalLinkContent />
              )}
              {!loadingExternalLink && (
                <FormContent
                  updateActivity={updateActivity}
                  visibilityOptions={visibilityOptions}
                  priorityOptions={priorityOptions}
                  priorityNoBgOptions={priorityNoBgOptions}
                  statusOptions={statusOptions}
                  typeOptions={typeOptions}
                  stageOptions={stageOptions}
                  pointsOptions={pointsOptions}
                  communicationOptions={communicationOptions}
                  userOptions={userOptions}
                  disciplineOptions={disciplineOptions}
                  isSubmit={isSubmit}
                  titleValue={titleValue}
                  defaultValues={defaultValues}
                  errors={errors}
                  handleTitleValue={handleTitleValue}
                  submit={submit}
                  dynamicUpdateSubmit={dynamicUpdateSubmit}
                  handleClearCustomError={handleClearCustomError}
                  handleCustomError={handleCustomError}
                  userInfo={userInfo}
                />
              )}
            </LeftContent>

            <VerticalHr />
            <RightContent>
              <ActionsContent
                updateActivity={updateActivity}
                csId={csId}
                loadingExternalLink={loadingExternalLink}
              />
            </RightContent>
          </Content>
        </Wrapper>

        <FooterHr />
      </ConfigProvider>
      {showCancelModal && (
        <ConfirmCloseModal
          visible={showCancelModal}
          onCancel={() => closeCancelModal()}
          onConfirm={confirmCancelShowCreateActivity}
        />
      )}
      {(showDeleteModal && updateActivity) && (
        <ConfirmDeleteModal
          visible={showDeleteModal}
          onCancel={() => closeDeleteModal()}
          loading={loadingDelete}
          onConfirm={() => confirmDeleteActivity()}
          activitieIdentifier={updateActivity.Identifier}
        />
      )}
    </Modal>
  );
}

export default ShowCreateActivity;
