import React, { FC } from "react";
import { Collapse, List, ConfigProvider } from "antd";
import { Icon } from "Components/UI";
import HighlighterWords from "Components/UI/HighlightWords";
import { IViewpoints } from "Data/interfaces/FederatedViewer/IViewpoints";
import styles from './ModelsViewpoints.module.scss';
import { ICamera, IChildrenNames, IDrawerOptions } from "Pages/FederatedViewer/useFederatedViewer";
import { CaretRightOutlined, RightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

interface IModelsViewpoints {
  listItems: IViewpoints[];
  searchItems: string;
  childrenNames: IChildrenNames[];
  activeDrawer?: IDrawerOptions;
  toggleViewCamera: ICamera;
  onToggleViewCamera: (camera: Autodesk.Viewing.BubbleNode | null, urn: string | null, id: number | null) => void;
}

const ModelsViewpoints: FC<IModelsViewpoints> = ({
  listItems,
  searchItems,
  childrenNames,
  activeDrawer,
  toggleViewCamera,
  onToggleViewCamera,
}) => {
  const renderEmpty = () => {
    return <></>
  }

  const isActive = (urn: string, id: number) => {
    return (toggleViewCamera.urn === urn && toggleViewCamera.id === id);
  }

  return (
    <>
      <div className={styles['headerWrapper']}>
        <Icon 
          icon="viewPoint"
          customColor={'#BEC2C6'}
          customSize={16}
        />
        <span>
          {activeDrawer}
        </span>
        <Icon 
          icon="informacaoAdicional"
          customColor={'#BEC2C6'}
          customSize={14}
        />
      </div>
      <ConfigProvider renderEmpty={() => renderEmpty()}>
        {listItems.map((viewpoints, index) => {
          const name = childrenNames.find(name => name.urn === viewpoints.urn)?.name || '';
          const filteredCameras = viewpoints.cameras?.filter((camera) => {
            return camera.name().toLowerCase().includes(searchItems.toLowerCase());
          });
          
          return (
            <Collapse 
              key={`${name}-${index}`}
              bordered={false}
              expandIcon={({ isActive }) => 
                <RightOutlined
                  rev=""
                  rotate={isActive ? 90 : 0} 
                  style={{
                    fontSize: '10px',
                    marginLeft: '8px',
                    marginRight: '17px',
                    color: '#BEC2C6',
                  }}
                />
              }
              accordion
              destroyInactivePanel
              className={styles['childrenCollapse']}
            >
              <Panel 
                key={name} 
                header={<div className={styles['childrenPanelText']}>{name}</div>} 
                className={styles['childrenPanel']}
              >
                {viewpoints.cameras && (
                  <List 
                    itemLayout="horizontal"
                    className={styles['listPanel']}
                    dataSource={filteredCameras}
                    bordered={false}
                    renderItem={(item, index) => {
                      return (
                        <List.Item 
                          key={`${item.name()}-${index}`}
                          className={`
                            ${styles['firstCamera']}
                            ${isActive(viewpoints.urn, item.id) ? styles['firstCameraActive'] : ''}
                          `}
                          onClick={() => onToggleViewCamera(item, viewpoints.urn, item.id)}
                        >
                          <div className={styles['firstCameraWrapper']}>
                            <div 
                              className={styles['firstCameraWrapperLeft']}
                            >
                              <Icon 
                                icon="viewpointsItens"
                                customColor={isActive(viewpoints.urn, item.id) ? '#324150' : '#8697A8'}
                                customSize={12}
                              />
                              <HighlighterWords 
                                text={item.name()} 
                                wordSearched={searchItems} 
                                className={styles['listLeftText']} 
                                maxLength={40}
                              />
                            </div>
                            {isActive(viewpoints.urn, item.id) && (
                              <div className={styles['firstCameraWrapperRight']}>
                                <Icon 
                                  icon="visualizar"
                                  customColor={'#3547C8'}
                                  customSize={10}
                                />
                              </div>
                            )}
                          </div>
                        </List.Item>
                      )
                    }}
                  />
                )}
                {viewpoints.folders && (
                  <List 
                    itemLayout="horizontal"
                    className={styles['listFolder']}
                    dataSource={viewpoints.folders}
                    bordered={false}
                    renderItem={(folder, index) => {
                      const filteredFoldersCameras = folder.cameras?.filter((camera) => {
                        return camera.name().toLowerCase().includes(searchItems.toLowerCase());
                      });

                      return (
                        <Collapse 
                          key={`${folder.name}-${index}`}
                          bordered={false}
                          expandIcon={({ isActive }) => 
                            <CaretRightOutlined
                              rev=""
                              rotate={isActive ? 90 : 0} 
                              style={{
                                fontSize: '10px',
                                marginLeft: '8px',
                                marginRight: '17px',
                                color: '#BEC2C6',
                              }}
                            />
                          }
                          className={styles['childrenCamerasCollapse']}
                        >
                          <Panel 
                            key={folder.name} 
                            header={folder.name} 
                            className={styles['childrenCamerasPanel']}
                          >
                            <List 
                              itemLayout="horizontal"
                              className={styles['listPanel']}
                              dataSource={filteredFoldersCameras}
                              bordered={false}
                              renderItem={(item, index) => {
                                return (
                                  <List.Item 
                                    key={`${item.name()}-${index}`}
                                    className={`
                                      ${styles['childrenList']}
                                      ${isActive(viewpoints.urn, item.id) ? styles['childrenListActive'] : ''}
                                    `}
                                    onClick={() => onToggleViewCamera(item, viewpoints.urn, item.id)}
                                  >
                                    <div className={styles['childrenListWrapper']}>
                                      <div 
                                        className={styles['childrenListWrapperLeft']}
                                      >
                                        <Icon 
                                          icon="viewpointsItens"
                                          customColor={isActive(viewpoints.urn, item.id) ? '#324150' : '#8697A8'}
                                          customSize={12}
                                        />
                                        <HighlighterWords 
                                          text={item.name()} 
                                          wordSearched={searchItems} 
                                          className={styles['listLeftText']} 
                                          maxLength={40}
                                        />
                                      </div>
                                      {isActive(viewpoints.urn, item.id) && (
                                        <div className={styles['childrenListWrapperRight']}>
                                          <Icon 
                                            icon="visualizar"
                                            customColor={'#3547C8'}
                                            customSize={10}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </List.Item>
                                )
                              }}
                            />
                          </Panel>
                        </Collapse>
                      )
                    }}
                  />
                )}
              </Panel>
            </Collapse>
          )
        })}
      </ConfigProvider>
    </>
  )
}

export default ModelsViewpoints;
