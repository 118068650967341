import React, { FC } from 'react';

import styles from './Ellipsis.module.scss';
import Tooltip from '../Tooltip';

interface IEllipsis {
  phrase: string;
  maxLength: number;
  className?: string;
}

const Ellipsis: FC<IEllipsis> = ({ phrase, maxLength, className }) => {
  return (
    <>
      {
        phrase.length > maxLength ?
        (
          <Tooltip title={phrase}>
          <div 
            className={className ? className : styles['phraseWrapper']}
          >
              {`${phrase.slice(0, maxLength - 3)}...`}
            </div>
          </Tooltip>
        ) : (
          <div className={className || ''} >{phrase}</div>
        )
      }
    </>
  );
};

export default Ellipsis;
