import { IFederatedViewerState } from './interfaces/IFederatedViewerState';
import {
  FederatedViewerActionUnion,
  FederatedViewerActionKeys
} from './FederatedViewer.actions';
import { IModels } from 'Data/interfaces/FederatedViewer/IModels';
import { IViewpoints } from 'Data/interfaces/FederatedViewer/IViewpoints';
import { IPranchas } from 'Data/interfaces/FederatedViewer/IPranchas';
import { IViews } from 'Data/interfaces/FederatedViewer/IViews';
import { IBucketFile, IStatusType } from 'Data/interfaces/FederatedViewer/IFileViewerListResponse';
import { IHideNodes } from 'Data/interfaces/FederatedViewer/INodes';
import { IFileData } from 'Data/interfaces/ListagemArquivos/IListFilesResponse';
import { IExtensionsValidated } from 'Data/interfaces/FederatedViewer/IExtensionsValidated';

const initialState: IFederatedViewerState = {
  viewerListDisciplines: [],
  urns: [],
  extensionValidateds: [],
  unloadUrn: undefined,
  models: [],
  modelsTree: [],
  selectedProperties: null,
  views: [],
  viewpoints: [],
  pranchas: [],
  hideNodes: [],
  selectedNode: null,
  isLoading: false,
  loadingDiscipline: false,
  loadingFiles: false,
};

const listagemArquivos = (
  state = initialState,
  action: FederatedViewerActionUnion
): IFederatedViewerState => {
  switch (action.type) {
    case FederatedViewerActionKeys.VIEWER_LIST_DISCIPLINES_REQUEST:
      return {
        ...state,
        loadingDiscipline: true,
      };
    case FederatedViewerActionKeys.VIEWER_LIST_DISCIPLINES_SUCCESS:
      return {
        ...state,
        viewerListDisciplines: action.payload,
        loadingDiscipline: false,
      };
    case FederatedViewerActionKeys.VIEWER_LIST_DISCIPLINES_FAILED:
      return {
        ...state,
        loadingDiscipline: false,
      };

    case FederatedViewerActionKeys.VIEWER_LIST_FILES_REQUEST:
      return {
        ...state,
        loadingFiles: true,
      };
    case FederatedViewerActionKeys.VIEWER_LIST_FILES_SUCCESS:
      let filesList = action.payload.fileList;
      filesList.forEach((fileRow) => {
        if (!fileRow.FileInfo.BucketFile) {
          fileRow.RenderingSignalR = {
            etapa: 0,
            progress: 0,
            fileIdentifier: fileRow.Identifier.ApiId || fileRow.Identifier.ItemId,
          }
        }
      });

      let newFileDisciplines = state.viewerListDisciplines;
      newFileDisciplines.forEach(discipline => {
        if (discipline.ConstructionSiteDisciplinesId === action.payload.constructionSiteDisciplineId) {
          discipline.FilesList = filesList;
        }
      })

      return {
        ...state,
        viewerListDisciplines: [...newFileDisciplines],
        loadingFiles: false,
      };
    case FederatedViewerActionKeys.VIEWER_LIST_FILES_FAILED:
      return {
        ...state,
        loadingFiles: false,
      };
    case FederatedViewerActionKeys.VIEWER_UPDATE_FILE_LIST:
      return {
        ...state,
      };

    case FederatedViewerActionKeys.UPDATE_STATUS_IN_VIEWER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FederatedViewerActionKeys.UPDATE_STATUS_IN_VIEWER_SUCCESS:
      let newFileListStatusDisciplines = state.viewerListDisciplines;
      let newFileStatus = action.payload.file;
      let newDisciplineStatus = action.payload.discipline;
      
      if (newFileStatus.FileInfo.inViewer) {
        if (newFileListStatusDisciplines.some(discipline => discipline.ConstructionSiteDisciplinesId === newFileStatus.FileInfo.ConstructionSiteDisciplineFk)) {
          newFileListStatusDisciplines.forEach(discipline => {
            if (discipline.ConstructionSiteDisciplinesId === newFileStatus.FileInfo.ConstructionSiteDisciplineFk) {
              if (discipline.FilesList) {
                if (!discipline.FilesList.some(file => file.Identifier.ApiId === newFileStatus.Identifier.ApiId || file.Identifier.ItemId === newFileStatus.Identifier.ItemId)) {
                  discipline.FilesList.push(newFileStatus);
                } 
                } else {
                  discipline.FilesList = [newFileStatus];
                }
            }
          });
        } else if (newDisciplineStatus) {
          newDisciplineStatus.FilesList = [newFileStatus];
          newFileListStatusDisciplines.push(newDisciplineStatus);
        }
      } else {
        newFileListStatusDisciplines.forEach(discipline => {
          if (discipline.ConstructionSiteDisciplinesId === newFileStatus.FileInfo.ConstructionSiteDisciplineFk) {
            if (discipline.FilesList) {
              const fileIndex = discipline.FilesList.findIndex(file => (file.Identifier.ApiId || file.Identifier.ItemId) === (newFileStatus.Identifier.ApiId || newFileStatus.Identifier.ItemId));
              discipline.FilesList.splice(fileIndex, 1);
            }
          }
        });
      }
      return {
        ...state,
        viewerListDisciplines: [...newFileListStatusDisciplines],
        isLoading: false
      };
    case FederatedViewerActionKeys.UPDATE_STATUS_IN_VIEWER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case FederatedViewerActionKeys.ADD_NEW_VIEWER_FILE:
      let newFileListDisciplines = state.viewerListDisciplines;
      let newFile = action.payload.file;
      let newDiscipline = action.payload.discipline;

      if (newFileListDisciplines.some(discipline => discipline.ConstructionSiteDisciplinesId === newFile.FileInfo.ConstructionSiteDisciplineFk)) {
        newFileListDisciplines.forEach(discipline => {
          if (discipline.ConstructionSiteDisciplinesId === newFile.FileInfo.ConstructionSiteDisciplineFk) {
            if (discipline.FilesList) {
              const newFileIndex = discipline.FilesList.findIndex(file => file.NameWithoutRevision === newFile.NameWithoutRevision);
              if (newFile.hasRevisionControl && newFileIndex !== -1) {
                if (newFile.RevisionNumber > discipline.FilesList[newFileIndex].RevisionNumber) {
                  discipline.FilesList[newFileIndex] = newFile;
                }
              } else {
                discipline.FilesList.push(newFile);
              }
            } else {
              discipline.FilesList = [newFile];
            }
          }
        });
      } else {
        newDiscipline.FilesList = [newFile];
        newFileListDisciplines.push(newDiscipline);
      }
      return {
        ...state,
        viewerListDisciplines: [...newFileListDisciplines],
        isLoading: false
      };

    case FederatedViewerActionKeys.AUTODESK_FEDERATED_VIEWER_REQUEST:
        return {
          ...state,
          isLoading: true,
        }
    case FederatedViewerActionKeys.AUTODESK_FEDERATED_VIEWER_SUCCESS:
        let newFileListDisciplinesRender = state.viewerListDisciplines;
        let autodeskInfo = action.payload;
        let newFileItemRender: IFileData | undefined;

        newFileListDisciplinesRender.forEach(discipline => {
          if (discipline.FilesList) {
            newFileItemRender = discipline.FilesList.find(file => (file.Identifier.ApiId || file.Identifier.ItemId) === autodeskInfo.fileIdentifier);
            if (newFileItemRender) {
              const handleStatus = (status: IStatusType, etapaRendering?: number) => {
                if (status === 'success') {
                  if ((!etapaRendering || ![2,3].includes(etapaRendering))) {
                    return 1;
                  } else {
                    return 2;
                  }
                }
                if (status === 'rendering') {
                  return 2;
                }
                if (status === 'error') {
                  return 3;
                }
              }
              newFileItemRender.FileInfo.BucketFile = {};
              newFileItemRender.FileInfo.BucketFile.Urn = autodeskInfo.urn;
              newFileItemRender.FileInfo.BucketFile.Status = handleStatus(autodeskInfo.status, newFileItemRender.RenderingSignalR.etapa);
            }
          }
        });
        return {
          ...state,
          ...newFileItemRender,
          isLoading: false,
        };
    case FederatedViewerActionKeys.AUTODESK_FEDERATED_VIEWER_FAILED:
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        }
    
    case FederatedViewerActionKeys.SET_VIEWER_FILE_STATUS_RERENDERING:
      let newFileListDisciplinesReRender = state.viewerListDisciplines;
      let newFileItemReRender: IFileData | undefined;

      newFileListDisciplinesReRender.forEach(discipline => {
        if (discipline.FilesList) {
          newFileItemReRender = discipline.FilesList.find(file => (file.Identifier.ApiId || file.Identifier.ItemId) === action.payload.FileApiId);
          if (newFileItemReRender) {
            if (newFileItemReRender.FileInfo.BucketFile?.Status === 3 || newFileItemReRender.RenderingSignalR?.etapa === 5) {
              newFileItemReRender.FileInfo.BucketFile = {
                ...newFileItemReRender.FileInfo.BucketFile,
                Status: 2,
              }
            }
            newFileItemReRender.RenderingSignalR = {
              etapa: 0,
              progress: 0,
              fileIdentifier: newFileItemReRender.Identifier.ApiId || newFileItemReRender.Identifier.ItemId,
            }
          }
        }
      });

      return {
        ...state,
        ...newFileItemReRender,
        isLoading: false,
      };

    case FederatedViewerActionKeys.SET_AUTODESK_FED_VIEWER_RENDERING_RESPONSE:
      let renderingStatus = action.payload;
      const newListDisciplineRender = state.viewerListDisciplines;
      let fileItem: IFileData | undefined; 

      newListDisciplineRender.forEach(discipline => {
        if (discipline.FilesList) {
          fileItem = discipline.FilesList.find(file => (file.Identifier.ApiId || file.Identifier.ItemId) === renderingStatus.fileIdentifier);

          if (fileItem) {
            if (!fileItem?.FileInfo.BucketFile) {
              fileItem.FileInfo.BucketFile = {} as IBucketFile
            }

            if (!fileItem.RenderingSignalR) {
              fileItem.RenderingSignalR = {
                ...renderingStatus,
                etapa: 1,
                progress: 0,
              }
              fileItem.FileInfo.BucketFile.Status = 2;
            }

            if ([1,2,3].includes(renderingStatus.etapa)) {
              fileItem.RenderingSignalR = {
                ...renderingStatus,
              }
              fileItem.FileInfo.BucketFile.Urn = renderingStatus.urn;
              fileItem.FileInfo.BucketFile.Status = 2;
            }
  
            if (renderingStatus.etapa === 4) {
              fileItem.RenderingSignalR = {
                ...renderingStatus,
                etapa: 0,
                progress: 0,
              }
              fileItem.FileInfo.BucketFile.Urn = renderingStatus.urn;
              fileItem.FileInfo.BucketFile.Status = 1;
            }
  
            if (renderingStatus.etapa === 5) {
              fileItem.RenderingSignalR = {
                ...renderingStatus,
                progress: 0,
              }
              fileItem.FileInfo.BucketFile.Urn = renderingStatus.urn;
              fileItem.FileInfo.BucketFile.Status = 3;
            }
          }
        }
      })
      return {
        ...state,
        ...fileItem,
        isLoading: false,
      };

    case FederatedViewerActionKeys.SET_URNS:
      const newUrns = state.urns;
      let filteredUrns: string[] = [];
      const clearExtensionValidateds = state.extensionValidateds;
      let filteredExtensionValidateds: IExtensionsValidated[] = [];
      const clearModelsTree = state.modelsTree;
      let filteredModelsTree: any[] = [];
      const clearViews = state.views;
      let filteredViews: IViews[] = [];
      const clearViewpoints = state.viewpoints;
      let filteredViewpoints: IViewpoints[] = [];
      const clearPranchas = state.pranchas;
      let filteredPranchas: IPranchas[] = [];
      let clearSelectedProperties = state.selectedProperties;
      let newUnloadUrn = state.unloadUrn;
      const urnExist = newUrns.find(urn => urn === action.payload);
      if (urnExist) {
        filteredUrns = newUrns.filter(urn => urn !== urnExist);
        filteredExtensionValidateds = clearExtensionValidateds.filter(item => item.urn !== urnExist);
        filteredModelsTree = clearModelsTree.filter(item => item.urn !== urnExist);
        filteredViews = clearViews.filter(item => item.urn !== urnExist);
        filteredViewpoints = clearViewpoints.filter(item => item.urn !== urnExist);
        filteredPranchas = clearPranchas.filter(item => item.urn !== urnExist);
        if (clearSelectedProperties) {
          clearSelectedProperties = null;
        }
        newUnloadUrn = urnExist;
      } else {
        filteredUrns = newUrns;
        filteredExtensionValidateds = clearExtensionValidateds;
        filteredUrns.push(action.payload);
        filteredModelsTree = clearModelsTree;
        filteredViews = clearViews;
        filteredViewpoints = clearViewpoints;
        filteredPranchas = clearPranchas;
        newUnloadUrn = undefined;
      }
      return {
        ...state,
        urns: [...filteredUrns],
        extensionValidateds: [...filteredExtensionValidateds],
        unloadUrn: newUnloadUrn,
        modelsTree: [...filteredModelsTree],
        views: [...filteredViews],
        viewpoints: [...filteredViewpoints],
        pranchas: [...filteredPranchas],
        selectedProperties: clearSelectedProperties,
        isLoading: false
      };
    case FederatedViewerActionKeys.SET_EXTENSION_VALIDATED:
      let newExtensionValidatedsState = state.extensionValidateds;
      newExtensionValidatedsState.filter(item => state.urns.includes(item.urn));
      if (newExtensionValidatedsState.length) {
        const objectExist = newExtensionValidatedsState.find(item => item.urn === action.payload.urn);
        if (objectExist) {
          newExtensionValidatedsState.filter(item => item.urn !== action.payload.urn);
        } else {
          newExtensionValidatedsState = [...newExtensionValidatedsState, action.payload];
        }
      } else {
        newExtensionValidatedsState = [action.payload];
      }
      return {
        ...state,
        extensionValidateds: newExtensionValidatedsState,
        isLoading: false
      };
    case FederatedViewerActionKeys.SET_MODELS:
      let newModelsState = state.models;
      let newFilteredModelState: IModels[] = [];
      let newClearHideNodes = state.hideNodes;
      let newFilteredClearHideNodes: IHideNodes[] = [];
      let newClearSelectedNode = state.selectedNode;

      if (action.payload.removeModel) {
        newFilteredModelState =  newModelsState.filter(model => model.urn !== action.payload.urn);
        newFilteredClearHideNodes = newClearHideNodes.filter(hideNode => hideNode.urn !== action.payload.urn.replace('urn:', ''));
        newClearSelectedNode = null;
      } else {
        newFilteredClearHideNodes = newClearHideNodes;
        if (newModelsState.length) {
          const objectExist = newModelsState.find(model => model.urn === action.payload.urn);
          if (objectExist) {
            newFilteredModelState = newModelsState;
          } else {
            newFilteredModelState = [...newModelsState, action.payload];
          }
        } else {
          newFilteredModelState = [action.payload];
        }
      }
      return {
        ...state,
        models: [...newFilteredModelState],
        hideNodes: [...newFilteredClearHideNodes],
        selectedNode: newClearSelectedNode,
        isLoading: false
      };
    case FederatedViewerActionKeys.CLEAR_UNLOAD_URN:
      return {
        ...state,
        unloadUrn: undefined,
        isLoading: false
      };
    case FederatedViewerActionKeys.SET_MODELS_TREE:
      let newModelsTreeState = state.modelsTree;
      newModelsTreeState.filter(model => state.urns.includes(model.urn));
      if (newModelsTreeState.length) {
        const objectExist = newModelsTreeState.find(model => model.urn === action.payload.urn);
        if (objectExist) {
          newModelsTreeState.filter(model => model.urn !== action.payload.urn);
        } else {
          newModelsTreeState = [...newModelsTreeState, action.payload];
        }
      } else {
        newModelsTreeState = [action.payload];
      }
      return {
        ...state,
        modelsTree: newModelsTreeState,
        isLoading: false
      };
    case FederatedViewerActionKeys.SET_SELECTED_PROPERTIES:
      return {
        ...state,
        selectedProperties: action.payload,
        isLoading: false
      };
    case FederatedViewerActionKeys.SET_VIEWS:
      let newViewsState = state.views;
      newViewsState.filter(model => state.urns.includes(model.urn));
      if (newViewsState.length) {
        const objectExist = newViewsState.find(model => model.urn === action.payload.urn);
        if (objectExist) {
          newViewsState.filter(model => model.urn !== action.payload.urn);
        } else {
          newViewsState = [...newViewsState, action.payload];
        }
      } else {
        newViewsState = [action.payload];
      }
      return {
        ...state,
        views: newViewsState,
        isLoading: false
      };
    case FederatedViewerActionKeys.SET_VIEWPOINTS:
      let newViewpointsState = state.viewpoints;
      newViewpointsState.filter(model => state.urns.includes(model.urn));
      if (newViewpointsState.length) {
        const objectExist = newViewpointsState.find(model => model.urn === action.payload.urn);
        if (objectExist) {
          newViewpointsState.filter(model => model.urn !== action.payload.urn);
        } else {
          newViewpointsState = [...newViewpointsState, action.payload];
        }
      } else {
        newViewpointsState = [action.payload];
      }
      return {
        ...state,
        viewpoints: newViewpointsState,
        isLoading: false
      };
    case FederatedViewerActionKeys.SET_PRANCHAS:
      let newPranchasState = state.pranchas;
      newPranchasState.filter(model => state.urns.includes(model.urn));
      if (newPranchasState.length) {
        const objectExist = newPranchasState.find(model => model.urn === action.payload.urn);
        if (objectExist) {
          newPranchasState.filter(model => model.urn !== action.payload.urn);
        } else {
          newPranchasState = [...newPranchasState, action.payload];
        }
      } else {
        newPranchasState = [action.payload];
      }
      return {
        ...state,
        pranchas: newPranchasState,
        isLoading: false
      };
    case FederatedViewerActionKeys.CLEAR_ALL_SETS:
      return {
        ...state,
        urns: [],
        unloadUrn: undefined,
        models: [],
        modelsTree: [],
        views: [],
        viewpoints: [],
        pranchas: [],
        hideNodes: [],
        selectedNode: null,
        selectedProperties: null,
        isLoading: false,
      };

    case FederatedViewerActionKeys.SET_HIDE_NODES:
      const hideNodeAction = action.payload;
      let newHideNodes: IHideNodes[] = state.hideNodes;
      
      if (hideNodeAction) {
        const hideNodeExist = newHideNodes.find(item => item.urn === hideNodeAction.urn);
        if (hideNodeAction.type === 'hide') {
          if (hideNodeExist) {
            hideNodeExist.hideNodes = hideNodeAction.nodeIdArrayHidden || [];
            hideNodeExist.showNodes = hideNodeAction.nodeIdArrayShow || [];
          } else {
            newHideNodes.push({
              urn: hideNodeAction.urn || '',
              hideNodes: hideNodeAction.nodeIdArrayHidden || [],
              showNodes: hideNodeAction.nodeIdArrayShow || [],
            })
          }
        }
        if (hideNodeAction.type === 'show') {
          if (hideNodeExist && hideNodeAction.nodeIdArrayHidden) {
            hideNodeExist.hideNodes = hideNodeAction.nodeIdArrayHidden;
            hideNodeExist.showNodes.push(hideNodeAction.nodeId || 0);
          } 
        }
        if (hideNodeAction.type === 'clearAll') {
          newHideNodes = [];
        }
      } else {
        newHideNodes = [];
      }
      return {
        ...state,
        hideNodes: [...newHideNodes],
        isLoading: false
      };
    case FederatedViewerActionKeys.SET_SELECT_NODE:
      return {
        ...state,
        selectedNode: action.payload,
        isLoading: false,
      };

    case FederatedViewerActionKeys.CREATE_STATUS_FILE_VIEWER_AUTODESK:
      return {
          ...state,
          FileViewerAutodesk: action.payload,
          isLoading: false,
      }

    case FederatedViewerActionKeys.UPDATE_STATUS_FILE_VIEWER_AUTODESK:
      const fileViewer = { ...state }.FileViewerAutodesk;
      if (fileViewer &&
          fileViewer?.fileIdentifier === action.payload.fileIdentifier &&
          ((fileViewer.etapa < action.payload.etapa) || (fileViewer.etapa === action.payload.etapa && fileViewer?.progress < action.payload.progress))) {
        
          return {
            ...state,
            FileViewerAutodesk: action.payload,
            isLoading: false,
          }
        }
        return {
          ...state,
          isLoading: false,
      }

    case FederatedViewerActionKeys.UPDATE_FILE_VIEWER_INFO:
      const newListDisciplinesInfo = state.viewerListDisciplines;
      let fileEditedInfo: IFileData | undefined;

      action.payload.forEach((editFileInfo) => {
        newListDisciplinesInfo.forEach(discipline => {
          fileEditedInfo = discipline.FilesList?.find(
            (file) => (file.Identifier.ApiId || file.Identifier.ItemId) === editFileInfo.ApiId
          );
          if (fileEditedInfo) {
            fileEditedInfo.FileInfo.Description = editFileInfo.Description;
          }
        })
      });
      return {
        ...state,
        ...fileEditedInfo,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default listagemArquivos;
