import * as Yup from 'yup';

const REVISION_FIELD = 4;

export const createSchema = (versionFields: any[]) => {
  const fields: any = {};
  versionFields?.forEach((field) => {
    let validation =  Yup.string().required(' ');
    if (field.CampoVersaoId === REVISION_FIELD) {
      validation = validation.matches(/\d+/g, 'Sem número');
    }
    fields[`field${field.Ordem}`] = validation;
  });

  return Yup.object().shape(fields);
};

const schema = Yup.object().shape({});

export default schema;
