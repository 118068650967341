import React, { CSSProperties, FC, useEffect } from 'react';
import ListContainer from '../Containers/ListContainer/ListContainer';
import { Icon } from '../Icon';
import Field from '../Field';
import Form from '../Form/Form';
import schema, { createSchema } from './UploadFiles.schema';
import TextArea from '../Textarea';
import { INomenclatureData, VersionFieldEnumLabel, SeparatorsData } from '../../../Data/interfaces/Nomenclatura/INomenclatureData';
import { IUploadFileData } from '../../../Data/interfaces/Upload/IUploadFileData';
import { sizeFileRangeToInitials } from '../../../Utils/toolBox';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { iconFileByFilename } from '../../../Utils/Icons';
import { useDispatch } from 'react-redux';
import { ListagemArquivosActions } from '../../../Store/ListagemArquivos/ListagemArquivos.actions';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import RadioButtons from '../RadioButtons';
import { NomenclaturaActions } from '../../../Store/Nomenclatura/Nomenclatura.actions';
import { history } from 'Store';
import styles from './UploadFiles.module.scss';
import 'react-circular-progressbar/dist/styles.css';

export interface IUploadFileItem {
  index: number;
  uploadFile: IUploadFileData;
  nomenclature: INomenclatureData;
  removeFile: (index: number) => void;
  changeFilename: (index: number, name: string) => void;
  isLoading: boolean;
}

const UploadFileItem: FC<IUploadFileItem> = (props) => {
  const [isShowEdit, setIsShowEdit] = React.useState(false);
  const [newNameFileExistSelected, setNewNameFileExistSelected] =
    React.useState<string | number | undefined>();
  const showEdit = () => setIsShowEdit(true);
  const dispatch = useDispatch();

  const checkUpload = props.uploadFile?.checkUploadFileInfo;

  useEffect(() => {
    if (checkUpload) {
      setNewNameFileExistSelected(checkUpload?.FileNameNewRevision);
    }
  }, [checkUpload]);

  if (!props.nomenclature.VersionFields) {
    dispatch(NomenclaturaActions.getNomenclature());
  }

  const hasNomenclatureError = () => {
    return checkUpload && checkUpload.HasInconsistencia;
  };

  const getEditForms = () => {
    if (isShowEdit && hasNomenclatureError() && checkUpload && checkUpload.HasInconsistencia) {
      return nomenclatureForm;
    }

    if (checkUpload && checkUpload.FileNameExist) {
      return existFileForm;
    }

    if (props.uploadFile.updateFileInfo && !props.uploadFile.updateFileInfo.finished) {
      return descriptionForm;
    }

    return '';
  };

  const nomenclatureSubmit = (data: any) => {
    const fields = Object.keys(data);
    let filename = '';
    for (let i = 0; i < fields.length / 2; i++) {
      filename += `${data[`field${i}`]}${data[`separator${i}`]}`;
    }
    const splittedValues = props.uploadFile.file.name.split('.');
    const extension = splittedValues[splittedValues.length - 1];
    filename = `${filename.slice(0, -1)}.${extension}`;

    props.changeFilename(props.index, filename);
  };

  const getWidthInput = (label: string) => {
    const width = label.length * 8.5;
    return width < 90 ? undefined : { minWidth: width } as CSSProperties;
  }

  const existFileForm = (
    <div className={styles['newFileNameOptionsWrap']} >
      <div className={styles['titleButtonWrap']} >
        <span className={styles['existFileTitle']}>Nome do arquivo já existe, selecione uma opção:</span>
        <button
          type='submit'
          className={`${styles['confirmButton']} ${styles['buttonNewFileName']}`}
          onClick={() => props.changeFilename(props.index, String(newNameFileExistSelected))}>
          <Icon icon='confirmacao' customColor={'#6FCF90'} customSize={26} />
        </button>
      </div>
      <div className={styles['radioButtonsWrap']} >
        <RadioButtons
          options={[
            {
              value: checkUpload?.FileNameNewRevision || '',
              label: (<div className={styles['newFileNameOption']}>
                <div>{checkUpload?.FileNameNewRevision}</div>
                <div className={styles['titleOption']}>Nova revisão</div>
              </div>),
            },
            {
              value: checkUpload?.FileNameCopy || '',
              label: (<div className={styles['newFileNameOption']}>
                <div>{checkUpload?.FileNameCopy}</div>
                <div className={styles['titleOption']}>Cópia</div>
              </div>),
            },
          ]}
          onSelect={setNewNameFileExistSelected}
          defaultValue={checkUpload?.FileNameNewRevision} />
      </div>
    </div>
  );

  const nomenclatureForm = (
    <div className={styles['fileItemEditWrapper']}>
      <div>
        <Form onSubmit={nomenclatureSubmit} schema={createSchema(props.nomenclature.VersionFields)}>
          {
            (propsField: any) => (
              <div className={styles['formWrapper']} >
                <div className={styles['nomenclatureFieldsWrapper']} >
                  {
                    props.nomenclature.VersionFields.map((field, index) => (
                      <span key={`fields${index}`} style={getWidthInput(field.CampoPersonalizado || VersionFieldEnumLabel.get(field.CampoVersaoId) || '')} >
                        <Field
                          key={`field${index}`}
                          name={`field${field.Ordem}`}
                          defaultValue={checkUpload?.Division[index]}
                          label={field.CampoPersonalizado
                            || VersionFieldEnumLabel.get(field.CampoVersaoId)
                            || ''}
                          style={getWidthInput(field.CampoPersonalizado || VersionFieldEnumLabel.get(field.CampoVersaoId) || '')}
                        />
                        <Field
                          key={`separator${index}`}
                          name={`separator${field.Ordem}`}
                          label=''
                          value={SeparatorsData.get(field.Separador)?.Value}
                          type='hidden' />
                      </span>
                    ))
                  }
                </div>
                <button type='submit' className={styles['confirmButton']} disabled={propsField.hasValidationErrors} >
                  <Icon icon='confirmacao' customColor={'#6FCF97'} customSize={26} />
                </button>
              </div>
            )
          }
        </Form>
      </div>
    </div >
  );

  const descriptionSubmit = (data: any) => {
    if (props.uploadFile.updateFileInfo) {
      if (!data.description) {
        props.uploadFile.updateFileInfo.notShowToast = true;
      }
      props.uploadFile.updateFileInfo.Description = data.description;
      dispatch(ListagemArquivosActions.updateFileInfo([props.uploadFile.updateFileInfo]));
    };
  }

  const descriptionForm = (
    <div className={styles['fileItemEditWrapper']} >
      <div>
        <Form onSubmit={descriptionSubmit} schema={schema}>
          <div className={styles['formWrapper']} >
            <TextArea
              name='description'
              label='Descrição do arquivo'
              placeholder='Insira uma descrição para o arquivo'
              style={{ height: 91 }} />
            <button
              type='submit'
              className={`${styles['confirmButton']} ${styles['confirmDescriptionButton']}`}>
              <Icon icon='confirmacao' customColor={'#6FCF97'} customSize={26} />
            </button>
          </div>
        </Form>
      </div>
    </div>
  );

  const handleOpenFolder = (uploadFile: IUploadFileData) => {
    if (!uploadFile.uploadBreadcrumbFile) return;

    history.push(`/obras/filelist/${uploadFile.csId}/${uploadFile.uploadBreadcrumbFile.fileParentId}${uploadFile.uploadBreadcrumbFile?.fileParentBreadcrumbId ? `?breadCrumbId=${uploadFile.uploadBreadcrumbFile.fileParentBreadcrumbId}` : ''}`);
  };

  if (props.uploadFile.removed) {
    return <></>;
  }

  return (
    <ListContainer height={'auto'}>
      <div className={styles['fileItemWrapper']}>
        <div className={styles['fileItemDescriptionWrapper']} >
          <Icon
            icon={iconFileByFilename(props.uploadFile.file.name)}
            color={hasNomenclatureError() ? 'vermelho' : 'cinzaPadrao'}
            customSize={24} />
          <div className={styles['filenameAndEditWrapper']} >
            <div className={styles['filenameWrapper'] + " " + (props.uploadFile.updateFileInfo && !props.uploadFile.updateFileInfo.finished ? styles["showEdit"] : "")} >
              <div className={styles['fileName']}>
                {
                  hasNomenclatureError() || props.uploadFile.uploadError
                    ? (<>
                      <span className={styles['hasError']}>
                        {props.uploadFile.newFileName || props.uploadFile.file.name}
                      </span>
                      {hasNomenclatureError() ? <span className={styles['hasError']}>Nomeclatura inválida</span> : <span className={styles['hasError']}>Erro ao enviar o arquivo</span>}
                    </>)
                    : <span>{props.uploadFile.newFileName || props.uploadFile.file.name}</span>
                }

              </div>
              {
                props.uploadFile.isSending
                  ? (<span className={styles['fileSize']}>
                    {sizeFileRangeToInitials(props.uploadFile.sentSize, props.uploadFile.totalSize)}
                  </span>)
                  : ''
              }
            </div>
            {
              hasNomenclatureError() && !isShowEdit
              && (<div className={styles['editButton']} onClick={showEdit}>
                <Icon icon='editar' color='primary' />
              </div>)
            }
          </div>
          <div>
            {
              hasNomenclatureError() && isShowEdit
              && (<span className={styles['removeFileButton']} onClick={() => props.removeFile(props.index)} >
                <Icon icon='cancelar' color='cinzaPadrao' customSize={10} />
              </span>)
            }
            {
              props.uploadFile.isSending && (!props.uploadFile.updateFileInfo?.finished || props.uploadFile.updateFileInfo?.finished === undefined)
                ? (<CircularProgressbar
                  className={styles['loading']}
                  strokeWidth={14}
                  styles={buildStyles({ pathColor: '#3547C8', trailColor: '#EDEFF2' })}
                  value={props.uploadFile.sentSize / props.uploadFile.totalSize}
                  maxValue={1} />) : <></>
            }
            {
              props.uploadFile.updateFileInfo?.finished ? (
                <span
                  className={styles['openOriginFolder']}
                  onClick={() => handleOpenFolder(props.uploadFile)}
                >
                  <Icon icon='arquivar' customSize={11} customColor={'#FFF'} />
                </span>
              ) : ''
            }
            {
              !(props.uploadFile.isSending || props.uploadFile.updateFileInfo?.finished || props.uploadFile.uploadCompleted || hasNomenclatureError() || checkUpload?.FileNameExist || props.uploadFile.uploadError)
                ? (<Spin indicator={<LoadingOutlined rev="" style={{ fontSize: 20 }} spin />} />) : ''
            }
          </div>
        </div>

        {getEditForms()}

      </div>
    </ListContainer>);
};

export default UploadFileItem;
