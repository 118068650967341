import styled, { css } from "styled-components";

export const TableWrapper = styled.div`
  margin-right: 42px;
  margin-left: 38px;
  /* max-height: calc(100% - 158px); */

  table{
    background-color: hsla(0,0%,89.8%,.3);
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  thead {
    tr th:first-child {
      width: 85px;
    }
  }
  
  tbody {
    display: block;
    max-height: calc(100vh - 366px);
    overflow-y: auto;
    
    tr td:first-child {
      width: 85px;
    }
  }
`;

interface IDisciplineItem {
  error?: boolean;
}

export const DisciplineItem = styled.div<IDisciplineItem>`
  display: flex;
  align-items: center;
  user-select: none;
  height: 51px;
  cursor: pointer;

  .disciplineItemText {
    color: #44566C;
    padding: 0 18px;
  }

  ${({error}) => error && css`
    cursor: default;
    
    .disciplineItemText {
      color: #E2574C;
      cursor: pointer;
    }
  `}
`;
