import { FC, useEffect, useMemo, useState } from 'react';
import PageHeader from '../../../Components/UI/PageHeader/PageHeader';
import Table from '../../../Components/UI/Table';
import BreadCrumb from '../../../Components/UI/BreadCrumb';
import { ListagemArquivosActions } from '../../../Store/ListagemArquivos/ListagemArquivos.actions';
import { ConnectedProps, connect, useDispatch, useSelector } from 'react-redux';
import { PlotagemActions } from '../../../Store/Plotagem/Plotagem.actions';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import { IPlotagemDisciplinas, breadCrumbs, columnsTable } from './PlotagemDisciplinas.helpers';
import emptyPlotImg from '../../../Static/images/plotagem-discipline-vazio.png';
import TabsShell, { PathActiveEnum } from 'Components/UI/TabsShell';
import LoadingDisciplinas from 'Components/UI/CustomLoading/LoadingDisciplinas';
import AvisoPlotagemUpgrade from '../AvisoPlotagemUpgrade';
import { history } from 'Store';
import { ObraActions } from 'Store/Obra/Obra.actions';
import Button from 'Components/UI/Button';
import { Mixpanel } from 'Utils/MixPanel';
import { BackgroundPlanFree, TableWrapper, WithoutConstruction } from './styles'
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';

const PlotagemDisciplinas: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const currentTenant = useSelector(getCurrentTenant);

    const [selectedDisciplinas, setSelectedDisciplinas] = useState([]);
    const [showErrorSAConnectionInfo, setShowErrorSAConnectionInfo] = useState(false);
    const obraApi = useMemo(() => {
      if (props.obrasList && props.obrasList.length > 0) {
        const currentObra = props.obrasList.find(obra => 
          Number(props.match.params.constructionSiteId) === obra.ConstructionSiteId
        )
        if (currentObra) {
          return currentObra.Api;
        }
      }

      return 0;
    }, [props.obrasList, props.match.params.constructionSiteId])

    const onChange = (selecteds: any) => setSelectedDisciplinas(selecteds);

    useEffect(() => {
      if (!props.isLoading) {
        setSelectedDisciplinas([]);
      }
    }, [props.isLoading]);

    const rowSelection = {
      selectedDisciplinas,
      onChange,
    };

    useEffect(() => {
      dispatch(PlotagemActions.listDisciplinasPlotagem(props.match.params.constructionSiteId));
      dispatch(ListagemArquivosActions.setShowPlotRequestTable(false))
    }, [props.match.params.constructionSiteId, dispatch]);

    useEffect(() => {
      const pathname = history.location.pathname;
      const search = history.location.search;
      dispatch(ObraActions.storePlotagensPath({
        ...props.match,
        url: `${pathname}${search}`
      }));
    }, [props.match]);

    const openUpgradePremium = () => {
      Mixpanel.track({
        name: 'CALL_T0_ACTION', 
        props: {
          origin: 'plotagens',
          originPath: window.location.pathname
        },
        userInfo: props.userInfo,
        currentListTenant: currentTenant,
      });

      if (props.userInfo?.CurrentRoleFk === 1) {
        history.push('/faturamento/planos');
      } else {
        window.open('https://bim.maletadoengenheiro.com.br/seja-premium');
      }
    };

    const handleShowErrorSAConnectionInfo = () => {
      setShowErrorSAConnectionInfo(!showErrorSAConnectionInfo);
    }

    if (props.DisciplinaResponse.info && !props.isLoading) {
      breadCrumbs[0] = {
        description: `${props.DisciplinaResponse.info.Name}`,
        href: `/obras/detail/${props.match.params.constructionSiteId}`,
      };
      breadCrumbs[1] = {
        description: 'Plotagens',
        href: `/obras/plotagens/${props.match.params.constructionSiteId}`,
      };
    } else {
      breadCrumbs[0] = {
        href: '',
        isLoading: true,
      };
      breadCrumbs[1] = {
        href: '',
        isLoading: true,
      };
    }

    return (
      <>
        <TabsShell 
          ConstructionSiteId={props.match.params.constructionSiteId}
          active={PathActiveEnum.plotagens}
          loading={props.isLoading}
          match={props.match}
        />
        {props.planFree ? (
          <BackgroundPlanFree>
              <div className="plotagem-free-wrapper">
                <AvisoPlotagemUpgrade 
                  onConfirm={openUpgradePremium} 
                  role={props.userInfo?.CurrentRoleFk}
                />
              </div>
          </BackgroundPlanFree>
        ) : (
          <PageHeader name={(!props.DisciplinaResponse.data || props.DisciplinaResponse.data.length <=0) && !props.isLoading ? "Plotagens" : "Disciplinas"}>
            <BreadCrumb breadcrumbs={breadCrumbs} />
            {(!props.DisciplinaResponse.data || props.DisciplinaResponse.data.length <=0) && !props.isLoading ? (
              <WithoutConstruction>
                <img src={emptyPlotImg} alt='Plotagem' />
                <span className="title">
                  Essa obra não possui nenhuma plotagem
                </span>
                <Button
                  className="buttonEmptyPlot"
                  onClick={() => window.open('https://help.maletadoengenheiro.com.br/support/solutions/articles/151000113432-como-fazer-o-controle-de-plotagem', '_blank', 'noopener,noreferrer')}
                >
                  Quero ajuda com a minha primeira plotagem
                </Button>
              </WithoutConstruction>
            ) : (
              <TableWrapper>
                {props.isLoading ? ( 
                  <LoadingDisciplinas plotagem multiple={6} />
                ) : (
                  props.DisciplinaResponse.data && (
                    <Table
                      columns={
                        columnsTable(
                          props.DisciplinaResponse.data, 
                          props.DisciplinaResponse.info, 
                          props.match.params.constructionSiteId, 
                          dispatch,
                          obraApi,
                          handleShowErrorSAConnectionInfo,
                        )
                      }
                      dataSource={props.DisciplinaResponse.data?.map((discipline: any, index: number) =>
                        ({ ...discipline, key: index }))}
                        isSpaced={true}   
                        rowSelection={rowSelection}
                    />
                  )
                )}
              </TableWrapper>
            )}
          </PageHeader>
        )}
      </>
    );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.plotagem,
  planFree: state.auth.planFree,
  userInfo: state.auth.userInfo,
  obrasList: state.obra.ObrasList,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IPlotagemDisciplinas;

export default connector(PlotagemDisciplinas);
