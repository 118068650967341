import React, { FC } from "react";

import styles from "./LoadingUsuarios.module.scss";

import SkeletonMain from "../LoadingMain";
import ColumnHeader from "Components/UI/Table/ColumnHeader";

export interface ILoadingUsuarios {
  multiple?: number;
}

const LoadingUsuarios: FC<ILoadingUsuarios> = ({ multiple = 1 }) => {
  const SkeletonUsers = () => {
    return (
      <>
        <tr className={styles['rowTable']}>
          <td>
            <span className={styles['rowName']}>
              <SkeletonMain width="32px" height="32px" borderRadius="50%" marginLeft="24px" minWidth="32px"/>
              <div>
                <SkeletonMain width="100%" height="14px" borderRadius="2px" marginLeft="12px" minWidth="80px" maxWidth="303px" />
                <SkeletonMain width="80%" height="12px" borderRadius="2px" marginLeft="12px" minWidth="60px" maxWidth="303px" />
              </div>
            </span>
          </td>
          <td style={{ width: '150px' }}>
            <span className={styles['rowName']}>
              <SkeletonMain width="100%" maxWidth="80px" height="24px" borderRadius="2px" />
            </span>
          </td>
          <td style={{ width: '250px' }}>
            <span className={styles['rowName']}>
              <SkeletonMain width="28px" height="28px" borderRadius="50%" minWidth="28px"/>
              <SkeletonMain width="28px" height="28px" borderRadius="50%" marginLeft="6px" minWidth="28px"/>
              <SkeletonMain width="28px" height="28px" borderRadius="50%" marginLeft="6px" minWidth="28px"/>
            </span>
          </td>
          <td style={{ width: '250px' }}>
            <span className={styles['rowName']}>
              <div className={styles['rowPaper']}>
                <SkeletonMain width="70%" height="12px" borderRadius="2px" />
                <SkeletonMain width="14px" height="14px" borderRadius="2px" marginLeft="58px" />
              </div>
            </span>
          </td>
          <td style={{ width: '35px', textAlign: 'center' }}>
            <SkeletonMain width="16px" maxWidth="16px" height="16px" borderRadius="2px" />
          </td>
        </tr>
        <tr className={styles['rowSeparator']}></tr>
      </>   
    )
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(SkeletonUsers);
  }

  return (
    <>
      <table className={styles['renderTable']}>
        <thead className={styles['headTable']}>
          <tr>
            <th>
              <ColumnHeader
                title="Usuário"
              />
            </th>
            <th style={{ width: '100px' }}>
              <ColumnHeader
                title="Status"
                showOrder={false}
              />
            </th>
            <th style={{ width: '125px' }}>
              <ColumnHeader
                title="Obras"
                showOrder={false}
              />
            </th>
            <th style={{ width: '130px' }}>
              <ColumnHeader
                title="Papel"
                showOrder={false}
                informacaoAdicional=" "
              />
            </th>
            <th style={{ width: '150px' }}>
              <ColumnHeader
                title=""
                showOrder={false}
              />
            </th>
          </tr>
          <tr className={styles['rowSeparator']}></tr>
        </thead>
        
        <tbody className={styles['rowTable']}>
          {multipleSkeletonsObras.map((Skeleton, i) => (
            <Skeleton key={`skeleton-key-${i}`} />
          ))}
        </tbody>
      </table>
    </>
  )
}

export default LoadingUsuarios;