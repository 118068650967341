import { FC, useEffect, useState } from 'react';
import { Icon } from '../Icon';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { UploadActions } from '../../../Store/Upload/Upload.actions';
import { IUploadFileData } from 'Data/interfaces/Upload/IUploadFileData';
import { ApisEnum } from 'Data/enums/Apis';
import { Mixpanel } from 'Utils/MixPanel';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';
import UploadFileItem from './UploadFileItem';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import NewUploadFileItem from './NewUploadFileItem';
import styles from './UploadFiles.module.scss';
import { UploadFileWrapper } from './styles';

export interface ITrackUploadBulkFiles {
  type?: 'init' | 'time' | 'last' | 'finish' | 'error';
  active?: boolean;
  elapsedTime?: number;
  totalFilesSize?: number;
  totalFiles?: number;
  lastItem?: boolean;
  destiny?: any;
  api?: ApisEnum;
}

const UploadFiles: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const namingPatternFeature = useFeatureFlag('nomenclature-default-feature');
  const isNamingPatternEnabled = namingPatternFeature.enabled && namingPatternFeature.value === 'test';

  const [collapse, setCollapse] = useState(false);

  const toggle = () => { setCollapse(!collapse); };

  const isAllFilesFinished = () => {
    return props.uploadFileList?.every((uploadFile) => uploadFile.uploadCompleted || uploadFile.removed || uploadFile.uploadError);
  };

  const closeUploadFiles = () => {
    dispatch(UploadActions.updateUploadFileList([]));
  };

  const removeFile = (index: number) => {
    if (props.uploadFileList) {
      props.uploadFileList[index].removed = true;
      dispatch(UploadActions.updateUploadFileList([...props.uploadFileList]));
    }
  };

  const changeFilename = (index: number, name: string) => {
    if (props.uploadFileList) {
      props.uploadFileList[index].newFileName = name;
      props.uploadFileList[index].checkUploadFileInfo = undefined;
      dispatch(UploadActions.updateUploadFileList([...props.uploadFileList]));
    }
  };

  useEffect(() => {
    const uploadSingleFile = (data: IUploadFileData) => {
      dispatch(UploadActions.uploadChunkFiles(data));
    }

    if (props.uploadFileList && props.uploadFileList.length > 0) {
      // track upload bulk
      const notUpload = props.uploadFileList.filter(file => !file.uploadCompleted);
      if (!props.trackUploadBulkFiles?.active && notUpload.length > 1) {
        const totalSize = notUpload.reduce((acc, value) => {
          return acc += value.file.size;
        }, 0);
        const request: ITrackUploadBulkFiles = {
          type: 'init',
          active: true,
          totalFiles: notUpload.length,
          totalFilesSize: totalSize,
          elapsedTime: 0,
          lastItem: false,
        };
        dispatch(UploadActions.trackUploadBulkFiles(request));
      }
      if (props.trackUploadBulkFiles?.active && notUpload.length === 1) {
        const request: ITrackUploadBulkFiles = {
          type: 'last',
          lastItem: true,
        };
        dispatch(UploadActions.trackUploadBulkFiles(request));
      }

      //verificar check de nomenclatura
      const filesToCheckNomenclature = props.uploadFileList?.filter(x => !(x?.checkUploadFileInfo || x.isChecking));

      if (filesToCheckNomenclature && filesToCheckNomenclature.length > 0) {

        filesToCheckNomenclature.forEach(x => x.isChecking = true);
        dispatch(UploadActions.checkUploadFiles(filesToCheckNomenclature));
      }

      //realizar upload do arquivo
      const filesUploading = props.uploadFileList.filter(x => x.isSending);

      if (!filesUploading || filesUploading?.length === 0) {

        const filesToUpload = props.uploadFileList.filter(file =>
          !file.checkUploadFileInfo?.FileNameExist &&
          !file.checkUploadFileInfo?.HasInconsistencia &&
          !file.isSending &&
          !file.isChecking &&
          !file.uploadCompleted &&
          !file.updateFileInfo?.finished &&
          !file.uploadError);

        if (filesToUpload?.length > 0) {
          const fileToUpload = filesToUpload[0];
          fileToUpload.isSending = true;

          if (!fileToUpload.uploadCompleted && !fileToUpload.updateFileInfo?.finished) {
            fileToUpload.Api = props.Api;

            if (isNamingPatternEnabled) {
              if (props.versionConfig) {
                fileToUpload.nomenclaturaControlTracking = props.versionConfig;
                fileToUpload.hasRevisionControl = props.versionConfig.HasRevisionControl;
              }

            } else {
              if (props.NomenclatureData) {
                fileToUpload.nomenclaturaControlTracking = props.NomenclatureData;
                fileToUpload.hasRevisionControl = props.NomenclatureData.HasRevisionControl;
              }
            }

            uploadSingleFile(fileToUpload);
          }
        }
      }
    }
  }, [dispatch, props.uploadFileList, props.Api, isNamingPatternEnabled]);

  useEffect(() => {
    if (props.trackUploadBulkFilesNow && props.trackUploadBulkFiles) {
      if (props.trackUploadBulkFiles.type === 'error') {
        Mixpanel.track({
          name: 'FILE_BULK_UPLOAD_ERROR',
          props: {
            quantity: props.trackUploadBulkFiles?.totalFiles,
            elapsed: props.trackUploadBulkFiles?.elapsedTime,
            destiny: props.trackUploadBulkFiles?.destiny,
            api: props.trackUploadBulkFiles?.api,
            filesTotalSize: props.trackUploadBulkFiles?.totalFilesSize,
          },
          userInfo: props.userInfo,
          currentListTenant: currentTenant,
        });
      }

      if (props.trackUploadBulkFiles.type === 'finish') {
        Mixpanel.track({
          name: 'FILE_BULK_UPLOAD',
          props: {
            quantity: props.trackUploadBulkFiles.totalFiles,
            elapsed: props.trackUploadBulkFiles.elapsedTime,
            destiny: props.trackUploadBulkFiles.destiny,
            api: props.trackUploadBulkFiles.api,
            filesTotalSize: props.trackUploadBulkFiles.totalFilesSize,
          },
          userInfo: props.userInfo,
          currentListTenant: currentTenant,
        });
      }

      dispatch(UploadActions.clearTrackUploadBulkFiles());
    }
  }, [props.trackUploadBulkFilesNow, props.trackUploadBulkFiles, props.userInfo]);

  if (isNamingPatternEnabled) {
    return (
      <UploadFileWrapper hidden={!props.uploadFileList?.length}>
        <div className="headerUploadWrapper">
          <span className="title" onClick={toggle}>Envio de arquivos</span>
          {isAllFilesFinished() ? (
            <div onClick={closeUploadFiles}>
              <Icon
                icon='errorSenha'
                customSize={10}
                className="headerUploadIcon"
                />
            </div>
          ) : (
            <div onClick={toggle}>
              <Icon
                icon={collapse ? 'avancar' : 'expandir'}
                customSize={10}
                className="headerUploadIcon"
                />
            </div>
          )}
        </div>
        <div className="listFilesWrapper" hidden={collapse}>
          {props.uploadFileList?.map((uploadFile, index) => {
            return (
              <NewUploadFileItem
                key={`uploadedItem${index}`}
                index={index}
                uploadFile={uploadFile}
                nomenclature={props.versionConfig}
                removeFile={removeFile}
                changeFilename={changeFilename}
                isLoading={props.isLoadingFileList}
              />
            )
          })}
        </div>
      </UploadFileWrapper>
    );
  }

  return (
    <div className={styles['uploadFileWrapper']} hidden={!props.uploadFileList?.length}>
      <div className={styles['headerUploadWrapper']}>
        <span className={styles['title']} onClick={toggle}>Envio de arquivos</span>
        {
          isAllFilesFinished()
            ? (<div onClick={closeUploadFiles}>
              <Icon
                icon='errorSenha'
                customSize={10}
                customColor='#44566C' />
            </div>)
            : (<div onClick={toggle}>
              <Icon
                icon={collapse ? 'avancar' : 'expandir'}
                customSize={10}
                customColor='#44566C' />
            </div>)
        }
      </div>
      <div className={styles['listFilesWrapper']} hidden={collapse}>
        {props.uploadFileList?.map((uploadFile, index) => {
          return (
            <UploadFileItem
              key={`uploadedItem${index}`}
              index={index}
              uploadFile={uploadFile}
              nomenclature={props.NomenclatureData}
              removeFile={removeFile}
              changeFilename={changeFilename}
              isLoading={props.isLoadingFileList}
            />
          )
        })}
      </div>
    </div>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.nomenclatura,
  ...state.upload,
  isLoadingFileList: state.listagemArquivos.isLoading,
  userInfo: state.auth.userInfo,
  Api: state.listagemArquivos.Obra.Api,
  versionConfig: state.listagemArquivos.Obra.VersionConfig,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(UploadFiles);
