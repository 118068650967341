import { FC, CSSProperties } from 'react';

import styles from './Field.module.scss';
import { Field as FieldReact } from 'react-final-form';
import { IconName, Icon } from '../Icon';
import { valueType } from 'antd/lib/statistic/utils';
import styled, { css } from 'styled-components';

export interface IField {
  label: string;
  name: string;
  placeholder?: string;
  style?: CSSProperties;
  className?: string;
  icon?: IconName;
  type?: string;
  autoFocus?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  onInput?: (value: string) => void;
  onChange?: (e?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onCustomChange?: (value: string) => void;
  value?: string;
  defaultValue?: string | number | undefined;
  parse?: (value: any, name: string) => valueType;
  maxLenght?: number;
  typeInput?: string;
  outError?: string;
  height?: string;
}

const hasError = (meta: any, disabled?: boolean) =>
  !disabled && meta.invalid && meta.touched;

const Field: FC<IField> = ({
  label,
  style,
  className,
  name,
  defaultValue,
  icon,
  type = 'text',
  placeholder = label,
  onFocus,
  onBlur,
  onInput,
  disabled,
  autoFocus,
  value,
  onChange,
  onCustomChange,
  parse,
  maxLenght,
  typeInput,
  outError
}) => {
  return (
    <FieldReact
      name={name}
      type={type}
      defaultValue={defaultValue}
      initialValue={value}
      parse={parse}
      typeInput={typeInput}
    >
      {!typeInput
        ? (props) => (
            <label
              hidden={type === 'hidden'}
              className={`${styles['labelInput']} ${className ?? ''} `}
              style={style}
            >
              {icon ? <Icon icon={icon} color="cinzaIcone" /> : ''}
              <input
                {...props.input}
                disabled={disabled}
                maxLength={maxLenght}
                placeholder={placeholder}
                autoFocus={autoFocus}
                className={`
                  ${icon ? styles['paddingIcon'] : ''}
                    ${
                      hasError(props.meta, disabled) && !props.meta.active
                        ? styles['invalid']
                        : ''
                    } ${disabled ? styles['disabledInput'] : ''}`}
                onInput={(e: any) => onInput && onInput(e.target.value)}
                onChangeCapture={(e) => {
                  onChange && onChange(e);
                  onCustomChange && onCustomChange(e.currentTarget.value)
                }}
              />
              <span className={styles['label']}>{label}</span>
              <div className={`${styles['errorInput']} errorCustomInput`}>
                {hasError(props.meta, disabled) ? props.meta.error : ''}
                {!hasError(props.meta, disabled) && outError ? outError : ''}
              </div>
              {props.meta.active ? onFocus && onFocus() : onBlur && onBlur()}
            </label>
          )
        : (props) => (
            <label
              hidden={type === 'hidden'}
              className={`${styles['labelInput']} ${className ?? ''} `}
              style={style}
            >
              {icon ? <Icon icon={icon} color="cinzaIcone" /> : ''}
              <textarea
                {...props.input}
                disabled={disabled}
                maxLength={maxLenght}
                placeholder={placeholder}
                autoFocus={autoFocus}
                className={`
                    ${icon ? styles['paddingIcon'] : ''}
                      ${
                        hasError(props.meta, disabled) && !props.meta.active
                          ? styles['invalid']
                          : ''
                      }`}
                onInput={(e: any) => onInput && onInput(e.target.value)}
                onChangeCapture={onChange}
              />
              <span className={styles['label']}>{label}</span>
              <div className={`${styles['errorInput']} errorCustomInput`}>
                {hasError(props.meta, disabled) ? props.meta.error : ''}
              </div>
              {props.meta.active ? onFocus && onFocus() : onBlur && onBlur()}
            </label>
          )}
    </FieldReact>
  );
};

export default styled(Field)`
  > input {
    color: ${({theme}) => theme.colors.surface.onSurface} !important;
    outline-color: ${({theme}) => `${theme.colors.primary.primary} !important`};
    ${({theme}) => theme.font ? `${theme.colors.primary.primary} !important` : undefined}
    border-radius: 5px;

    ${({height}) => !!height && css`
      height: ${height};
    `}
  }
`;
