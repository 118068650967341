import { IAdmState } from "./interfaces/IAdmState";
import { AdmActionUnion, AdmActionKeys } from "./Adm.actions";
import { ITenantAdm } from "Data/interfaces/Tenant/ITenantAdm";
import { IPurchaseHistories } from "Data/interfaces/Store/IPurchaseHistories";

const initialState: IAdmState = {
  isLoading: false,
  getIsLoading: false,
  Planos: undefined,
  Plano: undefined,
  Tenants: undefined,
  tenantUsers: undefined,
  tenantIsLoading: false,
  planIsLoading: false,
  BillingData: undefined,
  adminChangeLoading: false,
  tenantPersonalization: undefined,
  tenantPersonalizationLoading: false,
}

const admReducer = (state = initialState, action: AdmActionUnion): IAdmState => {
  switch (action.type) {
    case AdmActionKeys.LIST_PLANS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case AdmActionKeys.LIST_PLANS_SUCCESS:
      return {
        ...state,
        Planos: [ ...action.payload ],
        Plano: undefined,
        isLoading: false
      };
    case AdmActionKeys.LIST_PLANS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case AdmActionKeys.LIST_PLANS_UPDATE:
      return {
        ...state,
        Planos: [ ...action.payload ],
        Plano: undefined,
        isLoading: false
      };

    case AdmActionKeys.GET_PLAN_REQUEST:
      return {
        ...state,
        planIsLoading: true
      };
    case AdmActionKeys.GET_PLAN_SUCCESS:
      return {
        ...state,
        Plano: { ...action.payload },
        planIsLoading: false
      };
    case AdmActionKeys.GET_PLAN_FAILURE:
      return {
        ...state,
        error: action.payload,
        planIsLoading: false
      };  

    case AdmActionKeys.CREATE_OR_UPDATE_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true,
        getIsLoading: true
      };
    case AdmActionKeys.CREATE_OR_UPDATE_PLAN_SUCCESS:
      let newCOUState = state;
      if(newCOUState.Planos) {
        const index = newCOUState.Planos.findIndex((plan: any) => plan.StorePlansId === action.payload.StorePlansId);
        if (index >= 0) {
          newCOUState.Planos[index] = action.payload;
        } else {
          newCOUState.Planos = [ ...newCOUState.Planos, action.payload ];
        }
      }
      return {
        ...state,
        ...newCOUState,
        Plano: undefined,
        isLoading: false,
        getIsLoading: false
      };
    case AdmActionKeys.CREATE_OR_UPDATE_PLAN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        getIsLoading: false
      };
    
    case AdmActionKeys.DELETE_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true,
        getIsLoading: true
      };
    case AdmActionKeys.DELETE_PLAN_SUCCESS:
      let newDeleteState = state;
      if(newDeleteState.Planos) {
        const index = newDeleteState.Planos.findIndex((plan: any) => plan.StorePlansId === action.payload.planId);
        newDeleteState.Planos.splice(index, 1)
      }
      return {
        ...state,
        ...newDeleteState,
        Plano: undefined,
        isLoading: false,
        getIsLoading: false
      };
    case AdmActionKeys.DELETE_PLAN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        getIsLoading: false
      };

    case AdmActionKeys.LIST_TENANTS_REQUEST:
      return {
        ...state,
        tenantIsLoading: true
      };
    case AdmActionKeys.LIST_TENANTS_SUCCESS:
      return {
        ...state,
        Tenants: [ ...action.payload ],
        tenantIsLoading: false
      };
    case AdmActionKeys.LIST_TENANTS_FAILURE:
      return {
        ...state,
        tenantIsLoading: false
      };
    case AdmActionKeys.LIST_TENANTS_UPDATE:
      return {
        ...state,
        Tenants: [ ...action.payload ],
        tenantIsLoading: false
      };

    case AdmActionKeys.UPDATE_TENANT_REQUEST:
      return {
        ...state,
        tenantIsLoading: true
      };
    case AdmActionKeys.UPDATE_TENANT_SUCCESS:
      let newCOUTenantsState = state;
      if(newCOUTenantsState.Tenants) {
        const index = newCOUTenantsState.Tenants.findIndex((tenant: any) => 
          tenant.UserStoreItem.UserStoreItemsId === action.payload.UserStoreItemsId);
        newCOUTenantsState.Tenants[index].UserStoreItem = action.payload;
      }
      return {
        ...state,
        ...newCOUTenantsState,
        tenantIsLoading: false
      };
    case AdmActionKeys.UPDATE_TENANT_FAILURE:
      return {
        ...state,
        tenantIsLoading: false
      };
    
    case AdmActionKeys.CREATE_OR_UPDATE_TENANT_PAYMENT_REQUEST:
      return {
        ...state,
        tenantIsLoading: true
      };
    case AdmActionKeys.CREATE_OR_UPDATE_TENANT_PAYMENT_SUCCESS:
      let newTenantPaymentState = state;
      if(newTenantPaymentState.Tenants) {
        let tenantPayment = newTenantPaymentState.Tenants.find((tenant: ITenantAdm) => 
          tenant.UserStoreItem.UserStoreItemsId === action.payload.UserStoreItemFk);
      }
      return {
        ...state,
        ...newTenantPaymentState,
        tenantIsLoading: false
      };
    case AdmActionKeys.CREATE_OR_UPDATE_TENANT_PAYMENT_FAILURE:
      return {
        ...state,
        tenantIsLoading: false
      };

    case AdmActionKeys.GET_BILLING_DATA_REQUEST:
      return {
        ...state,
        tenantIsLoading: true
      };
    case AdmActionKeys.GET_BILLING_DATA_SUCCESS:
      return {
        ...state,
        BillingData: { ...action.payload },
        tenantIsLoading: false,
      };
    case AdmActionKeys.GET_BILLING_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        tenantIsLoading: false
      };

    case AdmActionKeys.CONFIRM_UPDATE_PLAN_REQUEST:
      return {
        ...state,
        tenantIsLoading: true
      };
    case AdmActionKeys.CONFIRM_UPDATE_PLAN_SUCCESS:
      let newconfirmUpdatePlanState = state;
      if(newconfirmUpdatePlanState.Tenants) {
        const index = newconfirmUpdatePlanState.Tenants.findIndex((tenant: any) => 
          tenant.UserStoreItem.UserStoreItemsId === action.payload.UserStoreItemsId);
        newconfirmUpdatePlanState.Tenants[index].UserStoreItem = action.payload;
      }
      return {
        ...state,
        ...newconfirmUpdatePlanState,
        tenantIsLoading: false,
      };
    case AdmActionKeys.CONFIRM_UPDATE_PLAN_FAILURE:
      return {
        ...state,
        error: action.payload,
        tenantIsLoading: false
      };

    case AdmActionKeys.LIST_TENANT_USERS_REQUEST:
      return {
        ...state,
        tenantUsers: undefined,
        adminChangeLoading: true
      };
    case AdmActionKeys.LIST_TENANT_USERS_SUCCESS:
      return {
        ...state,
        tenantUsers: [ ...action.payload ],
        adminChangeLoading: false
      };
    case AdmActionKeys.LIST_TENANT_USERS_FAILURE:
      return {
        ...state,
        adminChangeLoading: false
      };

    case AdmActionKeys.UPDATE_TENANT_ADMIN_REQUEST:
      return {
        ...state,
        adminChangeLoading: true
      };
    case AdmActionKeys.UPDATE_TENANT_ADMIN_SUCCESS:
      return {
        ...state,
        adminChangeLoading: false
      };
    case AdmActionKeys.UPDATE_TENANT_ADMIN_FAILURE:
      return {
        ...state,
        adminChangeLoading: false
      };

    case AdmActionKeys.TENANT_PERSONALIZATION_ADM_REQUEST:
      return {
        ...state,
        tenantPersonalization: undefined,
        tenantPersonalizationLoading: true
      };
    case AdmActionKeys.TENANT_PERSONALIZATION_ADM_SUCCESS:
      return {
        ...state,
        tenantPersonalization: {...action.payload},
        tenantPersonalizationLoading: false
      };
    case AdmActionKeys.TENANT_PERSONALIZATION_ADM_FAILURE:
      return {
        ...state,
        tenantPersonalization: undefined,
        tenantPersonalizationLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export default admReducer;