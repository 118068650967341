import React, { FC } from 'react';

import styles from './ListContainer.module.scss';

export interface IListContainer {
  children?: React.ReactNode;
  height?: number | string;
  className?: string;
  onClick?: () => void;
}

const ListContainer: FC<IListContainer> = ({ children, height= 70, className= '', onClick }) => {
  return (
    <section className={`${styles['ListContainer']} ${className}`} style={{ height }}
      onClick={onClick}>
      {children}
    </section>
  );
};

export default ListContainer;
