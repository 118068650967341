import React, { FC, useEffect, useCallback, useMemo } from 'react';

import styles from './UsuariosConvidadosTab.module.scss';
import { Icon } from '../../../../../Components/UI';
import ListContainer from '../../../../../Components/UI/Containers/ListContainer/ListContainer';
import Button from '../../../../../Components/UI/Button';
import Select from '../../../../../Components/UI/Select';
import Form from '../../../../../Components/UI/Form/Form';
import Field from '../../../../../Components/UI/Field';
import schema from './UsuariosConvidadosTab.schema';
import InfoTooltip from '../../../../../Components/UI/InfoTooltip';
import { ITenantRole } from '../../../../../Data/interfaces/Tenant/ITenantRole';
import { GetAvatar } from '../../../../../Utils/generateThumbnail';
import toastHandler from '../../../../../Utils/toastHandler';
import { REPEATED_EMAILS_ERROR } from '../../../../../Utils/Messages';
import { IUpdatedUsers } from '../../CadastroObra';
import { IUserData } from '../../../../../Data/interfaces/User/IUserData';
import { ISharedUser } from '../../../../../Data/interfaces/User/ISharedUser';
import { IInvitedUser } from '../../../../../Data/interfaces/User/IInvitedUser';

import './UsuariosConvidadosTab-override.css';
import { useSelector } from 'react-redux';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import SearchInput from 'Components/UI/SearchInput';
import LoadingUsersPermissions from 'Components/UI/CustomLoading/LoadingUsersPermissions';
import { getTheme } from 'Store/MultiDomain/MultiDomain.selector';
interface IUsuariosConvidadosTab {
  isSubmited?: boolean;
  onConfirm?: () => void;
  onSubmit?: (props: any) => void;
  tenantRoles: ITenantRole[];
  TenantSharedUsers: IUserData[];
  sharedUsers: ISharedUser[];
  invitedUsers: IInvitedUser[];
  registerCompleted?: boolean;
  changeUsers: (changedUser: IUpdatedUsers) => void;
  currentRole?: number;
  numberInvitedAllUsers?: number;
  maxUsers?: number;
  csId?: number;
  numberInvitedUsers?: number;
  firstInvitedUsers?: IInvitedUser[];
  isLoading?: boolean;
}

const UsuariosConvidadosTab: FC<IUsuariosConvidadosTab> = ({
  isSubmited,
  onSubmit,
  onConfirm,
  tenantRoles,
  TenantSharedUsers,
  sharedUsers,
  invitedUsers,
  changeUsers,
  currentRole,
  numberInvitedAllUsers,
  firstInvitedUsers,
  isLoading,
}) => {
  const theme = useSelector(getTheme);

  const getPrivilegesByRole = (roleId: number) => {
    const roleName = tenantRoles.find(x => x.TenantRoleId == roleId)?.RoleName;

    switch (roleName) {
      case 'Coordenador':
        return [0, 1, 2, 3, 4];
      case 'Engenheiro':
        return [0, 1, 2, 4];
      case 'Projetista':
        return [0, 1, 2];
      case 'Consultor':
        return [0];
    }
  }

  const TenantSharedUsersToSharedUsers = useCallback(() => {
    return TenantSharedUsers.filter((user) => user.CurrentRoleFk !== 1).map((user) => {
      const sharedUserSelected = sharedUsers?.find(
        (sharedUser) => sharedUser.UserFk === user.Id
      );
      if (sharedUserSelected !== undefined) {
        sharedUserSelected.isShared = true;
        return sharedUserSelected;
      }

      const privileges = getPrivilegesByRole(user.CurrentRoleFk ?? 0) ?? [];
      const hasPlottingPermission = privileges.indexOf(4) > -1;

      if (hasPlottingPermission)
        privileges.splice(privileges.indexOf(4), 1);

      return {
        User: user,
        HasPlottingPermission: hasPlottingPermission,
        isShared: false,
        UserFk: user.Id,
        UserId: user.Id,
        Privileges: privileges
      };
    }) as ISharedUser[];
  }, [TenantSharedUsers, sharedUsers]);

  const [InvitedUsers, setInvitedUsers] = React.useState(invitedUsers || []);
  const [SharedUsers, setSharedUsers] = React.useState(TenantSharedUsersToSharedUsers());
  const [RoleNewUser, setRoleNewUser] = React.useState<any>(4);
  const [infoUsuarioUpgradeVisible, setInfoUsuarioUpgradeVisible] = React.useState(false);
  const [newInviteds, setNewInviteds] = React.useState(0);
  const [searchUsers, setSearchUsers] = React.useState('');
  const [searchLoading, setSearchLoading] = React.useState(false);

  const planFree = useSelector((state: IGlobalReducerState) => state.auth.planFree);
  const maxUsers = useSelector((state: IGlobalReducerState) => 
    state.auth.userInfo?.UserStoreItem.MaxQtyUsers 
    || state.auth.userInfo?.UserStoreItem.StorePlan?.MaxQtyUsers
  );

  useEffect(() => {
    if (invitedUsers) {
      setInvitedUsers([...invitedUsers]);
    }
    setSharedUsers(TenantSharedUsersToSharedUsers());
  }, [TenantSharedUsersToSharedUsers, invitedUsers, sharedUsers]);

  useEffect(() => {
    if (InvitedUsers && firstInvitedUsers) {
      setNewInviteds(InvitedUsers.filter((user) => {
        return !firstInvitedUsers.map(userAll => userAll.Email).includes(user.Email);
      })?.length);
    }
    if (InvitedUsers && !firstInvitedUsers) {
      setNewInviteds(InvitedUsers.length);
    }
  }, [InvitedUsers, firstInvitedUsers]);

  useEffect(() => {
    if (maxUsers) {
      if (numberInvitedAllUsers && ((numberInvitedAllUsers + newInviteds) >= maxUsers)) {
        setInfoUsuarioUpgradeVisible(true);
      } else {
        setInfoUsuarioUpgradeVisible(false)
      }
    }
  }, [SharedUsers, InvitedUsers, newInviteds, numberInvitedAllUsers]);

  const getSelectedSharedUsers = () => {
    return SharedUsers.filter((sharedUser) => sharedUser.isShared);
  };

  const updateUsers = () => {
    changeUsers({ InvitedUsers, SharedUsers: getSelectedSharedUsers() });
  };

  const hasRepeatedEmail = (email: string) => {
    return (
      InvitedUsers.some((user) => user.Email.toLowerCase() === email.toLowerCase()) ||
      SharedUsers.some(
        (sharedUser) => sharedUser?.User?.Email?.toLowerCase() === email.toLowerCase()
      )
    );
  };

  const changeSharedUsers = (shared: ISharedUser) => {
    const index = SharedUsers.findIndex(user => user.UserFk === shared.UserFk);
    SharedUsers[index].isShared = !SharedUsers[index].isShared; 
    setSharedUsers(JSON.parse(JSON.stringify(SharedUsers)));
    updateUsers();
  };

  const addInvitedUser = (data: any) => {
    if (hasRepeatedEmail(data.Email)) {
      toastHandler.showError(REPEATED_EMAILS_ERROR);
    } else {

      const privileges = getPrivilegesByRole(RoleNewUser) ?? [];
      const hasPlottingPermission = privileges.indexOf(4) > -1;

      if (hasPlottingPermission)
        privileges.splice(privileges.indexOf(4), 1);

      const newUser: IInvitedUser = {
        Email: data.Email.toLowerCase(),
        RoleFk: RoleNewUser,
        Privileges: privileges,
        HasPlottingPermission: hasPlottingPermission
      };
      InvitedUsers.push(newUser);
      setInvitedUsers(InvitedUsers);
      updateUsers();
    }
  };

  const removeInvitedUser = (invited: IInvitedUser) => {
    const index = InvitedUsers.findIndex(user => user.Email === invited.Email);
    InvitedUsers.splice(index, 1);
    setInvitedUsers(InvitedUsers);
    updateUsers();
  };

  const changeInvitedUserRole = (invited: IInvitedUser, role: any) => {
    const privilagesChange = getPrivilegesByRole(role) || [0, 1, 2]
    const index = InvitedUsers.findIndex(user => user.Email === invited.Email);
    InvitedUsers[index].Privileges = privilagesChange
    InvitedUsers[index].RoleFk = role;
    InvitedUsers[index].HasPlottingPermission = role === 7 ? true : false
    setInvitedUsers(JSON.parse(JSON.stringify(InvitedUsers)));
    updateUsers();
  };

  const getSharedUserRoleName = (roleFk?: number) => {
    const roleName = tenantRoles.find((role) => role.TenantRoleId === roleFk)?.RoleName;
    return roleName;
  };

  const roles = tenantRoles.map((role) => ({
    value: role.TenantRoleId,
    description: role.RoleName,
    label: role.RoleName
  }));

  if (isSubmited && onSubmit) {
    onSubmit({ InvitedUsers, SharedUsers: getSelectedSharedUsers() });
  };

  const filteredInvitedUsers = useMemo(() => InvitedUsers?.filter((users: any) => (
    users.Email?.toLowerCase().includes(searchUsers?.toLowerCase()) 
  )), [InvitedUsers, searchUsers]);

  const filteredSharedUsers = useMemo(() => SharedUsers?.filter((users: any) => (
    users.User?.Nome?.toLowerCase().includes(searchUsers?.toLowerCase()) ||
    users.User?.Email?.toLowerCase().includes(searchUsers?.toLowerCase()) 
  )), [SharedUsers, searchUsers]);
  
  const handleChangeSearchUsers = (value: string) => {
    setSearchUsers(value);
  }  

  return (
    <div className={`${styles['usuariosConvidadosWrapper']} ${isLoading ? styles['usuariosConvidadosWrapperNoOver'] : ''}`}>
      <Form schema={schema} onSubmit={addInvitedUser} resetForm={true}>
        <div className={styles['newUsersWrapper']}>
          {infoUsuarioUpgradeVisible ? (
            <div className={styles['convertionToPremiumModal']}>
              <p 
                className={styles['primaryTextConvertion']}
                style={{color: theme.colors.primary.primary}}
              >
                {currentRole === 1 
                  ? 'Atenção: você atingiu o limite de usuários gratuitos!'
                  : 'Atenção: sua empresa atingiu o limite de usuários gratuitos!'
                }
              </p>
              <p className={styles['secondaryTextConvertion']}>
                {currentRole === 1 
                  ? 'Dê um upgrade na sua conta gratuita para convidar mais usuários'
                  : 'Dê um upgrade na conta da sua empresa gratuita para convidar mais usuários'
                }
              </p>
              <Button
                className={styles['button']}
                type="primary"
                height={37}
                onClick={onConfirm}
              >
                {`${currentRole === 1 ? 'Adquirir' : 'Solicitar'} o plano Premium`}
              </Button>
            </div>
          ) : (
            <></>
          )}
          <Field
            name="Email"
            label="Email usuário"
            placeholder="usuario@dominio.com.br"
            className={styles['inputEmail']}
            disabled={infoUsuarioUpgradeVisible ? true : false}
          />
          <Select
            onSelect={setRoleNewUser}
            className={styles['selectRole']}
            placeholder="Selecione um papel"
            defaultValue={RoleNewUser}
            options={roles}
            disabled={infoUsuarioUpgradeVisible ? true : false}
          />
          <Button
            htmlType="submit"
            className={styles['buttonNewUser']}
            type="primary"
            height={34}
            disabled={(infoUsuarioUpgradeVisible || searchLoading) ? true : false}
          >
            Convidar
          </Button>
        </div>
      </Form>
      <div className={styles['permissionsHintWrapper']}>
        <span>Precisa de ajuda com os papéis?</span>
        <InfoTooltip
          placement="bottom"
          overlay={
            <div className={styles['hintText']}>
              Para conhecer os papéis acesse Gerenciar usuários &gt; Papéis e permissões
              no menu lateral
            </div>
          }
        >
          <div>
            <Icon
              icon="informacao"
              color="cinzaPadrao"
              customSize={12}
              className={styles['iconInfo']}
            />
          </div>
        </InfoTooltip>
      </div>
      <div>
        <h3 className={styles['titleSharedUserList']}>
          Selecione os usuários que deseja compartilhar a obra
        </h3>
        {planFree && <p className={styles['titleSharedUserListFreemium']}>
          {`Convide até ${((maxUsers ?? 5) - 1)} usuários gratuitamente`}  
        </p>}
        <div>
          <div className={styles['TopbarInput']}>
            <SearchInput 
              setSearch={handleChangeSearchUsers}
              setSearchLoading={setSearchLoading}
              inputPlaceholder="Pesquise por um usuário"
            />
          </div>
        </div>
        {(isLoading || searchLoading) ? (
          <LoadingUsersPermissions multiple={2} />
        ) : (
          ((filteredInvitedUsers.length === 0) && (filteredSharedUsers.length === 0) && searchUsers) ? (
            <div className={styles['mainWrapper']}>
                <span className={styles['textBottomHead']}>
                  Nenhum usuário encontrado
                </span>
                <span className={styles['textBottomFooter']}>
                  Tente pesquisar pelo e-mail ou nome do usuário.
                </span>
            </div>
          ) : (
            <div>
              {filteredInvitedUsers.map((user, index) => (
                <ListContainer
                  height={51}
                  key={`invitedUser-${index}`}
                  className={styles['sharedUserItem']}
                >
                  <div className={styles['invitedUserWrapper']}>
                    <div>
                      <GetAvatar name={user.Email} />
                      <span className={styles['invitedUserName']}>{user.Email}</span>
                    </div>

                    <div className={styles['selectionsWrapper']}>
                      <div className={styles['selectRoleWrapper']}>
                        <Select
                          onSelect={(value) => changeInvitedUserRole(user, value)}
                          className={styles['selectRole']}
                          placeholder="Selecione um papel"
                          defaultValue={user.RoleFk}
                          options={roles}
                        />
                      </div>
                      
                      <div>
                        <div className={styles['waitingButton']}>
                          <span className={styles['waitingTitle']}>Aguardando</span>
                          <Button
                            className={styles['buttonRemoveUser']}
                            height={32}
                            onClick={() => removeInvitedUser(user)}
                          >
                            <Icon 
                              icon="cancelar" 
                              customSize={8} 
                              color="primary" 
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ListContainer>
              ))}
              {filteredSharedUsers.map((shared, index) => (
                <ListContainer
                  height={51}
                  key={`sharedUser-${index}`}
                  className={styles['sharedUserItem']}
                >
                  <div className={styles['invitedUserWrapper']}>
                    <GetAvatar
                      name={shared.User?.Nome || shared.User?.Email || ''}
                      shape='circle'
                      src={shared.User?.OriginalImageUrl}
                      thumbSrc={shared.User?.ThumbnailImageUrl}
                      thumbType="small"
                    />
                    <span className={styles['sharedUserName']}>
                      {shared.User?.Nome || shared.User?.Email}
                    </span>
                    <>
                      <div className={styles['sharedRoleName']}>
                        {getSharedUserRoleName(shared.User?.CurrentRoleFk)}
                      </div>
                    </>
                    <Button
                      height={30}
                      customText
                      isShared={shared.isShared}
                      className={`${styles['shareButton']} ${shared.isShared ? styles['shared'] : ''
                        }`}
                      onClick={() => changeSharedUsers(shared)}
                    >
                      {shared.isShared ? (
                        <>
                          <span>Compartilhado</span>
                          <Icon
                            icon="confirmar"
                            color="verde"
                            customSize={9}
                            style={{ height: 9 }}
                          />
                        </>
                      ) : (
                          'Compartilhar'
                        )}
                    </Button>
                  </div>
                </ListContainer>
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default UsuariosConvidadosTab;
