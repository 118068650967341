import { IObraState } from './interfaces/IObraState';
import { ObraActionUnion, ObraActionKeys } from './Obra.actions';
import { IObraData } from '../../Data/interfaces/Obra/IObraData';
import { IUsersConstructionSiteResponse } from '../../Data/interfaces/User/IUsersConstructionSiteResponse';
import { ISharedUser } from '../../Data/interfaces/User/ISharedUser';
import { IObraResponse } from 'Data/interfaces/Obra/IObraListResponse';

const initialState: IObraState = {
  Users: {} as IUsersConstructionSiteResponse,
  isLoading: false,
  Obra: {} as IObraData,
  constructionSiteIds: [],
  responseTime: undefined,
  isLoadingGetUsersConstruction: false,
  usersForLimitInvited: [],
  isCollapsed: false,
  archivesPath: undefined,
  plotagensPath: undefined,
  clearConstructionSiteSearch: false,
  editLoading: false,
};

const obraReducer = (state = initialState, action: ObraActionUnion): IObraState => {
  switch (action.type) {
    case ObraActionKeys.LIST_OBRA_REQUEST:
      return {
        ...state,
        ObrasList: undefined,
        isLoading: true,
      };
    case ObraActionKeys.LIST_OBRA_SUCCESS:
      let newObrasList: IObraResponse[] | undefined = state.ObrasList;
      const NewObraName = action.payload.newObraName;

      if (Array.isArray(action.payload.ObrasList)) {
        newObrasList = action.payload.ObrasList;
      }

      if (NewObraName && newObrasList) {
        const indexName = newObrasList.findIndex(obra => obra.ConstructionSiteId === NewObraName.ConstructionSiteId);
        if (
          newObrasList[indexName] && NewObraName.Name 
          && (newObrasList[indexName].Name !== NewObraName.Name)
        ) {
          newObrasList[indexName].Name = NewObraName.Name;
        }
      }

      return {
        ...state,
        ObrasList: newObrasList ? [ ...newObrasList ] : newObrasList,
        isLoading: false,
      };
    case ObraActionKeys.LIST_OBRA_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ObraActionKeys.ADD_OBRA_CHUNCK_DATA:
      return {
        ...state,
        Obra: { ...state.Obra, ...action.payload.valuesChunck },
        isLoading: action.payload.shouldSendData,
        editLoading: action.payload.shouldSendData,
      };

    case ObraActionKeys.CREATE_OR_UPDATE_OBRA_REQUEST:
      return {
        ...state,
        isLoading: true,
        editLoading: true,
      };
    case ObraActionKeys.CREATE_OR_UPDATE_OBRA_SUCCESS:
      let newObrasListObra: IObraResponse[] | undefined = state.ObrasList;
      const NewObra = action.payload;

      if (NewObra) {
        if (newObrasListObra && newObrasListObra.length >= 0) {
          const indexName = newObrasListObra.findIndex(obra => obra.ConstructionSiteId === NewObra.ConstructionSiteId);
          if (newObrasListObra[indexName]) {
            newObrasListObra[indexName] = { 
              ...newObrasListObra[indexName],
              ...NewObra,
            };

          } else {
            newObrasListObra = [ ...newObrasListObra, NewObra ];  
          }
        } 
      }

      return {
        ...state,
        ObrasList: newObrasListObra ? [ ...newObrasListObra ] : newObrasListObra,
        isLoading: false,
        editLoading: false,
      };
    case ObraActionKeys.CREATE_OR_UPDATE_OBRA_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        editLoading: false,
      };
    
    case ObraActionKeys.UPDATE_ARCHIVED_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    
    case ObraActionKeys.OBRA_RESET_STATE:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        message: undefined,
        Obra: {} as IObraData,
      };
      
    case ObraActionKeys.UPDATE_ARCHIVED_STATUS_SUCCESS:
      const obraAlterada = state.ObrasList
        ?.find((obra) => obra.ConstructionSiteId === action.payload.ConstructionSiteId);
      if (obraAlterada) {
        obraAlterada.isArchived = action.payload.isArchived;
      }
      return {
        ...state,
        isLoading: false,
        message: 'Obra arquivada com sucesso',
      };

    case ObraActionKeys.GET_OBRA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ObraActionKeys.GET_OBRA_SUCCESS:
      action.payload.SharedUsers = [...action.payload.SharedUsers
        .filter((sharedUser: any) => sharedUser.User.CurrentRoleFk !== 1)];
      return {
        ...state,
        Obra: { ...action.payload },
        isLoading: false,
      };
    case ObraActionKeys.GET_OBRA_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ObraActionKeys.GET_DISCIPLINES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ObraActionKeys.GET_DISCIPLINES_SUCCESS:
      const Disciplines = action.payload.filter((discipline) => discipline.Name !== 'Personalizado');
      return {
        ...state,
        Disciplines,
        isLoading: false,
      };
    case ObraActionKeys.GET_DISCIPLINES_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_REQUEST:
      return {
        ...state,
        isLoadingGetUsersConstruction: true,
      };
    case ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_SUCCESS:
      return {
        ...state,
        Users: action.payload,
        isLoadingGetUsersConstruction: false,
      };
    case ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoadingGetUsersConstruction: false,
      };

    case ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_REQUEST:
      return {
        ...state,
        isLoadingGetUsersConstruction: true,
      };
    case ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_SUCCESS:
      const updatedConstructionSite = state.ObrasList?.find((obra) =>
        obra.ConstructionSiteId === action.payload.ConstructionSiteId);
      if (updatedConstructionSite && updatedConstructionSite.SharedUsers) {
        updatedConstructionSite.SharedUsers = [
          ...updatedConstructionSite.SharedUsers.filter((user: ISharedUser) => user.User?.CurrentRoleFk === 1),
          ...action.payload.SharedUsers,
        ];
      }
      return {
        ...state,
        isLoadingGetUsersConstruction: false,
      };
    case ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoadingGetUsersConstruction: false,
      };

    case ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        AccountsInfo: action.payload,
        isLoading: false,
      };
    case ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_SUCCESS:
      return {
        ...state,
        StorageList: action.payload,
        isLoading: false,
      };
    case ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_REQUEST:
      return {
        ...state,
        isLoading: false,
      }
    case ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_SUCCESS:
      let ids = action.payload.map(constructionSite => constructionSite.ConstructionSiteId);

      return {
        ...state,
        isLoading: false,
        constructionSiteIds: ids
      }
    case ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }  
    
    case ObraActionKeys.CLEAR_ALL_CONSTRUCTIONS_SITES:
      return {
        ...state,
        isLoading: false,
        constructionSiteIds: [],
      }

    case ObraActionKeys.OBRA_USERS_LIMIT_INVITED:
      return {
        ...state,
        usersForLimitInvited: action.payload,
      } 

    case ObraActionKeys.TOGGLE_COLLAPSED:
      return {
        ...state,
        isCollapsed: action.payload,
      } 

    case ObraActionKeys.STORE_ARCHIVES_PATH:
      return {
        ...state,
        archivesPath: action.payload,
      } 
    case ObraActionKeys.STORE_PLOTAGENS_PATH:
      return {
        ...state,
        plotagensPath: action.payload,
      } 

    case ObraActionKeys.CLEAR_CONSTRUCTION_SITE_SEARCH:
      return {
        ...state,
        clearConstructionSiteSearch: action.payload,
      } 

    default:
      return state;
  }
};

export default obraReducer;
