import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import * as H from 'history';
import { IconName, Icon } from '../../../UI';
import Badge from 'Components/UI/Badge';
import Ellipsis from 'Components/UI/Ellipsis';
import useHistoryPush from 'Hooks/useHistoryPush';
import { IObraResponse } from 'Data/interfaces/Obra/IObraListResponse';
import { GetAvatar } from 'Utils/generateThumbnail';
import { CSSTransition } from 'react-transition-group';
import './childrenWrapper.css';
import { INotificationsMapAux } from 'Data/interfaces/Notification/INotificationsMapAux';
import { NotificationActionEnum } from 'Data/enums/Notification';
import { WrapperStyled } from './styles';

export interface IMenuItemObras {
  icon?: IconName;
  obra?: IObraResponse;
  title: string;
  path: string;
  obrasList?: IObraResponse[];
  isChildren?: boolean;
  location?: H.Location<H.LocationState>;
  isCollapsed: boolean;
  showIconPremium?: boolean,
  existNotification?: boolean,
  valueConstructionsNotification?: number;
  counter: number;
  onCounter: Dispatch<SetStateAction<number>>;
  openList: boolean;
  onOpenList: Dispatch<SetStateAction<boolean>>;
  notification?: INotificationsMapAux[];
  constructionSiteId?: number;
}

const MenuItemObras: FC<IMenuItemObras> = (
  {
    icon,
    obra,
    title,
    path,
    obrasList,
    isChildren,
    location,
    isCollapsed,
    existNotification,
    valueConstructionsNotification = 0,
    counter,
    onCounter,
    openList,
    onOpenList,
    notification,
    constructionSiteId,
  },
) => {
  const { historyPush } = useHistoryPush();

  const obras = obrasList?.filter((obra) => !obra.isArchived);

  const valueNotification = notification?.filter((obra, index, self) => obra.ConstructionSiteFk === constructionSiteId && self.findIndex(obraIndex => obraIndex.ItemId === obra.ItemId) === index).reduce((acc, cur) => {
    const allowedActions = [NotificationActionEnum.ArquivoMovido, NotificationActionEnum.NovoArquivo, NotificationActionEnum.NovaVersaoArquivo];

    if (allowedActions.includes(cur.Action) && !cur.hasRead) {
      return acc + 1;
    }

    return acc;
  }, 0) || 0;

  useEffect(() => {
    if (location?.pathname === '/' || location?.pathname === '/obras') {
      onCounter(1);
    } else {
      onCounter(0);
    }
  }, [onCounter, location])

  const handleToggleObras = () => {
    if (!obra && (counter === 0)) {
      historyPush(path);
      onOpenList(true);
    } else if (obra) {
      historyPush(path)
    } else {
      onOpenList(prevState => !prevState)
    }
  }

  const isActive = (): boolean => {
    if ((path && !obra) && ((path === location?.pathname) || (location?.pathname === '/'))) {
      return true;
    }

    const customArchives = path.replace('/detail', '/filelist');
    const customPlotagens = path.replace('/detail', '/plotagens');
    const customPlotagensArquives = path.replace('/detail', '/plotagens/filelist');
    const customPlotagensAReceber = path.replace('/detail', '/plotagens/filelist/receber');
    const customActivities = path.replace('/detail', '/activities/detail');
    const customViewer = path.replace('/detail', '/viewer/detail');

    if (
      (path && obra) && 
      (
        location?.pathname === path || 
        location?.pathname.includes(customArchives) || 
        location?.pathname.includes(customPlotagens) || 
        location?.pathname.includes(customPlotagensArquives) || 
        location?.pathname.includes(customPlotagensAReceber) ||
        location?.pathname.includes(customActivities) ||
        location?.pathname.includes(customViewer)
      )
    ) {
      return true;
    }

    return false;
  };
  
  const isSelected = useMemo(() => {
    if (location && location.pathname.includes('obras')) {
      return true;
    }

    return false
  }, [location])

  const active = isActive();

  return (
    <WrapperStyled 
      active={`${active}`}
      isselected={`${isSelected}`}
      isCollapsed={`${isCollapsed}`}
      isChildren={`${isChildren}`}
      isopen={`${openList}`}
    >
      <div 
        className="obrasMenuItem"
        onClick={() => handleToggleObras()}
      >
        {icon && (
          <Icon
            icon={icon}
            
            customSize={isCollapsed ? 12 : 14} 
            className="obrasIcon"
          />
        )}
        {obra && (
          <span className="avatar">
            <GetAvatar
              name={obra.Name}
              shape='circle'
              size={28}
              src={obra?.OriginalImageUrl || obra?.AvatarFileName}
              thumbSrc={obra?.ThumbnailImageUrl}
              thumbType="small"
            />
          </span>
        )}

        <p className="titleMenu">
          <Ellipsis phrase={title} maxLength={22}></Ellipsis>
        </p>
        
        {(obrasList && obras && !isCollapsed && (obras.length > 0)) && (
          <span className="obrasIconToggleWrap">
            <Icon 
              icon="avancar"
              customSize={10}
              className="obrasIconChildren"
            />
          </span>
        )}

        {(existNotification && !isCollapsed && valueConstructionsNotification > 0) && (
          <Badge type="square" value={valueConstructionsNotification} style={{ marginRight: 8 }} />
        )}
        {(constructionSiteId && !isCollapsed && valueNotification > 0) && (
          <Badge type="square" value={valueNotification} style={{ marginRight: 8 }} />
        )}
      </div>

      <CSSTransition 
        in={openList && !isCollapsed} classNames='collapseListObras' timeout={200} unmountOnExit
      >
        <div className="obrasChildrenWrapper">
          {obras &&
            obras.map((obra, i) => (
            <MenuItemObras 
              key={`item-menu-${i}-4`}
              isCollapsed={isCollapsed}
              obra={obra}
              title={obra.Name}
              path={`/obras/detail/${obra?.ConstructionSiteId}`}
              location={location}
              isChildren
              counter={counter}
              onCounter={onCounter}
              openList={openList}
              onOpenList={onOpenList}
              notification={notification}
              constructionSiteId={obra.ConstructionSiteId}
            />
          ))}
        </div>
      </CSSTransition>
    </WrapperStyled>
  );
};

export default MenuItemObras;
