import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { IObraListResponse, IObraResponse } from '../../Data/interfaces/Obra/IObraListResponse';
import { IArchivedStatusRequest } from '../../Data/interfaces/Obra/IArchivedStatusRequest';
import { ICreateOrUpdate } from '../../Data/interfaces/Obra/ICreateOrUpdate';
import { IDisciplineData, IObraData } from '../../Data/interfaces/Obra/IObraData';
import { IUsersConstructionSiteResponse } from '../../Data/interfaces/User/IUsersConstructionSiteResponse';
import IBindUsersConstructionSiteRequest from '../../Data/interfaces/User/IBindUsersConstructionSiteRequest';
import { IAccountInfo } from '../../Data/interfaces/Obra/IAccountInfo';
import { IUpdateConstructionSiteApiRequest } from '../../Data/interfaces/Obra/IUpdateConstructionSiteApiRequest';
import { IStorageData } from '../../Data/interfaces/Obra/IStorageData';
import { IUpdateDisciplineStatusRequest } from 'Data/interfaces/Obra/IUpdateDisciplineStatusRequest';
import { IInvitedUser } from '../../Data/interfaces/User/IInvitedUser';
import { IMatchParams } from 'Data/interfaces/Obra/IMatchParams';

export enum ObraActionKeys {
  LIST_OBRA_REQUEST = 'LIST_OBRA_REQUEST',
  LIST_OBRA_SUCCESS = 'LIST_OBRA_SUCCESS',
  LIST_OBRA_FAILED = 'LIST_OBRA_FAILED',

  GET_OBRA_REQUEST = 'GET_OBRA_REQUEST',
  GET_OBRA_SUCCESS = 'GET_OBRA_SUCCESS',
  GET_OBRA_FAILED = 'GET_OBRA_FAILED',

  CREATE_OR_UPDATE_OBRA_REQUEST = 'CREATE_OR_UPDATE_OBRA_REQUEST',
  CREATE_OR_UPDATE_OBRA_SUCCESS = 'CREATE_OR_UPDATE_OBRA_SUCCESS',
  CREATE_OR_UPDATE_OBRA_FAILED = 'CREATE_OR_UPDATE_OBRA_FAILED',

  ADD_OBRA_CHUNCK_DATA = 'ADD_OBRA_CHUNCK_DATA',

  UPDATE_ARCHIVED_STATUS_REQUEST = 'UPDATE_ARCHIVED_STATUS_REQUEST',
  UPDATE_ARCHIVED_STATUS_SUCCESS = 'UPDATE_ARCHIVED_STATUS_SUCCESS',
  UPDATE_ARCHIVED_STATUS_FAILED = 'UPDATE_ARCHIVED_STATUS_FAILED',

  GET_DISCIPLINES_REQUEST = 'GET_DISCIPLINES_REQUEST',
  GET_DISCIPLINES_SUCCESS = 'GET_DISCIPLINES_SUCCESS',
  GET_DISCIPLINES_FAILED = 'GET_DISCIPLINES_FAILED',

  GET_USERS_CONSTRUCTION_SITE_REQUEST = 'GET_USERS_CONSTRUCTION_SITE_REQUEST',
  GET_USERS_CONSTRUCTION_SITE_SUCCESS = 'GET_USERS_CONSTRUCTION_SITE_SUCCESS',
  GET_USERS_CONSTRUCTION_SITE_FAILED = 'GET_USERS_CONSTRUCTION_SITE_FAILED',

  BIND_USERS_CONSTRUCTION_SITE_REQUEST = 'BIND_USERS_CONSTRUCTION_SITE_REQUEST',
  BIND_USERS_CONSTRUCTION_SITE_SUCCESS = 'BIND_USERS_CONSTRUCTION_SITE_SUCCESS',
  BIND_USERS_CONSTRUCTION_SITE_FAILED = 'BIND_USERS_CONSTRUCTION_SITE_FAILED',

  LIST_CONSTRUCTION_SITE_ACCOUNTS_REQUEST = 'LIST_CONSTRUCTION_SITE_ACCOUNTS_REQUEST',
  LIST_CONSTRUCTION_SITE_ACCOUNTS_SUCCESS = 'LIST_CONSTRUCTION_SITE_ACCOUNTS_SUCCESS',
  LIST_CONSTRUCTION_SITE_ACCOUNTS_FAILED = 'LIST_CONSTRUCTION_SITE_ACCOUNTS_FAILED',

  UPDATE_CONSTRUCTION_SITE_API_REQUEST = 'UPDATE_CONSTRUCTION_SITE_API_REQUEST',
  UPDATE_CONSTRUCTION_SITE_API_SUCCESS = 'UPDATE_CONSTRUCTION_SITE_API_SUCCESS',
  UPDATE_CONSTRUCTION_SITE_API_FAILED = 'UPDATE_CONSTRUCTION_SITE_API_FAILED',

  LIST_CONSTRUCTION_SITES_STORAGE_REQUEST = 'LIST_CONSTRUCTION_SITES_STORAGE_REQUEST',
  LIST_CONSTRUCTION_SITES_STORAGE_SUCCESS = 'LIST_CONSTRUCTION_SITES_STORAGE_SUCCESS',
  LIST_CONSTRUCTION_SITES_STORAGE_FAILED = 'LIST_CONSTRUCTION_SITES_STORAGE_FAILED',

  GET_ALL_CONSTRUCTIONS_SITES_REQUEST = 'GET_ALL_CONSTRUCTIONS_SITES_REQUEST',
  GET_ALL_CONSTRUCTIONS_SITES_SUCCESS = 'GET_ALL_CONSTRUCTIONS_SITES_SUCCESS',
  GET_ALL_CONSTRUCTIONS_SITES_FAILURE = 'GET_ALL_CONSTRUCTIONS_SITES_FAILURE',

  CLEAR_ALL_CONSTRUCTIONS_SITES = 'CLEAR_ALL_CONSTRUCTIONS_SITES',

  OBRA_RESET_STATE = 'OBRA_RESET_STATE',

  OBRA_USERS_LIMIT_INVITED = 'OBRA_USERS_LIMIT_INVITED',

  TOGGLE_COLLAPSED = 'TOGGLE_COLLAPSED',

  STORE_ARCHIVES_PATH = 'STORE_ARCHIVES_PATH',
  STORE_PLOTAGENS_PATH = 'STORE_PLOTAGENS_PATH',

  CLEAR_CONSTRUCTION_SITE_SEARCH = 'CLEAR_CONSTRUCTION_SITE_SEARCH',
}

export const ObraActions = {
  list: (): ListAction =>
    createAction(ObraActionKeys.LIST_OBRA_REQUEST),
  listSuccess: (obraList: IObraListResponse): ListSuccessAction =>
    createAction(ObraActionKeys.LIST_OBRA_SUCCESS, obraList),
  listFailure: (err: string): ListFailureAction =>
    createAction(ObraActionKeys.LIST_OBRA_FAILED, err),

  getObra: (request: IUpdateDisciplineStatusRequest): GetObraAction =>
    createAction(ObraActionKeys.GET_OBRA_REQUEST, request),
  getObraSuccess: (obra: IObraResponse): GetObraSuccessAction =>
    createAction(ObraActionKeys.GET_OBRA_SUCCESS, obra),
  getObraFailure: (err: string): GetObraFailureAction =>
    createAction(ObraActionKeys.GET_OBRA_FAILED, err),

  createOrUpdate: (createOrUpdate: ICreateOrUpdate): CreateAction =>
    createAction(ObraActionKeys.CREATE_OR_UPDATE_OBRA_REQUEST, createOrUpdate),
  createOrUpdateSuccess: (response: IObraResponse): CreateSuccessAction =>
    createAction(ObraActionKeys.CREATE_OR_UPDATE_OBRA_SUCCESS, response),
  createOrUpdateFailure: (err: string): CreateFailureAction =>
    createAction(ObraActionKeys.CREATE_OR_UPDATE_OBRA_FAILED, err),

  addObraChunckData: (createOrUpdateData: ICreateOrUpdate): AddObraChunckData =>
    createAction(ObraActionKeys.ADD_OBRA_CHUNCK_DATA, createOrUpdateData),

  updateArchivedStatus: (status: IArchivedStatusRequest): UpdateArchivedStatusAction =>
    createAction(ObraActionKeys.UPDATE_ARCHIVED_STATUS_REQUEST, status),
  updateArchivedStatusSuccess: (constructionUpdated: IArchivedStatusRequest): UpdateArchivedStatusSuccessAction =>
    createAction(ObraActionKeys.UPDATE_ARCHIVED_STATUS_SUCCESS, constructionUpdated),
  updateArchivedStatusFailure: (err: string): UpdateArchivedStatusFailureAction =>
    createAction(ObraActionKeys.UPDATE_ARCHIVED_STATUS_FAILED, err),

  getDisciplines: (): GetDisciplinesAction =>
    createAction(ObraActionKeys.GET_DISCIPLINES_REQUEST),
  getDisciplinesSuccess: (disciplines: IDisciplineData[]): GetDisciplinesSuccessAction =>
    createAction(ObraActionKeys.GET_DISCIPLINES_SUCCESS, disciplines),
  getDisciplinesFailure: (err: string): GetDisciplinesFailureAction =>
    createAction(ObraActionKeys.GET_DISCIPLINES_FAILED, err),

  getUsersConstructionSite: (constructionSiteId: number): GetUsersConstructionSiteAction =>
    createAction(ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_REQUEST, constructionSiteId),
  getUsersConstructionSiteSuccess: (users: IUsersConstructionSiteResponse): GetUsersConstructionSiteSuccessAction =>
    createAction(ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_SUCCESS, users),
  getUsersConstructionSiteFailure: (err: string): GetUsersConstructionSiteFailureAction =>
    createAction(ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_FAILED, err),

  bindUsersConstructionSite: (users: IBindUsersConstructionSiteRequest): BindUsersConstructionSiteAction =>
    createAction(ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_REQUEST, users),
  bindUsersConstructionSiteSuccess: (users: IBindUsersConstructionSiteRequest):
    BindUsersConstructionSiteSuccessAction => createAction(ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_SUCCESS, users),
  bindUsersConstructionSiteFailure: (err: string): BindUsersConstructionSiteFailureAction =>
    createAction(ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_FAILED, err),

  listConstructionSiteAccounts: (): ListConstructionSiteAccountsAction =>
    createAction(ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_REQUEST),
  listConstructionSiteAccountsSuccess: (listAccounts: IAccountInfo[]):
    ListConstructionSiteAccountsSuccessAction =>
    createAction(ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_SUCCESS, listAccounts),
  listConstructionSiteAccountsFailure: (err: string): ListConstructionSiteAccountsFailureAction =>
    createAction(ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_FAILED, err),

  updateConstructionSiteApi: (request: IUpdateConstructionSiteApiRequest):
    UpdateConstructionSiteApiAction => createAction(ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_REQUEST, request),
  updateConstructionSiteApiSuccess: (response: any): UpdateConstructionSiteApiSuccessAction =>
    createAction(ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_SUCCESS, response),
  updateConstructionSiteApiFailure: (err: string): UpdateConstructionSiteApiFailureAction =>
    createAction(ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_FAILED, err),

  listConstructionSitesStorage: (): ListConstructionSitesStorageAction =>
    createAction(ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_REQUEST),
  listConstructionSitesStorageSuccess: (listStorage: IStorageData[]):
    ListConstructionSitesStorageSuccessAction =>
    createAction(ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_SUCCESS, listStorage),
  listConstructionSitesStorageFailure: (err: string): ListConstructionSitesStorageFailureAction =>
    createAction(ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_FAILED, err),

  listAllConstructionsSitesOfUser: (): ListAllConstructionsSitesOfUser => 
    createAction(ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_REQUEST),
  listAllConstructionsSitesOfUserSuccess: (constructoinsSitesResponse: IObraData[]): ListAllConstructionsSitesOfUserSuccess =>    
    createAction(ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_SUCCESS, constructoinsSitesResponse),
  listAllConstructionsSitesOfUserFailure: (error: string): ListAllConstructionsSitesOfUserFailed => 
    createAction(ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_FAILURE, error),

  clearAllConstructionsSites: (): ClearAllConstructionsSites => 
    createAction(ObraActionKeys.CLEAR_ALL_CONSTRUCTIONS_SITES),

  resetState: (): ResetStateAction =>
    createAction(ObraActionKeys.OBRA_RESET_STATE),

  usersForLimitInvited: (users: IInvitedUser[]): UsersForLimitInvitedAction => 
    createAction(ObraActionKeys.OBRA_USERS_LIMIT_INVITED, users),

  toggleCollapse: (toggle: boolean): ToggleCollapsedAction => 
    createAction(ObraActionKeys.TOGGLE_COLLAPSED, toggle),

  storeArchivesPath: (match: IMatchParams): StoreArchivesPathAction => 
    createAction(ObraActionKeys.STORE_ARCHIVES_PATH, match),
  storePlotagensPath: (match: IMatchParams): StorePlotagensPathAction => 
    createAction(ObraActionKeys.STORE_PLOTAGENS_PATH, match),

  clearConstructionSiteSearch: (clear: boolean): ClearConstructionSiteSearchAction => 
    createAction(ObraActionKeys.CLEAR_CONSTRUCTION_SITE_SEARCH, clear),
  };

export type ObraActionUnion = ActionsUnion<typeof ObraActions>;

export type ListAction = Action<ObraActionKeys.LIST_OBRA_REQUEST>;
export type ListSuccessAction = Action<ObraActionKeys.LIST_OBRA_SUCCESS, IObraListResponse>;
export type ListFailureAction = Action<ObraActionKeys.LIST_OBRA_FAILED, string>;

export type GetObraAction = Action<ObraActionKeys.GET_OBRA_REQUEST, IUpdateDisciplineStatusRequest>;
export type GetObraSuccessAction = Action<ObraActionKeys.GET_OBRA_SUCCESS, IObraResponse>;
export type GetObraFailureAction = Action<ObraActionKeys.GET_OBRA_FAILED, string>;

export type CreateAction = Action<ObraActionKeys.CREATE_OR_UPDATE_OBRA_REQUEST, ICreateOrUpdate>;
export type CreateSuccessAction = Action<ObraActionKeys.CREATE_OR_UPDATE_OBRA_SUCCESS, IObraResponse>;
export type CreateFailureAction = Action<ObraActionKeys.CREATE_OR_UPDATE_OBRA_FAILED, string>;

export type AddObraChunckData = Action<ObraActionKeys.ADD_OBRA_CHUNCK_DATA, ICreateOrUpdate>;

export type UpdateArchivedStatusAction = 
  Action<ObraActionKeys.UPDATE_ARCHIVED_STATUS_REQUEST, IArchivedStatusRequest>;
export type UpdateArchivedStatusSuccessAction =
  Action<ObraActionKeys.UPDATE_ARCHIVED_STATUS_SUCCESS, IArchivedStatusRequest>;
export type UpdateArchivedStatusFailureAction = 
  Action<ObraActionKeys.UPDATE_ARCHIVED_STATUS_FAILED, string>;

export type GetDisciplinesAction = Action<ObraActionKeys.GET_DISCIPLINES_REQUEST>;
export type GetDisciplinesSuccessAction = Action<ObraActionKeys.GET_DISCIPLINES_SUCCESS, IDisciplineData[]>;
export type GetDisciplinesFailureAction = Action<ObraActionKeys.GET_DISCIPLINES_FAILED, string>;

export type GetUsersConstructionSiteAction = Action<ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_REQUEST, number>;
export type GetUsersConstructionSiteSuccessAction =
  Action<ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_SUCCESS, IUsersConstructionSiteResponse>;
export type GetUsersConstructionSiteFailureAction = Action<ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_FAILED, string>;

export type BindUsersConstructionSiteAction =
  Action<ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_REQUEST, IBindUsersConstructionSiteRequest>;
export type BindUsersConstructionSiteSuccessAction =
  Action<ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_SUCCESS, IBindUsersConstructionSiteRequest>;
export type BindUsersConstructionSiteFailureAction =
  Action<ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_FAILED, string>;

export type ListConstructionSiteAccountsAction =
  Action<ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_REQUEST>;
export type ListConstructionSiteAccountsSuccessAction =
  Action<ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_SUCCESS, IAccountInfo[]>;
export type ListConstructionSiteAccountsFailureAction =
  Action<ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_FAILED, string>;

export type UpdateConstructionSiteApiAction =
  Action<ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_REQUEST, IUpdateConstructionSiteApiRequest>;
export type UpdateConstructionSiteApiSuccessAction =
  Action<ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_SUCCESS, any>;
export type UpdateConstructionSiteApiFailureAction =
  Action<ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_FAILED, string>;

export type ListConstructionSitesStorageAction =
  Action<ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_REQUEST>;
export type ListConstructionSitesStorageSuccessAction =
  Action<ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_SUCCESS, IStorageData[]>;
export type ListConstructionSitesStorageFailureAction =
  Action<ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_FAILED, string>;

export type ListAllConstructionsSitesOfUser = 
  Action<ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_REQUEST>;

export type ListAllConstructionsSitesOfUserSuccess = 
  Action<ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_SUCCESS, IObraData[]>;

export type ListAllConstructionsSitesOfUserFailed = 
  Action<ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_FAILURE, string>;

export type ClearAllConstructionsSites = 
  Action<ObraActionKeys.CLEAR_ALL_CONSTRUCTIONS_SITES>;

export type ResetStateAction = Action<ObraActionKeys.OBRA_RESET_STATE>;

export type UsersForLimitInvitedAction = 
  Action<ObraActionKeys.OBRA_USERS_LIMIT_INVITED, IInvitedUser[]>;

export type ToggleCollapsedAction = 
  Action<ObraActionKeys.TOGGLE_COLLAPSED, boolean>;

export type StoreArchivesPathAction = 
  Action<ObraActionKeys.STORE_ARCHIVES_PATH, IMatchParams>;
export type StorePlotagensPathAction = 
  Action<ObraActionKeys.STORE_PLOTAGENS_PATH, IMatchParams>;
  
  export type ClearConstructionSiteSearchAction = 
    Action<ObraActionKeys.CLEAR_CONSTRUCTION_SITE_SEARCH, boolean>;
