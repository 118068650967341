import { FC, useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { history } from 'Store';
import PageHeader from '../../../Components/UI/PageHeader/PageHeader';
import BreadCrumb, { IBreadcrumbItem } from '../../../Components/UI/BreadCrumb';
import NomenclaturaForm from '../../../Components/UI/NomenclaturaForm';
import NewNomenclaturaForm from '../../../Components/UI/NewNomenclaturaForm';
import { useDispatch, connect, ConnectedProps, useSelector } from 'react-redux';
import { IGlobalReducerState } from '../../../Store/Base/interface/IGlobalReducerState';
import { AuthActions } from '../../../Store/Auth/Auth.actions';
import { IUserInfoResponse } from '../../../Data/interfaces/MinhaConta/IUserInfoResponse';
import { isObjectEmpty } from 'Utils/ObjectUtils';
import ObraCard from './components/ObraCard';
import {
  IUpdateDisciplineStatusRequest,
} from 'Data/interfaces/Obra/IUpdateDisciplineStatusRequest';
import { ObraActions } from 'Store/Obra/Obra.actions';
import { TenantActions } from 'Store/Tenant/Tenant.actions';
import CadastroObra from 'Pages/Obras/Modals/CadastroObra';
import { IObraResponse } from 'Data/interfaces/Obra/IObraListResponse';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import EmptyList from './components/EmptyList';
import PremiumCard from './components/PremiumCard';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';
import { Mixpanel } from 'Utils/MixPanel';
import {
  Container,
  HorizontalHr,
  NomenclatureContainer,
  NomenclatureDivider,
  NomenclatureTitle,
  ObrasContainer,
  ObrasTitle,
  VerticalHr,
} from './styles';

const breadCrumb: IBreadcrumbItem[] = [
  {
    description: 'Configurar Serviço',
    href: '#'
  },
  {
    description: 'Nomenclatura de arquivos',
    href: '/config/nomenclatura'
  }
];

export interface INomenclatura {
  isSubmited?: boolean;
  onSubmit: (props?: object, erros?: string[]) => void;
  minhaConta: IUserInfoResponse;
}

const Nomenclatura: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const namingPatternFeature = useFeatureFlag('nomenclature-default-feature');
  const isNamingPatternEnabled = namingPatternFeature.enabled && namingPatternFeature.value === 'test';

  const [isEditObra, setIsEditObra] = useState(false);
  const [cadastroObraVisible, setCadastroObraVisible] = useState(false);

  const filteredGlobalNomenclatureObras = useMemo(() => {
    let obrasList: IObraResponse[] = [];
    if (props.obrasList && props.obrasList.length > 0) {
      obrasList = props.obrasList.filter(obra => {
        return !obra.isArchived && !obra.VersionConfigFk
      });
    }

    return obrasList;
  }, [props.obrasList]);

  const filteredLocalNomenclatureObras = useMemo(() => {
    let obrasList: IObraResponse[] = [];
    if (props.obrasList && props.obrasList.length > 0) {
      obrasList = props.obrasList.filter(obra => {
        return !obra.isArchived && obra.VersionConfigFk
      });
    }

    return obrasList;
  }, [props.obrasList]);

  const openEdicaoObra = (constructionSiteId: number) => {
    const request: IUpdateDisciplineStatusRequest = {
      csId: constructionSiteId
    }
    dispatch(ObraActions.getObra(request));
    dispatch(ObraActions.getUsersConstructionSite(constructionSiteId));
    setIsEditObra(true);
    dispatch(TenantActions.getUsers());
    setCadastroObraVisible(true);
  };

  const upgradePlanRedirect = (role: number) => {
    Mixpanel.track({
      name: 'CALL_T0_ACTION',
      props: {
        origin: 'nomenclatura_config',
        originPath: window.location.pathname
      },
      userInfo: props.userInfo,
      currentListTenant: currentTenant,
    });

    if (role === 1) {
      history.push('/faturamento/planos');

    } else {
      window.open('https://bim.maletadoengenheiro.com.br/seja-premium');
    }
  };

  useEffect(() => {
    if (!props.minhaConta || isObjectEmpty(props.minhaConta))
      dispatch(AuthActions.getUserInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.isLoadingGetUsersConstruction) {
      dispatch(ObraActions.usersForLimitInvited(props.Users?.InvitedUsers))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isLoadingGetUsersConstruction]);

  const isPro = (props.userInfo?.UserStoreItem.StorePlan?.Order || 0) >= 3;
  const isFreeOrStandard = (props.userInfo?.UserStoreItem.StorePlan?.Order || 0) <= 2;

  return (
    <div>
      {props.minhaConta.CurrentRoleFk === 1 || props.minhaConta.CurrentRoleFk === 7 ? (
        <PageHeader name="Nomenclatura de arquivos">
          <BreadCrumb breadcrumbs={breadCrumb} />
          <Container>
            <NomenclatureContainer>
              <NomenclatureTitle>
                Padrão de nomenclatura global
              </NomenclatureTitle>
              <NomenclatureDivider />

              {isNamingPatternEnabled && (
                <NewNomenclaturaForm {...props} />
              )}
              {!isNamingPatternEnabled && (
                <NomenclaturaForm {...props} />
              )}

              {isNamingPatternEnabled && (
                <>
                  <HorizontalHr />
                  <ObrasTitle>
                    Obras usando a nomenclatura global
                  </ObrasTitle>
                  {filteredGlobalNomenclatureObras.length > 0 &&
                    filteredGlobalNomenclatureObras.map(obra => (
                      <ObraCard
                        key={obra.ConstructionSiteId}
                        obra={obra}
                        onOpen={openEdicaoObra}
                      />
                    ))
                  }
                  {filteredGlobalNomenclatureObras.length === 0 && (
                    <EmptyList
                      text="Nenhuma obra usando a nomenclatura global."
                    />
                  )}
                </>
              )}
            </NomenclatureContainer>

            {isNamingPatternEnabled && (
              <>
                <VerticalHr />

                <ObrasContainer>
                  <NomenclatureTitle>
                    Obras com nomenclatura personalizadas
                  </NomenclatureTitle>
                  <NomenclatureDivider />

                  {isPro && (
                    <>
                      {filteredLocalNomenclatureObras.length > 0 &&
                        filteredLocalNomenclatureObras.map(obra => (
                          <ObraCard
                            key={obra.ConstructionSiteId}
                            obra={obra}
                            shorter
                            onOpen={openEdicaoObra}
                          />
                        ))
                      }
                      {filteredLocalNomenclatureObras.length === 0 && (
                        <EmptyList
                          text="Nenhuma obra com nomenclatura personalizada."
                        />
                      )}
                    </>
                  )}
                  {isFreeOrStandard && (
                    <PremiumCard
                      role={props.userInfo?.CurrentRoleFk || 0}
                      onRedirect={upgradePlanRedirect}
                    />
                  )}
                </ObrasContainer>
              </>
            )}
          </Container>
        </PageHeader>
      ) : (
        <>
          {props.minhaConta.CurrentRoleFk === 4 ||
            props.minhaConta.CurrentRoleFk === 3 ||
            props.minhaConta.CurrentRoleFk === 5 ? (
            <Redirect to="/obras" />
          ) : (
            <></>
          )}
        </>
      )}

      {cadastroObraVisible && (
        <CadastroObra
          isVisible={cadastroObraVisible}
          isEdit={isEditObra}
          onCancel={() => setCadastroObraVisible(false)}
          firstInvitedUsers={props.usersForLimitInvited}
          isNomenclatureEdit
        />
      )}
    </div>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.nomenclatura,
  minhaConta: state.minhaConta.userInfo,
  userInfo: state.auth.userInfo,
  extensions: state.nomenclatura.ExtensionsData,
  obrasList: state.obra.ObrasList,
  usersForLimitInvited: state.obra.usersForLimitInvited,
  Users: state.obra.Users,
  isLoadingGetUsersConstruction: state.obra.isLoadingGetUsersConstruction,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & INomenclatura;

export default connector(Nomenclatura);
