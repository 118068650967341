export type IconName =
  | 'menuAjuda'
  | 'menuConfiguracoes'
  | 'menuContexto'
  | 'menuGerenciarUsuario'
  | 'menuHome'
  | 'menuLoja'
  | 'menuObras'
  | 'menuPlotagem'
  | 'menuSolicitacoes'
  | 'disciplinaArquitetura'
  | 'disciplinaEstrutura'
  | 'disciplinaMecanica'
  | 'disciplinaFundacao'
  | 'disciplinaEletrica'
  | 'disciplinaHidrossanitaria'
  | 'disciplinaIncendio'
  | 'disciplinaAr'
  | 'arquivo2d'
  | 'arquivo3d'
  | 'arquivoPdf'
  | 'arquivoTxt'
  | 'arquivoImg'
  | 'arquivoExcel'
  | 'viewerDrive'
  | 'viewerDropbox'
  | 'viewerForge'
  | 'viewerOffice'
  | 'notificacoes'
  | 'adicionar'
  | 'cancelar'
  | 'adicionarUsuario'
  | 'conviteAceito'
  | 'editarImg'
  | 'upload'
  | 'download'
  | 'editar'
  | 'trocarStatus'
  | 'solicitarPlotagem'
  | 'informacao'
  | 'excluir'
  | 'filtrar'
  | 'arquivar'
  | 'buscar'
  | 'sair'
  | 'receber'
  | 'informacaoAdicional'
  | 'localizacao'
  | 'confirmar'
  | 'avancar'
  | 'retroceder'
  | 'expandir'
  | 'bolinhaSelecionada'
  | 'bolinhaNaoSelecionada'
  | 'collapse'
  | 'confirmacao'
  | 'error'
  | 'checkedSenha'
  | 'errorSenha'
  | 'cadeado'
  | 'arroba'
  | 'estrela'
  | 'visualizar'
  | 'diciplinaAr'
  | 'voltar'
  | 'mover'
  | 'setaOrdenarAsc'
  | 'setaOrdenarDesc'
  | 'semConexao'
  | 'viewerOnedrive'
  | 'atualizar'
  | 'aviso'
  | 'crown'
  | 'estrelaVazia'
  | 'moreActions'
  | 'email'
  | 'link'
  | 'excluirUser'
  | 'arquivo'
  | 'activities'
  | 'obsoleteArchive'
  | 'viewPoint'
  | 'avancarDuplo'
  | 'cadeadoFechado'
  | 'ccube'
  | 'docEye'
  | 'filePile'
  | 'camera'
  | 'maleta'
  | 'notVisualizar'
  | 'pranchasItens'
  | 'viewpointsItens'
  | 'unbind'
  | 'listList'
  | 'listKanban'
  | 'listDetailVision'
  | 'globe'
  | 'flag'
  | 'calendar'
  | 'kanbanCollapse'
  | 'adicionarAlt'
  | 'squareChat'
  | 'megaphone'
  | 'person'
  | 'criativy'
  | 'relogio'
  | 'TopLeft'
  | 'TopRight'
  | 'TopCenter'
  | 'CenterLeft'
  | 'CenterRight'
  | 'CenterCenter'
  | 'BottomLeft'
  | 'BottomRight'
  | 'BottomCenter'
  | 'alerta'
  | 'adicionarPasta'
  | 'share'
  | 'copiar'
  | 'eraseFilter'
  | 'emptyExcluir'
  | 'galery'
  | 'carousel'
  | 'openNewTab'
  | 'panels'
  | 'pin'
  | 'coordly'
  | 'customTag'
  | 'unpin'
  | 'rightArrow'
  | 'input'
  | 'history'
  | 'paperclip'
  | 'bolt'
  | 'phases'
  | 'storage'
  | 'team'
  | 'archive'
  | 'key';

export const iconClasses = {
  menuAjuda: 'icon-menu-ajuda',
  menuConfiguracoes: 'icon-menu-configuracoes',
  menuContexto: 'icon-menu-contexto',
  menuGerenciarUsuario: 'icon-menu-gerenciar-usuario',
  menuHome: 'icon-menu-home',
  menuLoja: 'icon-menu-loja',
  menuObras: 'icon-menu-obras',
  menuPlotagem: 'icon-menu-plotagem',
  menuSolicitacoes: 'icon-menu-solicitacoes',
  disciplinaArquitetura: 'icon-disciplina-arquitetura',
  disciplinaEstrutura: 'icon-disciplina-estrutura',
  disciplinaMecanica: 'icon-disciplina-mecanica',
  disciplinaFundacao: 'icon-disciplina-fundacoes',
  disciplinaEletrica: 'icon-disciplina-eletrica',
  disciplinaHidrossanitaria: 'icon-disciplina-hidrossanitarias',
  disciplinaIncendio: 'icon-disciplina-incendio',
  disciplinaAr: 'icon-disciplina-ar',
  arquivo2d: 'icon-arquivo-2d',
  arquivo3d: 'icon-arquivo-3d',
  arquivoPdf: 'icon-arquivo-pdf',
  arquivoTxt: 'icon-arquivo-texto',
  arquivoImg: 'icon-arquivo-img',
  arquivoExcel: 'icon-arquivo-excel',
  viewerDrive: 'icon-viewer-drive',
  viewerDropbox: 'icon-viewer-dropbox',
  viewerForge: 'icon-viewer-forge',
  viewerOffice: 'icon-viewer-office',
  notificacoes: 'icon-notificacoes',
  adicionar: 'icon-adicionar',
  cancelar: 'icon-cancelar',
  adicionarUsuario: 'icon-adicionar-usuario',
  conviteAceito: 'icon-notificacao-convite-aceito',
  editarImg: 'icon-editar-imagem',
  upload: 'icon-upload',
  download: 'icon-download',
  editar: 'icon-editar',
  trocarStatus: 'icon-trocar-status',
  solicitarPlotagem: 'icon-solicitar-plotagem',
  informacao: 'icon-informacao',
  excluir: 'icon-lixeira',
  filtrar: 'icon-filtrar',
  arquivar: 'icon-arquivar',
  buscar: 'icon-pesquisar',
  sair: 'icon-sair',
  receber: 'icon-receber',
  informacaoAdicional: 'icon-informacao-adicional',
  localizacao: 'icon-localizacao',
  confirmar: 'icon-confirmar',
  avancar: 'icon-avancar',
  retroceder: 'icon-retroceder',
  expandir: 'icon-expandir',
  bolinhaSelecionada: 'icon-bolinhaSelecionada',
  bolinhaNaoSelecionada: 'icon-bolinhaNaoSelecionada',
  collapse: 'icon-collapse',
  confirmacao: 'icon-confirmacao',
  error: 'icon-error',
  checkedSenha: 'icon-checkedSenha',
  errorSenha: 'icon-erroSenha',
  arroba: 'icon-arroba',
  cadeado: 'icon-cadeado',
  estrela: 'icon-estrela',
  visualizar: 'icon-visualizar',
  diciplinaAr: 'icon-disciplina-ar',
  voltar: 'icon-voltar',
  mover: 'icon-mover',
  setaOrdenarAsc: 'icon-seta-ordenar-desc',
  setaOrdenarDesc: 'icon-seta-ordenar-asc',
  semConexao: 'icon-sem-conexao',
  viewerOnedrive: 'icon-viewer-onedrive',
  atualizar: 'icon-atualizar',
  aviso: 'icon-aviso',
  crown: 'icon-crow-cta',
  estrelaVazia: 'icon-estrela-vazia',
  moreActions: 'icon-more-actions',
  email: 'icon-icon-email',
  link: 'icon-icon-link',
  excluirUser: 'icon-icon-excluir-user',
  arquivo: 'icon-arquivo-doc',
  activities: 'icon-activities',
  obsoleteArchive: 'icon-obsolete-archive',
  viewPoint: 'icon-viewpoint',
  avancarDuplo: 'icon-avancar-duplo',
  cadeadoFechado: 'icon-cadeado-fechado',
  ccube: 'icon-ccube',
  docEye: 'icon-doc-eye',
  filePile: 'icon-pile-file',
  camera: 'icon-camera',
  maleta: 'icon-maleta',
  notVisualizar: 'icon-not-visualizar',
  pranchasItens: 'icon-pranchas-itens',
  viewpointsItens: 'icon-viewpoints-itens',
  unbind: 'icon-unbind',
  listList: 'icon-list-list',
  listKanban: 'icon-list-kanban',
  listDetailVision: 'icon-list-detailVision',
  globe: 'icon-globe',
  flag: 'icon-flag',
  calendar: 'icon-calendar',
  kanbanCollapse: 'icon-kanban-collapse',
  adicionarAlt: 'icon-adicionar-alt',
  squareChat: 'icon-square-chat',
  megaphone: 'icon-megaphone',
  person: 'icon-person',
  criativy: 'icon-criativy',
  relogio: 'icon-relogio',
  TopLeft: 'icon-top-left',
  TopRight: 'icon-top-right',
  TopCenter: 'icon-top-center',
  CenterLeft: 'icon-center-left',
  CenterRight: 'icon-center-right',
  CenterCenter: 'icon-center',
  BottomLeft: 'icon-bottom-left',
  BottomRight: 'icon-bottom-right',
  BottomCenter: 'icon-bottom-center',
  alerta: 'icon-alert',
  adicionarPasta: 'icon-folder-add',
  share: 'icon-share',
  copiar: 'icon-copy',
  eraseFilter: 'icon-erase-filter',
  emptyExcluir: 'icon-lixeira-vazia',
  galery: 'icon-galery',
  carousel: 'icon-carousel',
  openNewTab: 'icon-open-new-tab',
  panels: 'icon-panels',
  pin: 'icon-pin',
  coordly: 'icon-coordly',
  customTag: 'icon-custom-tag',
  unpin: 'icon-unpin',
  rightArrow: 'icon-right-arrow',
  input: 'icon-input',
  history: 'icon-history',
  paperclip: 'icon-paperclip',
  bolt: 'icon-bolt',
  phases: 'icon-phases',
  storage: 'icon-storage',
  team: 'icon-team',
  archive: 'icon-archive',
  key: 'icon-key',
};
