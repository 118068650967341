import React, { FC } from "react";
import { IModelsTree, ITreeObjectModel } from "Data/interfaces/FederatedViewer/IModelsTree";
import { Tree } from 'antd';
import styles from './TreeView.module.scss';
import './TreeView.css';
import { Icon } from "Components/UI";
import Ellipsis from "Components/UI/Ellipsis";

const { TreeNode } = Tree;

interface ITreeView {
  result: IModelsTree;
  onToggleNode: (node: number, urn: string) => void;
  hideNodes: number[];
  showNodes: number[];
  onToggleSelectNode: (node: number, urn: string) => void;
  selectedNode?: number | null;
}

const TreeView: FC<ITreeView> = ({
  result,
  onToggleNode,
  hideNodes,
  showNodes,
  onToggleSelectNode,
  selectedNode,
}) => {
  const hide = (itemId: number, selectedParent?: boolean) => {
    if (hideNodes.includes(itemId) && !showNodes.includes(itemId)) return true;

    if (selectedParent && !showNodes.includes(itemId)) return true;
    
    return false;
  }

  const handleSelectedParent = (itemId: number, selectedParent?: boolean) => {
    if (selectedParent && !showNodes.includes(itemId)) return true;
    
    return (hideNodes.includes(itemId) && !showNodes.includes(itemId));
  }

  const correctName = (currentName: string) => {
    if (currentName.startsWith('constructionId')) {
      let newName = '';
      const indexName = currentName.search('Name');
      newName = currentName.slice(indexName + 4);
      return newName;
    } else {
      return currentName;
    }
  }

  const builderTree = (level: number, array?: ITreeObjectModel[], selectedParent?: boolean): any => {
    const currentLevel = level + 1;

    if (array && array.length > 0) {
      return array.map((item) => {
        if (item.children && item.children?.length > 0) {
          return (
            <TreeNode 
              key={`${item.id}`} 
              title={(
                <span
                  className={`${styles['treeNodeRow']} ${item.id === selectedNode ? styles['treeNodeRowActive'] : ''}`}
                  onClick={() => onToggleSelectNode(item.id, result.urn)}
                  style={{marginRight: `-${12-(currentLevel+0.7)}px`}}
                >
                  <Ellipsis 
                    phrase={correctName(`${item.title}`)}
                    maxLength={17}
                  />
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      onToggleNode(item.id, result.urn)
                    }}
                  >
                    <Icon 
                      icon={hide(item.id, selectedParent) ? 'notVisualizar' : 'visualizar'}
                      customSize={11}
                      customColor={hide(item.id, selectedParent) ? '#BEC2C6' : '#324150'}
                    />
                  </span>
                </span>
              )}
            >
              {(item.children.length > 0) && (
                builderTree(currentLevel, item.children, handleSelectedParent(item.id, selectedParent))
              )}
            </TreeNode>
          )
        } else {
          return (
            <TreeNode 
              key={`${item.id}`} 
              title={(
                <span
                  className={`${styles['treeNodeRow']} ${item.id === selectedNode ? styles['treeNodeRowActive'] : ''}`}
                  onClick={() => onToggleSelectNode(item.id, result.urn)}
                  style={{marginRight: `-${12-(currentLevel+0.68)}px`}}
                >
                  <Ellipsis 
                    phrase={correctName(`${item.title}`)}
                    maxLength={17}
                  />
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      onToggleNode(item.id, result.urn)
                    }}
                  >
                    <Icon 
                      icon={hide(item.id, selectedParent) ? 'notVisualizar' : 'visualizar'}
                      customSize={11}
                      customColor={hide(item.id, selectedParent) ? '#BEC2C6' : '#324150'}
                    />
                  </span>
                </span>
              )}
            />
          )
        }
      })
    } else {
      return <></>
    }
  }

  return (
    <Tree>
      <TreeNode 
        key={`${result.list[0].id}`}
        title={(
          <span
            className={`${styles['treeNodeRow']} ${result.list[0].id === selectedNode ? styles['treeNodeRowActive'] : ''}`}
            onClick={() => onToggleSelectNode(result.list[0].id, result.urn)}
            style={{marginRight: '-12.6px'}}
            >
            Model
            <span
              onClick={(e) => {
                e.stopPropagation();
                onToggleNode(result.list[0].id, result.urn)
              }}
            >
              <Icon 
                icon={(hideNodes.includes(result.list[0].id)) ? 'notVisualizar' : 'visualizar'}
                customSize={11}
                customColor={(hideNodes.includes(result.list[0].id)) ? '#BEC2C6' : '#324150'}
              />
            </span>
          </span>
        )}
      >
        {builderTree(0, result.list[0].children, hideNodes.includes(result.list[0].id))}
      </TreeNode>
    </Tree>
  );
};

export default TreeView;
