import { call, put, select } from 'redux-saga/effects';
import { ObrasApi } from '../../Data/Obras.api';
import toastHandler from '../../Utils/toastHandler';
import {
  ObraActions, GetObraAction, CreateAction, UpdateArchivedStatusAction,
  GetUsersConstructionSiteAction, BindUsersConstructionSiteAction, UpdateConstructionSiteApiAction
} from './Obra.actions';
import { loadError, updateError, createError, listAllTenantOfUserError } from '../../Utils/Messages';
import { history } from '../../Store/index';
import { AuthActions } from 'Store/Auth/Auth.actions';
import { IUserLimitsActive } from 'Store/Auth/interfaces/IAuthState';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import { Mixpanel } from 'Utils/MixPanel';
import { IObraListResponse } from 'Data/interfaces/Obra/IObraListResponse';
import { DisciplinaActions } from 'Store/Disciplina/Disciplina.actions';
import { ListagemArquivosActions } from 'Store/ListagemArquivos/ListagemArquivos.actions';

export function* list() {
  try {
    const { data } = yield call(ObrasApi.list);
    const response: IObraListResponse = { ObrasList: data.data }
    yield put(ObraActions.listSuccess(response));
  } catch {
    yield put(ObraActions.listFailure('Erro ao carregar obras'));
  }
}

export function* getObra({ payload }: GetObraAction) {
  try {
    const { data } = yield call(ObrasApi.getConstructionSite, payload);
    yield put(ObraActions.getObraSuccess(data.data));
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : loadError('obras');
    toastHandler.showError(error);
    yield put(ObraActions.getObraFailure(error));
  }
}

export function* createOrUpdate({ payload }: CreateAction) {
  try {
    const {
      firstObraTracking,
      userInfoTracking,
      currentTenantTracking,
    } = payload;
    delete payload.firstObraTracking;
    delete payload.userInfoTracking;
    delete payload.currentTenantTracking;

    const { tenantInvitedUsers, tenantUsers } = yield select((state: IGlobalReducerState) => state.tenant.UsersWithConstruction);
    yield put(ObraActions.addObraChunckData(payload));

    if (payload.shouldSendData) {
      // @ts-ignore:next-line
      const obra = yield select((state: IGlobalReducerState) => state.obra.Obra);
      let userLimitsActive: IUserLimitsActive;

      const filterSharedUsers = tenantUsers?.filter((user: any) => user.User.Id !== userInfoTracking?.Id);
      const isFirstUser = ((tenantInvitedUsers?.length || 0) <= 0) && ((filterSharedUsers?.length || 0) <= 0) && obra?.InvitedUsers?.length > 0;

      if (obra.ConstructionSiteId) {
        console.log({obra})
        const { data } = yield call(ObrasApi.update, obra);
        yield put(ObraActions.createOrUpdateSuccess(data.data.Value.data));
        yield put(DisciplinaActions.listSuccess(data.data.Value.data));
        yield put(ListagemArquivosActions.addObraChunckData(data.data.Value.data));
        
        if (isFirstUser) {
          Mixpanel.track({
            name: 'INVITED_FIRST_USER', 
            props: { constructionSiteId: data.data.ConstructionSiteId },
            userInfo: userInfoTracking,
            currentListTenant: currentTenantTracking,
          });
        }

        userLimitsActive = {
          QtyActiveUsers: obra?.InvitedUsers?.filter((newUsers: any) => {
            return !tenantInvitedUsers.map((user: any) => user.InvitedUserEmail).includes(newUsers.Email)
          }).length || undefined,
        }
        yield put(AuthActions.toogleUserLimitsActive(userLimitsActive));
        
        toastHandler.showSuccess('Obra Atualizada com sucesso!');
        
      } else {
        const { data } = yield call(ObrasApi.create, obra);
        yield put(ObraActions.list());
        
        if (firstObraTracking) {
          Mixpanel.track({
            name: 'CREATE_FIRST_OBRA', 
            props: { constructionSiteId: data.data.ConstructionSiteId },
            userInfo: userInfoTracking,  
            currentListTenant: currentTenantTracking,
          });
        }
        if (isFirstUser) {
          Mixpanel.track({
            name: 'INVITED_FIRST_USER', 
            props: { constructionSiteId: data.data.ConstructionSiteId },
            userInfo: userInfoTracking,
            currentListTenant: currentTenantTracking,
          });
        }

        userLimitsActive = {
          QtyActiveConstructionSites: 1,
          QtyActiveUsers: obra?.InvitedUsers?.filter((newUsers: any) => {
            return !tenantInvitedUsers.map((user: any) => user.InvitedUserEmail).includes(newUsers.Email)
          }).length || undefined,
        }
        yield put(AuthActions.toogleUserLimitsActive(userLimitsActive));
        
        if (obra.ExistingConstructionSiteTokenId) {
          history.push(`/obras/detail/${data.data.ConstructionSiteId}/true`);
        } else {
          window.location.href = data.info.redirectUrl;
        }
      }
    }
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : createError('obra');
    toastHandler.showError(error);
    yield put(ObraActions.createOrUpdateFailure(error));
  }
}

export function* updateArchivedStatus({ payload }: UpdateArchivedStatusAction) {
  try {
    yield call(ObrasApi.updateArchivedStatus, payload);
    yield put(ObraActions.updateArchivedStatusSuccess(payload));
    
    let userLimitsActive: IUserLimitsActive;
    if (payload.isArchived) {
      userLimitsActive = {
        QtyActiveConstructionSites: -1,
      }
    } else {
      userLimitsActive = {
        QtyActiveConstructionSites: 1,
      }
    }
    yield put(AuthActions.toogleUserLimitsActive(userLimitsActive));
  
    history.push(`/obras`);
    toastHandler.showSuccess('Obra arquivada/desarquivada com sucesso');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao arquivar/desarquivar obra';
    toastHandler.showError(error);
    yield put(ObraActions.updateArchivedStatusFailure(error));
  }
}

export function* getDisciplines() {
  try {
    const { data } = yield call(ObrasApi.getDisciplines);
    yield put(ObraActions.getDisciplinesSuccess(data.data));
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : loadError('disciplinas');
    toastHandler.showError(error);
    yield put(ObraActions.getDisciplinesFailure(error));
  }
}

export function* getUsersConstructionSite({ payload }: GetUsersConstructionSiteAction) {
  try {
    const { data } = yield call(ObrasApi.getUserByConstructionSite, payload);
    const response = data.data;
    response.ConstructionSiteId = payload;
    yield put(ObraActions.getUsersConstructionSiteSuccess(response));
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : loadError('usuários da Obra');
    toastHandler.showError(error);
    yield put(ObraActions.getUsersConstructionSiteFailure(error));
  }
}

export function* bindUsersConstructionSite({ payload }: BindUsersConstructionSiteAction) {
  try {
    const {
      firstUserTracking,
      userInfoTracking,
      currentTenantTracking,
    } = payload;
    delete payload.firstUserTracking;
    delete payload.userInfoTracking;
    delete payload.currentTenantTracking;

    yield call(ObrasApi.bindUsersToConstructionSite, payload);
    yield put(ObraActions.bindUsersConstructionSiteSuccess(payload));
    
    const userLimitsActive: IUserLimitsActive = {
      QtyActiveUsers: payload.newInvitedUsers,
    }
    
    if (firstUserTracking) {
      Mixpanel.track({
        name: 'INVITED_FIRST_USER', 
        props: { constructionSiteId: payload.ConstructionSiteId },
        userInfo: userInfoTracking,  
        currentListTenant: currentTenantTracking,
      });
    }
    yield put(AuthActions.toogleUserLimitsActive(userLimitsActive));

    toastHandler.showSuccess('Usuários atualizados com sucesso.');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : updateError('usuários da Obra');
    toastHandler.showError(error);
    yield put(ObraActions.bindUsersConstructionSiteFailure(error));
  }
}

export function* listConstructionSiteAccounts() {
  try {
    const { data } = yield call(ObrasApi.listConstructionSiteAccountsInfo);
    yield put(ObraActions.listConstructionSiteAccountsSuccess(data.data));
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : loadError('contas da construtora');
    toastHandler.showError(error);
    yield put(ObraActions.listConstructionSiteAccountsFailure(error));
  }
}

export function* updateConstructionSiteApi({ payload }: UpdateConstructionSiteApiAction) {
  try {
    const { data } = yield call(ObrasApi.updateConstructionSiteApi, payload);
    yield put(ObraActions.list());

    if (payload.isRedirect) {
      if (payload.ExistingConstructionSiteTokenId) {
        history.push(`/obras/detail/${payload.CsId}`);
      } else {
        window.location.href = data.data.redirectUrl;
      }
      yield put(ObraActions.updateArchivedStatusSuccess(data.data));
    }
    else {
      yield put(ObraActions.listConstructionSitesStorage());
      toastHandler.showSuccess("Obra atualizada com sucesso");
    }
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : updateError('serviço de nuvem');
    toastHandler.showError(error);
    yield put(ObraActions.updateArchivedStatusFailure(error));
  }
}

export function* listConstructionSitesStorage() {
  try {
    const { data } = yield call(ObrasApi.listConstructionSitesStorage);
    yield put(ObraActions.listConstructionSitesStorageSuccess(data.data));
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : loadError('contas da construtora');
    toastHandler.showError(error);
    yield put(ObraActions.listConstructionSitesStorageFailure(error));
  }
}

export function* listAllConstructionsSitesOfUser() {
  try {
    const { data } = yield call(ObrasApi.fetchAllConstructionsOfUser);
    yield put(ObraActions.listAllConstructionsSitesOfUserSuccess(data.data));
  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : listAllTenantOfUserError;
    toastHandler.showError(error);
    yield put(ObraActions.listAllConstructionsSitesOfUserFailure(error)); 
  }
}