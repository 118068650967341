import { FC } from "react";
import { Icon } from "Components/UI";
import { IInvitedUser } from "Data/interfaces/User/IInvitedUser";
import { GetAvatar } from "Utils/generateThumbnail";
import Select from "Components/UI/Select";
import { IRole } from "../..";
import Button from "Components/UI/Button";
import HighlighterWords from "Components/UI/HighlightWords";
import { Container } from "./styles";

interface IItemInvited {
  user: IInvitedUser;
  search: string;
  roles: IRole[];
  loading?: boolean;
}

const ItemInvited: FC<IItemInvited> = ({
  user,
  search,
  roles,
  loading,
}) => {
  return (
    <Container>
      <div className="avatarContainer">
        <GetAvatar name={user.Email} />
        <HighlighterWords
          text={user.Email}
          wordSearched={search}
          className="userName"
        />
      </div>
      <div className="actionContainer">
        <Select
          placeholder="Selecione um papel"
          defaultValue={user.RoleFk}
          options={roles}
          onSelect={(value) => console.log('onSelect: ', { user, value })}
          className="selectRole"
        />
        <div className="watingContainer">
          <span className="waitingTitle">Aguardando</span>
          <Button
            type="text"
            onClick={() => console.log('cancel: ', { user })}
            className="watingBtn"
          >
            <Icon
              icon="cancelar"
              customSize={10}
              className="waitingIcon"
            />
          </Button>
        </div>
      </div>
    </Container>
  )
}

export default ItemInvited;
