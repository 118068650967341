import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { ISignalRArchive, IUploadFileData } from '../../Data/interfaces/Upload/IUploadFileData';
import { IStopped } from './interfaces/IUploadState';
import { ITrackUploadBulkFiles } from 'Components/UI/UploadFiles';

export enum UploadActionKeys {
  UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST',
  UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS',
  UPLOAD_FILES_FAILED = 'UPLOAD_FILES_FAILED',

  CHECK_UPLOAD_FILES_REQUEST = 'CHECK_UPLOAD_FILES_REQUEST',
  CHECK_UPLOAD_FILES_SUCCESS = 'CHECK_UPLOAD_FILES_SUCCESS',
  CHECK_UPLOAD_FILES_FAILED = 'CHECK_UPLOAD_FILES_FAILED',
  
  UPDATE_UPLOAD_FILE_LIST = 'UPDATE_UPLOAD_FILE_LIST',

  ADD_NEW_UPLOAD_FILES = 'ADD_NEW_UPLOAD_FILES',
  
  UPDATE_UPLOAD_FILE = 'UPDATE_UPLOAD_FILE',
  
  SET_UPLOAD_PROGRESS_RESPONSE = 'SET_UPLOAD_PROGRESS_RESPONSE',
  
  UPLOAD_CHUNK_FILES_REQUEST = 'UPLOAD_CHUNK_FILES_REQUEST',
  UPLOAD_CHUNK_FILES_SUCCESS = 'UPLOAD_CHUNK_FILES_SUCCESS',
  UPLOAD_CHUNK_FILES_FAILED = 'UPLOAD_CHUNK_FILES_FAILED',

  UPDATE_UPLOAD_CHUNK_FILE = 'UPDATE_UPLOAD_CHUNK_FILE',

  STOP_UPLOAD_CHUNK_FILE = 'STOP_UPLOAD_CHUNK_FILE',

  TRACK_UPLOAD_BULK_FILES = 'TRACK_UPLOAD_BULK_FILES',
  CLEAR_TRACK_UPLOAD_BULK_FILES = 'CLEAR_TRACK_UPLOAD_BULK_FILES',
}

export const UploadActions = {
  uploadFiles: (file: IUploadFileData): UploadFilesAction =>
    createAction(UploadActionKeys.UPLOAD_FILES_REQUEST, file),
  uploadFilesSuccess: (file: IUploadFileData): UploadFilesSuccessAction =>
    createAction(UploadActionKeys.UPLOAD_FILES_SUCCESS, file),
  uploadFilesFailure: (err: string): UploadFilesFailureAction =>
    createAction(UploadActionKeys.UPLOAD_FILES_FAILED, err),
    
  checkUploadFiles: (files: IUploadFileData[]): CheckUploadFilesAction =>
    createAction(UploadActionKeys.CHECK_UPLOAD_FILES_REQUEST, files),
  checkUploadFilesSuccess: (response: IUploadFileData[]): CheckUploadFilesSuccessAction =>
    createAction(UploadActionKeys.CHECK_UPLOAD_FILES_SUCCESS, response),
  checkUploadFilesFailure: (err: string): CheckUploadFilesFailureAction =>
    createAction(UploadActionKeys.CHECK_UPLOAD_FILES_FAILED, err),
    
  updateUploadFileList: (uploadFileList: IUploadFileData[]): updateUploadFileListAction =>
    createAction(UploadActionKeys.UPDATE_UPLOAD_FILE_LIST, uploadFileList),

  addNewUploadFiles: (uploadFileList: IUploadFileData[]): addNewUploadFilesAction =>
    createAction(UploadActionKeys.ADD_NEW_UPLOAD_FILES, uploadFileList),

  updateUploadFile: (uploadFile: IUploadFileData): updateUploadFileAction =>
    createAction(UploadActionKeys.UPDATE_UPLOAD_FILE, uploadFile),
    
  uploadProgressResponse: (data: ISignalRArchive): UploadProgressResponseAction =>
    createAction(UploadActionKeys.SET_UPLOAD_PROGRESS_RESPONSE, data),
    
  uploadChunkFiles: (file: IUploadFileData): UploadChunkFilesAction =>
    createAction(UploadActionKeys.UPLOAD_CHUNK_FILES_REQUEST, file),
  uploadChunkFilesSuccess: (file: IUploadFileData): UploadChunkFilesSuccessAction =>
    createAction(UploadActionKeys.UPLOAD_CHUNK_FILES_SUCCESS, file),
  uploadChunkFilesFailure: (err: string): UploadChunkFilesFailureAction =>
    createAction(UploadActionKeys.UPLOAD_CHUNK_FILES_FAILED, err),
    
  updateUploadChunkFile: (uploadFile: IUploadFileData): UpdateUploadChunkFileAction =>
    createAction(UploadActionKeys.UPDATE_UPLOAD_CHUNK_FILE, uploadFile),

  stopUploadChunkFile: (stopped: IStopped): StopUploadChunkFileAction =>
    createAction(UploadActionKeys.STOP_UPLOAD_CHUNK_FILE, stopped),

  trackUploadBulkFiles: (data: ITrackUploadBulkFiles): TrackUploadBulkFilesAction =>
    createAction(UploadActionKeys.TRACK_UPLOAD_BULK_FILES, data),
  clearTrackUploadBulkFiles: (): ClearTrackUploadBulkFilesAction =>
    createAction(UploadActionKeys.CLEAR_TRACK_UPLOAD_BULK_FILES),
  };

export type UploadActionUnion = ActionsUnion<typeof UploadActions>;

export type UploadFilesAction = Action<UploadActionKeys.UPLOAD_FILES_REQUEST, IUploadFileData>;
export type UploadFilesSuccessAction = Action<UploadActionKeys.UPLOAD_FILES_SUCCESS, IUploadFileData>;
export type UploadFilesFailureAction = Action<UploadActionKeys.UPLOAD_FILES_FAILED, string>;

export type CheckUploadFilesAction = Action<UploadActionKeys.CHECK_UPLOAD_FILES_REQUEST, IUploadFileData[]>;
export type CheckUploadFilesSuccessAction = Action<UploadActionKeys.CHECK_UPLOAD_FILES_SUCCESS, IUploadFileData[]>;
export type CheckUploadFilesFailureAction = Action<UploadActionKeys.CHECK_UPLOAD_FILES_FAILED, string>;

export type updateUploadFileListAction = Action<UploadActionKeys.UPDATE_UPLOAD_FILE_LIST, IUploadFileData[]>;

export type addNewUploadFilesAction = Action<UploadActionKeys.ADD_NEW_UPLOAD_FILES, IUploadFileData[]>;

export type updateUploadFileAction = Action<UploadActionKeys.UPDATE_UPLOAD_FILE, IUploadFileData>;

export type UploadProgressResponseAction = Action<UploadActionKeys.SET_UPLOAD_PROGRESS_RESPONSE, ISignalRArchive>;

export type UploadChunkFilesAction = Action<UploadActionKeys.UPLOAD_CHUNK_FILES_REQUEST, IUploadFileData>;
export type UploadChunkFilesSuccessAction = Action<UploadActionKeys.UPLOAD_CHUNK_FILES_SUCCESS, IUploadFileData>;
export type UploadChunkFilesFailureAction = Action<UploadActionKeys.UPLOAD_CHUNK_FILES_FAILED, string>;

export type UpdateUploadChunkFileAction = Action<UploadActionKeys.UPDATE_UPLOAD_CHUNK_FILE, IUploadFileData>;

export type StopUploadChunkFileAction = Action<UploadActionKeys.STOP_UPLOAD_CHUNK_FILE, IStopped>;

export type TrackUploadBulkFilesAction = Action<UploadActionKeys.TRACK_UPLOAD_BULK_FILES, ITrackUploadBulkFiles>;
export type ClearTrackUploadBulkFilesAction = Action<UploadActionKeys.CLEAR_TRACK_UPLOAD_BULK_FILES>;
