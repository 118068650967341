import * as Yup from 'yup';

const REVISION_FIELD = 4;

export const createSchema = (versionFields: any[], hasNomenclatureControl?: boolean) => {
  const fields: any = {};

  if (hasNomenclatureControl) {
    versionFields?.forEach((field) => {
      let validation = Yup.string().required('O campo deve ser preenchido');
      if (field.CampoVersaoId === REVISION_FIELD) {
        validation = validation.matches(/\d+/g, 'Sem número');
      }
      fields[`field${field.Ordem}`] = validation;
    });
  }
  else {
    fields['simpleFileName'] = Yup.string().required('O campo deve ser preenchido');
  }

  return Yup.object().shape(fields);
};
