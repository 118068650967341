import { FC, useState } from 'react';
import { Select as SelectAnt, Divider } from 'antd';
import { SelectProps as SelectPropsAnt } from 'antd/es/select';
import { IconName, Icon } from '../../UI';
import styles from './Select.module.scss';
import './select.css';
import styled, { css } from 'styled-components';
const { Option } = SelectAnt;

export interface IOptionData {
  value?: string | number;
  description: React.ReactNode | string;
  label: string;
  removed?: boolean;
  isDivider?: boolean;
  customClass?: string;
  color?: string;
  icon?: IconName;
}

interface ISelectProps extends SelectPropsAnt {
  options: IOptionData[];
  iconInput?: IconName;
  iconClassName?: string;
}

const Select: FC<ISelectProps> = (props) => {
  const [focus, setFocus] = useState(false);
  const filteredOptions = props.options.filter((option) => !option.removed);

  return (
    <div
      className={styles['selectWrap']}
      onBlur={() => setFocus(false)}
      onClick={() => setFocus(!focus)}
    >
      {props.iconInput ? (
        <Icon
          icon={props.iconInput}
          color="cinzaIcone"
          customSize={14}
          className={`${styles['iconInput']} ${props.iconClassName}`}
        />
      ) : (
        <></>
      )}
      <SelectAnt
        suffixIcon={null}
        getPopupContainer={(node) => (node.parentNode || document) as HTMLElement}
        {...props}
        options={filteredOptions}
      >
        {filteredOptions?.map((option, index) => (
          <Option
            key={`option${index}`}
            label={option.label}
            value={option.value}
            className={`${styles['SelectText']}`}
          >
            {option.isDivider ? (
              <Divider
                style={{ color: '#828282', marginTop: -22, width: '100%', right: 4 }}
              />
            ) : (
              <></>
            )}
            {option.icon ? (
              <div className={`${styles['iconSelectBeforeText']}`}>
                <Icon icon={option.icon} color={'cinzaOpaco'} />
                {option.description}
              </div>
            ) : (
              <span>{option.description}</span>
            )}
          </Option>
        ))}
      </SelectAnt>
      <Icon
        icon="expandir"
        color="cinzaSuperior"
        customSize={10}
        className={`${styles['iconSelect']} ${focus ? styles['focus'] : ''}`}
      />
    </div>
  );
};

export { Option };

interface StyledSelectProps {
  customBackgroundColor?: string;
}

export default styled(Select) <StyledSelectProps>`
  
  & > .ant-select-selection:focus {
    border: ${({ theme }) => `2px solid ${theme.colors.primary.primary} !important;`};
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  ${({ customBackgroundColor }) => customBackgroundColor && css`
    .ant-select-selector {
      background-color: ${customBackgroundColor} !important;
    }

    .ant-select-selection-item {
      font-size: 12px;
      font-weight: 400;
      color: #324150;
    }

    /* ant-select-item ant-select-item-option */
    .ant-select-item-option {
      align-items: center;
      height: 44px;
    }

    .ant-select-item-option-content {
      font-size: 12px;
      font-weight: 400;
      color: #324150;
    }
  `}
`;
