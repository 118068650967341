import { useEffect, useRef, useState } from "react";
import {
  IDragAndDropDataFormat,
  topicKeysRequestFormat,
} from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { useDispatch, useSelector } from "react-redux";
import { history } from "Store";
import { ActivitiesActions } from "Store/Activities/Activities.actions";
import useQuery from "Hooks/useQuery";
import useWindowSize from "Hooks/useWindowSize";
import { ITableActionTopbarButton } from "Components/UI/TableActionTopbar";
import AccessPermission from "Utils/AcessPermission";
import toastHandler from '../../Utils/toastHandler';
import { ITopicSignalRHasUpdate } from "Data/interfaces/Activities/ITopicSignalRHasUpdate";
import { TopicVisibilityEnum } from "Data/interfaces/Activities/ITopicResponse";
import { useFeatureFlag } from "Hooks/useFeatureFlag";
import { IObraResponse } from "Data/interfaces/Obra/IObraListResponse";
import { TypeListButtons } from "./components/ListButtons";
import AppStorage from '../../Utils/AppStorage';
import { getFiltersKey } from "Utils/ActivitiesUtils";
import {
  FiltersRecordType,
  IUpdateFilter,
} from "Data/interfaces/Activities/IFilters";
import { IUserInforResponse } from "Data/interfaces/Auth/IUserInfoResponse";
import { Mixpanel } from "Utils/MixPanel";
import { v4 as uuid } from 'uuid';
import MoreFiltersActions from "./components/MoreFiltersActions";
import { ITopicRequest } from "Data/interfaces/Activities/ITopicRequest";
import { FilterType, IFiltersData, IFiltersRequest } from "Data/interfaces/Activities/IFiltersRequest";
import { BUTTON_ACTIVITY_LIST_KEY } from ".";
import { getCurrentTenant } from "Store/Tenant/Tenant.selector";

interface IUseActivity {
  constructionSiteId: number;
  listActivities?: IDragAndDropDataFormat;
  topicSignalRHasUpdate: ITopicSignalRHasUpdate | null;
  userInfo?: IUserInforResponse;
  externalGlobalLoading: boolean;
  obrasList?: IObraResponse[];
  isShareFiltersEnabled?: boolean;
  shareLink?: string;
  filters: FiltersRecordType;
}

const useActivity = ({
  constructionSiteId,
  listActivities,
  topicSignalRHasUpdate,
  userInfo,
  externalGlobalLoading,
  obrasList,
  isShareFiltersEnabled,
  shareLink,
  filters,
}: IUseActivity) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const historyActivitiesFeatureFlag = useFeatureFlag('history-activities-feature');
  const historyActivitiesFeature = historyActivitiesFeatureFlag.enabled &&
    historyActivitiesFeatureFlag.value === 'test';

  const query = useQuery();
  const { height, width } = useWindowSize();

  const filtersIdQuery = query.get('filterId');
  const searchActivityQuery = query.get('searchActivity');
  const workActivityQuery = query.get('workActivity');

  const firstRender = useRef(0);
  const firstRenderSignal = useRef(true);
  const firstOpenFilters = useRef(true);
  const firstRenderFilters = useRef(true);
  const blockFilterDropdown = useRef(true);
  const [searchActivities, setSearchActivities] = useState<string>('');
  const [searchActivitiesDefault, setSearchActivitiesDefault] = useState<string | undefined>(undefined);
  const [searchLoading, setSearchLoading] = useState(false);
  const [activeListButton, setActiveListButton] = useState<TypeListButtons>(
    AppStorage.GetItem(BUTTON_ACTIVITY_LIST_KEY, 'local') as TypeListButtons || 'kanban'
  );
  const [showCreateActivity, setShowCreateActivity] = useState(false);
  const [currentShowActivity, setCurrentShowActivity] = useState<string | null>(null);
  const [lastModifiedLoading, setLastModifiedLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showFiltersShare, setShowFiltersShare] = useState(false);
  const [lastFilterUpdateId, setLastFilterUpdateId] = useState('');
  const hasFilters = JSON.stringify(filters) !== '{}';

  const whatsappContactLinkFlag = useFeatureFlag('whatsapp-contact-link');
  const whatsappContactLink = (whatsappContactLinkFlag.enabled && typeof whatsappContactLinkFlag.value === 'string')
    ? whatsappContactLinkFlag.value
    : (process.env.REACT_APP_CONTACT_LINK || '');

  const addFilter = () => {
    blockFilterDropdown.current = false;

    const filterId = uuid();
    dispatch(ActivitiesActions.addActivityFilter(filterId));
    setLastFilterUpdateId(filterId);
  };

  const updateFilter = ({
    op,
    values,
  }: IUpdateFilter) => {
    const { filterId } = values;
    if (filterId) {
      dispatch(ActivitiesActions.updateActivityFilter({
        op,
        values,
      }))
      setLastFilterUpdateId(filterId);
    }
  };

  const deleteFilter = (filterId: string) => {
    dispatch(ActivitiesActions.deleteActivityFilter(filterId));
  };

  const handleActiveListButton = (value: TypeListButtons) => {
    setActiveListButton(value);
    setSearchActivities('');
  };

  const handleObraPermission = (
    currentConstructionSiteId: number,
    currentUserId: string,
    currentObrasList?: IObraResponse[],
  ) => {
    if (currentObrasList && currentObrasList.length > 0) {
      const obraPermission = currentObrasList.find(obra => obra.ConstructionSiteId === currentConstructionSiteId);
      if (obraPermission) {
        const haveObraPermission = obraPermission.SharedUsers.find((user: any) => user.UserFk === currentUserId);
        if (haveObraPermission) {
          return;
        }
      }
    }

    toastHandler.showError('Você não possui permissão de acesso a obra.');
    return history.replace('/');
  };

  const handleUserPermissionExternalLink = (
    index: string,
    currentListActivities: IDragAndDropDataFormat,
  ) => {
    const activity = currentListActivities.activities[index];

    const haveVisualizationPermission = AccessPermission.visualizationActivitiePermission(
      userInfo,
      activity.CreationAuthor,
      activity.TopicAssignedUsers,
      activity.Visibility
    );

    if (haveVisualizationPermission) {
      return setCurrentShowActivity(index);
    }


    toastHandler.showError('Não possui permissão para visualizar a Atividade.');
    handleCloseShowActivity();
    return history.replace('?');
  };

  const handleUpdateListActivities = () => {
    toastHandler.externalDeleteUpdateToast();
    const request: ITopicRequest = {
      csId: constructionSiteId,
      filtersId: filtersIdQuery,
      testHistoryMock: historyActivitiesFeature,
    };
    dispatch(ActivitiesActions.listActivities(request));
    setLastModifiedLoading(true);
  };

  const handleOpenCreateActivity = () => {
    if (workActivityQuery) {
      history.push(`?`);
    };
    setCurrentShowActivity(null);
    setShowCreateActivity(true);
  };

  const handleOpenShowActivity = (activitieId: string) => {
    if (workActivityQuery) {
      history.push(`?`);
    };
    setCurrentShowActivity(activitieId);
    setShowCreateActivity(true);
  };

  const handleCloseShowActivity = () => {
    setCurrentShowActivity(null);
    setShowCreateActivity(false);
  };

  const handleShowFilters = (show: boolean) => {
    if (show) {
      blockFilterDropdown.current = true;
    }
    setShowFilters(show);
  };

  const handleShowFiltersShare = (show: boolean) => {
    if (!shareLink) {
      const filtersFormat: IFiltersData[] = [];

      for (const filter of Object.values(filters)) {
        const { topicId, operation, value, description, properties } = filter;

        let currentValue = [];
        const extraData: Record<string, any> = {};

        if (value) {
          currentValue = Array.isArray(filter.value) ? filter.value : [filter.value];
        }
        if (operation) {
          extraData['operation'] = operation;
        }
        if (properties) {
          extraData['properties'] = properties;
        }
        if (description) {
          extraData['description'] = description;
        }

        filtersFormat.push({
          Field: topicKeysRequestFormat[topicId],
          Values: currentValue,
          ExtraData: extraData,
        });
      };

      const request: IFiltersRequest = {
        Type: FilterType.Activity,
        ConstructionSiteFk: constructionSiteId,
        Filters: filtersFormat,
        CreateShortURL: true,
      };
      dispatch(ActivitiesActions.shareLinkActivity(request));
    }
    setShowFiltersShare(show);
  };

  const clearShareLink = () => {
    dispatch(ActivitiesActions.shareLinkActivityClear());
  };

  const clearAllFilters = () => {
    dispatch(ActivitiesActions.clearAllActivityFilters());
    handleShowFilters(false);
  };

  const upgradePlanRedirect = (role: number, planOrder: number) => {
    Mixpanel.track({
      name: 'CALL_T0_ACTION',
      props: {
        origin: 'activities',
        originPath: window.location.pathname
      },
      userInfo,
      currentListTenant: currentTenant,
    });

    if (role === 1) {
      if (planOrder === 4) {
        window.open(whatsappContactLink);
      } else {
        history.push('/faturamento/planos');
      }

    } else {
      window.open('https://bim.maletadoengenheiro.com.br/seja-premium');
    }
  };

  const getActionsToolbar = (): ITableActionTopbarButton[] => {
    return [
      {
        icon: 'share',
        iconCustomSize: 18,
        description: 'Compartilhar',
        hidden: !isShareFiltersEnabled,
        action: () => isShareFiltersEnabled
          ? handleShowFiltersShare(!showFiltersShare)
          : undefined,
      },
      {
        icon: 'filtrar',
        iconCustomSize: 14,
        description: 'Filtros',
        hidden: false,
        action: () => handleShowFilters(!showFilters),
      },
      {
        icon: 'atualizar',
        iconCustomSize: 14,
        description: 'Atualizar',
        hidden: false,
        action: () => handleUpdateListActivities(),
      },
      {
        icon: 'adicionarAlt',
        iconCustomSize: 18,
        description: 'Nova Atividade',
        hidden: !AccessPermission.createOrEditActivitiePermission(),
        action: () => handleOpenCreateActivity(),
      },
      {
        icon: 'moreActions',
        hidden: false,
        dropdownMenu: (toggleDropdown: () => void) => (
          <MoreFiltersActions
            onClear={() => clearAllFilters()}
            onCancel={() => toggleDropdown()}
          />
        )
      },
    ];
  };

  useEffect(() => {
    const request: ITopicRequest = {
      csId: constructionSiteId,
      filtersId: filtersIdQuery,
    };
    dispatch(ActivitiesActions.listActivities(request));
  }, []);

  useEffect(() => {
    if (constructionSiteId && userInfo?.Id && !externalGlobalLoading) {
      const constructionSiteIdNumber = Number(constructionSiteId);
      handleObraPermission(constructionSiteIdNumber, userInfo.Id, obrasList);
    }

    if (workActivityQuery) {
      setShowCreateActivity(true);
      if (
        (userInfo && userInfo.Id) &&
        (listActivities && listActivities.activities.hasOwnProperty(workActivityQuery))
      ) {
        handleUserPermissionExternalLink(
          workActivityQuery,
          listActivities,
        );
      }

      if (listActivities && !listActivities.activities.hasOwnProperty(workActivityQuery)) {
        setShowCreateActivity(false);
      }
    }
  }, [
    listActivities,
    obrasList,
    userInfo,
    constructionSiteId,
    externalGlobalLoading,
  ]);

  useEffect(() => {
    if (lastModifiedLoading && listActivities) {
      setLastModifiedLoading(false);
    }
  }, [listActivities]);

  useEffect(() => {
    if (searchActivityQuery && firstRender.current === 0) {
      setSearchActivities(searchActivityQuery || '');
      setSearchActivitiesDefault(searchActivityQuery || undefined);
    }

    if (searchActivities) {
      let searchQuery = `?searchActivity=${encodeURIComponent(searchActivities)}`;

      history.push(searchQuery);
      firstRender.current = 1;

    } else {
      if (firstRender.current < 1) return;
      history.push(`?`);
    }
  }, [searchActivities]);

  useEffect(() => {
    if (
      !filtersIdQuery &&
      firstRenderFilters.current &&
      !hasFilters &&
      (AppStorage.GetItem(getFiltersKey(constructionSiteId), 'session'))
    ) {
      const useFilter: FiltersRecordType = JSON.parse(
        AppStorage.GetItem(getFiltersKey(constructionSiteId), 'session') ?? ''
      );

      dispatch(ActivitiesActions.populatedActivityFilter(useFilter));
      if (JSON.stringify(useFilter) !== '{}') {
        handleShowFilters(true);
      }

      setTimeout(() => {
        firstOpenFilters.current = false;
      }, 1000)
      firstRenderFilters.current = false;
    }

    if (
      filtersIdQuery &&
      firstRenderFilters.current &&
      hasFilters
    ) {
      handleShowFilters(true);

      setTimeout(() => {
        firstOpenFilters.current = false;
      }, 1000)
      firstRenderFilters.current = false;
    }

    const filtersString = JSON.stringify(filters);

    AppStorage.SetItem(getFiltersKey(constructionSiteId), filtersString, 'session');
    clearShareLink();
  }, [filters, hasFilters, constructionSiteId]);

  useEffect(() => {
    if (userInfo && userInfo.Id) {
      if (topicSignalRHasUpdate
        && topicSignalRHasUpdate.csId === constructionSiteId
        && topicSignalRHasUpdate.UpdaterUserId !== userInfo.Id
        && !firstRenderSignal.current
      ) {
        switch (topicSignalRHasUpdate.Visibility) {
          case TopicVisibilityEnum.Public:
            toastHandler.showActivitieHasUpdate(
              'Atualização disponível. Clique para atualizar.',
              '',
              handleUpdateListActivities,
            );
            break;

          case TopicVisibilityEnum.Private:
            if (userInfo.Id && topicSignalRHasUpdate.ResponsibleUsersId.includes(userInfo.Id)) {
              toastHandler.showActivitieHasUpdate(
                'Atualização disponível. Clique para atualizar.',
                '',
                handleUpdateListActivities,
              );
            }
            break;

          default:
            return;
        }
        dispatch(ActivitiesActions.topicSignalRHasUpdate(null));
      }
      firstRenderSignal.current = false;
    }
  }, [topicSignalRHasUpdate, userInfo]);

  return {
    activeListButton,
    handleActiveListButton,
    upgradePlanRedirect,
    getActionsToolbar,
    searchActivitiesDefault,
    setSearchActivities,
    setSearchLoading,
    height,
    width,
    query,
    handleOpenCreateActivity,
    handleOpenShowActivity,
    showCreateActivity,
    handleCloseShowActivity,
    currentShowActivity,
    lastModifiedLoading,
    showFilters,
    handleShowFilters,
    showFiltersShare,
    handleShowFiltersShare,
    filters,
    hasFilters,
    addFilter,
    updateFilter,
    deleteFilter,
    firstRenderFilters: firstOpenFilters.current,
    lastFilterUpdateId,
    blockFilterDropdown: blockFilterDropdown.current,
  };
}

export default useActivity;
