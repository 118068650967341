import { IUploadState } from './interfaces/IUploadState';
import { UploadActionUnion, UploadActionKeys } from './Upload.actions';
import { IUploadFileData } from '../../Data/interfaces/Upload/IUploadFileData';

const initialState: IUploadState = {
  uploadFileList: undefined,
  isLoading: false,
  error: undefined,
  message: undefined,
  stopped: {
    isStopped: false,
    uploadFileId: undefined,
  },
  trackUploadBulkFiles: undefined,
  trackUploadBulkFilesNow: false,
};

const uploadReducer = (state = initialState, action: UploadActionUnion): IUploadState => {
  switch (action.type) {
    case UploadActionKeys.UPLOAD_FILES_REQUEST:
      if (action.payload.index && state.uploadFileList) {
        state.uploadFileList[action.payload.index] = action.payload;
      }

      return {
        ...state,
        uploadFileList: [...state.uploadFileList || []],
        isLoading: true,
      };
    case UploadActionKeys.UPLOAD_FILES_SUCCESS:
      if (state.uploadFileList && action.payload.index) {
        state.uploadFileList[action.payload.index] = action.payload;
      }

      return {
        ...state,
        uploadFileList: [...state.uploadFileList || []],
        isLoading: false,
      };
    case UploadActionKeys.UPLOAD_FILES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UploadActionKeys.CHECK_UPLOAD_FILES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        message: undefined,
      };
    case UploadActionKeys.CHECK_UPLOAD_FILES_SUCCESS:
      let fileList: IUploadFileData[] | undefined;

      for (let file of action.payload) {
        if (state.uploadFileList && file.index) {
          state.uploadFileList[file.index] = { ...action.payload[file.index] };
          fileList = [...state.uploadFileList];
        }
      }
      return {
        ...state,
        uploadFileList: fileList,
        isLoading: false,
      };
    case UploadActionKeys.CHECK_UPLOAD_FILES_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case UploadActionKeys.UPDATE_UPLOAD_FILE_LIST:
      return {
        ...state,
        uploadFileList: [...action.payload],
      };

    case UploadActionKeys.ADD_NEW_UPLOAD_FILES:
      const list = state.uploadFileList
        ? [...state.uploadFileList, ...action.payload]
        : action.payload;
      return {
        ...state,
        uploadFileList: list,
      };

    case UploadActionKeys.UPDATE_UPLOAD_FILE:
      let newList = [] as IUploadFileData[];

      if (state.uploadFileList) {
        const fileIndex = state.uploadFileList.findIndex(x => {
          return x.identifier?.ApiId === action.payload.identifier?.ApiId;
        });
        if (fileIndex !== -1) {
          state.uploadFileList[fileIndex] = action.payload;
          newList = [...state.uploadFileList];
        }
      }
      return {
        ...state,
        uploadFileList: newList
      };

    case UploadActionKeys.SET_UPLOAD_PROGRESS_RESPONSE:
      let newFileList: IUploadFileData[] | undefined = state.uploadFileList;

      if (newFileList && action.payload) {
        const indexFile = newFileList.findIndex(fileRow => {
          return action.payload.fileIdentifier === fileRow.identifier?.ApiId;
        });

        if (indexFile !== -1 && newFileList[indexFile].UploadRenderingStatus) {
          newFileList[indexFile].UploadRenderingStatus = {
            fileIdentifier: action.payload.fileIdentifier,
            progress: action.payload.progress,
            archiveStatus: action.payload.archiveStatus
          }
        }
      }
      return {
        ...state,
        uploadFileList: newFileList ? [...newFileList] : undefined,
      };

    case UploadActionKeys.UPLOAD_CHUNK_FILES_REQUEST:
      if (action.payload.index && state.uploadFileList) {
        state.uploadFileList[action.payload.index] = action.payload;
      }

      return {
        ...state,
        uploadFileList: [...state.uploadFileList || []],
        isLoading: true,
      };
    case UploadActionKeys.UPLOAD_CHUNK_FILES_SUCCESS:
      if (state.uploadFileList && action.payload.index) {
        state.uploadFileList[action.payload.index] = action.payload;
      }

      return {
        ...state,
        uploadFileList: [...state.uploadFileList || []],
        isLoading: false,
      };
    case UploadActionKeys.UPLOAD_CHUNK_FILES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UploadActionKeys.UPDATE_UPLOAD_CHUNK_FILE:
      let newListChunks = [] as IUploadFileData[];

      if (state.uploadFileList) {
        const fileIndex = state.uploadFileList.findIndex(x => {
          return x.identifier?.ApiId === action.payload.identifier?.ApiId;
        });

        if (fileIndex !== -1) {
          state.uploadFileList[fileIndex] = action.payload;
          newList = [...state.uploadFileList];
        }
      }

      return {
        ...state,
      };

    case UploadActionKeys.STOP_UPLOAD_CHUNK_FILE:
      return {
        ...state,
        stopped: action.payload,
      };

    case UploadActionKeys.TRACK_UPLOAD_BULK_FILES:
      let currentTrackUploadBulkFiles = state.trackUploadBulkFiles;
      let newTrackUploadBulkFilesNow = state.trackUploadBulkFilesNow;
      const newTrackUploadBulkFiles = action.payload;

      if (newTrackUploadBulkFiles?.type === 'init' || newTrackUploadBulkFiles?.type === 'last') {
        currentTrackUploadBulkFiles = {
          ...currentTrackUploadBulkFiles,
          ...newTrackUploadBulkFiles,
        };
      }

      if (
        newTrackUploadBulkFiles?.type === 'time'
        && currentTrackUploadBulkFiles?.elapsedTime !== undefined
        && newTrackUploadBulkFiles?.elapsedTime
      ) {
        currentTrackUploadBulkFiles.elapsedTime += newTrackUploadBulkFiles.elapsedTime;
      }

      if (
        newTrackUploadBulkFiles?.type === 'finish'
        && currentTrackUploadBulkFiles?.elapsedTime !== undefined
        && newTrackUploadBulkFiles?.elapsedTime
      ) {
        currentTrackUploadBulkFiles.elapsedTime += newTrackUploadBulkFiles.elapsedTime;
        currentTrackUploadBulkFiles.destiny = newTrackUploadBulkFiles.destiny;
        currentTrackUploadBulkFiles.api = newTrackUploadBulkFiles.api;
        currentTrackUploadBulkFiles.type = newTrackUploadBulkFiles.type;
        newTrackUploadBulkFilesNow = true;
      }

      if (
        newTrackUploadBulkFiles?.type === 'error'
        && currentTrackUploadBulkFiles?.elapsedTime !== undefined
        && newTrackUploadBulkFiles?.elapsedTime
      ) {
        currentTrackUploadBulkFiles.elapsedTime += newTrackUploadBulkFiles.elapsedTime;
        currentTrackUploadBulkFiles.destiny = newTrackUploadBulkFiles.destiny;
        currentTrackUploadBulkFiles.api = newTrackUploadBulkFiles.api;
        currentTrackUploadBulkFiles.type = newTrackUploadBulkFiles.type;
        newTrackUploadBulkFilesNow = true;
      }

      return {
        ...state,
        trackUploadBulkFiles: { ...currentTrackUploadBulkFiles },
        trackUploadBulkFilesNow: newTrackUploadBulkFilesNow,
      };
    case UploadActionKeys.CLEAR_TRACK_UPLOAD_BULK_FILES:
      return {
        ...state,
        trackUploadBulkFiles: undefined,
        trackUploadBulkFilesNow: false,
      };

    default:
      return state;
  }
};

export default uploadReducer;
