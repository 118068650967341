import { FC } from "react";
import Form from "Components/UI/Form/Form";
import schema from "../../ShowCreateActivitie.schema";
import InputTitle from "../InputTitle";
import NewSimpleTextArea from "Components/UI/NewFormGroup/NewSimpleTextArea";
import NewSimpleSelect from "Components/UI/NewFormGroup/NewSimpleSelect";
import NewImgSelect from "Components/UI/NewFormGroup/NewImgSelect";
import NewSimpleDateText from "Components/UI/NewFormGroup/NewSimpleDateText";
import { ITopicDnDResponse } from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { ICustomOption } from "../../useOptionsSelect";
import { ITopicCreateRequestForm } from "../../useShowCreateActivity";
import { TypeCustomError } from "../../";
import { 
  TypePathUpdateData, 
  TypeValueUpdateData,
} from "Data/interfaces/Activities/IUpdateActivityRequest";
import AccessPermission from "Utils/AcessPermission";
import { IUserInforResponse } from "Data/interfaces/Auth/IUserInfoResponse";
import { BodyDefault, BodyDescription, BodyText, MidleHr } from "./styles";

interface IFormShowCreateActivity {
  updateActivity: ITopicDnDResponse | null;
  deleteLoading?: boolean;
  visibilityOptions: ICustomOption[];
  priorityOptions: ICustomOption[];
  priorityNoBgOptions: ICustomOption[];
  statusOptions: ICustomOption[];
  typeOptions: ICustomOption[];
  stageOptions: string[];
  pointsOptions: string[];
  communicationOptions: string[];
  userOptions: ICustomOption[];
  disciplineOptions: ICustomOption[];
  isSubmit: boolean;
  titleValue: string;
  defaultValues: ITopicCreateRequestForm;
  errors: TypeCustomError[];
  userInfo?: IUserInforResponse;
  handleTitleValue: (value: string) => void;
  submit: (value: ITopicCreateRequestForm) => void;
  dynamicUpdateSubmit: (path: TypePathUpdateData, values: TypeValueUpdateData) => void;
  handleClearCustomError: (value: TypeCustomError) => void;
  handleCustomError: (value: TypeCustomError) => void;
}

const FormShowCreateActivity: FC<IFormShowCreateActivity> = ({
  updateActivity,
  visibilityOptions,
  priorityOptions,
  priorityNoBgOptions,
  statusOptions,
  typeOptions,
  stageOptions,
  pointsOptions,
  communicationOptions,
  userOptions,
  disciplineOptions,
  isSubmit,
  titleValue,
  defaultValues,
  errors,
  userInfo,
  handleTitleValue,
  submit,
  dynamicUpdateSubmit,
  handleClearCustomError,
  handleCustomError,
}) => {
  const isUpdate = !!updateActivity?.Guid;
  const changeStatusPermission = AccessPermission.statusActivitieEditPermission(
    userInfo,
    updateActivity?.CreationAuthor, 
    updateActivity?.TopicAssignedUsers,
    updateActivity?.Visibility  
  );
  const editPermission = AccessPermission.createOrEditActivitiePermission(
    userInfo,
    isUpdate,
    updateActivity?.CreationAuthor, 
    updateActivity?.TopicAssignedUsers,
    updateActivity?.Visibility  
  );
  const descriptionPermission = AccessPermission.createOrEditActivitiePermission(
    userInfo,
    isUpdate,
    updateActivity?.CreationAuthor, 
    updateActivity?.TopicAssignedUsers,
    updateActivity?.Visibility,
    true,
  );
  const createNewOptsPermission = AccessPermission.createNewOptsActivitiePermission();

  return (
    <Form 
      schema={schema} 
      onSubmit={submit} 
      isSubmited={isSubmit} 
      initialValues={defaultValues} 
    >
      <InputTitle
        placeholder="Sem título"
        name="title"
        path="Title"
        defaultValue={titleValue}
        onConfirm={handleTitleValue}
        onDynamicUpdateSubmit={dynamicUpdateSubmit}
        errorId="title"
        error={errors.includes('title')}
        tooltipErrorText="O campo Resumo é obrigatório e deve ser preenchido."
        onCustomError={handleCustomError}
        onClearCustomError={handleClearCustomError}
        maxLenght={250}
        isUpdate={isUpdate}
        havePermission={editPermission}
      />
      <MidleHr />
      <div>
        {updateActivity && (
          <BodyDefault>
            <BodyText>Status</BodyText>
            <NewSimpleSelect
              className="selectRole"
              placeholder="Status"
              options={statusOptions}
              name="statusFk"
              isUpdate={isUpdate}
              path="StatusFk"
              onDynamicUpdateSubmit={dynamicUpdateSubmit}
              havePermission={changeStatusPermission}
            />
          </BodyDefault>
        )}
        <BodyDescription isupdate={`${isUpdate}`}>
          <BodyText isdescription={`${isUpdate}`}>
            Observação
          </BodyText>
          <NewSimpleTextArea
            name="description" 
            placeholder="Adicionar observação detalhada da atividade..."
            maxLenght={2500}
            isUpdate={isUpdate}
            path="Description"
            onDynamicUpdateSubmit={dynamicUpdateSubmit}
            havePermission={descriptionPermission}
          />
        </BodyDescription>
        {!isUpdate && (
          <BodyDefault>
            <BodyText>Tipo de atividade</BodyText>
            <NewSimpleSelect
              className="selectRole"
              placeholder="Atividade"
              activePlaceholder
              options={typeOptions}
              name="typeFk"
              isUpdate={isUpdate}
              path="TypeFk"
              onDynamicUpdateSubmit={dynamicUpdateSubmit}
              havePermission={editPermission}
            />
          </BodyDefault>
        )}
        <BodyDefault>
          <BodyText>Responsáveis</BodyText>
          <NewImgSelect
            className="selectRole"
            placeholder="Adicionar responsáveis"
            options={userOptions}
            name="assignedUsersFk"
            isUpdate={isUpdate}
            path="AssignedUsersFk"
            onDynamicUpdateSubmit={dynamicUpdateSubmit}
            havePermission={editPermission}
            emptyMsg="Nenhum usuário encontrado"
          />
        </BodyDefault>
        <BodyDefault>
          <BodyText iserror={`${errors.includes('discipline')}`}>
            Disciplinas
            {!isUpdate && <BodyText iserror="true">*</BodyText>}
          </BodyText>
          <NewImgSelect
            className="selectRole"
            placeholder="Adicionar disciplinas"
            options={disciplineOptions}
            name="constructionSiteDisciplinesFk"
            errorId="discipline"
            error={errors.includes('discipline')}
            tooltipErrorText="O campo Disciplinas é obrigatório e deve ser preenchido."
            onCustomError={handleCustomError}
            onClearCustomError={handleClearCustomError}
            isUpdate={isUpdate}
            path="ConstructionSiteDisciplinesFk"
            onDynamicUpdateSubmit={dynamicUpdateSubmit}
            havePermission={editPermission}
            emptyMsg="Nenhuma disciplina encontrada"
          />
        </BodyDefault>
        <BodyDefault>
        <BodyText iserror={`${errors.includes('date')}`}>
          Prazo
        </BodyText>
          <NewSimpleDateText
            name="dueDate" 
            placeholder="Adicionar prazo"
            type="date"
            errorId="date"
            error={errors.includes('date')}
            tooltipErrorText="O Prazo não pode ser anterior ao dia de hoje."
            onCustomError={handleCustomError}
            onClearCustomError={handleClearCustomError}
            isUpdate={isUpdate}
            path="DueDate"
            onDynamicUpdateSubmit={dynamicUpdateSubmit}
            havePermission={editPermission}
            notRequired
          />
        </BodyDefault>
        <BodyDefault>
          <BodyText>Visibilidade</BodyText>
          <NewSimpleSelect
            className="selectRole"
            options={visibilityOptions}
            name="visibility"
            isUpdate={isUpdate}
            path="Visibility"
            onDynamicUpdateSubmit={dynamicUpdateSubmit}
            havePermission={editPermission}
          />
        </BodyDefault>
        <BodyDefault>
          <BodyText>Prioridade</BodyText>
          <NewSimpleSelect
            className="selectRole"
            placeholder="Adicionar prioridade"
            options={priorityOptions}
            optionsCustom={priorityNoBgOptions}
            name="priority"
            isUpdate={isUpdate}
            path="Priority"
            onDynamicUpdateSubmit={dynamicUpdateSubmit}
            havePermission={editPermission}
            notRequired
          />
        </BodyDefault>
        <BodyDefault>
          <BodyText>Etapa</BodyText>
          <NewImgSelect
            className="selectRole"
            placeholder="Adicionar etapa"
            currentValue={defaultValues.stageFk}
            mode="tags"
            limit={1}
            options={[]}
            tagsDontImg
            optionsTagsDontImg={stageOptions}
            sufix=" (Nova etapa)"
            name="stageFk"
            isUpdate={isUpdate}
            path="StageFk"
            onDynamicUpdateSubmit={dynamicUpdateSubmit}
            havePermission={editPermission}
            haveCreateOptPermission={createNewOptsPermission}
            emptyMsg="Nenhuma etapa encontrada"
          />
        </BodyDefault>
        <BodyDefault>
          <BodyText iserror={`${errors.includes('point')}`}>
            Local
            {!isUpdate && <BodyText iserror="true">*</BodyText>}
          </BodyText>
          <NewImgSelect
            className="selectRole"
            placeholder="Adicionar local"
            currentValue={defaultValues.pointsFk}
            mode="tags"
            options={[]}
            tagsDontImg
            optionsTagsDontImg={pointsOptions}
            sufix=" (Novo local)"
            name="pointsFk"
            errorId="point"
            error={errors.includes('point')}
            tooltipErrorText="O campo Local é obrigatório e deve ser preenchido."
            onCustomError={handleCustomError}
            onClearCustomError={handleClearCustomError}
            havePermission={editPermission}
            haveCreateOptPermission={createNewOptsPermission}
            isUpdate={isUpdate}
            path="PointsFk"
            onDynamicUpdateSubmit={dynamicUpdateSubmit}
            isRequired
            emptyMsg="Nenhuma local encontrado"
          />
        </BodyDefault>
        <BodyDefault>
          <BodyText>Meio</BodyText>
          <NewImgSelect
            className="selectRole"
            placeholder="Adicionar meio"
            currentValue={defaultValues.communicationTypeFk}
            mode="tags"
            limit={1}
            options={[]}
            tagsDontImg
            optionsTagsDontImg={communicationOptions}
            sufix=" (Novo meio)"
            name="communicationTypeFk"
            isUpdate={isUpdate}
            path="CommunicationTypeFk"
            onDynamicUpdateSubmit={dynamicUpdateSubmit}
            havePermission={editPermission}
            haveCreateOptPermission={createNewOptsPermission}
            emptyMsg="Nenhuma meio encontrado"
          />
        </BodyDefault>
      </div>
    </ Form>
  )
}

export default FormShowCreateActivity;
