import React, { FC, useEffect } from 'react';

import styles from './ConexaoServico.module.scss';
import Modal from '../../../../Components/UI/Modal/Modal';
import ArmazenamentoTab from '../Tabs/ArmazenamentoTab';
import { useDispatch, connect, ConnectedProps } from 'react-redux';
import { ObraActions } from '../../../../Store/Obra/Obra.actions';
import { IGlobalReducerState } from '../../../../Store/Base/interface/IGlobalReducerState';
import { Skeleton } from 'antd';
import Button from '../../../../Components/UI/Button';
import { IUpdateConstructionSiteApiRequest } from '../../../../Data/interfaces/Obra/IUpdateConstructionSiteApiRequest';
import toastHandler from '../../../../Utils/toastHandler';

interface IConexaoServico {
  csId?: number;
  isVisible?: boolean;
  onCancel?: () => void;
  isRedirect?: boolean;
  accountIdSelected?: number;
}

const ConexaoServico: FC<Props> = ({ isVisible, AccountsInfo, onCancel, csId, isLoading, isRedirect, accountIdSelected }) => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isSendingData, setSendingData] = React.useState<boolean>(false);

  if (isSendingData && !isLoading) {
    setSendingData(false);
    if (onCancel) {
      onCancel();
    }
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (isVisible) {
      dispatch(ObraActions.listConstructionSiteAccounts());
    }
  }, [dispatch, isVisible]);

  const onSubmit = (values: any) => {
    if (csId && values !== undefined) {
      const request: IUpdateConstructionSiteApiRequest = {
        CsId: csId,
        Api: values.Api,
        ExistingConstructionSiteTokenId: values.ExistingConstructionSiteTokenId,
        isRedirect: isRedirect === undefined || isRedirect || !values.ExistingConstructionSiteTokenId
      };
      dispatch(ObraActions.updateConstructionSiteApi(request));
      setSendingData(true);
    }
    else {
      toastHandler.showError('Um serviço de nuvem deve ser selecionado.');
    }
    setIsSubmitted(false);
  };

  const footerButtons = (
    <>
      <Button onClick={onCancel}>
        <span style={{ padding: '0 35px' }}>Cancelar</span>
      </Button>
      <Button type='primary' onClick={() => setIsSubmitted(true)} loading={isLoading}>
        <span style={{ padding: '0 35px' }}>Concluir</span>
      </Button>
    </>
  );

  return (
    <Modal
      title='Conetar ao serviço de nuvem'
      visible={isVisible}
      onCancel={onCancel}
      width={800}
      footerButtons={footerButtons}>
      {
        isLoading
          ? (<div className={styles['skeletonWrap']} ><Skeleton active /></div>)
          : (<ArmazenamentoTab
            onSubmit={onSubmit}
            accountsInfo={AccountsInfo}
            isSubmited={isSubmitted}
            accountIdSelected={accountIdSelected}
          />)
      }
    </Modal>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  AccountsInfo: state.obra.AccountsInfo,
  isLoading: state.obra.isLoading,
});

const connector = connect(
  mapState,
);

type Props = ConnectedProps<typeof connector> & IConexaoServico;

export default connector(ConexaoServico);
