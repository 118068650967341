import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { IStorePlanResponse } from 'Data/interfaces/Store/IStorePlanResponse';
import { ITenantAdm } from 'Data/interfaces/Tenant/ITenantAdm';
import { IUserStoreItemRequest } from 'Data/interfaces/Store/IUserStoreItemRequest';
import { IStorePlanDeleteRequest, IStorePlanRequest } from 'Data/interfaces/Store/IStorePlanRequest';
import { IUserStoreItem } from 'Data/interfaces/Store/IUserStoreItem';
import { IPurchaseHistoriesRequest } from 'Data/interfaces/Store/IPurchaseHistoriesRequest';
import { IPurchaseHistories } from 'Data/interfaces/Store/IPurchaseHistories';
import { IBillingDataResponse } from 'Data/interfaces/Auth/IBillingData';
import { ITenantConfirmUpdatePlanRequest } from 'Data/interfaces/Tenant/ITenantConfirmUpdatePlanRequest';
import { ITenantUsersResponse } from 'Data/interfaces/Tenant/ITenantUsersResponse';
import { IUpdateTenantAdminRequest } from 'Data/interfaces/Tenant/IUpdateTenantAdminRequest';
import { IMultiDomainPersonalizationResponse } from 'Data/interfaces/MultiDomain/IMultiDomainPersonalizationResponse';

export enum AdmActionKeys {
  LIST_PLANS_REQUEST = 'LIST_PLANS_REQUEST',
  LIST_PLANS_SUCCESS = 'LIST_PLANS_SUCCESS',
  LIST_PLANS_FAILURE = 'LIST_PLANS_FAILURE',
  LIST_PLANS_UPDATE = 'LIST_PLANS_UPDATE',
  
  GET_PLAN_REQUEST = 'GET_PLAN_REQUEST',
  GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS',
  GET_PLAN_FAILURE = 'GET_PLAN_FAILURE',
  
  CREATE_OR_UPDATE_PLAN_REQUEST = 'CREATE_OR_UPDATE_PLAN_REQUEST',
  CREATE_OR_UPDATE_PLAN_SUCCESS = 'CREATE_OR_UPDATE_PLAN_SUCCESS',
  CREATE_OR_UPDATE_PLAN_FAILURE = 'CREATE_OR_UPDATE_PLAN_FAILURE',
  
  DELETE_PLAN_REQUEST = 'DELETE_PLAN_REQUEST',
  DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS',
  DELETE_PLAN_FAILURE = 'DELETE_PLAN_FAILURE',
  
  LIST_TENANTS_REQUEST = 'LIST_TENANTS_REQUEST',
  LIST_TENANTS_SUCCESS = 'LIST_TENANTS_SUCCESS',
  LIST_TENANTS_FAILURE = 'LIST_TENANTS_FAILURE',
  LIST_TENANTS_UPDATE = 'LIST_TENANTS_UPDATE',
  
  UPDATE_TENANT_REQUEST = 'UPDATE_TENANT_REQUEST',
  UPDATE_TENANT_SUCCESS = 'UPDATE_TENANT_SUCCESS',
  UPDATE_TENANT_FAILURE = 'UPDATE_TENANT_FAILURE',
  
  CREATE_OR_UPDATE_TENANT_PAYMENT_REQUEST = 'CREATE_OR_UPDATE_TENANT_PAYMENT_REQUEST',
  CREATE_OR_UPDATE_TENANT_PAYMENT_SUCCESS = 'CREATE_OR_UPDATE_TENANT_PAYMENT_SUCCESS',
  CREATE_OR_UPDATE_TENANT_PAYMENT_FAILURE = 'CREATE_OR_UPDATE_TENANT_PAYMENT_FAILURE',

  GET_BILLING_DATA_REQUEST = 'GET_BILLING_DATA_REQUEST',
  GET_BILLING_DATA_SUCCESS = 'GET_BILLING_DATA_SUCCESS',
  GET_BILLING_DATA_FAILURE = 'GET_BILLING_DATA_FAILURE',

  CONFIRM_UPDATE_PLAN_REQUEST = 'CONFIRM_UPDATE_PLAN_REQUEST',
  CONFIRM_UPDATE_PLAN_SUCCESS = 'CONFIRM_UPDATE_PLAN_SUCCESS',
  CONFIRM_UPDATE_PLAN_FAILURE = 'CONFIRM_UPDATE_PLAN_FAILURE',

  LIST_TENANT_USERS_REQUEST = 'LIST_TENANT_USERS_REQUEST',
  LIST_TENANT_USERS_SUCCESS = 'LIST_TENANT_USERS_SUCCESS',
  LIST_TENANT_USERS_FAILURE = 'LIST_TENANT_USERS_FAILURE',

  UPDATE_TENANT_ADMIN_REQUEST = 'UPDATE_TENANT_ADMIN_REQUEST',
  UPDATE_TENANT_ADMIN_SUCCESS = 'UPDATE_TENANT_ADMIN_SUCCESS',
  UPDATE_TENANT_ADMIN_FAILURE = 'UPDATE_TENANT_ADMIN_FAILURE',

  TENANT_PERSONALIZATION_ADM_REQUEST = 'TENANT_PERSONALIZATION_ADM_REQUEST',
  TENANT_PERSONALIZATION_ADM_SUCCESS = 'TENANT_PERSONALIZATION_ADM_SUCCESS',
  TENANT_PERSONALIZATION_ADM_FAILURE = 'TENANT_PERSONALIZATION_ADM_FAILURE',
}

export const AdmActions = {
  listPlans: (): ListPlansRequestAction =>  
    createAction(AdmActionKeys.LIST_PLANS_REQUEST),
  listPlansSuccess: (planos: IStorePlanResponse[]): ListPlansSuccessAction => 
    createAction(AdmActionKeys.LIST_PLANS_SUCCESS, planos),
  listPlansFailure: (err: string): ListPlansFailureAction => 
    createAction(AdmActionKeys.LIST_PLANS_FAILURE, err),
  listPlansUpdate: (planos: IStorePlanResponse[]): ListPlansUpdateAction => 
    createAction(AdmActionKeys.LIST_PLANS_UPDATE, planos),
    
  getPlan: (planId: number): GetPlanRequestAction =>  
    createAction(AdmActionKeys.GET_PLAN_REQUEST, planId),
  getPlanSuccess: (plano: IStorePlanResponse): GetPlanSuccessAction => 
    createAction(AdmActionKeys.GET_PLAN_SUCCESS, plano),
  getPlanFailure: (err: string): GetPlanFailureAction => 
    createAction(AdmActionKeys.GET_PLAN_FAILURE, err),

  createOrUpdatePlan: (plano: IStorePlanRequest): CreateOrUpdatePlansRequestAction => 
    createAction(AdmActionKeys.CREATE_OR_UPDATE_PLAN_REQUEST, plano),
  createOrUpdatePlanSuccess: (plano: IStorePlanResponse): CreateOrUpdatePlansSuccessAction => 
    createAction(AdmActionKeys.CREATE_OR_UPDATE_PLAN_SUCCESS, plano),
  createOrUpdatePlanFailure: (err: string): CreateOrUpdatePlansFailureAction => 
    createAction(AdmActionKeys.CREATE_OR_UPDATE_PLAN_FAILURE, err),

  deletePlan: (planId: IStorePlanDeleteRequest): DeletePlanRequestAction =>  
    createAction(AdmActionKeys.DELETE_PLAN_REQUEST, planId),
  deletePlanSuccess: (planId: IStorePlanDeleteRequest): DeletePlanSuccessAction => 
    createAction(AdmActionKeys.DELETE_PLAN_SUCCESS, planId),
  deletePlanFailure: (err: string): DeletePlanFailureAction => 
    createAction(AdmActionKeys.DELETE_PLAN_FAILURE, err),
  
  listTenants: (): ListTenantsRequestAction =>  
    createAction(AdmActionKeys.LIST_TENANTS_REQUEST),
  listTenantsSuccess: (tenants: ITenantAdm[]): ListTenantsSuccessAction => 
    createAction(AdmActionKeys.LIST_TENANTS_SUCCESS, tenants),
  listTenantsFailure: (err: string): ListTenantsFailureAction => 
    createAction(AdmActionKeys.LIST_TENANTS_FAILURE, err),
  listTenantsUpdate: (tenants: ITenantAdm[]): ListTenantsUpdateAction => 
    createAction(AdmActionKeys.LIST_TENANTS_UPDATE, tenants),

  updateTenant: (tenant: IUserStoreItemRequest): UpdateTenantsRequestAction => 
    createAction(AdmActionKeys.UPDATE_TENANT_REQUEST, tenant),
  updateTenantSuccess: (tenant: IUserStoreItem): UpdateTenantsSuccessAction => 
    createAction(AdmActionKeys.UPDATE_TENANT_SUCCESS, tenant),
  updateTenantFailure: (err: string): UpdateTenantsFailureAction => 
    createAction(AdmActionKeys.UPDATE_TENANT_FAILURE, err),
  
  createOrUpdateTenantPayment: (tenantPayment: IPurchaseHistoriesRequest): CreateOrUpdateTenantPaymentRequestAction => 
    createAction(AdmActionKeys.CREATE_OR_UPDATE_TENANT_PAYMENT_REQUEST, tenantPayment),
  createOrUpdateTenantPaymentSuccess: (tenantPayment: IPurchaseHistories): CreateOrUpdateTenantPaymentSuccessAction => 
    createAction(AdmActionKeys.CREATE_OR_UPDATE_TENANT_PAYMENT_SUCCESS, tenantPayment),
  createOrUpdateTenantPaymentFailure: (err: string): CreateOrUpdateTenantPaymentFailureAction => 
    createAction(AdmActionKeys.CREATE_OR_UPDATE_TENANT_PAYMENT_FAILURE, err),

  getBillingData: (userAdminId: string): GetBillingDataRequestAction =>  
    createAction(AdmActionKeys.GET_BILLING_DATA_REQUEST, userAdminId),
  getBillingDataSuccess: (billingData: IBillingDataResponse): GetBillingDataSuccessAction => 
    createAction(AdmActionKeys.GET_BILLING_DATA_SUCCESS, billingData),
  getBillingDataFailure: (err: string): GetBillingDataFailureAction => 
    createAction(AdmActionKeys.GET_BILLING_DATA_FAILURE, err),

  confirmUpdatePlan: (request: ITenantConfirmUpdatePlanRequest): ConfirmUpdatePlanRequestAction =>  
    createAction(AdmActionKeys.CONFIRM_UPDATE_PLAN_REQUEST, request),
  confirmUpdatePlanSuccess: (response: IUserStoreItem): ConfirmUpdatePlanSuccessAction => 
    createAction(AdmActionKeys.CONFIRM_UPDATE_PLAN_SUCCESS, response),
  confirmUpdatePlanFailure: (err: string): ConfirmUpdatePlanFailureAction => 
    createAction(AdmActionKeys.CONFIRM_UPDATE_PLAN_FAILURE, err),

  listTenantUsers: (id: number): ListTenantUsersRequestAction =>  
    createAction(AdmActionKeys.LIST_TENANT_USERS_REQUEST, id),
  listTenantUsersSuccess: (tenants: ITenantUsersResponse[]): ListTenantUsersSuccessAction => 
    createAction(AdmActionKeys.LIST_TENANT_USERS_SUCCESS, tenants),
  listTenantUsersFailure: (err: string): ListTenantUsersFailureAction => 
    createAction(AdmActionKeys.LIST_TENANT_USERS_FAILURE, err),

  updateTenantAdmin: (request: IUpdateTenantAdminRequest): UpdateTenantAdminRequestAction =>  
    createAction(AdmActionKeys.UPDATE_TENANT_ADMIN_REQUEST, request),
  updateTenantAdminSuccess: (response: boolean): UpdateTenantAdminSuccessAction => 
    createAction(AdmActionKeys.UPDATE_TENANT_ADMIN_SUCCESS, response),
  updateTenantAdminFailure: (err: string): UpdateTenantAdminFailureAction => 
    createAction(AdmActionKeys.UPDATE_TENANT_ADMIN_FAILURE, err),
  
  getTenantPersonalizationAdm: (request: number): GetTenantPersonalizationAdmAction =>  
    createAction(AdmActionKeys.TENANT_PERSONALIZATION_ADM_REQUEST, request),
  getTenantPersonalizationAdmSuccess: (response: IMultiDomainPersonalizationResponse): GetTenantPersonalizationAdmSuccessAction =>
    createAction(AdmActionKeys.TENANT_PERSONALIZATION_ADM_SUCCESS, response),
  getTenantPersonalizationAdmFailure: (err: string): GetTenantPersonalizationAdmFailureAction =>
    createAction(AdmActionKeys.TENANT_PERSONALIZATION_ADM_FAILURE, err),
}

export type AdmActionUnion = ActionsUnion<typeof AdmActions>;

export type ListPlansRequestAction = Action<AdmActionKeys.LIST_PLANS_REQUEST>
export type ListPlansSuccessAction = Action<AdmActionKeys.LIST_PLANS_SUCCESS, IStorePlanResponse[]>
export type ListPlansFailureAction = Action<AdmActionKeys.LIST_PLANS_FAILURE, string>
export type ListPlansUpdateAction = Action<AdmActionKeys.LIST_PLANS_UPDATE, IStorePlanResponse[]>

export type GetPlanRequestAction = Action<AdmActionKeys.GET_PLAN_REQUEST, number>
export type GetPlanSuccessAction = Action<AdmActionKeys.GET_PLAN_SUCCESS, IStorePlanResponse>
export type GetPlanFailureAction = Action<AdmActionKeys.GET_PLAN_FAILURE, string>

export type CreateOrUpdatePlansRequestAction = Action<AdmActionKeys.CREATE_OR_UPDATE_PLAN_REQUEST, IStorePlanRequest>
export type CreateOrUpdatePlansSuccessAction = Action<AdmActionKeys.CREATE_OR_UPDATE_PLAN_SUCCESS, IStorePlanResponse>
export type CreateOrUpdatePlansFailureAction = Action<AdmActionKeys.CREATE_OR_UPDATE_PLAN_FAILURE, string>

export type DeletePlanRequestAction = Action<AdmActionKeys.DELETE_PLAN_REQUEST, IStorePlanDeleteRequest>
export type DeletePlanSuccessAction = Action<AdmActionKeys.DELETE_PLAN_SUCCESS, IStorePlanDeleteRequest>
export type DeletePlanFailureAction = Action<AdmActionKeys.DELETE_PLAN_FAILURE, string>

export type ListTenantsRequestAction = Action<AdmActionKeys.LIST_TENANTS_REQUEST>
export type ListTenantsSuccessAction = Action<AdmActionKeys.LIST_TENANTS_SUCCESS, ITenantAdm[]>
export type ListTenantsFailureAction = Action<AdmActionKeys.LIST_TENANTS_FAILURE, string>
export type ListTenantsUpdateAction = Action<AdmActionKeys.LIST_TENANTS_UPDATE, ITenantAdm[]>

export type UpdateTenantsRequestAction = Action<AdmActionKeys.UPDATE_TENANT_REQUEST, IUserStoreItemRequest>
export type UpdateTenantsSuccessAction = Action<AdmActionKeys.UPDATE_TENANT_SUCCESS, IUserStoreItem>
export type UpdateTenantsFailureAction = Action<AdmActionKeys.UPDATE_TENANT_FAILURE, string>

export type CreateOrUpdateTenantPaymentRequestAction = Action<AdmActionKeys.CREATE_OR_UPDATE_TENANT_PAYMENT_REQUEST, IPurchaseHistoriesRequest>
export type CreateOrUpdateTenantPaymentSuccessAction = Action<AdmActionKeys.CREATE_OR_UPDATE_TENANT_PAYMENT_SUCCESS, IPurchaseHistories>
export type CreateOrUpdateTenantPaymentFailureAction = Action<AdmActionKeys.CREATE_OR_UPDATE_TENANT_PAYMENT_FAILURE, string>

export type GetBillingDataRequestAction = Action<AdmActionKeys.GET_BILLING_DATA_REQUEST, string>
export type GetBillingDataSuccessAction = Action<AdmActionKeys.GET_BILLING_DATA_SUCCESS, IBillingDataResponse>
export type GetBillingDataFailureAction = Action<AdmActionKeys.GET_BILLING_DATA_FAILURE, string>

export type ConfirmUpdatePlanRequestAction = Action<AdmActionKeys.CONFIRM_UPDATE_PLAN_REQUEST, ITenantConfirmUpdatePlanRequest>
export type ConfirmUpdatePlanSuccessAction = Action<AdmActionKeys.CONFIRM_UPDATE_PLAN_SUCCESS, IUserStoreItem>
export type ConfirmUpdatePlanFailureAction = Action<AdmActionKeys.CONFIRM_UPDATE_PLAN_FAILURE, string>

export type ListTenantUsersRequestAction = Action<AdmActionKeys.LIST_TENANT_USERS_REQUEST, number>
export type ListTenantUsersSuccessAction = Action<AdmActionKeys.LIST_TENANT_USERS_SUCCESS, ITenantUsersResponse[]>
export type ListTenantUsersFailureAction = Action<AdmActionKeys.LIST_TENANT_USERS_FAILURE, string>

export type UpdateTenantAdminRequestAction = Action<AdmActionKeys.UPDATE_TENANT_ADMIN_REQUEST, IUpdateTenantAdminRequest>
export type UpdateTenantAdminSuccessAction = Action<AdmActionKeys.UPDATE_TENANT_ADMIN_SUCCESS, boolean>
export type UpdateTenantAdminFailureAction = Action<AdmActionKeys.UPDATE_TENANT_ADMIN_FAILURE, string>

export type GetTenantPersonalizationAdmAction = 
  Action<AdmActionKeys.TENANT_PERSONALIZATION_ADM_REQUEST, number>;
export type GetTenantPersonalizationAdmSuccessAction = 
  Action<AdmActionKeys.TENANT_PERSONALIZATION_ADM_SUCCESS, IMultiDomainPersonalizationResponse>;
export type GetTenantPersonalizationAdmFailureAction = 
  Action<AdmActionKeys.TENANT_PERSONALIZATION_ADM_FAILURE, string>;
