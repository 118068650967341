import { FC } from 'react';
import NomenclaturaForm from '../../../../../Components/UI/NomenclaturaForm';
import NewNomenclaturaForm from '../../../../../Components/UI/NewNomenclaturaForm';
import { NomenclatureContainer } from 'Components/UI/NewNomenclaturaForm/styles';
import { INomenclatureData } from 'Data/interfaces/Nomenclatura/INomenclatureData';

export interface INomenclaturaTab {
  isSubmited?: boolean;
  isNamingPatternEnabled?: boolean;
  isFirstObra?: boolean;
  obraVersionData?: INomenclatureData;
  versionConfigFk?: number;
  onSubmit: (props?: object, erros?: string[]) => void;
}

const NomenclaturaTab: FC<INomenclaturaTab> = (props) => {
  return (
    <NomenclatureContainer>
      {props.isNamingPatternEnabled && (
        <NewNomenclaturaForm
          isSubmited={props.isSubmited}
          isTabVersion={true}
          isFirstObra={props.isFirstObra}
          obraVersionData={props.obraVersionData}
          versionConfigFk={props.versionConfigFk}
          onSubmit={props.onSubmit}
        />
      )}
      {!props.isNamingPatternEnabled && (
        <NomenclaturaForm
          isSubmited={props.isSubmited}
          onSubmit={props.onSubmit}
          isTabVersion={true}
        />
      )}
    </NomenclatureContainer>
  );
};

export default NomenclaturaTab;
