import { Dispatch } from 'react';
import { PlotagemActions, PlotagemActionUnion } from '../../../Store/Plotagem/Plotagem.actions';
import { IDiscipline, IDisciplineResponse } from '../../../Data/interfaces/Plotagem/IPlotagemData';
import { prop } from '../../../Utils/toolBox';
import { IBreadcrumbItem } from '../../../Components/UI/BreadCrumb';
import { ColumnProps } from 'antd/lib/table';
import { history } from '../../../Store';
import { Icon } from '../../../Components/UI';
import { disciplineIcons } from '../../../Utils/Icons';
import ColumnHeader from '../../../Components/UI/Table/ColumnHeader';
import { IBreadCrumb } from '../../../Data/interfaces/Plotagem/IBreadCrumb';
import { DisciplineItem } from './styles';
import ErrorSAConnection from '../Modals/ErrorSAConnection';
import TagIcon from 'Components/UI/TagIcon';
import { ApisEnum } from 'Data/enums/Apis';
import { DisciplineFolderStatusEnum } from 'Data/interfaces/Obra/IDisciplineResponse';

export interface IPlotagemDisciplinas {
  match: any
}

export const breadCrumbs: IBreadcrumbItem[] = [];

export const sortPlotagemDisciplinesList = (
    dataIndex: any,
    sortOrder: boolean,
    fileList: IDiscipline[],
    breadCrumPlotagem: IBreadCrumb,
    dispatch: Dispatch<PlotagemActionUnion>,
  ) => {
  const sortedFiles = [...fileList?.sort((a: IDiscipline, b: IDiscipline) => {
    const propA = prop(a, dataIndex) || '';
    const propB = prop(b, dataIndex) || '';

    if (typeof propA === 'number' && typeof propB === 'number') {
      return sortOrder ? propB - propA : propA - propB;
    }

    return propA.toString().localeCompare(propB.toString()) * (sortOrder ? -1 : 1);
  }) || []];

  const dataSortedFiles : IDisciplineResponse = {
    data: sortedFiles,
    info: breadCrumPlotagem,
  }

  dispatch(PlotagemActions.updatePlotagemDisciplinesList(dataSortedFiles));
};


export const columnsTable = (
  disciplinasPlotagem: IDiscipline[], 
  breadCrumPlotagem: IBreadCrumb, 
  constructionSiteId: number, 
  dispatch: Dispatch<any>,
  obraApi: ApisEnum,
  onShowErrorSAConnectionInfo: () => void,
): Array<ColumnProps<any>> => {
  return [
    {
      title: (<ColumnHeader 
        title={'Nome'}
        sorter={(sortDirection: boolean) => sortPlotagemDisciplinesList('Name', sortDirection, disciplinasPlotagem, breadCrumPlotagem, dispatch)}
      />),
      dataIndex: 'Name',
      render: (text, record) => {
        const errorConnection = record.DisciplineFolderStatus === DisciplineFolderStatusEnum.NotFound;
        
        return (
          <DisciplineItem
            error={errorConnection}
            onClick={() =>
              errorConnection 
                ? onShowErrorSAConnectionInfo() 
                : history.push(`/obras/plotagens/filelist/${constructionSiteId}/${record.ConstructionSiteDisciplineFk}?folderName=${text}`)
            }>
            <ErrorSAConnection platform={obraApi}>
              <Icon
                error={errorConnection}
                color="cinzaPadrao"
                icon={disciplineIcons.get(record.Icon) || 'menuContexto'}
                customSize={20}
              />
              <span className="disciplineItemText">
                {text}
              </span>
              {errorConnection ? (
                <TagIcon
                  text="erro de conexão"
                  icon={<Icon icon="aviso" color="branco" customSize={12} />} 
                  error={errorConnection} 
                  onClick={onShowErrorSAConnectionInfo}
                />
              ) : null}
            </ErrorSAConnection>
          </DisciplineItem>
        )
      },
    }
  ]
};
