import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';
import toastHandler from 'Utils/toastHandler';
import { ICreateOrUpdate } from 'Data/interfaces/Obra/ICreateOrUpdate';
import { ObraActions } from 'Store/Obra/Obra.actions';
import { getUserInfo } from "Store/Auth/Auth.selector";
import { IArchivedStatusRequest } from "Data/interfaces/Obra/IArchivedStatusRequest";
import { getObra } from "Store/Obra/Obra.selector";

interface IUseSubmitEdit {
  csId: number;
}

const useSubmitEdit = ({ csId }: IUseSubmitEdit) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);
  const userInfo = useSelector(getUserInfo);
  const obra = useSelector(getObra);

  const [isSubmited, setIsSubmited] = useState(false);
  const [sentRequest, setSentRequest] = useState(false);

  const submit = (values?: any, errors?: string[]) => {
    setIsSubmited(false);
    console.log('useSubmitEdit -> ', { values, errors });

    if (errors) {
      errors.forEach((error) => {
        toastHandler.handler({ description: error, icon: 'error', title: '' });
      });
      return false;
    } else {
      if (values) {
        const createOrUpdate: ICreateOrUpdate = {
          valuesChunck: values,
          shouldSendData: true,
          userInfoTracking: userInfo,
          currentTenantTracking: currentTenant,
        };
        dispatch(ObraActions.createOrUpdate(createOrUpdate));
        setSentRequest(true);
      }
    }
    return true;
  };

  const arquivarObra = () => {
    const status: IArchivedStatusRequest = {
      ConstructionSiteId: csId,
      isArchived: true,
    };
    dispatch(ObraActions.updateArchivedStatus(status));
  };

  useEffect(() => {
    if (obra.SharedUsers) {
      obra.SharedUsers.forEach((sharedUser) => sharedUser.UserId = sharedUser.UserFk);
    }
  }, [obra.SharedUsers]);

  return {
    isSubmited,
    sentRequest,
    setIsSubmited,
    setSentRequest,
    submit,
    arquivarObra,
  }
}

export default useSubmitEdit;
