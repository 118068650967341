import { FC } from "react";
import { Tag } from "antd";
import { Icon } from "Components/UI";
import Button from "./../../../../Components/UI/Button";
import Modal from "./../../../../Components/UI/Modal/Modal";
import styles from "./DownloadBloqueadosModal.module.scss";

interface IDownloadBloqueadosModal {
  visible: boolean;
  onConfirm: () => void;
  onClose: () => void;
  hasPermission: boolean;
  isBlocked: boolean;
}

const DownloadBloqueadosModal: FC<IDownloadBloqueadosModal> = ({
  visible,
  onConfirm,
  onClose,
  hasPermission,
  isBlocked,
}) => {

  const buttons = (
    <div className={styles['footer-buttons']}>
      <Button
        onClick={onClose}
        className={styles['cancel-button']}
      >
        Cancelar
      </Button>
      <Button
        type="primary"
        onClick={onConfirm}
        className={styles['confirm-button']}
      >
        Fazer o download
      </Button>
    </div>
  );

  if (hasPermission) {
    return (
      <Modal
        title="Atenção"
        width={487}
        visible={visible}
        onCancel={onClose}
        footerButtons={buttons}
      >
        <div className={styles['main-wrapper']}>
          <p>
            O arquivo que você está tentando baixar está bloqueado! Isso significa que ele pode não estar finalizado e liberado para uso.
          </p>
          <p>
            Você deseja continuar com o download?
          </p>
        </div>
      </Modal>
    )
  }
  
  return (
    <div
      className={styles[isBlocked ? 'ModalBlocked' : 'Modal']}
    >
      {isBlocked ? (
        <div className={styles['contentBlockedWrapper']}>
          <Tag color="#8697A8" className={styles['tag']}>
            <Icon icon="cadeadoFechado" color="branco" customSize={9} />
            Arquivo bloqueado
          </Tag>
          <h3 className={styles['titleBlockedContent']}>
            O arquivo está bloqueado!
          </h3>
          <p>
            O download foi impedido pois o arquivo que você selecionou está bloqueado! Isso significa que ele pode não estar finalizado e/ou liberado para uso.
          </p>
          <p>
            Caso precise do arquivo, entre em contato com o responsável para liberá-lo.
          </p>
          <button 
            className={styles['close']}
            onClick={onClose}
          >
            Fechar
          </button>
        </div>
      ) : (
        <div className={styles['contentWrapper']}>
          <h3 className={styles['titleContent']}>
            Download múltiplo de arquivos indisponível.
          </h3>
          <span className={styles['textContent']}>
            O download múltiplo de arquivos está indisponível. Selecione apenas um arquivo para fazer o download direto ou entre em contato com um coordenador.
          </span>
          <button 
            className={styles['close']}
            onClick={onClose}
          >
            Fechar
          </button>
        </div>
      )}
    </div>
  )
}

export default DownloadBloqueadosModal;
