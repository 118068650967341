import React, { FC } from 'react';

import styles from './Sobre.module.scss';
import PageHeader from '../../Components/UI/PageHeader/PageHeader';

import BreadCrumb, { IBreadcrumbItem } from '../../Components/UI/BreadCrumb';

const breadCrumb: IBreadcrumbItem[] = [
  {
    description: 'Sobre',
    href: '/sobre'
  }
];

const Sobre: FC = () => {
  return (
    <div className={styles['aboutWrapper']}>
      <PageHeader name="Sobre"> <div className={styles['breadCrumbAbout']}>
        <BreadCrumb breadcrumbs={breadCrumb} />
      </div>
        <div className={styles['pageAboutContainer']}>
          <p>Versão 5.0</p>
          <ul>
            <li>Controle as Atividades do seu projeto, escolha quais serão os responsáveis por essas atividades atribuindo parâmetros específicos</li>
            <li>Coordene os projetos tudo em um só lugar com o controle em kanban das atividades do projeto</li>
            <li>Identificação de falha de conexão do uma Disciplina: estamos melhorando a sua experiência de integração com o seu Serviço de Armazenamento de confiança. Sempre que uma Disciplina for excluída diretamente pelo Serviço de Armazenamento, o Administrador terá a possibilidade de definir a destinação dos dados vinculados à disciplina. Garantindo a persistência dos dados.</li>
            <li>Rastreabilidade com QrCodes: chegou para o nosso aplicativo Mobile uma função de verificação da versão dos documentos. Para isso, é possível incluir um Qrcode impresso nas pranchas da Solicitação de plotagem.</li>
            <li>Correção de erros e melhorias</li>
          </ul>

          <p>Versão 4.0</p>
          <ul>
            <li>Nessa nova versão, a plataforma conta com uma forma diferente de acesso a cada Obra/Projeto. Reorganizamos e criamos as "Abas" de Arquivos e Plotagens para facilitar a contextualização das informações por Obra/Projeto</li>
            <li>Criamos também uma navegação em lista para as Obras no menu lateral</li>
            <li>Reformulamos o botão para troca de ambiente de trabalho de diferentes empresas para um formato mais intuitivo</li>
            <li>A grande novidade dessa versão é a aba "Viewer" onde é possível construir um modelo BIM federado da sua Obra</li>
            <li>A visualização de modelos BIM também ganha novos recursos como:</li>
            <ul>
              <li>Visualização de viewpoints de modelos do Autodesk Navisworks®</li>
              <li>Visualização de pranchas de múltiplos modelos de diferentes formatos</li>
              <li>Corte do modelo por pavimentos</li>
            </ul>
            <li>Em breve contaremos também com o controle de Atividades de Projeto! Aguardem!</li>
          </ul>

          <p>Versão 3.5</p>
          <ul>
            <li>Página de Faturamento com os dados do seu plano, juntamente com o canal de comunicação com nosso time de atendimento</li>
            <li>Controle dos atributos existentes no seu plano como número de Obras, Usuários e Renderização de Arquivos</li>
            <li>Melhoria de performance na listagem das suas Disciplinas, Arquivos e Pastas através de um versionamento mais eficiente</li>
            <li>Ordenação de Disciplinas, Arquivos e Pastas agora ficam gravados na sua sessão de acesso</li>
            <li>Campo de busca para Usuários, Documentos e Pastas</li>
            <li>Controle do Status dos usuários</li>
            <li>Correções e melhorias de erros que vocês nos reportam</li>
          </ul>

          <p>Versão 3.4.2</p>
          <ul>
            <li>Correções e melhorias da Central de Notificações e Notificação das Obras</li>
            <li>Melhoria da experiência de acesso simultâneo de um mesmo usuário em diferentes navegadores</li>
            <li>Melhoria do convite de usuários através de links</li>
            <li>Integração da plataforma com o app Mobile</li>
          </ul>

          <p>Versão 3.4</p>
          <ul>
            <li>Melhorias de performance na listagem e navegação de arquivos e pastas</li>
            <li>Melhoria de performance no compartilhamento e convite de usuários</li>
            <li>Redirecionamento de múltiplos downloads e da Pasta Atual para o Serviço de Armazenamento da Obra</li>
            <li>Correções e melhorias nas Notificações</li>
          </ul>

          <p>Versão 3.3</p>
          <ul>
            <li>Central de notificações com Histórico de atividades nas Obras</li>
            <li>Notificações na listagem de Obras</li>
            <li>Envio de e-mail do resumo semanal das atividades</li>
            <li>Integração com o Onedrive</li>
            <li>Correções de erros e falhas</li>
          </ul>

          <p>Versão 3.2.3</p>
          <ul>
            <li>Correção de erros e melhorias de performance de download de arquivos</li>
            <li>Possibilidade de excluir Disciplinas</li>
            <li>Visualização de projeto para arquivos PDF</li>
          </ul>

          <p>Versão 3.2</p>
          <ul>
            <li>
              Uma nova Maleta, um novo Design e muitas melhorias
              <br />
              Veja o vídeo de lançamento
              dessa versão{' '}
              <a href="https://youtu.be/J3yPa_x8VZw" target="_blank">
                clicando aqui
              </a>
            </li>
          </ul>

          <p>Versão 3.1</p>
          <ul>
            <li>Exportação de relatório de plotagens em excel</li>
            <li>Possibilidade de excluir novas plotagens</li>
            <li>
              Melhorias da experiência da conexão entre Obra e Serviço de armazenamento em
              nuvem
            </li>
            <li>Correções no relatório de plotagem</li>
            <li>
              Correção de Bugs e Melhorias diversas porque somos humanos e erramos! :D
            </li>
          </ul>

          <p>Versão 3.0</p>
          <ul>
            <li>Controle de plotagens dos documentos da sua Obra</li>
            <li>
              Solicite e envie um e-mail com os links dos seus arquivos para uma Plotadora
            </li>
            <li>
              Controle o Status dos seus documentos Liberados e Bloqueados para Plotagem
            </li>
            <li>Receba e controle o Status dos Projetos Plotados</li>
            <li>Emita uma Lista Mestra dos Documentos Plotados</li>
            <li>
              Primeiro acesso a Maleta, com um perfil Freemium e possibilidade de fazer um
              Upgrade para o Premium, diretamente na Loja usando seu Cartão de Crédito
              Correção de Bugs e Melhorias diversas, por que Obra organizada, produz mais!
              d: D
            </li>
          </ul>

          <p>Versão 2.2</p>
          <ul>
            <li>Download simultâneo de arquivos</li>
            <li>Melhorias de Experiência do usuário</li>
            <li>Correções de erros em Gerenciar Usuários e Permissões</li>
            <li>Outros ajustes e correções</li>
          </ul>

          <p>Versão 2.1</p>
          <ul>
            <li>Melhorias e correções de erros para sua Maleta da Construtora</li>
          </ul>

          <p>Versão 2.0</p>
          <ul>
            <li>Crie sua Construtora e gerencie suas obras</li>
            <li>
              Adicione participantes (Coordenadores, Engenheiros, Projetistas e
              Consultores) para sua obra
            </li>
            <li>Administre os privilégios de acesso dos seus usuários</li>
            <li>
              Faça Upload para pastas de Disciplinas de Projetos, criadas diretamente no
              seu serviço de armazenamento em nuvem
            </li>
            <li>Colabora entre os participantes de uma obra em tempo real</li>
            <li>
              Membro dos programas:
              <ul>
                <li>Autodesk Developer Network (ADN)</li>
                <li>Dropbox Developer</li>
                <li>Dropbox Partner Network</li>
                <li>Google Cloud Partner Program</li>
                <li>Microsoft for Startups</li>
                <li>Microsoft Partner Network</li>
              </ul>
            </li>
          </ul>
          <div className={styles['footerAbout']}>
            <p>
              Todos os direitos reservados à Coordly, antiga maleta do engenheiro são dados à Maleta do Engenheiro LTDA
            </p>
          </div>
        </div>
      </PageHeader>
    </div>
  );
};

export default Sobre;
