import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HistoryActions } from "Store/History/History.actions";
import {
  getCurrentHistoryFileList,
  getHistoryDrawerVisible,
  getHistoryIsLoading,
} from "Store/History/History.selector";
import { Drawer } from "antd";
import { Icon, IconName } from "Components/UI";
import { getDisciplineIcon } from "Utils/ObraUtils";
import Card from "./components/Contents/FileOptions/Card";
import LoadingHistoryDrawer from "Components/UI/CustomLoading/LoadingHistoryDrawer";
import { iconFileByExtension } from "Utils/Icons";
import { Virtuoso } from "react-virtuoso";
import { Content, GlobalDrawerStyled, Header } from "./styles";

const HistoryDrawer: FC = () => {
  const dispatch = useDispatch();
  const visible = useSelector(getHistoryDrawerVisible);
  const historyFileList = useSelector(getCurrentHistoryFileList);
  const isLoading = useSelector(getHistoryIsLoading);

  const getIcon = (title: string, name: string): IconName => {
    if (title.toLowerCase().includes('disciplina')) {
      return getDisciplineIcon(name);
    }

    if (title.toLowerCase().includes('pasta')) {
      return 'arquivar';
    }

    return iconFileByExtension(name);
  }

  const handleClose = () => {
    dispatch(HistoryActions.showHistoryDrawer(false));
  }

  return (
    <>
      <GlobalDrawerStyled />
      <Drawer
        title={(
          <Header>
            <div className="left">
              <span className="title">{historyFileList?.title}</span>
              <div className="tag">
                <Icon
                  icon={getIcon(
                    historyFileList?.title || '',
                    historyFileList?.parentName || '',
                  )}
                  customSize={getIcon(
                    historyFileList?.title || '',
                    historyFileList?.parentName || '',
                  ) === 'menuContexto' ? 12 : 14
                  }
                  className="tagIcon"
                />
                <span className="tagText">{historyFileList?.parentName}</span>
              </div>
            </div>
            <div className="closeBtn" onClick={handleClose}>
              <Icon
                icon="cancelar"
                customSize={10}
                className="closeIcon"
              />
            </div>
          </Header>
        )}
        closeIcon={null}
        onClose={handleClose}
        open={visible}
        styles={{
          wrapper: {
            width: 450,
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
            overflow: 'hidden',
          },
          header: { padding: 0 },
        }}
      >
        <Content loading={isLoading}>
          {isLoading && (
            <LoadingHistoryDrawer multiple={8} />
          )}
          {!isLoading && historyFileList?.historyContent && (
            <Virtuoso
              style={{ flex: 1 }}
              totalCount={historyFileList?.historyContent.length}
              data={historyFileList?.historyContent}
              initialTopMostItemIndex={historyFileList?.historyContent.length - 1}
              itemContent={(index, history) => (
                <Card key={history.ItemId} item={history} />
              )}
            />
          )}
        </Content>
      </Drawer>
    </>
  );
}

export default HistoryDrawer;
