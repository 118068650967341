import { FC, useState } from 'react';

import styles from './ColumnHeader.module.scss';
import { Icon } from '../../Icon';
import Tooltip from '../../Tooltip';

export interface IColumnHeader {
  title: string;
  sorter?: (sortDirection: boolean) => void;
  showFilter?: () => void;
  informacaoAdicional?: string;
  showOrder?: boolean;
  className?: string;
}

const ColumnHeader: FC<IColumnHeader> = ({
  title,
  sorter,
  showFilter,
  informacaoAdicional,
  showOrder, 
  className,
}) => {
  const [sortDirection, setSortDirection] = useState<boolean>();
  const sort = () => {
    if (sorter) {
      sorter(Boolean(sortDirection));
      setSortDirection(!sortDirection);
    }
  };
  return (
    <div className={`${styles['columnHeaderWrap']} ${className || ''}`}>
      {showFilter ? (
        <Tooltip overlay="Filtrar" placement="bottom">
          <span className={styles['filterWrap']} onClick={showFilter}>
            <Icon
              icon="filtrar"
              color="cinzaIcone"
              customSize={12}
              className={styles['filterIcon']}
            />
            <span className={styles['title']}>{title}</span>
          </span>
        </Tooltip>
      ) : informacaoAdicional ? (
        <>
        <Tooltip overlay={informacaoAdicional} placement="bottom" overlayStyle={{width: 330, wordWrap: 'break-word'}}>
          <span className={styles['infoWrap']}>
            <Icon
              icon="informacaoAdicional"
              color="cinzaIcone"
              customSize={12}
              className={styles['infoIcon']}
            />
          </span>
          
        </Tooltip>
        <span className={styles['title']}>{title}</span>
        </>
      ) : (
        <span className={styles['title']}>{title}</span>
      )}
      {showOrder === false ? 
      <></> 
      :       
      <Tooltip overlay="Ordenar">
        <span className={styles['orderButton']} onClick={sort}>
          <Icon
            icon={sortDirection ? 'setaOrdenarAsc' : 'setaOrdenarDesc'}
            customSize={6}
            customColor="#4F4F4F"
            className={styles['orderIcon']}
          />
        </span>
      </Tooltip>}

    </div>
  );
};

export default ColumnHeader;
