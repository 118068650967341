import { FC, useMemo, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Button from "Components/UI/Button";
import useSubmitEdit from "Pages/EditObra/useSubmitEdit";
import Item from "./components/Item";
import { IUserData } from "Data/interfaces/User/IUserData";
import { ISharedUser } from "Data/interfaces/User/ISharedUser";
import { IInvitedUser } from "Data/interfaces/User/IInvitedUser";
import {
  BtnContainer,
  Container,
  Content,
  Description,
  Divider,
  Main,
  Title,
} from "./styles";

export interface IRole {
  value: number;
  description: string;
  label: string;
}

export interface IUpdatedUsers {
  InvitedUsers: IInvitedUser[];
  SharedUsers: ISharedUser[];
}

interface IPermissions {
  csId: number;
  tenantSharedUsers: IUserData[];
  sharedUsers: ISharedUser[];
  invitedUsers: IInvitedUser[];
  loading?: boolean;
}

const Permissions: FC<IPermissions> = ({
  csId,
  tenantSharedUsers,
  sharedUsers,
  invitedUsers,
  loading,
}) => {
  const {
    sentRequest,
    setSentRequest,
    submit,
  } = useSubmitEdit({ csId });
  console.log({ sharedUsers, tenantSharedUsers })

  const [showEdit, setShowEdit] = useState(true);

  const InvitedUsers = useMemo(() => {
    return invitedUsers || [];
  }, [invitedUsers]);

  const SharedUsers = useMemo(() => {
    let data: ISharedUser[] = [];

    if (sharedUsers && sharedUsers?.length > 0) {
      data = sharedUsers.map(user => ({ ...user, isShared: true }));
    }

    return data;
  }, [sharedUsers]);

  // useEffect(() => {
  //   if (
  //     disciplinesList.filter(d => d.IsChecked).length > (disciplines?.length || 0)
  //   ) {
  //     setShowEdit(true);
  //   } else {
  //     setShowEdit(false);
  //   }
  // }, [disciplinesList, disciplines]);

  // useEffect(() => {
  //   if (sentRequest && !loading) {
  //     setSentRequest(false);
  //     setShowEdit(false);
  //   }
  // }, [sentRequest, loading]);

  return (
    <Container>
      <Title>Permissões</Title>

      <Main>
        <Description>
          Usuários da obra
        </Description>

        <Content>
          {InvitedUsers.length > 0 && (
            InvitedUsers.map(user => (
              <Item
                key={user.Email}
                ID={user.Email}
                username={user.Email}
                privileges={user.Privileges}
                hasPlottingPermission={user.HasPlottingPermission}
                loading={loading}
                onChange={(data) => console.log({ data })}
              />
            ))
          )}
          {SharedUsers.length > 0 && (
            SharedUsers.map(user => (
              <Item
                key={user.UserFk}
                ID={user.UserFk || user.UserId || user.User?.Nome || user.User?.Email || ''}
                username={user.User?.Nome || user.User?.Email || ''}
                userAvatar={user.User?.OriginalImageUrl}
                userAvatarThumb={user.User?.ThumbnailImageUrl}
                userAvatarThumbType="small"
                privileges={user.Privileges}
                hasPlottingPermission={user.HasPlottingPermission}
                loading={loading}
                onChange={(data) => console.log({ data })}
              />
            ))
          )}
        </Content>
      </Main>

      {showEdit && (
        <>
          <Divider />
          <BtnContainer>
            <Button
              type="primary"
              className="saveBtn"
              onClick={loading ? undefined : undefined}
            >
              {loading && (
                <Spin
                  indicator={
                    <LoadingOutlined
                      rev=""
                      color="white"
                      style={{ fontSize: 12, color: 'white', marginRight: 6 }}
                    />
                  }
                />
              )}
              {loading ? 'Salvando...' : 'Salvar alterações'}
            </Button>
            <Button
              type="text"
              className="cancelBtn"
              disabled={loading}
              onClick={() => setShowEdit(false)}
            >
              Cancelar
            </Button>
          </BtnContainer>
        </>
      )}
    </Container>
  )
}

export default Permissions;
