import React, { FC } from "react";

import styles from "./LoadingDisciplinas.module.scss";

import SkeletonMain from "../LoadingMain";

export interface ILoadingDisciplinas {
  multiple?: number;
  plotagem?: boolean;
}

// Comentados irão retornar em novo PBI

const LoadingDisciplinas: FC<ILoadingDisciplinas> = ({ 
  multiple = 1,
  plotagem,
}) => {
  const skeletonObra = {
    render: (index: number) => {
      return (
        <div key={index.toString()} className={styles['main']}>
            <div className={styles['header']}>
              <SkeletonMain width="12px" height="12px" borderRadius="2px" marginLeft="34px" />
              <SkeletonMain width="24px" height="24px" borderRadius="4px" marginLeft="38px" />
              <SkeletonMain width="334px" height="12px" borderRadius="2px" marginLeft="18px" />
            </div>
            {/* {!plotagem && <div className={styles['footer']}>
              <SkeletonMain width="95px" height="12px" borderRadius="2px" />
            </div>} */}
        </div>
      )
    }
  }

  const multipleSkeletonsObras = [];
  for (let i = 0; i < multiple; i++) {
    multipleSkeletonsObras.push(skeletonObra);
  }

  return (
    <>
      <div className={styles['header-table']}>
        <div className={styles['first']}>
          <span><input className={styles['checkbox']} type="checkbox" disabled /></span>
          <span>NOME</span>
        </div>
        {/* {!plotagem && <div className={styles['last']}>
          <span>ÚLTIMA ATUALIZAÇÃO</span>
        </div>} */}
      </div>
      {multipleSkeletonsObras.map((skeleton, index) => skeleton.render(index))}
    </>
  )
}

export default LoadingDisciplinas;