export interface INomenclatureData {
  IsTabSubmit?: boolean;
  HasRevisionControl: boolean;
  VersionFields: IVersionField[];
}

export interface IVersionField {
  Ordem: number;
  CampoVersaoId: VersionFieldEnum;
  Separador: SeparatorEnum;
  CampoPersonalizado?: string;
}

export enum SeparatorEnum {
  Underline,
  Ponto,
  Traco,
}
export interface ISeparator {
  SeparatorId: number;
  Description: string;
  Value: string;
}
export const SeparatorsData = new Map<SeparatorEnum, ISeparator>([
  [SeparatorEnum.Underline, {SeparatorId: SeparatorEnum.Underline, Description: 'Underline (_)', Value: '_'}],
  [SeparatorEnum.Ponto, {SeparatorId: SeparatorEnum.Ponto, Description: 'Ponto (.)', Value: '.'}],
  [SeparatorEnum.Traco, {SeparatorId: SeparatorEnum.Traco, Description: 'Traço (-)', Value: '-'}],
]);

export enum VersionFieldEnum {
  SelecioneUmCampo = 1,
  Revisao = 4,
  Prancha = 5,
  Cliente = 6,
  Obra = 7,
  Projeto = 8,
  Disciplina = 9,
  Fase = 10,
  Objeto = 11,
  Elemento = 12,
  Assunto = 13,
  Tipologia = 16,
  PlanoDeProjecao = 17,
  Localizacao = 18,
  Setor = 19,
  Blocos = 20,
  FasesDeConstrucao = 21,
  DefinicoesDoUsuario = 22,
  CampoPersonalizado = 29,
}

export const VersionFieldEnumLabel = new Map<VersionFieldEnum, string>([
  [VersionFieldEnum.SelecioneUmCampo ,'Selecione um campo'],
  [VersionFieldEnum.Revisao ,'Revisão'],
  [VersionFieldEnum.Prancha ,'Prancha'],
  [VersionFieldEnum.Cliente ,'Cliente'],
  [VersionFieldEnum.Obra ,'Obra'],
  [VersionFieldEnum.Projeto ,'Projeto'],
  [VersionFieldEnum.Disciplina ,'Disciplina'],
  [VersionFieldEnum.Fase ,'Fase'],
  [VersionFieldEnum.Objeto ,'Objeto'],
  [VersionFieldEnum.Elemento ,'Elemento'],
  [VersionFieldEnum.Assunto ,'Assunto'],
  [VersionFieldEnum.Tipologia ,'Tipologia'],
  [VersionFieldEnum.PlanoDeProjecao ,'Plano de Projeção'],
  [VersionFieldEnum.Localizacao ,'Localização'],
  [VersionFieldEnum.Setor ,'Setor'],
  [VersionFieldEnum.Blocos ,'Blocos'],
  [VersionFieldEnum.FasesDeConstrucao ,'Fases de Construção'],
  [VersionFieldEnum.DefinicoesDoUsuario ,'Definições do usuário'],
  [VersionFieldEnum.CampoPersonalizado ,'Campo personalizado'],
]);

export interface ICampoVersao {
  CampoVersaoId: number;
  NomeCampo: string;
  Role: number;
}

export interface IExtensionData {
  Extension: string;
  FileVisualizationTypeEnum: FileVisualizationTypeEnum;
  Versionable: boolean;
}

export interface IBindSuportedFileExtensions {
  Extension: string;
} 

export enum FileVisualizationTypeEnum {
  Tipo3D = 0,
  Tipo2D = 1,
  Imagem = 2,
  Office = 3,
  Texto = 4,
  Indefined = 5
}

export const FileVisualizationTypeEnumLabel = new Map<FileVisualizationTypeEnum, string>([
  [FileVisualizationTypeEnum.Tipo3D, 'FORMATOS 3D'],
  [FileVisualizationTypeEnum.Tipo2D, 'FORMATOS 2D'],
  [FileVisualizationTypeEnum.Imagem, 'IMAGEM'],
  [FileVisualizationTypeEnum.Office, 'MICROSOFT OFFICE'],
  [FileVisualizationTypeEnum.Texto, 'TEXTO SIMPLES'],
  [FileVisualizationTypeEnum.Indefined, 'Indefined']
]);