import { call, put } from "redux-saga/effects";
import { AdmApi } from "Data/Adm.api";
import { AdmActions, CreateOrUpdatePlansRequestAction, DeletePlanRequestAction, GetPlanRequestAction, CreateOrUpdateTenantPaymentRequestAction, UpdateTenantsRequestAction, GetBillingDataRequestAction, ConfirmUpdatePlanRequestAction, ListTenantUsersRequestAction, UpdateTenantAdminRequestAction, GetTenantPersonalizationAdmAction } from "./Adm.actions";
import toastHandler from "../../Utils/toastHandler";
import { aproveError, aproveSuccess, createError, createSuccess, loadError, reproveError, reproveSuccess, updateError, updateSuccess } from "Utils/Messages";
import { store } from "Store";
import { Mixpanel } from "Utils/MixPanel";
import { TenantApi } from "Data/Tenant.api";

export function* listPlansAdm() {
  try {
    const { data } = yield call(AdmApi.listPlans);
    yield put(AdmActions.listPlansSuccess(data.data));

  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : loadError('planos');
    toastHandler.showError(error);
    yield put(AdmActions.listPlansFailure(error));
  }
};

export function* getPlanAdm({ payload }: GetPlanRequestAction) {
  try {
    const { data } = yield call(AdmApi.getPlan, payload);
    yield put(AdmActions.getPlanSuccess(data.data));

  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : loadError('plano');
    toastHandler.showError(error);
    yield put(AdmActions.getPlanFailure(error));
  }
};

export function* createOrUpdatePlanAdm({ payload }: CreateOrUpdatePlansRequestAction) {
  try {
    const { data } = yield call(AdmApi.createOrUpdatePlan, payload);
    yield put(AdmActions.createOrUpdatePlanSuccess(data.data));
    const msg = payload.StorePlansId ? updateSuccess('plano') : createSuccess('plano');
    toastHandler.showSuccess(msg);
    
  } catch (err: any) {
    const error = err.errors && err.errors.length > 0 ? err.errors[0].Message : (payload.StorePlansId ? updateError('plano') : createError('plano'));
    toastHandler.showError(error);
    yield put(AdmActions.createOrUpdatePlanFailure(error));
  }
};

export function* deletePlanAdm({ payload }: DeletePlanRequestAction) {
  try {
    yield call(AdmApi.deletePlan, payload);
    toastHandler.showSuccess('Plano removido com sucesso!')
    yield put(AdmActions.deletePlanSuccess(payload));

  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : "Erro ao listar planos";
    toastHandler.showError(error);
    yield put(AdmActions.deletePlanFailure(error));
  }
};

export function* listTenantsAdm() {
  try {
    const { data } = yield call(AdmApi.listTenants);
    yield put(AdmActions.listTenantsSuccess(data.data));

  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : loadError('tenants');
    toastHandler.showError(error);
    yield put(AdmActions.listPlansFailure(error));
  }
};

export function* updateTenantAdm({ payload }: UpdateTenantsRequestAction) {
  try {
    const {
      isUpdateTracking,
      isUpgradeTracking,
      tenantIdTracking,
      oldStorePlanTracking,
      userInfoTracking,
      currentTenantTracking,
    } = payload;
    delete payload.isUpdateTracking;
    delete payload.isUpgradeTracking;
    delete payload.tenantIdTracking;
    delete payload.oldStorePlanTracking;
    delete payload.oldStorePlanOrderTracking;
    delete payload.userInfoTracking;
    delete payload.currentTenantTracking;

    const { data } = yield call(AdmApi.updateTenant, payload);
    
    yield put(AdmActions.updateTenantSuccess(data.data));
    toastHandler.showSuccess(updateSuccess('tenant'));

    if (isUpdateTracking) {
      if (isUpgradeTracking) {
        Mixpanel.track({
          name: 'TENANT_CONFIRM_UPGRADE', 
          props: {
            tenantId: tenantIdTracking,
            storePlan: oldStorePlanTracking,
            newStorePlan: data.data.StorePlan.Name,
            storePlanChangeDate: new Date().toLocaleString(),
          },
          userInfo: userInfoTracking,
          currentListTenant: currentTenantTracking,
        });
  
      } else {
        Mixpanel.track({
          name: 'TENANT_CONFIRM_DOWNGRADE', 
          props: {
            tenantId: tenantIdTracking,
            storePlan: oldStorePlanTracking,
            newStorePlan: data.data.StorePlan.Name,
            storePlanChangeDate: new Date().toLocaleString(),
          },
          userInfo: userInfoTracking,
          currentListTenant: currentTenantTracking,
        });
      }
    }

  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : updateError('tenant');
    toastHandler.showError(error);
    yield put(AdmActions.updateTenantFailure(error));
  }
};

export function* createOrUpdateTenantPaymentAdm({ payload }: CreateOrUpdateTenantPaymentRequestAction) {
  try {
    const { data } = yield call(AdmApi.createOrUpdateTenantPayment, payload);
    yield put(AdmActions.createOrUpdateTenantPaymentSuccess(data.data));
    const msg = payload.PurchaseHistoryId ? updateSuccess('pagamento do tenant') : createSuccess('pagamento do tenant');
    toastHandler.showSuccess(msg);
    
  } catch (err: any) {
    const error = err.errors && err.errors.length > 0 ? err.errors[0].Message : (payload.PurchaseHistoryId ? updateError('pagamento do tenant') : createError('pagamento do tenant'));
    toastHandler.showError(error);
    yield put(AdmActions.createOrUpdateTenantPaymentFailure(error));
  }
};

export function* getBillingData({ payload }: GetBillingDataRequestAction) {
  try {
    const { data } = yield call(AdmApi.getBillingData, payload);
    yield put(AdmActions.getBillingDataSuccess(data.data));

  } catch (err: any) {
    const error = err.errors && err.errors.length > 0 ? err.errors[0].Message : loadError('dados de faturamento');
    toastHandler.showError(error);
    yield put(AdmActions.getBillingDataFailure(error));
  }
}

export function* confirmTenantUpdatePlan({ payload }: ConfirmUpdatePlanRequestAction) {
  try {
    const {
      isUpgradeTracking,
      tenantIdTracking,
      oldStorePlanTracking,
      userInfoTracking,
      currentTenantTracking,
    } = payload;
    delete payload.isUpgradeTracking;
    delete payload.tenantIdTracking;
    delete payload.oldStorePlanTracking;
    delete payload.userInfoTracking;
    delete payload.currentTenantTracking;

    const { data } = yield call(AdmApi.confirmUpdatePlan, payload);

    yield put(AdmActions.confirmUpdatePlanSuccess(data.data));
    const msg = payload.isApproved ? aproveSuccess('da troca do plano') : reproveSuccess('da troca do plano');
    toastHandler.showSuccess(msg);
    
    if (payload.isApproved) {
      if (isUpgradeTracking) {
        Mixpanel.track({
          name: 'TENANT_CONFIRM_UPGRADE', 
          props: {
            tenantId: tenantIdTracking,
            storePlan: oldStorePlanTracking,
            newStorePlan: data.data.StorePlan.Name,
            storePlanChangeDate: new Date().toLocaleString(),
          },
          userInfo: userInfoTracking,
          currentListTenant: currentTenantTracking,
        });
  
      } else {
        Mixpanel.track({
          name: 'TENANT_CONFIRM_DOWNGRADE', 
          props: {
            tenantId: tenantIdTracking,
            storePlan: oldStorePlanTracking,
            newStorePlan: data.data.StorePlan.Name,
            storePlanChangeDate: new Date().toLocaleString(),
          },
          userInfo: userInfoTracking,
          currentListTenant: currentTenantTracking,
        });
      }
    }

  } catch (err: any) {
    const error = err.errors && err.errors.length > 0 ? err.errors[0].Message : (
      payload.isApproved
        ? aproveError('da troca do plano')
        : reproveError('da troca do plano')
    );
    toastHandler.showError(error);
    yield put(AdmActions.confirmUpdatePlanFailure(error));
  }
};

export function* listTenantUsersAdm({ payload }: ListTenantUsersRequestAction) {
  try {
    const { data } = yield call(AdmApi.listTenantUsers, payload);
    yield put(AdmActions.listTenantUsersSuccess(data.data));

  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : loadError('usuários do tenant');
    toastHandler.showError(error);
    yield put(AdmActions.listTenantUsersFailure(error));
  }
};

export function* updateTenantAdmin({ payload }: UpdateTenantAdminRequestAction) {
  try {
    const { data } = yield call(AdmApi.updateTenantAdmin, payload);
    if (data.success) {
      yield put(AdmActions.updateTenantAdminSuccess(true));
      store.dispatch(AdmActions.listTenants());
      const msg = updateSuccess('troca do admin tenant');
      toastHandler.showSuccess(msg);
    }

  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : updateError('troca do admin tenant');
    toastHandler.showError(error);
    yield put(AdmActions.updateTenantAdminFailure(error));
  }
};

export function* getTenantPersonalizationAdm({ payload }: GetTenantPersonalizationAdmAction) {
  try {
    const { data } = yield call(TenantApi.getTenantPersonalization, payload);

    yield put(AdmActions.getTenantPersonalizationAdmSuccess(data.data));

  } catch (err: any) {
    const msg = 'Erro ao carregar personalização do Tenant.';
    const error = err.errors ? (err.errors[0]?.Message || msg) : msg;

    yield put(AdmActions.getTenantPersonalizationAdmFailure(error));
  }
};
