import { FC, useEffect, useMemo, useState } from 'react';
import { Avatar } from 'antd';

export type alphabet =
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'x'
  | 'y'
  | 'z';

export const backgroundColor = {
  a: 'ED5564',
  b: 'FFCE55',
  c: '48CFAE',
  d: '5D9CEC',
  e: 'EC87BF',
  f: 'D74655',
  g: 'F6BB43',
  h: '36BC9B',
  i: '4B89DC',
  j: 'D870AD',
  k: 'CCD0D9',
  l: 'FB6E52',
  m: 'A0D468',
  n: '4FC0E8',
  o: 'AC92ED',
  p: 'AAB2BD',
  q: 'E9573E',
  r: '8CC051',
  s: '3BAEDA',
  t: '967BDC',
  u: '48CFAE',
  v: 'D870AD',
  w: '3BAEDA',
  x: 'CCD0D9',
  y: 'D74655',
  z: 'FFCE55',
};

type sizeAvatar = number | 'large' | 'default' | 'small' | undefined;

export type ThumbType = 'small' | 'mid' | 'big';

const thumbObj: Record<ThumbType, string> = {
  'small': '64x64',
  'mid': '128x128',
  'big': '256x256',
}

interface IGetAvatar {
  name: string;
  shape?: 'circle' | 'square';
  size?: sizeAvatar;
  fontSize?: number;
  src?: string;
  thumbSrc?: string;
  custom?: boolean;
  border?: string;
  zIndex?: number;
  marginRight?: string;
  cursor?: string;
  marginTop?: string;
  thumbType?: ThumbType;
}

export const GetAvatar: FC<IGetAvatar> = ({
  name,
  shape,
  size,
  fontSize,
  src,
  thumbSrc,
  custom,
  border,
  zIndex,
  marginRight,
  cursor,
  marginTop,
  thumbType,
}) => {
  try {
    const [currentSrc, setCurrentSrc] = useState<string | undefined>(undefined)

    const initials = nameToInitials(name);
    const color = getColorByName(name) ?? '#666';

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (thumbSrc) {
        if (thumbSrc?.includes('/thumbnails/')) {
          setCurrentSrc(thumbSrc.replace(
            '/thumbnails/',
            `/thumbnails/${thumbObj[thumbType || 'small']}/`
          ));
        }
        if (thumbSrc?.includes('/thumbnail/')) {
          setCurrentSrc(thumbSrc.replace(
            '/thumbnail/',
            `/thumbnails/${thumbObj[thumbType || 'small']}/`
          ));
        }
      }
    }, [thumbSrc, thumbType])

    return (
      <span>
        {!custom && (
          <Avatar
            style={{
              backgroundColor: color, fontSize,
              fontWeight: 'bold',
              border: border,
              zIndex: zIndex,
              marginRight: marginRight,
              cursor: cursor,
              marginTop: marginTop,
            }}
            shape={shape}
            size={size}
            src={currentSrc}
            onError={() => {
              setCurrentSrc(src)
              return true
            }}
          >
            {initials}
          </Avatar>
        )}
        {custom && (
          <Avatar
            style={{ backgroundColor: color, fontSize, fontWeight: 'bold', width: '50px', height: '32px', cursor: cursor }}
            src={currentSrc}
            onError={() => {
              setCurrentSrc(src)
              return true
            }}
            shape={shape}
          >
            {initials}
          </Avatar>
        )}
      </span>
    );
  } catch {
    return (
      <span>
        <Avatar
          style={{ backgroundColor: 'red', fontSize, fontWeight: 'bold' }}
          shape={shape}
          size={size}
          src={src}
        >
          {''}
        </Avatar>
      </span>
    );
  }
};

const nameToInitials = (name: string) => {
  const names = name.split(' ');
  let initials = '';
  if (names.length > 1) {
    const lastName = names.pop();
    if (lastName) {
      const formatLastName = lastName.replace(/[^0-9a-zA-Z]+/g, '');
      initials = names[0].slice(0, 1) + formatLastName.slice(0, 1);
    }
  } else {
    initials = names[0].slice(0, 2);
  }
  return initials.toUpperCase();
};

const getColorByName = (name: string) => {
  return `#${backgroundColor[name.charAt(0).toLowerCase() as alphabet]}`;
};

export const getUserAvatarOrThumb = (
  avatarName: string | null,
  username: string,
  size?: sizeAvatar
) => {
  const urlImage = `${process.env.REACT_APP_IMAGE_STORAGE_URL}/${avatarName}`;

  if (avatarName !== null) {
    return <GetAvatar name={username} shape="circle" size={size || 'small'} src={urlImage} />;
  }

  return <GetAvatar name={username} shape="circle" size={size || 'small'} />;
};
