import { FC, useEffect, useCallback, useState } from 'react';
import { Checkbox, Skeleton, Button } from 'antd';
import { Icon } from '../../../Components/UI';
import Select, { IOptionData } from '../../../Components/UI/Select';
import { SelectValue } from 'antd/lib/select';
import { useDispatch, connect, ConnectedProps } from 'react-redux';
import {
  NomenclaturaActions,
} from '../../../Store/Nomenclatura/Nomenclatura.actions';
import {
  INomenclatureData,
  IVersionField,
} from '../../../Data/interfaces/Nomenclatura/INomenclatureData';
import {
  IGlobalReducerState,
} from '../../../Store/Base/interface/IGlobalReducerState';
import Input from '../../../Components/UI/Input';
import toastHandler from '../../../Utils/toastHandler';
import styles from './Nomenclatura.module.scss';

const separatorTypes: IOptionData[] = [
  {
    label: '_',
    description: 'Underline (_)',
    value: 0,
  },
  {
    label: '.',
    description: 'Ponto (.)',
    value: 1,
  },
  {
    label: '-',
    description: 'Traço (-)',
    value: 2,
  },
];

interface INomenclatureFieldRow {
  fields: IOptionData[];
  separators: IOptionData[];
  fieldSelected?: number | string;
  separatorSelected?: number | string;
  fieldCustomName?: string;
}

export interface INomenclatura {
  isSubmited?: boolean;
  isTabVersion?: boolean;
  onSubmit: (props?: object, erros?: string[]) => void;
}

const Nomenclatura: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [revisionControl, setRevisionControl] = useState<boolean>(true);
  const [nomenclatureFields, setNomenclatureFields] = useState<INomenclatureFieldRow[]>([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isTabSubmit, setIsTabSubmit] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const availableFields: IOptionData[] = props.CampoVersao?.map((campo) => ({
    value: campo.CampoVersaoId,
    description: campo.NomeCampo,
    label: campo.NomeCampo
  })) || [];

  if (!props.isLoading && isSubmit) {
    setIsSubmit(false);
  }

  const addVersionFieldNomenclaturaRow = useCallback((versionFields: IVersionField[]) => {
    const nomencatureFieldsAux: INomenclatureFieldRow[] = [];

    versionFields.forEach((versionField) => {
      availableFields.forEach((field) => {
        if (
          field.value !== 1 && field.value !== 29 && field.value !== versionField.CampoVersaoId &&
          versionFields.some((versionFieldRemoved) => versionFieldRemoved.CampoVersaoId === field.value)
        ) {
          field.removed = true;
        } else {
          field.removed = false;
        }
      });

      nomencatureFieldsAux.push({
        fields: JSON.parse(JSON.stringify(availableFields)),
        separators: separatorTypes,
        separatorSelected: versionField.Separador,
        fieldSelected: versionField.CampoVersaoId,
        fieldCustomName: versionField.CampoPersonalizado,
      } as INomenclatureFieldRow);
    });

    return nomencatureFieldsAux;
  }, [availableFields]);

  const newNomenclaturaRow = useCallback(() => {
    return {
      fields: JSON.parse(JSON.stringify(availableFields)),
      separators: separatorTypes,
      separatorSelected: 0,
      fieldSelected: 1,
    };
  }, [availableFields]);

  const updateAllAvaliableFields = useCallback(() => {
    availableFields?.forEach((field) => {
      if (
        field.value !== 1 &&
        field.value !== 29 &&
        nomenclatureFields.some((nomenclatureField) => nomenclatureField.fieldSelected === field.value)
      ) {
        field.removed = true;
      }
    });
  }, [availableFields, nomenclatureFields]);

  const getErrors = useCallback(() => {
    const hasEmptyField = undefined !== nomenclatureFields
      .find((field, index) => (
        field.fieldSelected === 1 || (field.fieldSelected === 29 && !field?.fieldCustomName) ||
        (field.separatorSelected === undefined && nomenclatureFields.length !== index + 1)
      ));
    if (hasEmptyField) {
      return 'Os campos devem ser preenchidos';
    }
    const hasRevisionError = revisionControl && !nomenclatureFields
      .find((field) => (
        field.fieldSelected === 4
      ));

    if (hasRevisionError) {
      return 'É obrigatório conter o campo "Revisão"';
    }

    return null;
  }, [nomenclatureFields, revisionControl]);

  const submit = useCallback(() => {
    setIsSubmit(true);

    if (!props.isLoading) {
      const errors = getErrors();
      if (errors) {
        if (props.isTabVersion) {
          props.onSubmit(undefined, [errors]);
        } else {
          toastHandler.showError(errors);
        }
      } else {
        const requestData: INomenclatureData = {
          IsTabSubmit: props.isTabVersion,
          HasRevisionControl: revisionControl,
          VersionFields: nomenclatureFields.map((field, index) => ({
            Ordem: index,
            CampoVersaoId: field.fieldSelected,
            Separador: field.separatorSelected || 0,
            CampoPersonalizado: field.fieldSelected === 29 ? field.fieldCustomName : null,
          } as IVersionField)),
        };

        dispatch(NomenclaturaActions.create(requestData));
      }
    }
  }, [dispatch, getErrors, nomenclatureFields, props, revisionControl]);

  const deleteRow = (index: number) => {
    if ((nomenclatureFields.length - 1) === index) {
      nomenclatureFields[index - 1].separatorSelected = 0;
    }

    const removedField = nomenclatureFields.splice(index, 1);
    if (removedField[0].fieldSelected !== undefined) {
      updateNomenclatureFields(undefined, removedField[0].fieldSelected);
    }
  };

  const insertRow = () => {
    setNomenclatureFields([
      ...nomenclatureFields,
      newNomenclaturaRow(),
    ]);
  };

  const updateNomenclatureFields = (
    index?: number,
    oldValue?: any | string,
    newValue?: any,
  ) => {
    nomenclatureFields.forEach((nomenclatura, indexNomenclatura) => {
      if (index === undefined || index !== indexNomenclatura) {
        nomenclatura.fields.forEach((field) => {
          if (newValue && field.value === newValue) {
            field.removed = true;
          }

          if (field.value === oldValue || field.value === 1 || field.value === 29) {
            field.removed = false;
          }
        });
      }
    });

    setNomenclatureFields([...nomenclatureFields]);
  };

  const fieldChange = (
    index: number,
    selectValue: SelectValue,
    customName?: string,
  ) => {
    const oldSelectedValue = nomenclatureFields[index]['fieldSelected'];
    nomenclatureFields[index]['fieldSelected'] = selectValue as number;
    nomenclatureFields[index].fieldCustomName = (selectValue as number === 29)
      ? customName
      : undefined;

    updateNomenclatureFields(index, oldSelectedValue, selectValue);
  };

  const separatorChange = (index: number, selectValue: SelectValue) => {
    nomenclatureFields[index]['separatorSelected'] = selectValue as number;
    setNomenclatureFields([...nomenclatureFields]);
  };

  useEffect(() => {
    if (!props.NomenclatureData.VersionFields) {
      dispatch(NomenclaturaActions.getNomenclature());
    }
  }, [dispatch, props.NomenclatureData.VersionFields]);

  useEffect(() => {
    if (!props.CampoVersao) {
      dispatch(NomenclaturaActions.getCampoVersao());
    }
  }, [dispatch, props.CampoVersao]);

  useEffect(() => {
    if (
      props.NomenclatureData.VersionFields &&
      availableFields.length &&
      !nomenclatureFields.length
    ) {
      if (props.NomenclatureData.VersionFields.length) {
        setRevisionControl(props.NomenclatureData.HasRevisionControl);
        setNomenclatureFields(addVersionFieldNomenclaturaRow(props.NomenclatureData.VersionFields));
      } else {
        setNomenclatureFields([
          newNomenclaturaRow(),
          newNomenclaturaRow(),
        ]);
      }
    }

    updateAllAvaliableFields();
  }, [
    addVersionFieldNomenclaturaRow,
    availableFields.length,
    newNomenclaturaRow,
    nomenclatureFields.length,
    props.NomenclatureData,
    updateAllAvaliableFields,
  ]);

  useEffect(() => {
    if (props.isSubmited) {
      if (!isTabSubmit) {
        setIsTabSubmit(true);
        submit();
      } else {
        setIsTabSubmit(false);
        props.onSubmit();
      }
    } else {
      setIsTabSubmit(false);
    }
  }, [isSubmit, isTabSubmit, props, props.isSubmited, submit]);

  return (
    <>
      {props.isLoading && !isSubmit
        ? <Skeleton active />
        : (
          <div>
            <div className={styles['titlePreviewCheckbox']}>
              <span className={styles['titlePreview']}>Preview da nomenclatura</span>
              <Checkbox checked={revisionControl} onChange={() => setRevisionControl(!revisionControl)}>
                Controle de revisão
              </Checkbox>
            </div>
            <div className={styles['nomenclaturePreview']}>
              {nomenclatureFields
                .filter((nomenclature) => nomenclature.fieldSelected && (Number(nomenclature.fieldSelected) > 1))
                .map((nomenclature, index, array) => {
                  const field = nomenclature.fieldSelected !== 29
                    ? nomenclature.fields
                      .find((fieldType) => fieldType.value === nomenclature.fieldSelected)?.description
                    : nomenclature.fieldCustomName;
                  const separator = nomenclature.separators
                    .find((separatorType) => separatorType.value === nomenclature.separatorSelected)?.label;

                  return (
                    <>
                      <span className={styles['spansPreview']} key={`fieldPreview${index}`}>{field}</span>
                      {
                        array.length - 1 !== index
                          ? (
                            <span className={styles['spansPreview']} key={`separatorPreview${index}`}>
                              {separator}
                            </span>
                          )
                          : ''
                      }
                    </>
                  );
                })}
            </div>
            <div className={styles['nomenclatureStructure']}>
              <span className={styles['nomenclatureStructureTitle']}>
                Estrutura da nomenclatura
              </span>
              {/* <Icon
                  icon='informacaoAdicional'
                  customSize={10}
                  color='cinzaPadrao'
                  className={styles['icon']}
                /> */}
              <div className={styles['nomenclatureFieldsContainer']}>
                {nomenclatureFields.map((nomenclatureField, index) => (
                  <div className={styles['fieldRow']} key={`Nomenclatura-${index}`}>
                    {nomenclatureField.fieldSelected === 29 ? (
                      <div className={styles['customName']}>
                        <Input
                          label={'Campo personalizado'}
                          autoFocus={true}
                          customHeight={40}
                          onInput={(value) => fieldChange(index, 29, value)}
                          value={nomenclatureField.fieldCustomName}
                          className={styles['field']} />
                        <Icon
                          icon='cancelar'
                          customSize={8}
                          onClick={() => fieldChange(index, 0)}
                          color='cinzaPadrao'
                        />
                      </div>
                    ) : (
                      <Select
                        notFoundContent={null}
                        options={nomenclatureField.fields}
                        value={nomenclatureField.fieldSelected || 1}
                        showSearch={nomenclatureField.fieldSelected === 29}
                        size='large'
                        className={styles['field']}
                        onChange={(selectValue: SelectValue) => {
                          fieldChange(index, selectValue);
                        }}
                      />
                    )}
                    {(nomenclatureFields.length - 1) === index
                      ? ''
                      : (
                        <Select
                          options={nomenclatureField.separators}
                          fieldNames={{ label: 'description' }}
                          size='large'
                          value={nomenclatureField.separatorSelected}
                          className={styles['separator']}
                          onChange={(selectValue: SelectValue) => {
                            separatorChange(index, selectValue);
                          }}
                        />
                      )
                    }
                    {nomenclatureFields.length > 1
                      ? (
                        <span
                          className={styles['deleteButton']}
                          onClick={() => {
                            deleteRow(index);
                          }}
                        >
                          <Icon icon='excluir' customSize={16} />
                        </span>
                      )
                      : ''
                    }
                  </div>
                ))}
              </div>
              <span className={styles['linkNewRow']} onClick={insertRow}>
                + Adicionar novo campo
              </span>
              {!props.isTabVersion
                ? (
                  <div className={styles['saveButton']}>
                    <Button type='primary' onClick={submit} loading={props.isLoading}>
                      <span style={{ padding: '0 35px' }}>
                        Salvar
                      </span>
                    </Button>
                  </div>
                )
                : ''
              }
            </div>
          </div>
        )}
    </>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.nomenclatura,
  camposVersao: state.nomenclatura.CampoVersao,
});

const connector = connect(
  mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & INomenclatura;

export default connector(Nomenclatura);
