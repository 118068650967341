import React, { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import OptionCustom from "Components/UI/NewFormGroup/OptionImg";
import { getDisciplineIcon, getTypeIcon } from "Utils/ObraUtils";
import { 
  ITopicCommunicationType, 
  ITopicPoint, 
  ITopicStage, 
  ITopicStatus, 
  ITopicType,
} from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { IDisciplineResponse } from "Data/interfaces/Obra/IDisciplineResponse";
import { IUserData } from "Data/interfaces/User/IUserData";
import { TopicPriorityEnum, TopicVisibilityEnum } from "Data/interfaces/Activities/ITopicResponse";
import AccessPermission from "Utils/AcessPermission";

export interface IUseOptionSelect {
  topicStatusOptions: ITopicStatus[];
  topicStagesOptions: ITopicStage[];
  topicPointsOptions: ITopicPoint[];
  topicTypesOptions: ITopicType[];
  constructionSiteDisciplinesOptions: IDisciplineResponse[];
  topicCommunicationTypesOptions: ITopicCommunicationType[];
  usersOptions: IUserData[];
}

export interface ICustomOption extends LabeledValue {
  id: string | number;
}

const useOptionsSelect = ({
  topicStatusOptions,
  topicTypesOptions,
  topicStagesOptions,
  topicPointsOptions,
  topicCommunicationTypesOptions,
  constructionSiteDisciplinesOptions,
  usersOptions,
}: IUseOptionSelect) => {
  const visibilityOptions: ICustomOption[] = [
    {label: <OptionCustom
      label="Rascunho"
      icon="editar"
      customColor={'#BEC2C6'}
      customSize={12}
    />, 
    id: TopicVisibilityEnum.Draft,
    key: '0',
    value: TopicVisibilityEnum.Draft
  },
    {label: <OptionCustom
      label="Público"
      icon="globe"
      customColor={'#5D9CEC'}
      customSize={18}
    />, 
    id: TopicVisibilityEnum.Public,
    key: '1',
    value: TopicVisibilityEnum.Public
  },
    {label: <OptionCustom
      label="Privado"
      icon="cadeadoFechado"
      customColor={'#8697A8'}
      customSize={16}
    />, 
    id: TopicVisibilityEnum.Private,
    key: '2',
    value: TopicVisibilityEnum.Private
  },
  ];
  const priorityOptions: ICustomOption[] = [
    {label: <OptionCustom
      label="Alta"
      icon="flag"
      customColor={'#E2574C'}
      customSize={18}
      textColor={'#E2574C'}
      bgColor={'#E2574C26'}
    />, 
    id: TopicPriorityEnum.High,
    key: '3',
    value: TopicPriorityEnum.High
  },
    {label: <OptionCustom
      label="Média"
      icon="flag"
      customColor={'#FDBF5E'}
      customSize={16}
      textColor={'#FDBF5E'}
      bgColor={'#FDBF5E26'}
    />, 
    id: TopicPriorityEnum.Medium,
    key: '2',
    value: TopicPriorityEnum.Medium
  },
    {label: <OptionCustom
      label="Baixa"
      icon="flag"
      customColor={'#BEC2C6'}
      customSize={16}
      textColor={'#BEC2C6'}
      bgColor={'#BEC2C626'}
    />, 
    id: TopicPriorityEnum.Low,
    key: '1',
    value: TopicPriorityEnum.Low
  },
  ];
  const priorityNoBgOptions: ICustomOption[] = [
    {label: <OptionCustom
      label="Alta"
      icon="flag"
      customColor={'#E2574C'}
      customSize={18}
      textColor={'#E2574C'}
    />, 
    id: TopicPriorityEnum.High,
    key: '3',
    value: TopicPriorityEnum.High
  },
    {label: <OptionCustom
      label="Média"
      icon="flag"
      customColor={'#FDBF5E'}
      customSize={16}
      textColor={'#FDBF5E'}
    />, 
    id: TopicPriorityEnum.Medium,
    key: '2',
    value: TopicPriorityEnum.Medium
  },
    {label: <OptionCustom
      label="Baixa"
      icon="flag"
      customColor={'#BEC2C6'}
      customSize={16}
      textColor={'#BEC2C6'}
    />, 
    id: TopicPriorityEnum.Low,
    key: '1',
    value: TopicPriorityEnum.Low
  },
  ];

  const statusOptions = useMemo(() => {
    const options: ICustomOption[] = [];
    if (topicStatusOptions.length > 0) {
      topicStatusOptions.map((item) => (
        options.push({
          label: <OptionCustom
            label={item.Name}
            bgColor={`${item.Color}1F`}
          />,
          id: item.TopicStatusId,
          key: item.Name,
          value: item.TopicStatusId
        })
      ));
    }
    return options;
  }, [topicStatusOptions]);

  const typeOptions = useMemo(() => {
    const options: ICustomOption[] = [];
    if (topicTypesOptions.length > 0) {
      let filteredTypesOptions = topicTypesOptions;
      if (AccessPermission.isEngenheiro()) {
        filteredTypesOptions = topicTypesOptions.filter(type => 
          type.Name.toLowerCase().replace(' ', '') === 'Chamado de Obra'.toLowerCase().replace(' ', '')
        );
      }
      filteredTypesOptions.map((item) => (
        options.push({
          label: <OptionCustom
            label={item.Name}
            icon={getTypeIcon(item.Name)}
            customColor={'#8697A8'}
          />,
          id: item.TopicTypeId,
          key: item.Name,
          value: item.TopicTypeId
        })
      ))
    }
    return options;
  }, [topicTypesOptions]);

  const stageOptions = useMemo(() => {
    const options: string[] = [];
    if (topicStagesOptions.length > 0) {
      topicStagesOptions.map((item) => (
        options.push(item.Name)
      ))
    }
    return options;
  }, [topicStagesOptions]);

  const pointsOptions = useMemo(() => {
    const options: string[] = [];
    if (topicPointsOptions.length > 0) {
      topicPointsOptions.map((item) => (
        options.push(item.Name)
      ))
    }
    return options;
  }, [topicPointsOptions]);

  const communicationOptions = useMemo(() => {
    const options: string[] = [];
    if (topicCommunicationTypesOptions.length > 0) {
      topicCommunicationTypesOptions.map((item) => (
        options.push(item.Name)
      ))
    }
    return options;
  }, [topicCommunicationTypesOptions]);

  const disciplineOptions = useMemo(() => {
    const options: ICustomOption[] = [];
    if (constructionSiteDisciplinesOptions.length > 0) {
      constructionSiteDisciplinesOptions.map((discipline: any) => (
        options.push({
          label: <OptionCustom
            label={discipline?.CustomName || discipline?.Discipline?.Name}
            icon={getDisciplineIcon(discipline?.CustomName || discipline?.Discipline?.Name)}
            customColor={'#8697A8'}
          />,
          id: discipline.ConstructionSiteDisciplinesId,
          key: discipline?.CustomName || discipline?.Discipline?.Name,
          value: discipline.ConstructionSiteDisciplinesId
        })
      ))
    }
    return options;
  }, [constructionSiteDisciplinesOptions]);

  const userOptions = useMemo(() => {
    const options: ICustomOption[] = [];
    if (usersOptions.length > 0) {
      usersOptions.map((user) => (
        options.push({
          label: <OptionCustom
            label={user.Nome || user.Email || ''}
            avatar
            src={user?.OriginalImageUrl || ''}
            thumbSrc={user?.ThumbnailImageUrl}
            thumbType="small"
          />,
          id: user.Id || '',
          key: user.Nome || '',
          value: user.Id || ''
        })
      ))
    }
    return options;
  }, [usersOptions]);  

  return {
    visibilityOptions,
    priorityOptions,
    priorityNoBgOptions,
    statusOptions,
    typeOptions,
    stageOptions,
    pointsOptions,
    communicationOptions,
    userOptions,
    disciplineOptions,
  };
}

export default useOptionsSelect;
