import { IUserInfoResponse } from "Data/interfaces/MinhaConta/IUserInfoResponse";
import { ROLES } from "..";
import { FC, useState } from "react";
import { IObraResponse } from "Data/interfaces/Obra/IObraListResponse";
import Tooltip from "Components/UI/Tooltip";
import Button from "Components/UI/Button";
import { Icon } from "Components/UI/Icon";

import styles from './HeaderActions.module.scss';
import Form from "Components/UI/Form/Form";
import * as Yup from 'yup';
import Field from "Components/UI/Field";

export interface IHeaderActions {
    minhaConta: IUserInfoResponse;
    obras?: IObraResponse[];
    isLoading: boolean;
    obrasForShow: boolean;

    openCadastroObra: () => void;
    onRemoveSearch: () => void;
    onSearch: (search: string) => void;
    onSearchChange: (search: string) => void;
}

export const HeaderActions: FC<IHeaderActions> = ({ 
  minhaConta, 
  isLoading, 
  obras, 
  obrasForShow,
  onSearch, 
  onSearchChange, 
  onRemoveSearch, 
  openCadastroObra,
}) => {
    const [showCSSearchInput, setShowCSSearchInput] = useState(false)
    const allowedRoles = [ROLES.ADMIN, ROLES.COORDENADOR]

    if (!allowedRoles.includes(minhaConta.CurrentRoleFk) || (!isLoading && obras === undefined)) {
        return <></>;
    }

    const onSearchSubmit = (values: { search: string }) => {
        setShowCSSearchInput(false);
        onSearch(values.search);
    }

    const toggleSearchInput = () => {
        onRemoveSearch();
        setShowCSSearchInput(true);
    }

    return (
        <div className={styles['actions-container']}>
            {obrasForShow && 
                (showCSSearchInput ? (
                    <Form schema={Yup.object().shape({})} className={styles['form-container']} onSubmit={onSearchSubmit}>
                        <Field
                            name="search"
                            label=""
                            icon="buscar"
                            autoFocus={true}
                            className={styles['search-input']}
                            placeholder="Pesquise por uma obra..."
                            onChange={(x) => onSearchChange((x?.target as any)?.value)}
                        />
                        <div
                            className={styles['search-input-backdrop']}
                            onClick={() => {
                                setShowCSSearchInput(false)
                                onRemoveSearch()
                            }}
                        />
                    </Form>
                ) : (
                    <Tooltip title="Filtrar Obras">
                        <Button
                            type='text'
                            height={36}
                            width={36}
                            shape="circle"
                            className={styles['buttonObra']}
                            onClick={toggleSearchInput}
                        >
                            <Icon icon='buscar' customSize={16} />
                        </Button>
                    </Tooltip>
                ))
            }
            
            <Tooltip title="Adicionar Nova Obra">
                <Button
                height={45}
                shape="circle"
                className={styles['buttonObra']}
                onClick={openCadastroObra}
                >
                <Icon
                    icon="adicionar"
                    customSize={20}
                    color="onPrimary"
                    style={{ fontWeight: 700 }}
                />
                </Button>
            </Tooltip>
        </div>
    );
  }