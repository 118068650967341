import { FC, useMemo, useState } from "react";
import { Icon } from "Components/UI";
import Form from "Components/UI/Form/Form";
import schema from "./users.schema";
import Field from "Components/UI/Field";
import Button from "Components/UI/Button";
import useSubmitEdit from "Pages/EditObra/useSubmitEdit";
import ItemInvited from "./components/ItemInvited";
import ItemShared from "./components/ItemShared";
import { ITenantRole } from "Data/interfaces/Tenant/ITenantRole";
import { IUserData } from "Data/interfaces/User/IUserData";
import { ISharedUser } from "Data/interfaces/User/ISharedUser";
import { IInvitedUser } from "Data/interfaces/User/IInvitedUser";
import Select from "Components/UI/Select";
import InfoTooltip from "Components/UI/InfoTooltip";
import SearchInput from "Components/UI/SearchInput";
import LoadingUsersPermissions from "Components/UI/CustomLoading/LoadingUsersPermissions";
import {
  Container,
  Content,
  Description,
  FormContainer,
  Main,
  Title,
} from "./styles";

export interface IRole {
  value: number;
  description: string;
  label: string;
}

export interface IUpdatedUsers {
  InvitedUsers: IInvitedUser[];
  SharedUsers: ISharedUser[];
}

interface IUsers {
  csId: number;
  tenantRoles: ITenantRole[];
  tenantSharedUsers: IUserData[];
  sharedUsers: ISharedUser[];
  invitedUsers: IInvitedUser[];
  currentRole?: number;
  numberInvitedAllUsers?: number;
  firstInvitedUsers?: IInvitedUser[];
  loading?: boolean;
}

const Users: FC<IUsers> = ({
  csId,
  tenantRoles,
  tenantSharedUsers,
  sharedUsers,
  invitedUsers,
  currentRole,
  numberInvitedAllUsers,
  firstInvitedUsers,
  loading,
}) => {
  const {
    sentRequest,
    setSentRequest,
    submit,
  } = useSubmitEdit({ csId });
  console.log({ sharedUsers, tenantSharedUsers })

  const [RoleNewUser, setRoleNewUser] = useState<any>(4);
  const [searchUsers, setSearchUsers] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);

  const roles: IRole[] = tenantRoles.map((role) => ({
    value: role.TenantRoleId,
    description: role.RoleName,
    label: role.RoleName
  }));

  const filteredInvitedUsers = useMemo(() => {
    if (searchUsers && invitedUsers && invitedUsers?.length > 0) {
      return invitedUsers.filter((user: any) => {
        return user.Email?.toLowerCase().includes(searchUsers.toLowerCase());
      });
    }

    return invitedUsers || [];
  }, [invitedUsers, searchUsers]);

  const filteredSharedUsers = useMemo(() => {
    let data: ISharedUser[] = [];

    if (sharedUsers && sharedUsers?.length > 0) {
      data = sharedUsers.map(user => ({ ...user, isShared: true }));

      if (searchUsers) {
        data = data.filter((user: any) => {
          return user.User?.Nome?.toLowerCase().includes(searchUsers.toLowerCase()) ||
            user.Email?.toLowerCase().includes(searchUsers.toLowerCase());
        });
      }
    }

    return data;
  }, [sharedUsers, searchUsers]);

  // useEffect(() => {
  //   if (
  //     disciplinesList.filter(d => d.IsChecked).length > (disciplines?.length || 0)
  //   ) {
  //     setShowEdit(true);
  //   } else {
  //     setShowEdit(false);
  //   }
  // }, [disciplinesList, disciplines]);

  // useEffect(() => {
  //   if (sentRequest && !loading) {
  //     setSentRequest(false);
  //     setShowEdit(false);
  //   }
  // }, [sentRequest, loading]);

  return (
    <Container>
      <Title>Usuários</Title>

      <FormContainer>
        <Description>
          Convide um usuário para a obra
        </Description>
        <Form schema={schema} onSubmit={() => undefined} resetForm={true}>
          <div className="formWrapper">
            <Field
              name='CustomDispName'
              label=''
              placeholder="Nova disciplina"
              height="36px"
              className="fieldName"
            />
            <Select
              onSelect={setRoleNewUser}
              placeholder="Selecione um papel"
              defaultValue={RoleNewUser}
              options={roles}
              // disabled={infoUsuarioUpgradeVisible ? true : false}
              className="selectRole"
            />
            <Button type="primary" htmlType="submit" className="formBtn">
              Convidar
            </Button>
          </div>
        </Form>
        <div className="infoContainer">
          <span className="infoText">Precisa de ajuda com os papéis?</span>
          <InfoTooltip
            placement="bottom"
            overlay={
              <div>
                Para conhecer os papéis acesse Gerenciar usuários &gt; Papéis e permissões
                no menu lateral
              </div>
            }
          >
            <Icon
              icon="informacao"
              customSize={12}
              className="infoIcon"
            />
          </InfoTooltip>
        </div>
      </FormContainer>

      <Main>
        <Description>
          Selecione os usuários que deseja compartilhar a obra
        </Description>

        <div className="searchContainer">
          <SearchInput
            setSearch={setSearchUsers}
            setSearchLoading={setSearchLoading}
            inputPlaceholder="Pesquise por um usuário"
          />
        </div>

        <Content>
          {searchLoading && (
            <LoadingUsersPermissions multiple={6} />
          )}
          {
            searchUsers &&
            filteredInvitedUsers.length === 0 &&
            filteredSharedUsers.length === 0 && (
              <div className="emptyContainer">
                <span className="emptyTitle">
                  Nenhum usuário encontrado
                </span>
                <span className="emptyText">
                  Tente pesquisar pelo e-mail ou nome do usuário.
                </span>
              </div>
            )}
          {!searchLoading && filteredInvitedUsers.length > 0 && (
            filteredInvitedUsers.map(user => (
              <ItemInvited
                key={user.Email}
                user={user}
                search={searchUsers}
                roles={roles}
                loading={loading}
              />
            ))
          )}
          {!searchLoading && filteredSharedUsers.length > 0 && (
            filteredSharedUsers.map(user => (
              <ItemShared
                key={user.UserFk}
                user={user}
                search={searchUsers}
                roles={roles}
                loading={loading}
              />
            ))
          )}
        </Content>
      </Main>

    </Container>
  )
}

export default Users;
