import { FC } from "react";
import { Container } from "./styles";
import { Checkbox, ConfigProvider } from "antd";
import { getDisciplineIcon } from "Utils/ObraUtils";
import { Icon } from "Components/UI";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";

interface IItem {
  name: string;
  checked?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onCheck: () => void;
}

const Item: FC<IItem> = ({
  name,
  checked,
  disabled,
  loading,
  onCheck,
}) => {
  const theme = useSelector(getTheme);

  return (
    <ConfigProvider theme={{
      token: {
        colorBgContainerDisabled: loading ? theme.colors.surface.surfaceOutline : `color-mix(in srgb, ${theme.colors.primary.primaryOutline}, ${theme.colors.surface.surface})`,
        colorTextDisabled: theme.colors.primary.onPrimary,
        colorPrimary: theme.colors.primary.primary
      }
    }}>
      <Container
        ischecked={checked}
        isdisabled={disabled}
        isloading={loading}
        onClick={!disabled ? onCheck : undefined}
      >
        <Checkbox checked={checked} disabled={disabled || loading} />
        <Icon icon={getDisciplineIcon(name)} customSize={16} className="icon" />
        <span className="disciplinaTitle">{name}</span>
      </Container>
    </ConfigProvider>
  )
}

export default Item;
