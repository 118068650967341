import { FC, useEffect, useCallback, useState, useRef } from 'react';
import CadastroTab from '../Tabs/CadastroTab';
import DisciplinasTab from '../Tabs/DisciplinasTab';
import UsuariosConvidadosTab from '../Tabs/UsuariosConvidadosTab';
import PermissoesUsuariosTab from '../Tabs/PermissoesUsuariosTab';
import Modal from '../../../../Components/UI/Modal/Modal';
import Tabs from '../../../../Components/UI/Tabs/Tabs';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { IGlobalReducerState } from '../../../../Store/Base/interface/IGlobalReducerState';
import Button from '../../../../Components/UI/Button';
import NomenclaturaTab from '../Tabs/NomenclaturaTab';
import { ObraActions } from '../../../../Store/Obra/Obra.actions';
import toastHandler from '../../../../Utils/toastHandler';
import ArmazenamentoTab from '../Tabs/ArmazenamentoTab';
import { ICreateOrUpdate } from '../../../../Data/interfaces/Obra/ICreateOrUpdate';
import { TenantActions } from '../../../../Store/Tenant/Tenant.actions';
import { Skeleton } from 'antd';
import { IArchivedStatusRequest } from '../../../../Data/interfaces/Obra/IArchivedStatusRequest';
import AvisoArquivarObra from '../AvisoArquivarObra';
import { IInvitedUser } from '../../../../Data/interfaces/User/IInvitedUser';
import { ISharedUser } from '../../../../Data/interfaces/User/ISharedUser';
import { history } from 'Store';
import { IUserData } from 'Data/interfaces/User/IUserData';
import { Mixpanel } from 'Utils/MixPanel';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import styles from './CadastroObra.module.scss';

interface ICadastroObra {
  isVisible?: boolean;
  onCancel?: () => void;
  isEdit: boolean;
  firstInvitedUsers?: IInvitedUser[];
  isNomenclatureEdit?: boolean;
}

export interface IUpdatedUsers {
  InvitedUsers: IInvitedUser[];
  SharedUsers: ISharedUser[];
}

const CadastroObra: FC<Props> = (props) => {
  const namingPatternFeature = useFeatureFlag('nomenclature-default-feature');
  const isNamingPatternEnabled = namingPatternFeature.enabled && namingPatternFeature.value === 'test';

  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const firstRender = useRef(0);
  const openNomenclatureTab = useRef(false);
  const [tabSelected, setTabSelected] = useState(0);
  const [tabEditSubmit, setTabEditSubmit] = useState(0);
  const [isSubmited, setSubmit] = useState(false);
  const [updatedUsers, setUpdatedUsers] = useState<IUpdatedUsers>({
    InvitedUsers: props.Obra.InvitedUsers,
    SharedUsers: props.Obra.SharedUsers,
  });
  const [closeModalAfterSubmit, setCloseModalAfterSubmit] = useState(false);
  const [arquivarObraVisible, setArquivarObraVisible] = useState(false);
  const [TenantActiveSharedUsers, setTenantActiveSharedUsers] = useState<IUserData[]>([]);

  const isLoading = () => props.isLoading || props.tenantIsLoading;

  if (closeModalAfterSubmit && !props.isLoading) {
    setCloseModalAfterSubmit(false);
    if (props.onCancel) {
      props.onCancel();
    }
  }

  const submitEdit = (values?: object, errors?: string[]) => {
    if (submit(values, errors)) {
      submitNextTab();
    } else {
      setTabSelected(tabEditSubmit);
      resetSubmitEdit();
    }
  };

  const submitNextTab = () => {
    if (tabEditSubmit < getTabs().length - 1) {
      setTabEditSubmit(tabEditSubmit + 1);
      setSubmit(true);
    } else {
      setCloseModalAfterSubmit(true);
    }
  };

  const resetSubmitEdit = () => {
    setSubmit(false);
    setTabEditSubmit(0);
  };

  const submitConfirm = (values?: object, errors?: string[]) => {
    if (submit(values, errors)) {
      if (tabSelected < getTabs().length - 1) {
        setTabSelected(tabSelected + 1);
      } else {
        setCloseModalAfterSubmit(true);
      }
    }
  };

  const submit = (values?: any, errors?: string[]) => {
    setSubmit(false);
    if (errors) {
      errors.forEach((error) => {
        toastHandler.handler({ description: error, icon: 'error', title: '' });
      });
      return false;
    } else {
      if (values) {
        const isFirstObra = props.ObrasList && props.ObrasList?.length <= 0;
        const tabIndex = props.Obra.ConstructionSiteId ? tabEditSubmit : tabSelected;
        const createOrUpdate: ICreateOrUpdate = {
          valuesChunck: values,
          shouldSendData: tabIndex === getTabs().length - 1,
          firstObraTracking: isFirstObra,
          userInfoTracking: props.userInfo,
          currentTenantTracking: currentTenant,
        };
        dispatch(ObraActions.createOrUpdate(createOrUpdate));
      }
    }
    return true;
  };

  const showTabUserPermissions = useCallback(() => {
    return (updatedUsers?.InvitedUsers && updatedUsers?.InvitedUsers.length > 0)
      || (updatedUsers?.SharedUsers && updatedUsers?.SharedUsers.length > 0);
  }, [updatedUsers]);

  const openUpgradePremium = () => {
    Mixpanel.track({
      name: 'CALL_T0_ACTION',
      props: {
        origin: 'convidar-usuarios-modal',
        originPath: window.location.pathname
      },
      userInfo: props.userInfo,
      currentListTenant: currentTenant,
    });

    setCloseModalAfterSubmit(true);
    if (props.currentRole === 1) {
      history.push('/faturamento/planos')
    } else {
      window.open('https://bim.maletadoengenheiro.com.br/seja-premium');
    }
  };

  const getTabs = useCallback(() => {
    const tabs = [
      { title: 'Cadastro' },
      { title: 'Disciplinas' },
      { title: 'Usuários' },
    ];

    if (showTabUserPermissions()) {
      tabs.push({ title: 'Permissões' });
    }
    if (!props.ObrasList?.length || isNamingPatternEnabled) {
      tabs.push({ title: 'Nomenclatura' });
    }
    if (!props.isEdit) {
      tabs.push({ title: 'Armazenamento' });
    }

    firstRender.current++;
    return tabs;
  }, [
    showTabUserPermissions,
    props.ObrasList,
    props.isEdit,
    isNamingPatternEnabled,
  ]);

  const getTab = (title: string, index: any) => {
    const tabs = new Map();
    const onSubmitTab = props.Obra.ConstructionSiteId ? submitEdit : submitConfirm;
    const isSubmitedTab = isSubmited && (props.Obra.ConstructionSiteId
      ? tabEditSubmit === index : tabSelected === index);

    tabs.set('Cadastro', <CadastroTab
      isSubmited={isSubmitedTab}
      onSubmit={onSubmitTab}
      name={props.Obra.Name}
      type={props.Obra.Type}
      address={props.Obra.Address}
      avatarFileName={props.Obra.AvatarFileName}
      listObras={props.ObrasList}
      constructionSiteId={props.Obra.ConstructionSiteId}
    />);
    tabs.set('Disciplinas', <DisciplinasTab
      isSubmited={isSubmitedTab}
      onSubmit={onSubmitTab}
    />);
    tabs.set('Usuários', <UsuariosConvidadosTab
      isSubmited={isSubmitedTab}
      onSubmit={onSubmitTab}
      tenantRoles={props.tenantRoles}
      onConfirm={openUpgradePremium}
      registerCompleted={props.registerCompleted}
      TenantSharedUsers={TenantActiveSharedUsers}
      sharedUsers={updatedUsers.SharedUsers}
      invitedUsers={updatedUsers.InvitedUsers}
      changeUsers={setUpdatedUsers}
      currentRole={props.currentRole}
      numberInvitedAllUsers={props.userLimitsActive?.QtyActiveUsers}
      maxUsers={props.userInfo?.UserStoreItem.MaxQtyUsers ||
        props.userInfo?.UserStoreItem.StorePlan?.MaxQtyUsers
      }
      csId={props.Obra.ConstructionSiteId}
      firstInvitedUsers={props.firstInvitedUsers}
    />);
    tabs.set('Permissões', <PermissoesUsuariosTab
      isSubmited={isSubmitedTab}
      onSubmit={onSubmitTab}
      invitedUsers={updatedUsers.InvitedUsers}
      sharedUsers={updatedUsers.SharedUsers}
      changeUsers={setUpdatedUsers}
    />);
    tabs.set('Nomenclatura', <NomenclaturaTab
      isSubmited={isSubmitedTab}
      isNamingPatternEnabled={isNamingPatternEnabled}
      isFirstObra={!props.ObrasList?.length}
      obraVersionData={props.Obra?.VersionConfig}
      versionConfigFk={props.Obra?.VersionConfigFk}
      onSubmit={onSubmitTab}
    />);
    tabs.set('Armazenamento', <ArmazenamentoTab
      isSubmited={isSubmitedTab}
      onSubmit={onSubmitTab}
      accountsInfo={props.AccountsInfo}
    />);

    return (
      <span>
        <div style={{ padding: 20 }} hidden={!isLoading()}>
          <Skeleton loading={true} paragraph={{ rows: 4 }} />
        </div>
        <div hidden={isLoading()}>
          {tabs.get(title)}
        </div>
      </span>
    );
  };

  const getTitleModal = () => {
    return props.isEdit ? 'Edição de obra' : 'Cadastrar Obras';
  };

  const isTabDisabled = (indexTab: number) => {
    return indexTab >= tabSelected && !props.isEdit;
  };

  const showButtonArchiveConstruction = () => {
    return props.isEdit
      && !props.isLoading
      && getTabs()[tabSelected].title === 'Cadastro'
      && (
        <span
          className={styles['archiveConstructionButton']}
          onClick={() => setArquivarObraVisible(true)}
        >
          Arquivar obra
        </span>
      );
  };

  const showHintNomenclature = () => {
    return getTabs()[tabSelected].title === 'Nomenclatura'
      && (<span className={styles['hintNomenclature']}>
        Para configurações avançadas acesse Configurar serviço &gt; Nomeclatura
      </span>);
  };

  const arquivarObra = () => {
    const status: IArchivedStatusRequest = {
      ConstructionSiteId: props.Obra.ConstructionSiteId || 0,
      isArchived: true,
    };
    dispatch(ObraActions.updateArchivedStatus(status));

    const obras = props?.ObrasList?.filter((obra) => !obra.isArchived);
    if (obras && obras.length === 1) {
      dispatch(ObraActions.clearConstructionSiteSearch(true));
    }

    setArquivarObraVisible(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  useEffect(() => {
    setTabSelected(0);
    setTabEditSubmit(0);
    setSubmit(false);
  }, [props.isVisible]);

  useEffect(() => {
    dispatch(TenantActions.getUsers());
    dispatch(TenantActions.getTenantListUsersConstruction());
  }, []);

  useEffect(() => {
    if (props.Obra.SharedUsers) {
      props.Obra.SharedUsers.forEach((sharedUser) => sharedUser.UserId = sharedUser.UserFk);
    }
    setUpdatedUsers({
      InvitedUsers: [...props.Obra.InvitedUsers || []],
      SharedUsers: [...props.Obra.SharedUsers || []],
    });
  }, [props.Obra.SharedUsers, props.Obra.InvitedUsers]);

  useEffect(() => {
    if (props.TenantUsers?.tenantUsers) {
      setTenantActiveSharedUsers([
        ...props.TenantUsers.tenantUsers.filter((tenant) => tenant.isActive === true).map((tenant) => {
          return { ...tenant.User, CurrentRoleFk: tenant.Role.TenantRoleId }
        })
      ]);
    }
  }, [props.TenantUsers]);

  useEffect(() => {
    if (getTabs()[tabSelected].title === 'Armazenamento' && !props.AccountsInfo) {
      dispatch(ObraActions.listConstructionSiteAccounts());
    }
  }, [tabSelected, props.AccountsInfo, dispatch, getTabs]);

  useEffect(() => {
    if (
      isNamingPatternEnabled &&
      props.isNomenclatureEdit &&
      firstRender.current > 15 &&
      !openNomenclatureTab.current
    ) {
      const nomenclatureIndex = getTabs().findIndex(tabGet => {
        return tabGet?.title === 'Nomenclatura'
      });

      if (nomenclatureIndex !== -1) {
        setTabSelected(nomenclatureIndex);
        openNomenclatureTab.current = true;
      }
    }
  }, [
    tabSelected,
    getTabs,
    props.isNomenclatureEdit,
    isNamingPatternEnabled,
  ]);

  return (
    <>
      <Modal
        width={800}
        title={getTitleModal()}
        visible={props.isVisible}
        onCancel={props.onCancel}
        footerButtons={(
          <>
            {showButtonArchiveConstruction()}
            {showHintNomenclature()}
            <Button onClick={props.onCancel}>
              <span style={{ padding: '0 35px' }}>Cancelar</span>
            </Button>
            <Button type='primary' onClick={() => setSubmit(true)} loading={props.isLoading}>
              <span style={{ padding: '0 35px' }}>
                {tabSelected === getTabs().length - 1 || props.isEdit ? 'Concluir' : 'Continuar'}
              </span>
            </Button>
          </>
        )}
      >
        <Tabs
          tabs={getTabs().map((tab, index) => ({
            ...tab,
            key: index.toString(),
            disabled: isTabDisabled(index),
            children: <span>{getTab(tab.title, index)}</span>,
            forceRender: props.isEdit,
          }))}
          activeKey={tabSelected.toString()}
          onTabClick={(key: string) => { setTabSelected(+key); }}
        />
      </Modal>

      {props.isEdit && (
        <AvisoArquivarObra
          nomeObra={props.Obra.Name}
          visible={arquivarObraVisible}
          onCancel={() => setArquivarObraVisible(false)}
          onConfirm={arquivarObra} />
      )}
    </>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.obra,
  userInfo: state.auth.userInfo,
  userLimitsActive: state.auth.userLimitsActive,
  tenantAllUsers: state.tenant.UsersWithConstruction,
  currentRole: state.auth.userInfo?.CurrentRoleFk,
  tenantIsLoading: state.tenant.isLoading,
  tenantRoles: [...(state.tenant.TenantRoles || [])],
  TenantSharedUsers: [...(state.tenant.Users || [])],
  registerCompleted: state.minhaConta.userInfo?.isRegisterComplete,
  TenantUsers: state.tenant.UsersWithConstruction,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ICadastroObra;

export default connector(CadastroObra);
