import { 
  IUpdateActivityRequest, 
  TypeUpdateStatus,
} from 'Data/interfaces/Activities/IUpdateActivityRequest';
import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { IUpdateListRequest } from 'Data/interfaces/Activities/IUpdateList';
import { ITopicCreateRequest } from 'Data/interfaces/Activities/ITopicCreateRequest';
import { IListActivities } from 'Data/interfaces/Activities/IListActivities';
import { ITopicCreateResponse } from 'Data/interfaces/Activities/ITopicCreateResponse';
import { IUpdateActivitieResponse } from 'Data/interfaces/Activities/IUpdateActivityResponse';
import { 
  IChangeStatus, 
  IChangeStatusError, 
  IInitialListUpdateRequest,
} from 'Data/interfaces/Activities/IChangeStatus';
import { ITopicSignalRHasUpdate } from 'Data/interfaces/Activities/ITopicSignalRHasUpdate';
import { IHasRemovedOptions } from 'Data/interfaces/Activities/IHasRemovedOptions';
import { ITopicRequest } from 'Data/interfaces/Activities/ITopicRequest';
import { FiltersRecordType, IUpdateFilter } from 'Data/interfaces/Activities/IFilters';
import { IFiltersRequest } from 'Data/interfaces/Activities/IFiltersRequest';
import { 
  IAttachmentError, 
  IAttachmentForUpload, 
  IAttachmentForUploadRequest, 
  IAttachmentUpdateProgress,
} from 'Data/interfaces/Activities/IAttachmentsForUpload';
import { CancelTokenSource } from 'axios';
import { IAttachmentsForUploadResponse } from 'Data/interfaces/Activities/IAttachmentsForUploadResponse';
import { 
  IDeleteAttachmentForCreate, 
  IDeleteAttachmentRequest, 
} from 'Data/interfaces/Activities/IAttachmentForDelete';

export enum ActivitiesActionKeys {
  LIST_ACTIVITIES_REQUEST = 'LIST_ACTIVITIES_REQUEST',
  LIST_ACTIVITIES_SUCCESS = 'LIST_ACTIVITIES_SUCCESS',
  LIST_ACTIVITIES_FAILURE = 'LIST_ACTIVITIES_FAILURE',

  LIST_ACTIVITIES_UPDATE_REQUEST = 'LIST_ACTIVITIES_UPDATE_REQUEST',
  LIST_ACTIVITIES_UPDATE_SUCCESS = 'LIST_ACTIVITIES_UPDATE_SUCCESS',
  LIST_ACTIVITIES_UPDATE_FAILURE = 'LIST_ACTIVITIES_UPDATE_FAILURE',

  CREATE_ACTIVITY_REQUEST = 'CREATE_ACTIVITY_REQUEST',
  CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS',
  CREATE_ACTIVITY_FAILURE = 'CREATE_ACTIVITY_FAILURE',
  
  CHANGE_ACTIVITY_STATUS_REQUEST = 'CHANGE_ACTIVITY_STATUS_REQUEST',
  CHANGE_ACTIVITY_STATUS_SUCCESS = 'CHANGE_ACTIVITY_STATUS_SUCCESS',
  CHANGE_ACTIVITY_STATUS_FAILURE = 'CHANGE_ACTIVITY_STATUS_FAILURE',

  UPDATE_ACTIVITY_REQUEST = 'UPDATE_ACTIVITY_REQUEST',
  UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS',
  UPDATE_ACTIVITY_FAILURE = 'UPDATE_ACTIVITY_FAILURE',

  DELETE_ACTIVITY_REQUEST = 'DELETE_ACTIVITY_REQUEST',
  DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS',
  DELETE_ACTIVITY_FAILURE = 'DELETE_ACTIVITY_FAILURE',

  INITIAL_CHANGE_ACTIVITY_STATUS = 'INITIAL_CHANGE_ACTIVITY_STATUS',
  
  EXTERNAL_GLOBAL_LOADING = 'EXTERNAL_GLOBAL_LOADING',
  
  CLEAR_GET_OLD = 'CLEAR_GET_OLD',

  CHANGE_UPDATE_STATUS = 'CHANGE_UPDATE_STATUS',

  TOPIC_SIGNALR_HAS_UPDATE = 'TOPIC_SIGNALR_HAS_UPDATE',

  HAS_REMOVED_OPTIONS = 'HAS_REMOVED_OPTIONS',

  POPULATED_ACTIVITY_FILTERS = 'POPULATED_ACTIVITY_FILTERS',
  ADD_ACTIVITY_FILTER = 'ADD_ACTIVITY_FILTER',
  UPDATE_ACTIVITY_FILTER = 'UPDATE_ACTIVITY_FILTER',
  DELETE_ACTIVITY_FILTER = 'DELETE_ACTIVITY_FILTER',
  CLEAR_ALL_ACTIVITY_FILTERS = 'CLEAR_ALL_ACTIVITY_FILTERS',

  SHARE_LINK_ACTIVITY_REQUEST = 'SHARE_LINK_ACTIVITY_REQUEST',
  SHARE_LINK_ACTIVITY_SUCCESS = 'SHARE_LINK_ACTIVITY_SUCCESS',
  SHARE_LINK_ACTIVITY_FAILURE = 'SHARE_LINK_ACTIVITY_FAILURE',
  SHARE_LINK_ACTIVITY_CLEAR = 'SHARE_LINK_ACTIVITY_CLEAR',
  
  ADD_ATTACHMENTS_FOR_UPLOAD = 'ADD_ATTACHMENTS_FOR_UPLOAD',
  REMOVE_ATTACHMENT_ACTIVITY = 'REMOVE_ATTACHMENT_ACTIVITY',

  UPLOAD_ATTACHMENT_REQUEST = 'UPLOAD_ATTACHMENT_REQUEST',
  UPLOAD_ATTACHMENT_SUCCESS = 'UPLOAD_ATTACHMENT_SUCCESS',
  UPLOAD_ATTACHMENT_FAILURE = 'UPLOAD_ATTACHMENT_FAILURE',
  UPLOAD_ATTACHMENT_CANCEL_ALL = 'UPLOAD_ATTACHMENT_CANCEL_ALL',
  UPDATE_UPLOAD_ATTACHMENT_PROGRESS = 'UPDATE_UPLOAD_ATTACHMENT_PROGRESS',
  ADD_UPLOAD_ATTACHMENT_CANCEL_TOKEN = 'ADD_UPLOAD_ATTACHMENT_CANCEL_TOKEN',
  CLEAR_UPLOAD_ATTACHMENT_CANCEL_TOKEN = 'CLEAR_UPLOAD_ATTACHMENT_CANCEL_TOKEN',
  NOT_SHOW_ATTACHMENT_CANCEL_ERROR = 'NOT_SHOW_ATTACHMENT_CANCEL_ERROR',
  
  DELETE_ATTACHMENT_FOR_CREATE_REQUEST = 'DELETE_ATTACHMENT_FOR_CREATE_REQUEST',
  DELETE_ATTACHMENT_FOR_CREATE_SUCCESS = 'DELETE_ATTACHMENT_FOR_CREATE_SUCCESS',
  DELETE_ATTACHMENT_FOR_CREATE_FAILURE = 'DELETE_ATTACHMENT_FOR_CREATE_FAILURE',
  
  DELETE_ATTACHMENT_REQUEST = 'DELETE_ATTACHMENT_REQUEST',
  DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS',
  DELETE_ATTACHMENT_FAILURE = 'DELETE_ATTACHMENT_FAILURE',
  
  ATTACHMENT_PREVIEW_ON = 'ATTACHMENT_PREVIEW_ON',
}

export const ActivitiesActions = {
  listActivities: (request: ITopicRequest): ListActivitiesAction =>  
    createAction(ActivitiesActionKeys.LIST_ACTIVITIES_REQUEST, request),
  listActivitiesSuccess: (ListActivitiesResponse: IListActivities): ListActivitiesSuccessAction =>
    createAction(ActivitiesActionKeys.LIST_ACTIVITIES_SUCCESS, ListActivitiesResponse),
  listActivitiesFailure: (err: string): ListActivitiesFailureAction =>
    createAction(ActivitiesActionKeys.LIST_ACTIVITIES_FAILURE, err),

  listActivitiesUpdate: (data: IUpdateListRequest): ListActivitiesUpdateAction =>
    createAction(ActivitiesActionKeys.LIST_ACTIVITIES_UPDATE_REQUEST, data),
  listActivitiesUpdateSuccess: (data: IUpdateListRequest): ListActivitiesUpdateSuccessAction =>
    createAction(ActivitiesActionKeys.LIST_ACTIVITIES_UPDATE_SUCCESS, data),
  listActivitiesUpdateFailure: (err: string): ListActivitiesUpdateFailureAction =>
    createAction(ActivitiesActionKeys.LIST_ACTIVITIES_UPDATE_FAILURE, err),

  createActivity: (createActivitieRequest: ITopicCreateRequest): CreateActivityAction =>  
    createAction(ActivitiesActionKeys.CREATE_ACTIVITY_REQUEST, createActivitieRequest),
  createActivitySuccess: (createActivitieResponse: ITopicCreateResponse): CreateActivitySuccessAction =>
    createAction(ActivitiesActionKeys.CREATE_ACTIVITY_SUCCESS, createActivitieResponse),
  createActivityFailure: (err: string): CreateActivityFailureAction =>
    createAction(ActivitiesActionKeys.CREATE_ACTIVITY_FAILURE, err),
    
  changeActivityStatus: (changeActivitieStatusRequest: IChangeStatus): ChangeActivityStatusAction =>  
    createAction(ActivitiesActionKeys.CHANGE_ACTIVITY_STATUS_REQUEST, changeActivitieStatusRequest),
  changeActivityStatusSuccess: (createActivitieResponse: boolean): ChangeActivityStatusSuccessAction =>
    createAction(ActivitiesActionKeys.CHANGE_ACTIVITY_STATUS_SUCCESS, createActivitieResponse),
  changeActivityStatusFailure: (err: IChangeStatusError): ChangeActivityStatusFailureAction =>
    createAction(ActivitiesActionKeys.CHANGE_ACTIVITY_STATUS_FAILURE, err),

  updateActivity: (updateActivitieRequest: IUpdateActivityRequest): UpdateActivityAction =>  
    createAction(ActivitiesActionKeys.UPDATE_ACTIVITY_REQUEST, updateActivitieRequest),
  updateActivitySuccess: (updateActivitieResponse: IUpdateActivitieResponse): UpdateActivitySuccessAction =>
    createAction(ActivitiesActionKeys.UPDATE_ACTIVITY_SUCCESS, updateActivitieResponse),
  updateActivityFailure: (err: string): UpdateActivityFailureAction =>
    createAction(ActivitiesActionKeys.UPDATE_ACTIVITY_FAILURE, err),

  deleteActivity: (activitieId: string): DeleteActivityAction =>  
    createAction(ActivitiesActionKeys.DELETE_ACTIVITY_REQUEST, activitieId),
  deleteActivitySuccess: (deletedActivitieId: string): DeleteActivitySuccessAction =>
    createAction(ActivitiesActionKeys.DELETE_ACTIVITY_SUCCESS, deletedActivitieId),
  deleteActivityFailure: (err: string): DeleteActivityFailureAction =>
    createAction(ActivitiesActionKeys.DELETE_ACTIVITY_FAILURE, err),

  initialChangeActivityStatus: (data: IInitialListUpdateRequest): InitialChangeActivityStatusAction =>
    createAction(ActivitiesActionKeys.INITIAL_CHANGE_ACTIVITY_STATUS, data),
  
  externalGlobalLoading: (loading: boolean): ExternalGlobalLoadingAction =>
    createAction(ActivitiesActionKeys.EXTERNAL_GLOBAL_LOADING, loading),
  
  clearGetOld: (): ClearGetOldAction =>
    createAction(ActivitiesActionKeys.CLEAR_GET_OLD),
  
  changeUpdateStatus: (value: TypeUpdateStatus): ChangeUpdateStatusAction =>
    createAction(ActivitiesActionKeys.CHANGE_UPDATE_STATUS, value),
  
  topicSignalRHasUpdate: (value: ITopicSignalRHasUpdate | null): TopicSignalRHasUpdateAction =>
    createAction(ActivitiesActionKeys.TOPIC_SIGNALR_HAS_UPDATE, value),
  
  hasRemovedOptions: (value: IHasRemovedOptions): HasRemovedOptionsAction =>
    createAction(ActivitiesActionKeys.HAS_REMOVED_OPTIONS, value),

  populatedActivityFilter: (filters: FiltersRecordType): PopulatedActivityFiltersAction =>  
    createAction(ActivitiesActionKeys.POPULATED_ACTIVITY_FILTERS, filters),
  addActivityFilter: (filterId: string): AddActivityFilterAction =>  
    createAction(ActivitiesActionKeys.ADD_ACTIVITY_FILTER, filterId),
  updateActivityFilter: (updateData: IUpdateFilter): UpdateActivityFilterAction =>
    createAction(ActivitiesActionKeys.UPDATE_ACTIVITY_FILTER, updateData),
  deleteActivityFilter: (filterId: string): DeleteActivityFilterAction =>
    createAction(ActivitiesActionKeys.DELETE_ACTIVITY_FILTER, filterId),
  clearAllActivityFilters: (): ClearAllActivityFiltersAction =>
    createAction(ActivitiesActionKeys.CLEAR_ALL_ACTIVITY_FILTERS),

  shareLinkActivity: (request: IFiltersRequest): ShareLinkActivityAction =>  
    createAction(ActivitiesActionKeys.SHARE_LINK_ACTIVITY_REQUEST, request),
  shareLinkActivitySuccess: (response: string): ShareLinkActivitySuccessAction =>
    createAction(ActivitiesActionKeys.SHARE_LINK_ACTIVITY_SUCCESS, response),
  shareLinkActivityFailure: (err: string): ShareLinkActivityFailureAction =>
    createAction(ActivitiesActionKeys.SHARE_LINK_ACTIVITY_FAILURE, err),
  shareLinkActivityClear: (): ShareLinkActivityClearAction =>
    createAction(ActivitiesActionKeys.SHARE_LINK_ACTIVITY_CLEAR),

  addAttachmentsForUpload: (attachments: IAttachmentForUploadRequest[]): AddAttachmentsForUploadAction =>
    createAction(ActivitiesActionKeys.ADD_ATTACHMENTS_FOR_UPLOAD, attachments),
  removeAttachmentActivity: (attachmentId: string | number): RemoveAttachmentActivityAction =>
    createAction(ActivitiesActionKeys.REMOVE_ATTACHMENT_ACTIVITY, attachmentId),

  uploadAttachment: (request: IAttachmentForUpload): UploadAttachmentAction =>  
    createAction(ActivitiesActionKeys.UPLOAD_ATTACHMENT_REQUEST, request),
  uploadAttachmentSuccess: (response: IAttachmentsForUploadResponse): UploadAttachmentSuccessAction =>
    createAction(ActivitiesActionKeys.UPLOAD_ATTACHMENT_SUCCESS, response),
  uploadAttachmentFailure: (err: IAttachmentError): UploadAttachmentFailureAction =>
    createAction(ActivitiesActionKeys.UPLOAD_ATTACHMENT_FAILURE, err),
  uploadAttachmentCancelAll: (): UploadAttachmentCancelAllAction =>
    createAction(ActivitiesActionKeys.UPLOAD_ATTACHMENT_CANCEL_ALL),
  updateUploadAttachmentProgress: (progress: IAttachmentUpdateProgress): UpdateUploadAttachmentProgressAction =>
    createAction(ActivitiesActionKeys.UPDATE_UPLOAD_ATTACHMENT_PROGRESS, progress),
  addUploadAttachmentCancelToken: (cancelToken: CancelTokenSource): AddUploadAttachmentCancelTokenAction =>
    createAction(ActivitiesActionKeys.ADD_UPLOAD_ATTACHMENT_CANCEL_TOKEN, cancelToken),
  clearUploadAttachmentCancelToken: (): ClearUploadAttachmentCancelTokenAction =>
    createAction(ActivitiesActionKeys.CLEAR_UPLOAD_ATTACHMENT_CANCEL_TOKEN),
  notShowAttachmentCancelError: (notShow: boolean): NotShowAttachmentCancelErrorAction =>
    createAction(ActivitiesActionKeys.NOT_SHOW_ATTACHMENT_CANCEL_ERROR, notShow),

  deleteAttachmentForCreate: (request: IDeleteAttachmentForCreate): DeleteAttachmentForCreateAction =>  
    createAction(ActivitiesActionKeys.DELETE_ATTACHMENT_FOR_CREATE_REQUEST, request),
  deleteAttachmentForCreateSuccess: (response: IDeleteAttachmentForCreate): DeleteAttachmentForCreateSuccessAction =>
    createAction(ActivitiesActionKeys.DELETE_ATTACHMENT_FOR_CREATE_SUCCESS, response),
  deleteAttachmentForCreateFailure: (err: string): DeleteAttachmentForCreateFailureAction =>
    createAction(ActivitiesActionKeys.DELETE_ATTACHMENT_FOR_CREATE_FAILURE, err),

  deleteAttachment: (request: IDeleteAttachmentRequest): DeleteAttachmentAction =>  
    createAction(ActivitiesActionKeys.DELETE_ATTACHMENT_REQUEST, request),
  deleteAttachmentSuccess: (response: IDeleteAttachmentRequest): DeleteAttachmentSuccessAction =>
    createAction(ActivitiesActionKeys.DELETE_ATTACHMENT_SUCCESS, response),
  deleteAttachmentFailure: (err: string): DeleteAttachmentFailureAction =>
    createAction(ActivitiesActionKeys.DELETE_ATTACHMENT_FAILURE, err),

  attachmentPreviewOn: (preview: boolean): AttachmentPreviewOnAction =>
    createAction(ActivitiesActionKeys.ATTACHMENT_PREVIEW_ON, preview),
};

export type ActivitiesActionUnion = ActionsUnion<typeof ActivitiesActions>;

export type ListActivitiesAction = Action<ActivitiesActionKeys.LIST_ACTIVITIES_REQUEST, ITopicRequest>;
export type ListActivitiesSuccessAction = Action<ActivitiesActionKeys.LIST_ACTIVITIES_SUCCESS, IListActivities>;
export type ListActivitiesFailureAction = Action<ActivitiesActionKeys.LIST_ACTIVITIES_FAILURE, string>;

export type ListActivitiesUpdateAction = Action<ActivitiesActionKeys.LIST_ACTIVITIES_UPDATE_REQUEST, IUpdateListRequest>;
export type ListActivitiesUpdateSuccessAction = Action<ActivitiesActionKeys.LIST_ACTIVITIES_UPDATE_SUCCESS, IUpdateListRequest>;
export type ListActivitiesUpdateFailureAction = Action<ActivitiesActionKeys.LIST_ACTIVITIES_UPDATE_FAILURE, string>;

export type CreateActivityAction = Action<ActivitiesActionKeys.CREATE_ACTIVITY_REQUEST, ITopicCreateRequest>;
export type CreateActivitySuccessAction = Action<ActivitiesActionKeys.CREATE_ACTIVITY_SUCCESS, ITopicCreateResponse>;
export type CreateActivityFailureAction = Action<ActivitiesActionKeys.CREATE_ACTIVITY_FAILURE, string>;

export type ChangeActivityStatusAction = Action<ActivitiesActionKeys.CHANGE_ACTIVITY_STATUS_REQUEST, IChangeStatus>;
export type ChangeActivityStatusSuccessAction = Action<ActivitiesActionKeys.CHANGE_ACTIVITY_STATUS_SUCCESS, boolean>;
export type ChangeActivityStatusFailureAction = Action<ActivitiesActionKeys.CHANGE_ACTIVITY_STATUS_FAILURE, IChangeStatusError>;

export type UpdateActivityAction = Action<ActivitiesActionKeys.UPDATE_ACTIVITY_REQUEST, IUpdateActivityRequest>;
export type UpdateActivitySuccessAction = Action<ActivitiesActionKeys.UPDATE_ACTIVITY_SUCCESS, IUpdateActivitieResponse>;
export type UpdateActivityFailureAction = Action<ActivitiesActionKeys.UPDATE_ACTIVITY_FAILURE, string>;

export type DeleteActivityAction = Action<ActivitiesActionKeys.DELETE_ACTIVITY_REQUEST, string>;
export type DeleteActivitySuccessAction = Action<ActivitiesActionKeys.DELETE_ACTIVITY_SUCCESS, string>;
export type DeleteActivityFailureAction = Action<ActivitiesActionKeys.DELETE_ACTIVITY_FAILURE, string>;

export type InitialChangeActivityStatusAction = Action<ActivitiesActionKeys.INITIAL_CHANGE_ACTIVITY_STATUS, IInitialListUpdateRequest>;

export type ExternalGlobalLoadingAction = Action<ActivitiesActionKeys.EXTERNAL_GLOBAL_LOADING, boolean>;

export type ClearGetOldAction = Action<ActivitiesActionKeys.CLEAR_GET_OLD>;

export type ChangeUpdateStatusAction = Action<ActivitiesActionKeys.CHANGE_UPDATE_STATUS, TypeUpdateStatus>;

export type TopicSignalRHasUpdateAction = Action<ActivitiesActionKeys.TOPIC_SIGNALR_HAS_UPDATE, ITopicSignalRHasUpdate | null>;

export type HasRemovedOptionsAction = Action<ActivitiesActionKeys.HAS_REMOVED_OPTIONS, IHasRemovedOptions>;

export type PopulatedActivityFiltersAction = Action<ActivitiesActionKeys.POPULATED_ACTIVITY_FILTERS, FiltersRecordType>;
export type AddActivityFilterAction = Action<ActivitiesActionKeys.ADD_ACTIVITY_FILTER, string>;
export type UpdateActivityFilterAction = Action<ActivitiesActionKeys.UPDATE_ACTIVITY_FILTER, IUpdateFilter>;
export type DeleteActivityFilterAction = Action<ActivitiesActionKeys.DELETE_ACTIVITY_FILTER, string>;
export type ClearAllActivityFiltersAction = Action<ActivitiesActionKeys.CLEAR_ALL_ACTIVITY_FILTERS>;

export type ShareLinkActivityAction = Action<ActivitiesActionKeys.SHARE_LINK_ACTIVITY_REQUEST, IFiltersRequest>;
export type ShareLinkActivitySuccessAction = Action<ActivitiesActionKeys.SHARE_LINK_ACTIVITY_SUCCESS, string>;
export type ShareLinkActivityFailureAction = Action<ActivitiesActionKeys.SHARE_LINK_ACTIVITY_FAILURE, string>;
export type ShareLinkActivityClearAction = Action<ActivitiesActionKeys.SHARE_LINK_ACTIVITY_CLEAR>;

export type AddAttachmentsForUploadAction = Action<ActivitiesActionKeys.ADD_ATTACHMENTS_FOR_UPLOAD, IAttachmentForUploadRequest[]>;
export type RemoveAttachmentActivityAction = Action<ActivitiesActionKeys.REMOVE_ATTACHMENT_ACTIVITY, string | number>;

export type UploadAttachmentAction = Action<ActivitiesActionKeys.UPLOAD_ATTACHMENT_REQUEST, IAttachmentForUpload>;
export type UploadAttachmentSuccessAction = Action<ActivitiesActionKeys.UPLOAD_ATTACHMENT_SUCCESS, IAttachmentsForUploadResponse>;
export type UploadAttachmentFailureAction = Action<ActivitiesActionKeys.UPLOAD_ATTACHMENT_FAILURE, IAttachmentError>;
export type UploadAttachmentCancelAllAction = Action<ActivitiesActionKeys.UPLOAD_ATTACHMENT_CANCEL_ALL>;
export type UpdateUploadAttachmentProgressAction = Action<ActivitiesActionKeys.UPDATE_UPLOAD_ATTACHMENT_PROGRESS, IAttachmentUpdateProgress>;
export type AddUploadAttachmentCancelTokenAction = Action<ActivitiesActionKeys.ADD_UPLOAD_ATTACHMENT_CANCEL_TOKEN, CancelTokenSource>;
export type ClearUploadAttachmentCancelTokenAction = Action<ActivitiesActionKeys.CLEAR_UPLOAD_ATTACHMENT_CANCEL_TOKEN>;
export type NotShowAttachmentCancelErrorAction = Action<ActivitiesActionKeys.NOT_SHOW_ATTACHMENT_CANCEL_ERROR, boolean>;

export type DeleteAttachmentForCreateAction = Action<ActivitiesActionKeys.DELETE_ATTACHMENT_FOR_CREATE_REQUEST, IDeleteAttachmentForCreate>;
export type DeleteAttachmentForCreateSuccessAction = Action<ActivitiesActionKeys.DELETE_ATTACHMENT_FOR_CREATE_SUCCESS, IDeleteAttachmentForCreate>;
export type DeleteAttachmentForCreateFailureAction = Action<ActivitiesActionKeys.DELETE_ATTACHMENT_FOR_CREATE_FAILURE, string>;

export type DeleteAttachmentAction = Action<ActivitiesActionKeys.DELETE_ATTACHMENT_REQUEST, IDeleteAttachmentRequest>;
export type DeleteAttachmentSuccessAction = Action<ActivitiesActionKeys.DELETE_ATTACHMENT_SUCCESS, IDeleteAttachmentRequest>;
export type DeleteAttachmentFailureAction = Action<ActivitiesActionKeys.DELETE_ATTACHMENT_FAILURE, string>;

export type AttachmentPreviewOnAction = Action<ActivitiesActionKeys.ATTACHMENT_PREVIEW_ON, boolean>;
