import { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { HolderOutlined } from "@ant-design/icons";
import Input from "Components/UI/Input";
import { Icon } from "Components/UI/Icon";
import Select from "Components/UI/Select";
import Button from "Components/UI/Button";
import { SelectValue } from "antd/es/select";
import { INomenclatureFieldRow } from "../..";
import { DragButton, FieldRow } from "./styles";
import { UniqueIdentifier } from "@dnd-kit/core";

interface ISortableItem {
  nomenclatureField: INomenclatureFieldRow;
  nomenclatureFields: INomenclatureFieldRow[];
  isDisabled?: boolean;
  index: number;
  isDragDisabled?: boolean;
  activeId?: UniqueIdentifier | null;
  fieldChange: (index: number, selectValue: SelectValue, customName?: string) => void;
  separatorChange: (index: number, selectValue: SelectValue) => void;
  deleteRow: (index: number) => void;
}

const SortableItem: FC<ISortableItem> = ({
  nomenclatureField,
  nomenclatureFields,
  isDisabled,
  index,
  isDragDisabled,
  activeId,
  fieldChange,
  separatorChange,
  deleteRow,
}) => {
  const {
    attributes,
    listeners,
    transition,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    isDragging,
  } = useSortable({
    id: nomenclatureField.id,
  });

  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition
  };

  return (
    <FieldRow
      key={`Nomenclatura-${nomenclatureField.id}`}
      ref={setNodeRef}
      style={style}
      className="fieldRow"
    >
      {!isDragDisabled && (
        <DragButton
          isgrabbing={`${nomenclatureField.id === activeId}`}
          {...attributes}
          {...listeners}
          ref={setActivatorNodeRef}
        >
          <HolderOutlined rev="" className="iconDrag" />
        </DragButton>
      )}
      {
        nomenclatureField.fieldSelected === 29 ?
          (
            <div
              className="customName"
            >
              <Input
                label={'Campo personalizado'}
                autoFocus={true}
                customHeight={40}
                onInput={(value) => fieldChange(index, 29, value)}
                value={nomenclatureField.fieldCustomName}
                className="field"
                disabled={isDisabled}
              />
              <Icon
                icon='cancelar'
                customSize={8}
                onClick={() => fieldChange(index, 0)}
                color='cinzaPadrao'
                disabled={isDisabled}
              />
            </div>
          ) : (
            <Select
              notFoundContent={null}
              options={nomenclatureField.fields}
              value={nomenclatureField.fieldSelected || 1}
              showSearch={nomenclatureField.fieldSelected === 29}
              size='large'
              className="field"
              onChange={(selectValue: SelectValue) => {
                fieldChange(index, selectValue);
              }}
              disabled={isDisabled}
            />
          )
      }
      {
        (nomenclatureFields.length - 1) === index
          ? ''
          : <Select
            options={nomenclatureField.separators}
            fieldNames={{ label: 'description' }}
            size='large'
            value={nomenclatureField.separatorSelected}
            className="separator"
            onChange={(selectValue: SelectValue) => {
              separatorChange(index, selectValue);
            }}
            disabled={isDisabled}
          />
      }
      {
        nomenclatureFields.length > 1
          ? (
            <Button
              type="text"
              className="deleteButton"
              onClick={() => deleteRow(index)}
              disabled={isDisabled}
              icon={
                <Icon
                  icon='excluir'
                  className="deleteIcon"
                  customSize={16}
                  disabled={isDisabled}
                />
              }
            />
          )
          : ''
      }
    </FieldRow>
  );
}

export default SortableItem;
