import React, { FC, useEffect, useState } from 'react';

import styles from './ListagemArquivos.module.scss';
import { useDispatch, connect, ConnectedProps, useSelector } from 'react-redux';
import { ListagemArquivosActions } from '../../Store/ListagemArquivos/ListagemArquivos.actions';
import { ObraActions } from '../../Store/Obra/Obra.actions';
import { IGlobalReducerState } from '../../Store/Base/interface/IGlobalReducerState';
import {
  IRequestPlotColumns,
  getColumnsRequestPlotTable
} from './ListagemArquivos.columns';

import Form from '../../Components/UI/Form/Form';
import Table from '../../Components/UI/Table';
import Button from '../../Components/UI/Button';
import { getButtonsPlotRequestTableToolbar } from './ListagemArquivos.helpers';
import PageHeader from '../../Components/UI/PageHeader/PageHeader';
import TableActionTopbar from '../../Components/UI/TableActionTopbar';
import schema, { createSchema } from './SolicitacaoPlotagem.schema';
import BreadCrumb, { IBreadcrumbItem } from '../../Components/UI/BreadCrumb';
import ListContainer from '../../Components/UI/Containers/ListContainer/ListContainer';
import { Row, Col, Checkbox, Radio, Select } from 'antd';
import Field from '../../Components/UI/Field';
import TextArea from '../../Components/UI/Textarea';
import { PlotagemActions } from '../../Store/Plotagem/Plotagem.actions';
import toastHandler from '../../Utils/toastHandler';

import {
  IPlotRequestRequest,
  IFilePlotRequest,
  QRCodePositionEnum
} from '../../Data/interfaces/Plotagem/IPlotRequestRequest';
import { IUpdateDisciplineStatusRequest } from 'Data/interfaces/Obra/IUpdateDisciplineStatusRequest';
import { Http } from 'Utils/Http';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';

interface ISolicitacaoPlotagem {
  selectedPlotRequestRowKeys: number[];
  requestPlotListFiles: IRequestPlotColumns[];
  breadcrumbs: IBreadcrumbItem[];
}

const SolicitacaoPlotagem: FC<Props> = ({
  isLoading,
  defaultPlotterEmail,
  selectedPlotRequestRowKeys,
  requestPlotListFiles,
  breadcrumbs,
  Obra,
  userInfo,
}) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const [totalCopies, setTotalCopies] = useState(0);
  const [totalCopiesPB, setTotalCopiesPB] = useState(0);
  const [totalCopiesColor, setTotalCopiesColor] = useState(0);
  const [fileList, setFileList] = useState(requestPlotListFiles);
  const [showPlotRequestInfo, setShowPlotRequestInfo] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState(false);
  const [addressConstruction, setAddressConstruction] = useState(true);
  const [tituloPage, setTituloPage] = useState(
    'Solicitação de plotagem - Arquivos'
  );
  const [enderecoPadrao, setEnderecoPadrao] = useState('');
  const [addressConstructionIsNull, setAddressConstructionIsNull] = useState(false);
  const [QRCodeIdPosition, setQRCodeIdPosition] = useState<QRCodePositionEnum>(1);
  const disciplineId = Number(Http.GetQueryParams('ConstructionSiteDisciplinesId'));
  const disciplineName = Http.GetQueryParams('DisciplineName');
  const [emails, setEmails] = useState('');
  const [validCange, setValidCange] = useState(false);
  const childrenEmails: [] = [];
  const defaultEmails: [] = (defaultPlotterEmail?.split(',') || []) as never;

  const ConstructionSiteDisciplineFolderId = breadcrumbs[2].href
    .split('/')[4]
    .replace(/(\r\n|\n|\r)/gm, '')
    .split('?')[0];
  const ConstructionSiteFk = Number(breadcrumbs[2].href.split('/')[3]);

  const deleteRow = (index: number) => {
    if (fileList.length === 1) {
      dispatch(ListagemArquivosActions.setShowPlotRequestTable(false));
    }
    fileList.splice(index, 1);
    setFileList([...fileList]);
  };

  const onChangePlotRequest = (selecteds: any) => {
    dispatch(ListagemArquivosActions.setSelectedPlotRequestRowKeys(selecteds));
  };

  const rowSelectionPlotRequest = {
    selectedRowKeys: selectedPlotRequestRowKeys,
    onChange: onChangePlotRequest
  };

  const openPlotRequestInfo = (values: any, erros: any) => {
    setShowPlotRequestInfo(true);
    let countPB = 0;
    let countColor = 0;
    fileList.forEach((file) => {
      if (file.HasColor) {
        countColor += Number(file.QtyCopies);
      } else {
        countPB += Number(file.QtyCopies);
      }
    });
    setTotalCopiesColor(countColor);
    setTotalCopiesPB(countPB);
  };

  const requestFiles: IFilePlotRequest[] = [];
  fileList.forEach((file) => {
    requestFiles.push({
      FileApiId: file.FileApiId,
      FileInfoFk: file.FileInfoFk,
      QtyCopies: file.QtyCopies,
      HasColor: file.HasColor,
      ConstructionSiteDisciplineFolderId: ConstructionSiteDisciplineFolderId,
      Name: file.Name,
      ShowQRCode: file.ShowQRCode,
    } as IFilePlotRequest);
  });

  const solicitaPlotagem = (values: any, errors?: string[]) => {
    if (addressConstructionIsNull === true && addressConstruction === true) {
      return toastHandler.showError('O Endereço padrão da obra está vazio');
    }
    let mails = '';
    if (!validCange) {
      mails = defaultEmails.length > 1 ? defaultEmails.join(',') : String(defaultEmails);

      const requestPlotting: IPlotRequestRequest = {
        Description: values.descSolicitacao,
        PlotterResponsibleName: values.entregarPara,
        UseConstructionAddress: addressConstruction,
        Address: enderecoPadrao,
        PlotterEmail: mails,
        ConstructionSiteFk: ConstructionSiteFk,
        isDefaultPlotterEmail: defaultEmail,
        Observation: values.observacao || '',
        Files: requestFiles,
        QRCodePosition: QRCodeIdPosition,
        disciplineIdTracking: disciplineId,
        userInfoTracking: userInfo,
        currentTenantTracking: currentTenant,
      };

      if (errors) {
        errors.forEach((error) => {
          toastHandler.handler({ description: error, icon: 'error', title: '' });
        });
        return false;
      } else {
        dispatch(PlotagemActions.plotRequest(requestPlotting));
      }
    } else {
      const requestPlotting: IPlotRequestRequest = {
        Description: values.descSolicitacao,
        PlotterResponsibleName: values.entregarPara,
        UseConstructionAddress: addressConstruction,
        Address: enderecoPadrao,
        PlotterEmail: emails,
        ConstructionSiteFk: ConstructionSiteFk,
        isDefaultPlotterEmail: defaultEmail,
        Observation: values.observacao || '',
        Files: requestFiles,
        QRCodePosition: QRCodeIdPosition,
        disciplineIdTracking: disciplineId,
        userInfoTracking: userInfo,
        currentTenantTracking: currentTenant,
      };

      if (errors) {
        errors.forEach((error) => {
          toastHandler.handler({ description: error, icon: 'error', title: '' });
        });
        return false;
      } else {
        dispatch(PlotagemActions.plotRequest(requestPlotting));
      }
    }
  };

  const onChangeAddress = (e: any) => {
    setAddressConstruction(e.target.value);
    setEnderecoPadrao('');
  };

  const onChangeQtyValue = (value: number, index: number) => {
    fileList[index].QtyCopies = Number(value);
    
    setFileList([...fileList]);

    const total = fileList.reduce((acc, cur) => acc + (cur.QtyCopies || 0), 0);
    setTotalCopies(Number(total));
  };

  const onChangeHasColor = (e: any) => {
    const optionPosition = Number(e.target.name.replace(/\D/g, ''));
    const check = e.target.checked;
    fileList[optionPosition].HasColor = check || false;
    setFileList([...fileList]);
  };

  const onChangeAllHasColor = (e: any) => {
    const filesGroup = [...fileList];
    filesGroup.forEach((fileGroup) => {
      fileGroup.HasColor = e.target.checked ? true : false;
    });
    setFileList(filesGroup);
  };

  const onChangeShowQRCode = (e: any) => {
    const optionPosition = Number(e.target.name.replace(/\D/g, ''));
    const check = e.target.checked;
    fileList[optionPosition].ShowQRCode = check || false;
    setFileList([...fileList]);
  };

  const onChangeAllShowQRCode = (e: any) => {
    const filesGroup = [...fileList];
    filesGroup.forEach((fileGroup) => {
      if (fileGroup?.Extension?.toLowerCase() === 'pdf') {
        fileGroup.ShowQRCode = e.target.checked ? true : false;
      } else {
        fileGroup.ShowQRCode = false;
      }
    });
    setFileList(filesGroup);
  };

  const breadcrumbsSolicitacao = [
    ...breadcrumbs,
    {
      href: '',
      description: 'Solicitação de plotagem'
    }
  ];

  const onChangeDefaultEmail = (e: any) => {
    setDefaultEmail(e.target.checked);
  };

  const onChangeStep = (step: number) => {
    if (step === 1) {
      setTituloPage('Solicitação de plotagem - Arquivos');
      setShowPlotRequestInfo(false);
    } else if (step === 2) {
      setTituloPage('Solicitação de plotagem - Informações');
    }
  };
  const handleChange = (value: []) => {
    setValidCange(true);
    let listaEmails = '';
    listaEmails = value.length > 1 ? value.join(',') : String(value);
    setEmails(listaEmails);
  };

  const onFocus = () => {
    document
      .getElementsByClassName('ant-select-dropdown')[0]
      .setAttribute('hidden', 'true');
  };

  useEffect(() => {
    const queryString = window.location.pathname;
    const currentWorkId = queryString.split('/')[3];
    const request: IUpdateDisciplineStatusRequest = {
      csId: parseInt(currentWorkId)
    }
    dispatch(ObraActions.getObra(request));
  }, []);

  useEffect(() => {
    if (Obra.Address === null || Obra.Address === undefined) {
      setAddressConstructionIsNull(true);
    } else {
      setAddressConstructionIsNull(false);
    };

    if (Obra.LastPlottingQRCodePosition) {
      setQRCodeIdPosition(Obra.LastPlottingQRCodePosition);
    }
  }, [Obra]);

  const handleActiveQRPositionId = (id: QRCodePositionEnum) => {
    setQRCodeIdPosition(id);
  };

  return (
    <PageHeader name={tituloPage}>
      <BreadCrumb breadcrumbs={breadcrumbsSolicitacao} />
      {showPlotRequestInfo ? (
        <div className={styles['tableContainer']}>
          <span className={styles['subTitle']}>INFORMAÇÕES SOBRE A SOLICITAÇÃO</span>
          <Form onSubmit={solicitaPlotagem} schema={schema}>
            <ListContainer height={'auto'} className={styles['boxSolicitaPlot']}>
              <div className={styles['rowBorder']}>
                <Row gutter={[8, 8]} className={styles['row']}>
                  <Col span={16}>
                    <Field
                      name="descSolicitacao"
                      label="Descreva aqui a solicitação"
                      className={styles['inputs']}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      name="entregarPara"
                      label="Entregar aos cuidados de..."
                      className={styles['inputs']}
                    />
                  </Col>
                </Row>
              </div>
              <div className={styles['rowCopias']}>
                <Row justify="start">
                  <Col span={4} className={styles['titleCopias']}>
                    {' '}
                    Cópias P&B
                  </Col>
                  <Col span={4} className={styles['titleCopias']}>
                    {' '}
                    Cópias Coloridas
                  </Col>
                  <Col span={4} className={styles['titleCopias']}>
                    {' '}
                    Total de Cópias
                  </Col>
                </Row>
                <Row justify="start">
                  <Col span={4} className={styles['qtCopias']}>
                    {' '}
                    {totalCopiesPB}
                  </Col>
                  <Col span={4} className={styles['qtCopias']}>
                    {' '}
                    {totalCopiesColor}
                  </Col>
                  <Col span={4} className={styles['qtCopias']}>
                    {' '}
                    {totalCopies}
                  </Col>
                </Row>
              </div>
              <div className={styles['rowEndereco']}>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Radio.Group
                      onChange={onChangeAddress}
                      name="setChangeAddress"
                      value={addressConstruction}
                    >
                      <Radio className={styles['radioAddress']} value={true}>
                        <div className={styles['addresOption']}>
                          Utilizar endereço cadastrado de cada obra
                        </div>
                      </Radio>
                      <br></br>
                      <Radio className={styles['radioAddress']} value={false}>
                        <div className={styles['addresOption']}>
                          Entregar todas as plotagens em um único endereço
                        </div>
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row gutter={[8, 8]} style={{ marginTop: 12 }}>
                  <Col span={24}>
                    <label
                      className={
                        Obra.Address && addressConstruction === true
                          ? `${styles['labelInputAddress']} ${'inputs' ?? ''} `
                          :  addressConstruction === false ? `${styles['labelInputAddress']} ${'inputs' ?? ''} `
                          : `${styles['labelInputAddressError']} ${'inputs' ?? ''} `
                      }
                    >
                      <input
                        disabled={addressConstruction}
                        placeholder={
                          Obra.Address && addressConstruction
                            ? Obra.Address 
                            :  addressConstruction === false ? 'Informe um endereço completo'
                            : 'O Endereço padrão da obra está vazio'
                            
                            
                        }
                        onChange={(e) => setEnderecoPadrao(e.target.value)}
                        value={enderecoPadrao}
                        name="enderecoCompleto"
                        required={!addressConstruction}
                      />
                      <span className={styles['label']}>
                        Informe um endereço completo
                      </span>
                    </label>
                  </Col>
                </Row>
              </div>
              <div className={styles['rowDefaultEmail']}>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Select
                      mode="tags"
                      className={styles['inputEmails']}
                      style={{ width: '100%' }}
                      onFocus={onFocus}
                      onChange={handleChange}
                      tokenSeparators={[',']}
                      defaultValue={defaultEmails}
                    >
                      {childrenEmails}
                    </Select>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={24} className={styles['defaultEmail']}>
                    <Checkbox onChange={onChangeDefaultEmail}>
                      Salvar e-mail padrão
                    </Checkbox>
                  </Col>
                </Row>
              </div>
              <Row gutter={[8, 8]} className={styles['row']}>
                <Col span={24}>
                  <TextArea
                    name="observacao"
                    label="Observações"
                    placeholder="Observações"
                    style={{ height: 91 }}
                  />
                </Col>
              </Row>
            </ListContainer>
            <PageHeader name="" style={{ marginTop: 0 }}>
              <div className={styles['footerPlotRequest']}>
                <div className={styles['qtyCopies']}>
                  <Button height={37} width={145} onClick={() => onChangeStep(1)}>
                    Ver arquivos
                  </Button>
                </div>
                <div className={styles['requestPlotButtonsWrap']}>
                  <Button
                    onClick={() =>
                      dispatch(ListagemArquivosActions.setShowPlotRequestTable(false))
                    }
                  >
                    Cancelar
                  </Button>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    Enviar
                  </Button>
                </div>
              </div>
            </PageHeader>
          </Form>
        </div>
      ) : (
        <div className={styles['tableContainer']}>
          <TableActionTopbar
            buttons={getButtonsPlotRequestTableToolbar(
              selectedPlotRequestRowKeys,
              deleteRow,
              dispatch,
              QRCodeIdPosition,
              handleActiveQRPositionId,
            )}
            totalSelectedElements={selectedPlotRequestRowKeys.length}
          />
          <Form onSubmit={openPlotRequestInfo} schema={createSchema(fileList.length)}>
            <Table
              className={styles['requestPlotTable']}
              dataSource={fileList}
              columns={getColumnsRequestPlotTable({
                fileList,
                dispatch,
                deleteRow,
                onChangeQtyValue,
                onChangeHasColor,
                onChangeAllHasColor,
                onChangeShowQRCode,
                onChangeAllShowQRCode,
              })}
              rowSelection={rowSelectionPlotRequest}
            />
            <PageHeader name="">
              <div className={styles['footerPlotRequest']}>
                <div className={styles['qtyCopies']}>{totalCopies} CÓPIAS</div>
                <div className={styles['requestPlotButtonsWrap']}>
                  <Button
                    onClick={() =>
                      dispatch(ListagemArquivosActions.setShowPlotRequestTable(false))
                    }
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    onClick={() => onChangeStep(2)}
                  >
                    Prosseguir
                  </Button>
                </div>
              </div>
            </PageHeader>
          </Form>
        </div>
      )}
    </PageHeader>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.listagemArquivos,
  ...state.plotagem,
  isLoading: state.plotagem.isLoading,
  Obra: state.obra.Obra,
  defaultPlotterEmail: state.obra.Obra.DefaultPlotterEmail,
  nomenclature: state.nomenclatura.NomenclatureData,
  userInfo: state.auth.userInfo,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ISolicitacaoPlotagem;

export default connector(SolicitacaoPlotagem);
