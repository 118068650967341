import { INomenclaturaState } from './interfaces/INomenclaturaState';
import { NomenclaturaActionUnion, NomenclaturaActionKeys } from './Nomenclatura.actions';
import { INomenclatureData, IExtensionData, IVersionField } from '../../Data/interfaces/Nomenclatura/INomenclatureData';

const initialState: INomenclaturaState = {
  NomenclatureData: {} as INomenclatureData,
  error: undefined,
  message: undefined,
  isLoading: false,
  ExtensionsData: [] as IExtensionData[]
};

const obraReducer = (state = initialState, action: NomenclaturaActionUnion): INomenclaturaState => {
  switch (action.type) {
    case NomenclaturaActionKeys.CREATE_NOMENCLATURA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        message: undefined,
      };
    case NomenclaturaActionKeys.CREATE_NOMENCLATURA_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case NomenclaturaActionKeys.CREATE_NOMENCLATURA_SUCCESS:
      return {
        ...state,
        NomenclatureData: action.payload,
        isLoading: false,
        message: 'sucesso',
      };

    case NomenclaturaActionKeys.GET_NOMENCLATURA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        message: undefined,
      };
    case NomenclaturaActionKeys.GET_NOMENCLATURA_SUCCESS:

      const versionFields = action.payload.VersionFields?.sort((a: IVersionField, b: IVersionField) => a.Ordem - b.Ordem);
      versionFields?.forEach((field, index) => field.Ordem = index);

      const nomenclatureData = action.payload;
      nomenclatureData.VersionFields = versionFields;

      return {
        ...state,
        NomenclatureData: action.payload,
        isLoading: false,
      };
    case NomenclaturaActionKeys.GET_NOMENCLATURA_FAILED:
      return {
        ...state,
        isLoading: false,
        NomenclatureData: { HasRevisionControl: true, VersionFields: [] },
        error: action.payload,
      };

    case NomenclaturaActionKeys.REMOVE_MESSAGES_NOMENCLATURA:
      return {
        ...state,
        error: undefined,
        message: undefined,
      };

    case NomenclaturaActionKeys.GET_CAMPO_VERSAO_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        message: undefined,
      };
    case NomenclaturaActionKeys.GET_CAMPO_VERSAO_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case NomenclaturaActionKeys.GET_CAMPO_VERSAO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        CampoVersao: action.payload,
      };
    case NomenclaturaActionKeys.GET_EXTENSOES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        message: undefined,
      };
    case NomenclaturaActionKeys.GET_EXTENSOES_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case NomenclaturaActionKeys.GET_EXTENSOES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ExtensionsData: action.payload
      };
    case NomenclaturaActionKeys.SET_EXTENSOES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        message: undefined,
      };
    case NomenclaturaActionKeys.SET_EXTENSOES_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case NomenclaturaActionKeys.SET_EXTENSOES_SUCCESS:
      return {
        ...state,
        ExtensionsData: action.payload,
        isLoading: false,
        message: 'sucesso',
      };

    default:
      return state;
  }
};

export default obraReducer;
